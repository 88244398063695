import { DataStore } from 'aws-amplify';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../../data/AmplifyDB';
import { createParentMedication } from '../../../../../data/functions/MedicationDB';
import {
  ProtocolJSON,
  createProtocol,
} from '../../../../../data/functions/ProtocolDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import InfusionItem from '../../../../../data/model/InfusionItem';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem, {
  cloneProtocol,
  cloneProtocolJSON,
} from '../../../../../data/model/ProtocolItem';
import {
  Concentration,
  Drip,
  Electrical,
  ElectricalShock,
  Medication,
  MedicationProtocol,
  ProgressStatus,
} from '../../../../../models';
import { globals, upgradeVersion } from '../../../../_global/common/Utils';

export const performAudit = async (
  department: DepartmentItem,
  db: DatabaseResponse,
  isUpdate: boolean,
  flagDuplicates: boolean = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      //Perform audit by confirming all the pairings
      let updates: any[] = [];

      for (let i = 0; i < db.protocols.length; i++) {
        let protocol: ProtocolItem = db.protocols[i];
        let protocolID =
          protocol.status === ProgressStatus.ACTIVE ||
          (protocol.status === ProgressStatus.DRAFT &&
            protocol.activeID == null)
            ? protocol.uid
            : protocol.activeID;

        let confirmedMedIDs = [];
        let confirmedInfusIDs = [];
        let confirmedElecIDs = [];

        /* Audit all the medications for this protocol */
        let medIDs = protocol.model.medicationIDs
          ? protocol.model.medicationIDs
          : [];
        for (let j = 0; j < db.medications.length; j++) {
          let med: MedicationItem = db.medications[j];
          let dbMed: Medication = med.model as Medication;
          let medID =
            med.status === ProgressStatus.ACTIVE ||
            (med.status === ProgressStatus.DRAFT && med.activeID == null)
              ? med.uid
              : med.activeID;

          if (!medID) continue;

          let medProtIDs = dbMed.protocolOptions.map((p) => p.protocolID);
          /* Check to see if this protocol needs to add this medication */
          for (let k = 0; k < medProtIDs.length; k++) {
            if (medProtIDs[k] === protocolID) {
              confirmedMedIDs.push(medID);
              if (!medIDs.includes(medID)) {
                updates.push({
                  model: 'Medication',
                  id: medID,
                  description: 'Added ' + med.name + ' ID to ' + protocol.name,
                  department: department.id,
                  departmentName: department.name,
                });
              }
            }
          }
        }

        /* Audit all the infusions for this protocol */
        let dripIDs = protocol.model.dripIDs ? protocol.model.dripIDs : [];
        for (let j = 0; j < db.infusions.length; j++) {
          let infus: InfusionItem = db.infusions[j];
          let dbDrip: Drip = infus.model as Drip;
          let dripID =
            infus.status === ProgressStatus.ACTIVE ||
            (infus.status === ProgressStatus.DRAFT && infus.activeID == null)
              ? infus.uid
              : infus.activeID;

          if (!dripID) continue;

          let dripProtIDs = dbDrip.dripOptions.map((p) => p.protocolID);
          /* Check to see if this protocol needs to add this medication */
          for (let k = 0; k < dripProtIDs.length; k++) {
            if (dripProtIDs[k] === protocolID) {
              confirmedInfusIDs.push(dripID);
              if (!dripIDs.includes(dripID)) {
                updates.push({
                  model: 'Infusion',
                  id: dripID,
                  description:
                    'Added ' + infus.name + ' ID to ' + protocol.name,
                  department: department.id,
                  departmentName: department.name,
                });
              }
            }
          }
        }

        /* Audit all the electricals for this protocol */
        let elecIDs = protocol.model.electricalIDs
          ? protocol.model.electricalIDs
          : [];
        for (let j = 0; j < db.electrical.length; j++) {
          let elec: ElectricalItem = db.electrical[j];
          let dbElec: ElectricalShock = elec.model;
          let elecID =
            elec.status === ProgressStatus.ACTIVE ||
            (elec.status === ProgressStatus.DRAFT && elec.activeID == null)
              ? elec.uid
              : elec.activeID;

          if (!elecID) continue;

          let elecProtIDs = dbElec.options.map((p) => p.protocolID);

          /* Check to see if this protocol needs to add this medication */
          for (let k = 0; k < elecProtIDs.length; k++) {
            if (elecProtIDs[k] === protocolID) {
              confirmedElecIDs.push(elecID);
              if (!elecIDs.includes(elecID)) {
                updates.push({
                  model: 'Electrical',
                  id: elecID,
                  description: 'Added ' + elec.name + ' ID to ' + protocol.name,
                  department: department.id,
                  departmentName: department.name,
                });
              }
            }
          }
        }

        /* Check to see if the the confirmed medicationIDs is different from the protocol model */
        let update = false;
        if (!checkIDsMatch(confirmedMedIDs, medIDs)) update = true;
        if (!checkIDsMatch(confirmedInfusIDs, dripIDs)) update = true;
        if (!checkIDsMatch(confirmedElecIDs, elecIDs)) update = true;

        if (update) {
          let newProt = cloneProtocolJSON(protocol);
          newProt.medications = confirmedMedIDs;
          newProt.infusions = confirmedInfusIDs;
          newProt.electrical = confirmedElecIDs;
          newProt.status = ProgressStatus.DRAFT;
          newProt.activeID = protocolID;
          if (protocol.status === ProgressStatus.ACTIVE)
            newProt.version = upgradeVersion(protocol.version);
          if (isUpdate) {
            let response = await createProtocol(newProt, protocol);
            if (response.type === ResponseType.Success) {
              if (globals.debug)
                console.log(
                  'Updated protocol: ' + protocol.name,
                  response.data
                );
              updates.push({
                model: 'Protocol',
                id: protocolID,
                description:
                  'Updated ' + protocol.name + ' with new found ID pairings',
                department: department.id,
                departmentName: department.name,
              });
            }
          } else {
            updates.push({
              model: 'Protocol',
              id: protocolID,
              description:
                'Updated ' + protocol.name + ' with new found ID pairings',
              department: department.id,
              departmentName: department.name,
            });
          }
        }
      }

      let concentrations = await DataStore.query(Concentration, (c) =>
        c.departmentID.eq(department.id)
      );
      /* Check if any concentrations are being duplicated to the same medication */
      let concens: Concentration[] = [];
      let duplicates: Concentration[] = [];
      for (let i = 0; i < concentrations.length; i++) {
        let concen: Concentration = concentrations[i];
        if (
          concens.find(
            (c) =>
              c.dripID === concen.dripID &&
              c.medicationID === concen.medicationID &&
              c.firstAmnt === concen.firstAmnt &&
              c.firstUnit === concen.firstUnit &&
              c.secAmnt === concen.secAmnt &&
              c.secUnit === concen.secUnit
          )
        ) {
          duplicates.push(concen);
          updates.push({
            model: 'Concentration',
            id: 'duplicates',
            description: 'Found duplicate concentrations',
            department: department.id,
            departmentName: department.name,
          });
        } else {
          concens.push(concen);
        }
      }

      console.log('CONCENTRATION DUPLICATES:', duplicates);
      if (isUpdate) {
        let promises = duplicates.map(async (concen) => {
          let response = await DataStore.delete(Concentration, concen.id);
        });
        await Promise.all(promises);
      }
      resolve({
        type: ResponseType.Success,
        data: updates,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const performDuplicateCheck = async (
  department: DepartmentItem,
  db: DatabaseResponse
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let updates: any[] = [];
      // for(let i = 0; i < db.medications.length; i++) {
      //   let
      // }

      resolve({
        type: ResponseType.Success,
        data: updates,
      });
    } catch (error) {
      reject(error);
    }
  });
};

function checkIDsMatch(ids1: string[], ids2: string[]): boolean {
  if (ids1.length !== ids2.length) return false;

  for (let i = 0; i < ids1.length; i++) {
    if (!ids2.includes(ids1[i])) return false;
  }

  return true;
}
