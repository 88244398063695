import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Loading from '../../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  DatabaseResponse,
  fetchCategoriesWithProtocols,
  getDepartments,
  loadDatabase,
  Response,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { handleGetDepartment } from '../../../../../../store/actions';
import { handleCopy, toTitleCase } from '../../../../../_global/common/Utils';
import { set } from 'lodash';
import { ViewportList } from 'react-viewport-list';
import MedicationSubItem from '../../../../../../data/model/MedicationSubItem';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../../data/model/MedicationItem';
import { DataStore } from 'aws-amplify';
import {
  Concentration,
  Drip,
  Medication,
  MedicationConcentration,
  MedicationDose,
} from '../../../../../../models';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import {
  auditIsRestrictiveStatus,
  convertAllMedicationConcentrationsToConcentrationTables,
  convertToNewDatabaseLogic,
} from '../../../../../../data/AmplifyActions';
import InfusionItem from '../../../../../../data/model/InfusionItem';
import CategoryItem from '../../../../../../data/model/CategoryItem';
import Status from '../../../../../components/ProgressStatus/ProgressStatus';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const MigrateIsRestrive = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    categories: [] as CategoryItem[],
    protocols: [] as ProtocolItem[],
  });

  const handleBack = () => {
    navigate('/actions/database-migrate');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments(true);
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
    }
  };

  const loadData = async (department: DepartmentItem) => {
    setIsLoading('Loading ' + department.name + ' Data...');
    let subDeps = await department.checkSubDeps();
    if (subDeps.length > 0) {
      let resp: Response = await fetchCategoriesWithProtocols(department);
      if (resp.type === ResponseType.Success) {
        setInfo({
          department: department,
          categories: resp.data[0],
          protocols: resp.data[1],
        });
      } else {
        alert('Error fetching categories and protocols: ' + resp.data);
      }
    } else {
      alert('No Sub Departments found');
    }

    setIsLoading(null);
  };

  const handlePerformAudit = async () => {
    console.log('Perming Audit');
    auditIsRestrictiveStatus(info.department)
      .then((response) => {
        console.log('Response:', response);
        if (response.type === ResponseType.Success) {
          let n = response.data;
          setModalState({
            isVisible: true,
            title: 'Successfully Audited Is Restrictive Status',
            primaryBtnName: 'Okay',
            secondaryBtnName: 'Okay',
            primaryDescription:
              'Successfully updated ' +
              n +
              ' Folders/Protocols for ' +
              info.department?.name,
          });
          // setInfo({
          //   ...info,
          //   newStyleMeds: medDoses,
          // });
          loadData(info.department);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isSaveValid = useMemo(() => {
    return info.department !== undefined;
  }, [info]);

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={
          'Audit Is Restrictive Status for all folders and protocols in a department'
        }
        description={
          'This will loop throgh every category and protocol and check if all the pairedDepIDs are included in which it really should be restricted to.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Run"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit();
        }}
      />
      <label className="ketamine-general-label">
        Department: <span className="required-field">*</span>
      </label>
      <p className="sidebarText" style={{ marginLeft: '10px' }}>
        Select the department you want to perform the audit on.
      </p>
      <SearchableDropdown<DepartmentItem>
        id="searchDropdown"
        options={departments}
        labelField={(option) => option.name}
        valueField={(option) => option.name}
        keyField={(option) => option.id}
        onChange={(option: DepartmentItem) => {
          setIsLoading('Loading ' + option.name + ' Data...');
          loadData(option);
        }}
        onClear={() => {
          setInfo({
            department: undefined,
            database: undefined,
          });
        }}
        placeholder="Search department..."
      />

      {info.department && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {info.department && info.department.logoVerifiedUrl && (
              <img
                className="large-dep-logo"
                src={info.department.logoVerifiedUrl}
                alt="Agency Logo"
              />
            )}
            {info.department && (
              <div
                className="large-dep-name"
                style={{
                  marginLeft: '2rem',
                  marginRight: '6px',
                }}
              >
                {info.department.name}
              </div>
            )}
          </div>
          <div
            className="input-container roundBorder "
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
          >
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>ID:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.department.id}
                <span>
                  {isCopied && isCopied === info.department.id ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(info.department.id, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className="input-container roundBorder "
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <h4
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Categories:{' '}
                <span style={{ color: '#636363' }}>
                  {info.categories.length}
                </span>
              </h4>
              <ViewportList items={info.categories}>
                {(item: CategoryItem, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{}}>{item.name}</div>
                      </div>
                      <div>
                        {item.isRestrictive === true
                          ? 'Restrictive'
                          : 'Inclusive'}
                      </div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
            <div
              className="input-container roundBorder "
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <h4
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Protocols:{' '}
                <span style={{ color: '#636363' }}>
                  {info.protocols.length}
                </span>
              </h4>
              <ViewportList items={info.protocols}>
                {(item: ProtocolItem, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{}}>{item.name}</div>
                      </div>
                      <div>
                        {item.isRestrictive === true
                          ? 'Restrictive'
                          : 'Inclusive'}
                      </div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MigrateIsRestrive;
