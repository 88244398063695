import { User, Workbook } from '../../models';
import { ProgressStatus } from '../../API';
import ProtocolItem from './ProtocolItem';
import DepartmentItem from './DepartmentItem';
import ModelItem from './ModelItem';

class WorkbookItem extends ModelItem<Workbook> {
  workbookUrl: string;
  parents: ProtocolItem[];
  fileSize: number;
  aiPdfParserResults?: string | null | undefined;
  createdBy: User;
  modifiedBy: User;

  constructor(workbook: Workbook, modifiedBy: User) {
    super(workbook, workbook.name, workbook.departmentID);
    this.workbookUrl = workbook.workbookID;
    this.parents = [];
    this.modifiedBy = modifiedBy;
    this.fileSize = workbook.fileSize;
    this.aiPdfParserResults = workbook.aiPdfParserResults;
    this.createdBy = modifiedBy;
    this.modifiedBy = modifiedBy;
  }

  toString(): string {
    return `WorkbookItem -> {
            uid=${this.uid},
            name=${this.name},
            pdfUrl=${this.workbookUrl},
            protocolVersion=${this.version},
        }`;
  }
}

export default WorkbookItem;
