import * as _ from 'lodash';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { sideoutList } from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../Modal/ConfirmModal';
import { DatabaseResponse, ResponseType } from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';
import { User } from '../../../models';
import { InputText } from 'primereact/inputtext';
import { GroupType } from '../../../API';
import { GroupJSON, createGroupItem } from '../../../data/functions/GroupDB';
import GroupItem from '../../../data/model/GroupItem';

interface AddGroupSideoutProps {
  handleClose: () => void;
  handleAdd?: (items: GroupItem) => void | any;
  details?: any;
  title?: string;
  isVisible?: boolean;
  isEditMode?: any;
  page?: any;
  protocol?: ProtocolItem;
}

const AddGroupSideout: React.FC<AddGroupSideoutProps> = (props) => {
  const { isVisible, handleClose, isEditMode, page, handleAdd, protocol } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const department = database.department;
  const [isNewMedication, setIsNewMedication] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);

  const pairedProtocolList: any = [];
  const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
  const [selectedList, setSelectedList] = useState<User[]>([]);
  const [selectedProtocolList, setSelectedProtocolList] = useState<any>([]);
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const [title, setTitle] = useState('Group Title');
  const [titleError, setTitleError] = useState('');
  /* 11-07-23 Arul: handle function for radio buttton onChange */
  const handleCheckboxChange = (option: User) => {
    const index = selectedOptions.findIndex(
      (item: User) => item?.id === option?.id
    );
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
      setSelectedList([...selectedList, option]);
    } else {
      const data = selectedOptions.filter(
        (item: User) => item?.id !== option?.id
      );
      setSelectedOptions(data);
      setSelectedList(data);
    }
  };

  const dataList = useMemo(() => {
    return database.users;
  }, []);

  useEffect(() => {
    setOptions(dataList);
  }, [dataList]);

  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = dataList?.filter(
          (item: User) =>
            item?.firstName?.toLowerCase().includes(value.toLowerCase()) ||
            item?.lastName?.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        setOptions(dataList);
      }
    },
    [value, dataList, setOptions]
  );

  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };

  const handleDeletePairedProtocol = () => {
    setIsDelete(false);
    const data = selectedProtocolList?.filter(
      (item: any) => item.name !== deleteItem.name
    );
    setSelectedProtocolList(data);
  };

  const handleAddGroup = async () => {
    if (handleAdd) {
      let GroupJSON: GroupJSON = {
        name: title,
        type: GroupType.NOTIFICATION,
        isAutoGen: false,
        departmentID: department.id,
        pairedDepIDs: department.subDepIDs,
        pairedUserIDs: selectedList.map((item: User) => item.id),
      };

      let result = await createGroupItem(GroupJSON);
      if (result.type === ResponseType.Failure) return result;
      let newGroup = result.data as GroupItem;
      handleAdd(newGroup);
      handleCloseSideouts();
    }
  };

  const handleCloseSideouts = () => {
    setIsDelete(false);
    setSelectedOptions([]);
    setSelectedList([]);
    handleClose();
  };

  const handleTitleChange = (event: any) => {
    event.preventDefault();
    setTitle(event.target.value);
    if (!event.target.value) setTitleError('Title is required');
    else if (event.target.value.length > 50) setTitleError('Title is too long');
    else setTitleError('');
  };

  return (
    <div>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}

      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleCloseSideouts}
        style={{
          width: '25%',
          minWidth: '400px',
        }}
        className={'protocolSidebar sidebarWidth'}
      >
        <div style={{ height: '100%', padding: '10px' }}>
          <div
            className="buttonContainer contentTitleLarge hoverText"
            onClick={handleCloseSideouts}
          >
            <span className="headerTilte">
              <BiArrowBack
                className="header-icon cursorPointer"
                style={{ paddingLeft: '4px' }}
              />
              <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
              Create Group
            </span>
          </div>

          <div className="contentText">
            Select users from the list to create a new group for notifications.
            Duplicate groups will not be created.
          </div>
          <div
            style={{
              paddingBottom: '10px',
              paddingTop: '20px',
            }}
          >
            <HiPencil
              className="icon-black"
              style={{
                height: '1.5rem',
                width: '1.5rem',
                cursor: 'pointer',
                color: '#00534C',
              }}
            />
            <InputText
              value={title}
              onChange={handleTitleChange}
              placeholder="Add Group Title..."
              className="title-input-text-box"
              style={{
                fontWeight: 'bold',
                width: `calc(1rem*${title.length})`,
                minWidth: '200px',
                fontSize: '20px',
              }}
            />
            {titleError && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '8px' }}>
                {titleError}
              </p>
            )}
          </div>
          <>
            {' '}
            <div className="contentLabelBold">Select Users</div>
            <SearchBar
              value={value}
              onChange={handleSearch}
              placeholder="Search"
              containerStyle={{ width: '100%' }}
            />
            <div
              style={{ maxHeight: '60%' }}
              className="contentBorder protocolCalculationPad secondaryListScroll"
            >
              <ViewportList items={options}>
                {(item: User, index: any) => (
                  <div
                    key={item.id + index}
                    onClick={() => {
                      handleCheckboxChange(item);
                    }}
                    className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                      options?.length !== index + 1 ? 'contentUnderline ' : ''
                    } `}
                  >
                    <RadioButton
                      inputId="pairedProtocol"
                      name="pairedProtocol"
                      className="radioBtnColor"
                      style={{ marginRight: '10px' }}
                      value={item?.firstName + ' ' + item?.lastName}
                      checked={selectedOptions?.includes(item)}
                      onChange={() => {
                        handleCheckboxChange(item);
                      }}
                    />
                    <div>{item?.firstName + ' ' + item?.lastName}</div>
                  </div>
                )}
              </ViewportList>
            </div>
          </>

          <div className="btn_Bottom">
            <Button
              data-testid="cancel"
              className="secondary-button btn-rightMargin"
              onClick={handleCloseSideouts}
            >
              X Cancel
            </Button>
            <Button
              data-testid="save"
              className="primary-button"
              disabled={!(selectedOptions?.length > 0)}
              onClick={handleAddGroup}
            >
              + Add
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddGroupSideout;
