import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoAdd, IoClose } from 'react-icons/io5';
import React, { useEffect, useMemo } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import SearchableDropdown from '../SearchableDropdown';
import OwnerImage from '../OwnerImage/OwnerImage';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
interface UnlockKeychainModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (departments: DepartmentItem[]) => void;
  department: DepartmentItem;
  values?: DepartmentItem[];
  availableDepartments?: DepartmentItem[];
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const SelectDepartmentsModal: React.FC<UnlockKeychainModalProps> = (
  props: UnlockKeychainModalProps
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    department,
    values,
    availableDepartments,
  } = props;

  const [state, setState] = React.useState<DepartmentItem[]>(values ?? []);

  useEffect(() => {
    if (values) {
      setState(values);
    }
  }, [values]);

  // const isSaveValid = useMemo(() => {
  //   return state.length > 0;
  // }, [state]);

  const fullDepartmentList = useMemo(() => {
    if (availableDepartments) return availableDepartments;
    return department.allSubDeps ?? [];
  }, [department, availableDepartments]);

  const departmentList = useMemo(() => {
    let deps = fullDepartmentList.filter(
      (dep) => !state.find((s) => s.id === dep.id)
    );
    return deps;
  }, [state, fullDepartmentList]);

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          minWidth: '800px',
          minHeight: '500px',
          // flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '500px',
        }}
        onHide={() => {
          handleClose();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '3rem' }}
        >
          <h4
            className="keychain-title"
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <OwnerImage owner={department} size={40} />
            <span
              style={{
                // marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              Select Departments
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <p
            className="departmentItemText"
            style={{
              fontSize: '0.9rem',
              color: 'black',
              margin: '10px 20px',
              // maxWidth: '50%',
            }}
          >
            Selects the departments that will have access to this folder.{' '}
            {department.name} will have access by default.
          </p>
          {department && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {state.length + 1 + '/'}
                  {fullDepartmentList.length + 1}
                  <span onClick={() => setState(fullDepartmentList)}>
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that is under{' '}
                {department.name}.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  setState([...state, option])
                }
                onClear={() => {
                  setState([]);
                }}
                placeholder="Search department..."
              />
              {/* {state.length === 0 && (
                  <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                    No departments added...
                  </h6>
                )} */}
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={[department, ...state]}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === state.length ? '' : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      {index !== 0 && (
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            setState(state.filter((dep) => dep.id !== item.id));
                          }}
                        />
                      )}
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="dialogButtonsForce">
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            onClick={() => {
              handleSubmit(state);
              handleClose();
            }}
          >
            <span>
              <IoAdd size="1rem" />
            </span>{' '}
            Add
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SelectDepartmentsModal;
