// Note: EditDB for Vital
// Date: 01/19/2024
// Author: Guruprasad Venkatraman

import { DataStore, graphqlOperation } from 'aws-amplify';
import {
  ResponseType,
  Response,
  DatabaseResponse,
  executeQuery,
  mapModelItems,
} from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import VitalItem from '../model/VitalItem';
import DepartmentItem from '../model/DepartmentItem';
import { globals } from '../../ui/_global/common/Utils';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Vitals } from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import {
  getVitals,
  listVitals,
  vitalsByDepartmentID,
} from '../../graphql/queries';

export type VitalDB = {
  title: string;
  optionItems: any[];
  departmentID: string;
  activeID: string | null | undefined;
  version: string | null | undefined;
  status: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the Vital is a draft version and delete it
 * @param vitalItem The vital to check
 * @returns Success if ready to create a new vital or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Vitals[];
    if (isActive)
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.activeID.eq(id)])
      );
    else
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbVital = results[0];
    if (dbVital.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Vitals, dbVital.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbVital,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new vital in the database and choose the version
 * @param vital VitalDB JSON format
 * @returns The successful vitalItem or the error
 */
export const createVital = async (
  vital: VitalDB | VitalItem,
  previousItem?: VitalItem
): Promise<Response> => {
  try {
    let json: VitalDB;
    if (vital instanceof VitalItem) {
      json = {
        title: vital.name,
        status: vital.status,
        activeID: vital.activeID,
        departmentID: vital.departmentID,
        version: vital.version != null ? vital.version : 'v1.0.0',
        optionItems: vital.options != null ? vital.options : [],
        createdBy: vital.model.createdBy ? vital.model.createdBy : '',
        modifiedBy: vital.modifiedBy ? vital.modifiedBy.id : undefined,
      };
    } else json = vital;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let v: Vitals;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbVital = await DataStore.query(Vitals, previousItem.uid);
      if (dbVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Vital does not exist could not update',
        };
      }

      v = await DataStore.save(
        Vitals.copyOf(dbVital, (updated) => {
          updated.title = json.title;
          updated.options = json.optionItems;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      v = await DataStore.save(
        new Vitals({
          title: json.title,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          options: json.optionItems,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    if (globals.debug) console.log('Created vital:', v);
    let vitalItem = new VitalItem(v);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    console.error('Error in createVital function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the vital to the database
 *    1. Create a new ARCHEIVED vital based on the current ACTIVE vital
 *    2. Update the ACTIVE vital with the new information
 *    3. Delete the DRAFT vital
 * @param draftVitalItem The vital to publish
 */

export const publishVital = async (
  draftVitalItem: VitalItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the vital is configured correctly as a draft version */
    if (draftVitalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The vital is not a draft version',
      };
    }

    let dbVital = await DataStore.query(Vitals, draftVitalItem.uid);
    if (dbVital == null) {
      return {
        type: ResponseType.Failure,
        data: 'The vital does not exist',
      };
    }

    let activeVital: Vitals;

    /* Use case 1: Creating the FIRST active version */
    if (draftVitalItem.activeID == null) {
      /* Update the draft vital to be active */
      activeVital = await DataStore.save(
        Vitals.copyOf(dbVital, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      if (globals.debug)
        if (globals.debug)
          console.log('Created the first release of the vital:', activeVital);
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active vital item */
      let id: string = draftVitalItem.activeID;
      let curVital = await DataStore.query(Vitals, id);

      /* Base Case 3 -- check if the active vital exists */
      if (curVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active vital does not exist',
        };
      }

      let archive = new VitalItem(curVital);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curVital.id;

      // 2. Create a new ARCHEIVED vital based on the current ACTIVE vital
      let archiveResult: Response = await createVital(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as VitalItem;

      // 2. Update the ACTIVE vital with the new information
      activeVital = await DataStore.save(
        Vitals.copyOf(curVital, (updated) => {
          updated.title = draftVitalItem.name;
          updated.options = draftVitalItem.options;

          updated.modifiedBy = draftVitalItem.model.modifiedBy;
          updated.createdBy = draftVitalItem.model.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftVitalItem.version;
        })
      );

      // 3. Delete the DRAFT vital
      let draftVital = await DataStore.delete(Vitals, draftVitalItem.uid);
      if (draftVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft vital does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeVital.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeVital.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let vitalItem = new VitalItem(activeVital);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteVital = async (
  vitalItem: VitalItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = vitalItem.uid;
    if (isSoft) {
      let vital = await DataStore.query(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }

      let result = await DataStore.save(
        Vitals.copyOf(vital, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted vital:', vital);
    } else {
      let vital = await DataStore.delete(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted vital:', vital);
    }
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isVitalDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getVitalDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = db.vitals.filter((v) => v.status === 'DRAFT');

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if (model.status === 'DELETED') message = `Deleted Vital: ${model.title}`;
      if (model.activeID == null) message = `Created Vital: ${model.name}`;
      else message = `Updated Vital: ${model.name}`;
      updates.push({
        model: model,
        title: 'Vital ' + model.name,
        message: message,
        changeType: DraftChangeType.VITAL,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertVitalChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Vital ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as VitalItem),
      changeType: DraftChangeType.VITAL,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: VitalItem): string {
  if (draftItem.activeItem == null) return `Created Vital: ${draftItem.name}`;
  return `Updated Vital: ${draftItem.name}`;
}

export const removeCurrentVitalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftVitals = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    if (globals.debug) console.log('Found draft vitals:', draftVitals.length);
    for (let i = 0; i < draftVitals.length; i++) {
      let vital: Vitals = draftVitals[i];
      if (globals.debug) console.log('Removing vitals:', vital.title);
      await DataStore.delete(vital);
      updates.push({
        model: vital,
        message: `Removed Folder: ${vital.title}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchVitals = async (
  dep: DepartmentItem,
  useDataStore = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let vitalsList: Vitals[] = [];
    if (useDataStore) {
      vitalsList = await DataStore.query(Vitals, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      vitalsList = await executeQuery(listVitals, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            {
              and: [
                { status: { ne: 'ARCHIVE' } },
                { status: { ne: 'DELETED' } },
              ],
            },
          ],
        },
      });
    }
    let vitals: VitalItem[] = [];
    for (let i = 0; i < vitalsList.length; i++) {
      let vital = new VitalItem(vitalsList[i]);
      // if (vital.status.includes('DRAFT') || vital.status === 'ACTIVE') {
      /* Take out the active version if there is one */
      mapModelItems(vital, vitals, vital.status, dep);
      // }
    }
    vitals.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: vitals,
    };
  } catch (error) {
    console.error('Error fetching vitals:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getVitalByID = async (
  db: DatabaseResponse,
  id: string
): Promise<VitalItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbEquip = db.vitals.find((c) => c.uid === id);
      if (dbEquip != null) return resolve(dbEquip);
      else {
        executeQuery(graphqlOperation(getVitals, { id: id }), 1500)
          .then((data) => {
            if (data.data.getVitals == null) {
              resolve(null);
            } else {
              let elec = data.data.getVitals as Vitals;
              let equipment = new VitalItem(elec);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(equipment);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
