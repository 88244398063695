import React, { Key, useEffect, useMemo, useState } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import Status from '../ProgressStatus/ProgressStatus';
import { FaChevronDown, FaLock, FaLockOpen } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import KeychainItem from '../../../data/model/KeychainItem';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import './Accordion.scss';

interface AccordionItemProps {
  title: string;
  titleStyle?: React.CSSProperties;
  children: React.ReactNode;
  rightTitle?: string;
  rightTitleStyle?: React.CSSProperties;
  expanded?: boolean;
  expandedColorFlag?: boolean;
  onChange?: (expanded: boolean) => void;
}

/* 09-28-23 Arul: Created the global Accordion component to display the protocol list  */
const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const { title, rightTitle, children } = props;
  const [expanded, setExpanded] = useState(props.expanded ? true : false);

  return (
    <Accordion
      style={{
        boxShadow: 'none',
      }}
      expanded={expanded}
      onChange={(e, expanded) => {
        setExpanded(expanded);
        if (props.onChange) props.onChange(expanded);
        // if (!expanded && formik.values.owner == null)
        //   setTimeout(() => {
        //     formik.setFieldValue('owner', department);
        //   }, 100);
      }}
    >
      <AccordionSummary
        expandIcon={<FaChevronDown />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`accordion-header ${expanded ? (props.expandedColorFlag ? 'expanded-color' : 'expanded') : ''}`}
        style={{
          ...props.titleStyle,
          border: '1px solid #ccc',
          borderRadius: '0.5rem',
        }}
      >
        {title}
        {rightTitle && (
          <div
            style={{
              fontWeight: '500',
              ...props.rightTitleStyle,
              marginLeft: 'auto',
              marginRight: '1rem',
            }}
          >
            {rightTitle}
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
