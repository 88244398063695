// Layouts
import DashboardLayout from './ui/layouts/Dashboard';
import Protocol from './ui/pages/protocol/Protocol';
import ProtocolDetails from './ui/pages/protocol/details/ProtocolDetails';
import ProtocolLandingPage from './ui/pages/protocol/edit/ProtocolLandingPage/ProtocolLandingPage';
import ProtocolEditMainPage from './ui/pages/protocol/edit/HomeEditFolder/ProtocolEditMainPage';
import ProtocolNavigator from './route/ProtocolNavigator';
import CheckListNewPage from './ui/pages/protocol/edit/CheckList/CheckListNewPage';
import Snapshot from './ui/pages/snapshot/Snapshot';
import NotificationMainPage from './ui/pages/notification/details/NotificationMainPage';
import NotificationCreatePage from './ui/pages/notification/details/NotificationCreatePage';
import SnapshotViewPage from './ui/pages/snapshot/details/SnapshotViewPage';
import ViewNotificationPage from './ui/pages/notification/details/NotificationViewPage';
import LandingPage from './ui/pages/login/LandingPage';
import DatabasePage from './ui/pages/database/DatabasePage';
import Loading from './ui/components/Loading/Loading';
import ListProtocols from './ui/pages/database/lists/ListProtocols';
import ListMedicaions from './ui/pages/database/lists/ListMedications';
import ListInfusions from './ui/pages/database/lists/ListInfusions';
import ListElectrical from './ui/pages/database/lists/ListElectrical';
import ListEquipment from './ui/pages/database/lists/ListEquipment';
import ListVitals from './ui/pages/database/lists/ListVitals';
import ListChecklists from './ui/pages/database/lists/ListChecklists';
import ListAmbulances from './ui/pages/database/lists/ListAmbulances';
import ListContacts from './ui/pages/database/lists/ListContacts';
import CreateEditAmbulance from './ui/pages/protocol/edit/Ambulance/AmbulanceCreateEditPage';
import CreateEditContact from './ui/pages/protocol/edit/Contacts/ContactCreateEditPage';
import ListUsers from './ui/pages/database/lists/ListUsers';
import CreateEditUser from './ui/pages/protocol/edit/User/UserCreateEditPage';
import ListWorkbooks from './ui/pages/protocol-sets/ProtocolSetList';
import WorkBookCreate from './ui/pages/protocol-sets/ProtocolSetCreate';
import WorkBookEdit from './ui/pages/protocol-sets/ProtocolSetEdit';
import FullScreenPDF from './ui/pages/FullScreenPDF';
import ArchivePage from './ui/pages/archive/ArchivePage';
import ListArchiveMedications from './ui/pages/archive/lists/ListArchiveMedications';
import ListArchiveProtocols from './ui/pages/archive/lists/ListArchiveProtocols';
import ListArchiveChecklists from './ui/pages/archive/lists/ListArchiveChecklists';
import ListArchiveInfusions from './ui/pages/archive/lists/ListArchiveInfusions';
import ListArchiveEquipment from './ui/pages/archive/lists/ListArchiveEquipment';
import ListArchiveElectrical from './ui/pages/archive/lists/ListArchiveElectrical';
import ListArchiveVitals from './ui/pages/archive/lists/ListArchiveVitals';
import Actions from './ui/pages/actions/ActionsPage';
import Copy from './ui/pages/actions/details/copy/Copy';
import ActionsNavigator from './route/ActionsNavigator';
import ListWeightObjects from './ui/pages/database/lists/ListWeightObjects';
import WeightObjectCreateEdit from './ui/pages/database/edit/WeightObjectCreateEditPage';
import PublicDashboard from './ui/pages/public/PublicDashboardLayout';
import PublicProtocol from './ui/pages/public/Protocol/PublicProtocol';
import PublicPdfScreen from './ui/pages/public/Protocol/PublicPdfScreen';
import PublicRequest from './ui/pages/public/PublicRequest';
import ListKeychains from './ui/pages/database/lists/ListKeychains';
import KeychainCreate from './ui/pages/database/edit/KeychainCreatePage';
import KeychainEdit from './ui/pages/database/edit/KeychainEditPage';
import Page404 from './ui/pages/404';
import SettingsPage from './ui/pages/settings/SettingsPage';
import NotificationQuestionPage from './ui/pages/notification/details/NotificationQuestionPage';
import ReviewDetails from './ui/pages/review/ReviewDetails';
import ReviewChanges from './ui/pages/review/ReviewChanges';
import ReviewCreate from './ui/pages/review/ReviewCreate';
import ReviewPage from './ui/pages/review/ReviewPage';
import ReviewEdit from './ui/pages/review/ReviewEdit';

/* 09-27-23 Arul: Created the global navigation route components for all screens */
const AppRoute = [
  {
    path: '/',
    element: (
      <DashboardLayout pageTitle="" component={<Loading type="bubbles" />} />
    ),
  },
  {
    path: '/404',
    element: <Page404 />,
  },
  {
    path: '/login',
    element: <LandingPage />,
  },
  {
    path: '/fullscreen-pdf/:pdf',
    element: <FullScreenPDF />,
  },
  // {
  //   path: '/sites/:departmentName/:departmentCode',
  //   element: <PublicRequest />,
  // },
  {
    path: '/sites/:departmentName',
    element: <PublicDashboard pageTitle="" component={<PublicProtocol />} />,
  },
  {
    path: '/sites/:departmentName/protocols',
    element: <PublicPdfScreen />,
  },
  {
    path: '/sites/:departmentName/protocols/',
    element: <PublicPdfScreen />,
  },
  {
    path: '/protocol',
    element: <DashboardLayout pageTitle="" component={<Protocol />} />,
  },
  {
    path: '/:protocolId/protocol-detail',
    element: <DashboardLayout pageTitle="" component={<ProtocolDetails />} />,
  },
  {
    path: '/protocol/edit',
    element: (
      <DashboardLayout pageTitle="" component={<ProtocolEditMainPage />} />
    ),
  },
  {
    path: '/protocol/edit/create-protocol',
    element: (
      <DashboardLayout pageTitle="" component={<ProtocolLandingPage />} />
    ),
  },
  {
    path: '/protocol/edit/edit-protocol',
    element: <DashboardLayout pageTitle="" component={<ProtocolNavigator />} />,
  },
  {
    path: '/protocol/edit/new-protocol',
    element: <DashboardLayout pageTitle="" component={<ProtocolNavigator />} />,
  },
  {
    path: '/protocol/edit/newCheck-protocol',
    element: <DashboardLayout pageTitle="" component={<CheckListNewPage />} />,
  },
  {
    path: '/snapshot',
    element: <DashboardLayout pageTitle="" component={<Snapshot />} />,
  },
  {
    path: '/snapshot-detail',
    element: <DashboardLayout pageTitle="" component={<SnapshotViewPage />} />,
  },
  {
    path: '/notification',
    element: (
      <DashboardLayout pageTitle="" component={<NotificationMainPage />} />
    ),
  },
  {
    path: '/create/notification',
    element: (
      <DashboardLayout pageTitle="" component={<NotificationCreatePage />} />
    ),
  },
  {
    path: '/create/notification/question',
    element: (
      <DashboardLayout pageTitle="" component={<NotificationQuestionPage />} />
    ),
  },
  {
    path: '/view/notification',
    element: (
      <DashboardLayout pageTitle="" component={<ViewNotificationPage />} />
    ),
  },
  {
    path: '/database',
    element: <DashboardLayout pageTitle="" component={<DatabasePage />} />,
  },
  {
    path: '/database/list-protocols',
    element: <DashboardLayout pageTitle="" component={<ListProtocols />} />,
  },
  {
    path: '/database/list-medications',
    element: <DashboardLayout pageTitle="" component={<ListMedicaions />} />,
  },
  {
    path: '/database/list-infusions',
    element: <DashboardLayout pageTitle="" component={<ListInfusions />} />,
  },
  {
    path: '/database/list-electricals',
    element: <DashboardLayout pageTitle="" component={<ListElectrical />} />,
  },
  {
    path: '/database/list-equipment',
    element: <DashboardLayout pageTitle="" component={<ListEquipment />} />,
  },
  {
    path: '/database/list-vitals',
    element: <DashboardLayout pageTitle="" component={<ListVitals />} />,
  },
  {
    path: '/database/list-checklists',
    element: <DashboardLayout pageTitle="" component={<ListChecklists />} />,
  },
  {
    path: '/database/list-ambulances',
    element: <DashboardLayout pageTitle="" component={<ListAmbulances />} />,
  },
  {
    path: '/database/:createState/ambulance',
    element: (
      <DashboardLayout pageTitle="" component={<CreateEditAmbulance />} />
    ),
  },
  {
    path: '/database/list-contacts',
    element: <DashboardLayout pageTitle="" component={<ListContacts />} />,
  },
  {
    path: '/database/:createState/contact',
    element: <DashboardLayout pageTitle="" component={<CreateEditContact />} />,
  },
  {
    path: '/database/list-subtractable-weights',
    element: <DashboardLayout pageTitle="" component={<ListWeightObjects />} />,
  },
  {
    path: '/database/:createState/subtractable-weights',
    element: (
      <DashboardLayout pageTitle="" component={<WeightObjectCreateEdit />} />
    ),
  },
  {
    path: '/database/list-keychains',
    element: <DashboardLayout pageTitle="" component={<ListKeychains />} />,
  },
  {
    path: '/database/new/keychains',
    element: <DashboardLayout pageTitle="" component={<KeychainCreate />} />,
  },
  {
    path: '/database/edit/keychains',
    element: <DashboardLayout pageTitle="" component={<KeychainEdit />} />,
  },
  {
    path: '/database/list-users',
    element: <DashboardLayout pageTitle="" component={<ListUsers />} />,
  },
  {
    path: '/database/:createState/user',
    element: <DashboardLayout pageTitle="" component={<CreateEditUser />} />,
  },
  {
    path: '/protocol-sets',
    element: <DashboardLayout pageTitle="" component={<ListWorkbooks />} />,
  },
  {
    path: '/protocol-sets/details',
    element: <DashboardLayout pageTitle="" component={<ListWorkbooks />} />,
  },
  {
    path: '/protocol-sets/new',
    element: <DashboardLayout pageTitle="" component={<WorkBookCreate />} />,
  },
  {
    path: '/protocol-sets/edit',
    element: <DashboardLayout pageTitle="" component={<WorkBookEdit />} />,
  },
  {
    path: '/archive',
    element: <DashboardLayout pageTitle="" component={<ArchivePage />} />,
  },
  {
    path: '/archive/list-medications',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveMedications />} />
    ),
  },
  {
    path: '/archive/list-protocols',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveProtocols />} />
    ),
  },
  {
    path: '/archive/list-checklists',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveChecklists />} />
    ),
  },
  {
    path: '/archive/list-infusions',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveInfusions />} />
    ),
  },
  {
    path: '/archive/list-equipment',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveEquipment />} />
    ),
  },
  {
    path: '/archive/list-vitals',
    element: <DashboardLayout pageTitle="" component={<ListArchiveVitals />} />,
  },
  {
    path: '/archive/list-electricals',
    element: (
      <DashboardLayout pageTitle="" component={<ListArchiveElectrical />} />
    ),
  },
  {
    path: '/actions',
    element: <DashboardLayout pageTitle="" component={<Actions />} />,
  },
  {
    path: '/actions/:action',
    element: <DashboardLayout pageTitle="" component={<ActionsNavigator />} />,
  },
  {
    path: '/actions/:action/:subAction',
    element: <DashboardLayout pageTitle="" component={<ActionsNavigator />} />,
  },
  {
    path: 'settings',
    element: <DashboardLayout pageTitle="" component={<SettingsPage />} />,
  },
  {
    path: '/review',
    element: <DashboardLayout pageTitle="" component={<ReviewPage />} />,
  },

  {
    path: '/reviewDetails',
    element: <DashboardLayout pageTitle="" component={<ReviewDetails />} />,
  },
  {
    path: '/reviewChanges',
    element: <DashboardLayout pageTitle="" component={<ReviewChanges />} />,
  },
  {
    path: '/reviewCreate',
    element: <DashboardLayout pageTitle="" component={<ReviewCreate />} />,
  },
  {
    path: '/reviewEdit',
    element: <DashboardLayout pageTitle="" component={<ReviewEdit />} />,
  },
];

export default AppRoute;
