import { PatientAgeGroup, ProgressStatus } from '../../API';
import { User } from '../../models';
import PatientAge from '../../ui/_global/common/PatientAge';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import {
  getPatientAgeGroup,
  getProgressStatus,
  globals,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import ModelInterface from './ModelInterface';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';

class ModelSubItem<T> implements ModelInterface {
  TAG = 'ModelSubItem';
  model: T;
  anyModel: any;
  parent: ModelItem<any>;
  parentProtocol: ProtocolItem;
  uid: string;
  departmentID: string;
  name: string;
  index: number = -1;

  title: string;
  instruction: string;
  note: string;
  warning: string;
  description: string = '';

  fullBasis: string = '';
  fullMaxDose: string = '';
  fullMinDose: string = '';
  fullMaxTotalDose: string = '';

  basis: number = -1;
  basisHigh: number = globals.MAX_VALUE;
  rangeLow: number = -1;
  rangeHigh: number = globals.MAX_VALUE;
  ageLow: PatientAge | null = null;
  ageHigh: PatientAge | null = null;
  ageGroup: PatientAgeGroup | null | undefined;

  maxDose: number = globals.MAX_VALUE;
  minDose: number = -1;
  calcMax: number = globals.MAX_VALUE;
  calcMin: number = -1;
  maxTotalDose: number = globals.MAX_VALUE;
  repeatTimeSec: number = -1;
  increment: number = -1;

  version: string = 'v1.0.0';
  status: ProgressStatus = ProgressStatus.ACTIVE;
  activeID: string | null | undefined;
  overrideID: string | null | undefined;
  activeItem: ModelSubItem<T> | null | undefined;
  overrideItem: ModelSubItem<T> | null | undefined;

  createdByID: string;
  modifiedByID: string | null | undefined;
  createdBy: User | null | undefined;
  modifiedBy: User | null | undefined;
  createdAt: Date | null | undefined;
  modifiedAt: Date | null | undefined;

  constructor(
    parent: ModelItem<any>,
    parentProtocol: ProtocolItem,
    model: any
  ) {
    this.uid = model.id;
    this.name = parent.name;
    this.model = model;
    this.departmentID = model.departmentID;
    this.parent = parent;
    this.parentProtocol = parentProtocol;

    this.rangeLow =
      model.rangeLow !== null && model.rangeLow > 0 ? model.rangeLow : 0;
    this.rangeHigh = model.rangeHigh || globals.MAX_VALUE;
    this.ageLow = model.ageLow ? new PatientAge(model.ageLow) : null;
    this.ageHigh =
      model.ageHigh && model.ageHigh !== globals.MAX_VALUE
        ? new PatientAge(model.ageHigh)
        : null;
    this.ageGroup = getPatientAgeGroup(model.ageGroup);

    this.title = model.title || '';
    this.instruction = model.instruction || '';
    this.note = model.note || '';
    this.warning = model.warning || '';
    this.index = model.index !== null ? model.index : 0;

    this.fullBasis = model.basis ?? '';
    this.fullMaxDose = model.maxDose ?? '';
    this.fullMinDose = model.minDose ?? '';
    this.fullMaxTotalDose = model.maxTotalDose ?? '';

    this.activeID = model.activeID;
    this.overrideID = model.overrideID;
    this.status = getProgressStatus(model.status);
    this.version = model.version || 'v1.0.0';
    this.createdByID = model.createdBy;
    this.modifiedByID = model.modifiedBy;
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null;
  }
  getUid(): string {
    throw new Error('Method not implemented.');
  }
  getName(): string {
    throw new Error('Method not implemented.');
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method not implemented.');
  }
  getModel() {
    throw new Error('Method not implemented.');
  }

  /* ---------------- ModelSubItem functions ---------------- */

  equals(obj: any): boolean {
    if (obj == null) return false;
    return isObjectEqual(this, obj);
  }
  compareTo(other: ModelSubItem<any>): number {
    if (this.index === other.index) return 0;
    return this.index > other.index ? 1 : -1;
  }
  getDescription(): string {
    return this.name + ' ' + this.uid;
  }
  toString(): string {
    return `ModelItem -> {
            uid=${this.uid},
            name=${this.name},
            index=${this.index},
            rangeLow=${this.rangeLow},
            rangeHigh=${this.rangeHigh}
        }`;
  }
}

export default ModelSubItem;
