import { DataStore, graphqlOperation } from 'aws-amplify';
import {
  getUser,
  usersByCognitoID,
  usersByDepartmentID,
} from '../../graphql/queries';
import DepartmentItem from '../model/DepartmentItem';
import { User, UserStatus } from '../../models';
import {
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from '../AmplifyDB';
import { globals } from '../../ui/_global/common/Utils';

export const getUserByCognitoID = async (
  cognitoID: string
): Promise<User | undefined> => {
  try {
    const userData = await executeSingleQuery(usersByCognitoID, {
      cognitoID: cognitoID,
    });

    return userData;
    // if (userData.data.usersByCognitoID.items.length === 0) return undefined;
    // else return userData.data.usersByCognitoID.items[0];
  } catch (error: any) {
    return undefined;
  }
};

/*
  const userFormik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      cognitoID: user.cognitoID,
      type: user.type,
      depAdmins: user.depAdmins,
      departmentID: user.departmentID,
      hashedPin: user.hashedPin,
      saltPin: user.saltPin,
      status: user.status,
      notificationTokens: user.notificationTokens,
      pairedDepIDs: user.pairedDepIDs,
      oneDoseVersion: user.oneDoseVersion,
    },
    */
export const editUserItem = async (
  user: User,
  values: any
): Promise<User | undefined> => {
  try {
    let oldUser = await DataStore.query(User, user.id);
    if (!oldUser) return undefined;

    let updatedUser = await DataStore.save(
      User.copyOf(oldUser, (updated) => {
        updated.firstName = values.firstName;
        updated.lastName = values.lastName;
        updated.type = values.type;
        updated.status = values.status;
        updated.pairedDepIDs = values.pairedDepIDs;
        updated.oneDoseVersion = values.oneDoseVersion;
        updated.notificationTokens = values.notificationTokens;
        updated.depAdmins = values.depAdmins;
        updated.hashedPin = values.hashedPin;
        updated.saltPin = values.saltPin;
        updated.departmentID = values.departmentID;
      })
    );

    return updatedUser;
    // if (userData.data.usersByCognitoID.items.length === 0) return undefined;
    // else return userData.data.usersByCognitoID.items[0];
  } catch (error: any) {
    return undefined;
  }
};

export const fetchUsers = async (
  dep: DepartmentItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let usersList;
    if (useDataStore) {
      usersList = await DataStore.query(User, (u) =>
        u.and((u) => [u.departmentID.eq(dep.id), u.status.ne('DELETED')])
      );
    } else {
      usersList = await executeQuery(usersByDepartmentID, {
        departmentID: dep.id,
        filter: {
          status: { ne: 'DELETED' },
        },
      });
    }
    return {
      type: ResponseType.Success,
      data: usersList,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchUser = async (
  userID: string,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let user: User | undefined;
    if (useDataStore) {
      user = await DataStore.query(User, userID);
    } else {
      user = await executeSingleQuery(getUser, {
        id: userID,
      });
    }
    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const editUser = async (
  user: any,
  pairedDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    let u = await DataStore.query(User, user.id);
    if (!u)
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };
    let res = await DataStore.save(
      User.copyOf(u, (updated) => {
        updated.firstName = user.firstName;
        updated.lastName = user.lastName;
        updated.pairedDepIDs = pairedDeps.map((d: DepartmentItem) => d.id);
        updated.type = user.type;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const updateUserStatus = async (
  user: User | null,
  status: UserStatus
): Promise<Response> => {
  if (!user) {
    if (globals.debug) console.log('User not found', 'Params:', user, status);
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  }
  try {
    const dbUser = await DataStore.query(User, user.id);
    if (dbUser) {
      //Modify the user status to
      let res = await DataStore.save(
        User.copyOf(dbUser, (updated) => {
          updated.status = status;
        })
      );
      if (globals.debug) console.log('User status updated:', res);
      return {
        type: ResponseType.Success,
        data: res,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
