import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import ContactItem from '../../../../data/model/ContactItem';
import SearchBar from '../../../components/Search/SearchBar';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListContacts = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const contacts: ContactItem[] = database.contacts;

  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filters, setFilters] = useState<any[]>(
    state.search ? state.search.filters : []
  );
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state.search ? state.search.categoriesFilter : []
  );

  const [list, setList] = useState<any[]>([]);

  // const reloadDatabase = async () => {
  //     const response = await loadDatabase(department);
  //     if (response.type === ResponseType.Success) {
  //         const database = response.data;
  //         setDatabase(database);
  //         const protocols: ProtocolItem[] = database.protocols;
  //         handleFilterChange(protocols);
  //     }
  // }

  // useEffect(() => {
  //     reloadDatabase();
  // }
  // , []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = contacts.filter((item: ContactItem) => {
        return (
          item.name.toLowerCase().includes(searchQuery) ||
          (item.title && item.title.toLowerCase().includes(searchQuery)) ||
          item.number.toLowerCase().includes(searchQuery)
        );
      });
    } else filteredList = contacts;
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: ContactItem, b: ContactItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(contacts);
  }, [searchQuery, filters, categoriesFilter]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (check: ContactItem) => {
    const state = {
      selectedProtocol: null,
      value: check,
      subValue: null,
      type: 'Contact',
      editType: 'edit',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/edit/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Contacts',
      editType: 'new',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/new/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 3fr 4fr 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Contacts: ' + list.length + ' items'}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Contacts...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Title</h6>
                <h6 className="departmentItemText">Number</h6>
                <h6 className="departmentItemText">Modified Date</h6>
                <h6 className="departmentItemText">Note</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: ContactItem, index) => {
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">{item.title}</h6>
                    <h6 className="departmentItemText">{item.number}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item.dbContact.updatedAt
                          ? item.dbContact.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">{item.note}</h6>
                    </div>
                    <FaChevronRight
                      className="icon-normal "
                      style={{ margin: '4px' }}
                    />
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListContacts;
