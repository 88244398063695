import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  handleGetDepartment,
  handleUpdateSelectedAccordion,
} from '../../../../store/actions';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import AddProtocolSideout from '../../../components/SideOut/AddProtocolSideout';
import ProtocolHeader from '../ProtocolHeader';
import CalculationScreen from './CalculationsScreen';
import PDFScreen from './PdfScreen';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
  getAllWorkbooks,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolItem, {
  cloneProtocol,
} from '../../../../data/model/ProtocolItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { createMedicationDoseItem } from '../../../../data/functions/MedicationDB';
import {
  Equipment,
  Form,
  ProgressStatus,
  User,
  Workbook,
} from '../../../../models';
import {
  findDepartmentOwner,
  findPairedDepartments,
  globals,
  hasAdminUserAccess,
  isObjectEqual,
  upgradeVersion,
} from '../../../_global/common/Utils';
import {
  addIDtoProtocol,
  createProtocol,
  deleteProtocol,
  uploadNewPDFToS3,
} from '../../../../data/functions/ProtocolDB';
import Loading from '../../../components/Loading/Loading';
import MedicationItem from '../../../../data/model/MedicationItem';
import { createInfusionDoseItem } from '../../../../data/functions/InfusionDB';
import { createElectricalDoseItem } from '../../../../data/functions/ElectricalDB';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import ReviewSideOut from '../../../components/SideOut/reviewSideOut/ReviewSideOut';
import FormItem from '../../../../data/model/FormItem';
import { DataStore, Storage } from 'aws-amplify';
import { Protocol } from '../../../../models';
import { UserType } from '../../../../models';
import UploadProtocolPDF from './UploadPDF';
import { getHashedPin } from '../../../_global/common/Encrypt';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../../../../data/model/InfusionSubItem';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { set } from 'lodash';
import MedicationProtocolEditPage from '../edit/Medication/MedicationProtocolEditPage';
import ElectricalProtocolEditPage from '../edit/Electrical/ElectricalProtocolEditPage';
import InfusionProtocolEditPage from '../edit/Infusion/InfusionProtocolEditPage';
import NewMedicationDoseSideout from './NewMedicationDoseSideout';
import NewInfusionDoseSideout from './NewInfusionDoseSideout';
import NewElectricalDoseSideout from './NewElectricalDoseSideout';

type ProtocolDetailsItemsProps = {
  onBackClick?: any;
};

/* 10-02-23 Arul: Created the component for Protocol details page*/
const ProtocolDetails: React.FC<ProtocolDetailsItemsProps> = (props) => {
  const rootUrl = window.location.origin;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const { selectedProtocol, editMode, backPage } = state;
  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  const [selectedTab, setSelectedTab] = useState<string | null>(
    isLoggedIn && protocol.sum !== 0 ? 'Details' : 'Pdf'
  );
  const [curIndex, setCurIndex] = useState(0);
  const [itemSelected, setItemSelected] = useState('');
  const [newPairedProtocols, setNewPairedProtocols] = useState<ProtocolItem[]>(
    []
  );
  const [newEquipment, setNewEquipment] = useState<EquipmentItem[]>([]);
  const [newChecklist, setNewChecklist] = useState<FormItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    findDepartmentOwner(department, protocol)
  );
  const [adminAccess, setAdminAccess] = useState(
    hasAdminUserAccess(user, protocol)
  );
  const adminLevel = useMemo(() => {
    return database.department?.adminLevel ?? 4;
  }, [database.department]);

  const [isEditMode, setIsEditMode] = useState(adminAccess && editMode);

  const [temporaryStatus, setTemporaryStatus] = useState<
    ProgressStatus | undefined
  >();
  const [isOpenSideOut, setIsOpenSideOut] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedWorkbookID, setSelectedWorkbookID] = useState('');
  const [workbook, setWorkbook] = useState<any>([]);
  const [filteredWorkbooks, setFilteredWorkbooks] =
    useState<Workbook[]>(workbook);

  const [modalType, setModalType] = useState('');
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [newExtractedWorkbookKey, setNewExtractedWorkbookKey] = useState('');
  const [isParsedSuccessfull, setIsParsedSuccessfull] = useState(false);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [routeToProtocolWarning, setRouteToProtocolWarning] = useState(false);

  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isActiveStateModal, setIsActiveStateModal] = useState(false);

  const [file, setFile] = useState<File | undefined>();
  const [selectedWorkbookURL, setSelectedWorkbookURL] = useState<
    string | null
  >();

  const [dropState, setDropState] = useState({
    isDragActive: false,
  });
  const [selectedMedicationDose, setSelectedMedicationDose] = useState<
    MedicationSubItem | undefined
  >();
  const [selectedInfusionDose, setSelectedInfusionDose] = useState<
    InfusionSubItem | undefined
  >();
  const [selectedElectricalDose, setSelectedElectricalDose] = useState<
    ElectricalSubItem | undefined
  >();
  const [sideoutData, setSideoutData] = useState<any>(null);
  const objURL = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (selectedWorkbookURL) return selectedWorkbookURL;
    return '';
  }, [file, selectedWorkbookURL]);

  const [originalProtocolData, setOriginalProtocolData] = useState<any>({
    equipment: protocol.equipment,
    forms: protocol.forms,
    pairedProtocols: protocol.pairedProtocols,
    sum: protocol.sum,
  });

  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>(
    findPairedDepartments(department, protocol) ?? []
  );

  const [reorderedLists, setReorderedLists] = useState<any>({
    medications: [],
    infusions: [],
    equipment: [],
    electrical: [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: protocol.name,
      nickname: protocol.nickname,
      rangeLow: protocol.rangeLow === 0 ? '' : protocol.rangeLow + '',
      rangeHigh:
        protocol.rangeHigh === globals.MAX_VALUE ? '' : protocol.rangeHigh + '',
      isRestrictive: protocol.isRestrictive,
      activeID: protocol.activeID,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Protocol ame is required'),
      nickname: Yup.string().required('Protocol nickname is required'),
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
    }),
    onSubmit: (values) => {
      if (globals.debug) console.log('values', values);
    },
  });

  useEffect(() => {
    if (protocol) {
      let allProts = protocol.parent.protocols;
      let indexx = allProts.findIndex(
        (item: ProtocolItem) => item.uid === protocol.uid
      );
      setCurIndex(indexx);

      setPairedDeps(findPairedDepartments(department, protocol) ?? []);

      setNewPairedProtocols(protocol.pairedProtocols);
      setNewEquipment(protocol.equipment);
      setNewChecklist(protocol.forms);
    }
  }, [protocol, department]);

  useEffect(() => {
    setDepartmentOwner(findDepartmentOwner(department, protocol));
    let adminAccess = hasAdminUserAccess(user, protocol);
    setAdminAccess(adminAccess);
    if (!adminAccess) setIsEditMode(false);
  }, [protocol, department, user]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        handleBack();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setOriginalProtocolData({
      equipment: protocol.equipment,
      forms: protocol.forms,
      pairedProtocols: protocol.pairedProtocols,
      sum: protocol.sum,
    });
  }, [protocol]);

  useEffect(() => {
    const getDetails = async () => {
      const result = await getAllWorkbooks(department.id);
      setWorkbook(result.data);
      setFilteredWorkbooks(result.data);
    };
    getDetails();
  }, []);

  useEffect(() => {
    setNewChecklist(selectedProtocol.forms);
    setNewEquipment(selectedProtocol.equipment);
    setNewPairedProtocols(selectedProtocol.pairedProtocols);
    setReorderedLists({
      medications: [],
      infusions: [],
      equipment: [],
      electrical: [],
    });
    setProtocol(selectedProtocol);
    if (selectedProtocol.sum === 0 && selectedTab === 'Details')
      setSelectedTab('Pdf');
  }, [selectedProtocol]);

  const handleItemClick = (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol',
    state?: string
  ) => {
    if ('Paired Protocol' === type) {
      if (isSaveActive) {
        setSelectedData(item);
        setRouteToProtocolWarning(true);
      } else {
        setPdfLoaded(false);
        let p = item as ProtocolItem;
        navigate(`/${p.nickname}/protocol-detail`, {
          state: { selectedProtocol: p, editMode: false },
        });
      }
    } else if (type === 'Medication') {
      setSelectedMedicationDose(item as MedicationSubItem);
      // setShowMedicationProtocolSideout(true);
      // setSideoutData(state);
    } else if (type === 'Electrical' && state !== null) {
      setSelectedElectricalDose(item as ElectricalSubItem);
      // setShowElectricalProtocolSideout(true);
      // setSideoutData(state);
    } else if (type === 'Infusion' && state !== null) {
      setSelectedInfusionDose(item as InfusionSubItem);

      // setShowInfusionProtocolSideout(true);
      // setSideoutData(state);
    }
  };

  const handleRemoveItem = (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => {
    if (type === 'Paired Protocol') {
      if (isEditMode) {
        let list = newPairedProtocols.filter(
          (i: ProtocolItem) => i.uid !== item.uid
        );
        setNewPairedProtocols(list);
        protocol.sum -= 1;
      }
    } else if (type === 'Equipment') {
      if (isEditMode) {
        let list = newEquipment.filter(
          (i: EquipmentItem) => i.uid !== item.uid
        );
        setNewEquipment(list);
        protocol.sum -= 1;
      }
    } else if (type === 'Checklist') {
      if (isEditMode) {
        let list = newChecklist.filter((i: FormItem) => i.uid !== item.uid);
        setNewChecklist(list);
        protocol.sum -= 1;
      }
    }
  };

  const isSaveActive = useMemo(() => {
    if (!isEditMode) return false;
    if (isPdfModal) return file != null || isParsedSuccessfull;
    else {
      if (newPairedProtocols.length !== protocol.pairedProtocols.length)
        return true;
      else {
        for (let i = 0; i < newPairedProtocols.length; i++) {
          if (newPairedProtocols[i].uid !== protocol.pairedProtocols[i].uid) {
            return true;
          }
        }
      }
      if (newEquipment.length !== protocol.equipment.length) return true;
      else {
        for (let i = 0; i < newEquipment.length; i++) {
          if (newEquipment[i].uid !== protocol.equipment[i].uid) {
            return true;
          }
        }
      }
      if (newChecklist.length !== protocol.forms.length) return true;
      else {
        for (let i = 0; i < newChecklist.length; i++) {
          if (newChecklist[i].uid !== protocol.forms[i].uid) {
            return true;
          }
        }
      }

      if (reorderedLists.medications.length > 0) return true;
      if (reorderedLists.infusions.length > 0) return true;
      if (reorderedLists.equipment.length > 0) return true;
      if (reorderedLists.electrical.length > 0) return true;
      if (protocol.isRestrictive !== formik.values.isRestrictive) return true;
      else if (protocol.isRestrictive) {
        if (
          pairedDeps.map((item: any) => item.id).join(',') !==
          protocol.pairedDeps.map((item: any) => item.id).join(',')
        )
          return true;
      }
    }
    return formik.dirty;
  }, [
    isParsedSuccessfull,
    isEditMode,
    newPairedProtocols,
    newEquipment,
    newChecklist,
    reorderedLists,
    file,
    isPdfModal,
    formik,
    protocol,
    pairedDeps,
  ]);

  // // set the edit mode
  // useEffect(() => {
  //   if (state?.editMode) {
  //     setIsEditMode(true);
  //   }
  // }, [state]);

  useEffect(() => {
    const loadPDF = async () => {
      let result: Response = await fetchPDF(protocol.pdfUrl);
      if (result.type === ResponseType.Success) setPdfUrl(result.data);
      else {
        console.error(
          'ERROR: Could not load pdf for protocol:' + protocol.name,
          result.data
        );
        setPdfUrl('');
      }
    };
    if (protocol.pdfUrl && !pdfLoaded) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [protocol, pdfLoaded]);

  useEffect(() => {
    setPdfLoaded(false);
    setProtocol(selectedProtocol);
  }, [selectedProtocol]);

  /* 10-02-23 Arul: handle function for switching the tabs*/
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  /* 10-02-23 Arul: handle go back to protocol page*/
  const handleBack = () => {
    if (isPdfModal) {
      setFile(undefined);
      setIsPdfModal(false);
      return;
    }
    if (backPage === 'list-protocols') {
      navigate(`/database/list-protocols`, {
        state: {
          department: department,
          data: database.protocols,
          database: database,
        },
      });
    } else {
      if (isEditMode) {
        navigate(`/protocol/edit`);
      } else {
        navigate('/protocol');
      }
      dispatch<any>(handleUpdateSelectedAccordion([]));
    }
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
        if (modalType === 'Add') {
          setIsOpenSideOut(true);
        }
        setModalType('');
        reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
          if (newDB) updateCurrentProtocol(newDB);
        });
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department]);

  /* 11-14-23 Praveen: function for handling to open sideout*/
  const handleAdd = useCallback(() => {
    if (isEditMode) {
      setIsOpenSideOut(true);
    } else {
      handleCheckIsDraft().then((isDraft: boolean) => {
        if (isDraft) {
          setIsOpen(true);
          setModalType('Add');
        } else {
          setIsOpenSideOut(true);
        }
      });
      // setModalType('Add')
    }
  }, [isEditMode, handleCheckIsDraft]);

  /* 11-14-23 Praveen: function for draft continue button*/
  const handleContinue = useCallback(() => {
    setIsEditMode(true);
    setIsOpen(false);
    if (modalType === 'Add') {
      setIsOpenSideOut(true);
    }
    setModalType('');
  }, [modalType]);

  const handleUploadpdf = (data: any) => {
    let url;
    if (data?.pdf.name) {
      url = URL?.createObjectURL(data?.pdf);
    }
    setPdfUrl(data?.pdf?.name ? url : data.pdf);
    setIsPdfModal(false);
  };

  const onReorder = async (
    list: (
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => {
    console.log('onReorder', list, type);
    /* Check if the list is different from the original list */
    let isDifferent = false;
    for (let i = 0; i < list.length; i++) {
      let m1 = list[i];
      let m2;
      if (type === 'Medication') m2 = protocol.medications[i];
      else if (type === 'Infusion') m2 = protocol.infusions[i];
      else if (type === 'Equipment') m2 = protocol.equipment[i];
      else if (type === 'Electrical') m2 = protocol.electrical[i];

      if (!isObjectEqual(m1, m2)) {
        isDifferent = true;
        break;
      }
    }

    if (isDifferent) {
      if (type === 'Medication')
        setReorderedLists({ ...reorderedLists, medications: list });
      else if (type === 'Infusion')
        setReorderedLists({ ...reorderedLists, infusions: list });
      else if (type === 'Equipment')
        setReorderedLists({ ...reorderedLists, equipment: list });
      else if (type === 'Electrical')
        setReorderedLists({ ...reorderedLists, electrical: list });
    }
  };

  async function getProtocolsByIds(ids: string[] | any, type: string) {
    const addedProtocols: any = [];
    const idsArray = Array.isArray(ids) ? ids : [ids];

    for (const id of idsArray) {
      let protocol;
      if (type === 'Paired Protocol') {
        protocol = await DataStore.query(Protocol, id);
      } else if (type === 'Equipment') {
        protocol = await DataStore.query(Equipment, id);
      } else {
        protocol = await DataStore.query(Form, id);
      }
      if (protocol) {
        addedProtocols.push(protocol);
      }
    }
    return addedProtocols;
  }

  const handleAddPairedProtocols = async (
    protocolIDtoPair: string[],
    type: string
  ) => {
    const fetchedProtocols = await getProtocolsByIds(protocolIDtoPair, type);
    if (type === 'Paired Protocol') {
      if (globals.debug) console.log('fetchedProtocols', fetchedProtocols);
      const updatedPairedProtocols = [
        ...protocol.pairedProtocols,
        ...fetchedProtocols,
      ];

      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            pairedProtocols: updatedPairedProtocols,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    } else if (type === 'Equipment') {
      const updatedEquipment = [...protocol.equipment, ...fetchedProtocols];
      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            equipment: updatedEquipment,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    } else {
      const updatedForms = [...protocol.forms, ...fetchedProtocols];
      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            forms: updatedForms,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    }
  };

  const handleSetFromSideout = (
    items: ProtocolItem[] | EquipmentItem[] | FormItem[],
    type: string
  ) => {
    if (globals.debug) console.log('handleSetFromSideout', items, type);
    if (!isEditMode) setIsEditMode(true);
    // setSelectedData(selectedList);
    if (type === 'Paired Protocol') {
      let list: ProtocolItem[] = [...items] as ProtocolItem[];
      //Filter out the duplicates
      list = list.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.uid === item.uid)
      );
      setNewPairedProtocols(list as ProtocolItem[]);
      protocol.sum += items.length;
    } else if (type === 'Equipment') {
      let list = [...items];
      //Filter out the duplicates
      list = list.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.uid === item.uid)
      );
      setNewEquipment(list as EquipmentItem[]);
      protocol.sum += items.length;
    } else {
      const item: any = items as FormItem[];
      setNewChecklist(item);
      protocol.sum += items.length;
    }
  };

  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    if (globals.debug) console.log('loading database');
    const resp: Response = await loadDatabase(
      database.department,
      undefined,
      true
    );
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    }
  };

  /**
   * Handle the save button being pressed there are two different save buttons
   *  - The save button for the PDF modal
   *  - The save button for the protocol details page for Re-ordering the ModelItems
   */
  const handleSave = async () => {
    if (isPdfModal) {
      if (file != null) {
        setLoading(true);
        let newProtocol = cloneProtocol(protocol);
        let prevVersion = protocol.version;
        let prevPdfVersion = protocol.pdfVersion;
        newProtocol.version = upgradeVersion(protocol.version);
        newProtocol.pdfVersion = upgradeVersion(protocol.pdfVersion);
        let result: Response = await uploadNewPDFToS3(
          department,
          newProtocol,
          file
        );
        if (result.type === ResponseType.Success) {
          setFile(undefined);
          setIsPdfModal(false);

          newProtocol.pdfUrl = result.data;
          newProtocol.activeID =
            protocol.status === ProgressStatus.ACTIVE
              ? protocol.uid
              : protocol.activeID;
          newProtocol.status = ProgressStatus.DRAFT;
          newProtocol.modifiedBy = user;
          result = await createProtocol(newProtocol, protocol);

          if (result.type === ResponseType.Success) {
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
          } else {
            console.error('Failed to create new protocol', result.data);
            protocol.version = prevVersion;
            protocol.pdfVersion = prevPdfVersion;
            alert(
              'Failed to create new protocol: ' +
                protocol.name +
                ' with new PDF:\n' +
                result.data
            );
          }
          setPdfLoaded(false);
        } else {
          alert('Failed to upload new PDF to S3');
          if (globals.debug)
            console.log('Failed to upload new PDF to S3', result.data);
          protocol.version = prevVersion;
          protocol.pdfVersion = prevPdfVersion;
        }
        setLoading(false);
        setIsEditMode(false);
        setIsParsedSuccessfull(false);
      } else if (isParsedSuccessfull) {
        setLoading(true);
        const pdfKey = newExtractedWorkbookKey;
        try {
          const signedUrlResponse: any = await Storage.get(pdfKey, {
            level: 'public',
            download: true,
          });
          if (!signedUrlResponse.Body)
            throw new Error('Failed to download PDF.');

          const blob = signedUrlResponse.Body;
          const fileName = 'workbookParsed.pdf';
          const lastModified = Date.now();
          const convertedToFile = new File([blob], fileName, {
            type: 'application/pdf',
            lastModified,
          });
          let prevVersion = protocol.version;
          let prevPdfVersion = protocol.pdfVersion;
          protocol.version = upgradeVersion(protocol.version);
          protocol.pdfVersion = upgradeVersion(protocol.pdfVersion);
          let result: Response = await uploadNewPDFToS3(
            department,
            protocol,
            convertedToFile
          );
          if (result.type === ResponseType.Success) {
            setFile(undefined);
            setIsPdfModal(false);
            let newProtocol = cloneProtocol(protocol);

            newProtocol.pdfUrl = result.data;
            newProtocol.activeID =
              protocol.status === ProgressStatus.ACTIVE
                ? protocol.uid
                : protocol.activeID;
            newProtocol.status = ProgressStatus.DRAFT;
            newProtocol.modifiedBy = user;
            result = await createProtocol(newProtocol, protocol);

            if (result.type === ResponseType.Success) {
              reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
                if (newDB) updateCurrentProtocol(newDB);
              });
              removeParsedPDF();
            } else {
              console.error('Failed to create new protocol', result.data);
              protocol.version = prevVersion;
              protocol.pdfVersion = prevPdfVersion;
              alert(
                'Failed to create new protocol: ' +
                  protocol.name +
                  ' with new PDF:\n' +
                  result.data
              );
            }
            setPdfLoaded(false);
            setIsParsedSuccessfull(false);
          } else {
            alert('Failed to upload new PDF to S3');
            if (globals.debug)
              console.log('Failed to upload new PDF to S3', result.data);
            protocol.version = prevVersion;
            protocol.pdfVersion = prevPdfVersion;
          }
          setLoading(false);
          setIsEditMode(false);
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
      }
    } else {
      let p = cloneProtocol(protocol);

      if (isSaveActive) {
        // if (newPairedProtocols)
        p.pairedProtocolIDs = newPairedProtocols.map(
          (item: ProtocolItem) => item.activeID || item.uid
        );
        // if (newEquipment)
        p.equipmentIDs = newEquipment.map(
          (item: EquipmentItem) => item.activeID || item.uid
        );
        // if (newChecklist)
        p.checklistIDs = newChecklist.map(
          (item: FormItem) => item.activeID || item.uid
        );
        p.name = formik.values.name;
        p.nickname = formik.values.nickname;
        p.pairedDeps = pairedDeps;
        p.pairedDepIDs = pairedDeps.map((item: any) => item.id);
        p.rangeLow = Number(formik.values.rangeLow);
        p.rangeHigh = Number(
          formik.values.rangeHigh === ''
            ? globals.MAX_VALUE
            : formik.values.rangeHigh
        );
        p.isRestrictive = formik.values.isRestrictive;
        p.activeID = p.status === ProgressStatus.ACTIVE ? p.uid : p.activeID;
        p.status = ProgressStatus.DRAFT;
        p.version = upgradeVersion(p.version);
        p.modifiedBy = user;
        console.log('SETTING PROTOCOL', p);
        let result = await createProtocol(p, protocol);
        if (result.type === ResponseType.Success) {
          let newProtocol = result.data as ProtocolItem;
          reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
            if (newDB) updateCurrentProtocol(newDB, newProtocol);
          });
        } else {
          console.error('Failed to create new protocol', result.data);
        }
      }

      if (newPairedProtocols || newEquipment || newChecklist) {
        console.log('newPairedProtocols', newPairedProtocols);
        console.log('newEquipment', newEquipment);
        console.log('newChecklist', newChecklist);
        // let result = await addIDtoProtocol(
        //   p,
        //   newEquipment
        //     ? newEquipment?.map((item: EquipmentItem) => item.uid)
        //     : [],
        //   newChecklist && newChecklist.length > 0 ? newChecklist[0].uid : '',
        //   newPairedProtocols
        //     ? newPairedProtocols?.map((item: ProtocolItem) => item.uid)
        //     : [],
        //   user.id
        // );
        // if (result.type === ResponseType.Success) {
        //   let newProtocol = result.data as ProtocolItem;
        //   setProtocol(newProtocol);
        //   setNewChecklist([]);
        //   setNewEquipment(undefined);
        //   setNewPairedProtocols(undefined);
        //   reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
        //     if (newDB) updateCurrentProtocol(newDB, newProtocol);
        //   });
        // } else {
        //   console.error('Failed to add ID to protocol', result.data);
        // }
      }
      if (reorderedLists.medications.length > 0) {
        let promises: any[] = [];
        let meds: MedicationSubItem[] = [];
        for (let i = 0; i < reorderedLists.medications.length; i++) {
          let medication = reorderedLists.medications[i] as MedicationSubItem;
          if (medication.index !== i) {
            let newMed = cloneMedicationSubItem(medication);
            if (database.department.parentDep?.parentDep) i += 10000;
            else if (database.department.parentDep) i += 1000;
            newMed.index = i;
            newMed.status = ProgressStatus.DRAFT;
            newMed.version = upgradeVersion(medication.version);
            newMed.activeID =
              medication.status === ProgressStatus.ACTIVE
                ? medication.uid
                : medication.activeID;

            promises.push(createMedicationDoseItem(newMed, medication));
          } else {
            meds.push(medication);
          }
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          let result = results[i];
          if (result.type === ResponseType.Failure) {
            console.error('Failed to reorder medications', result.data);
          } else {
            meds.push(result.data as MedicationSubItem);
          }
        }
        p.medications = meds.sort(
          (m1: MedicationSubItem, m2: MedicationSubItem) => {
            if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
            return m1.index - m2.index;
          }
        );
        console.log('SUCCESS: Reordered Medications', meds);
      }

      if (reorderedLists.infusions.length > 0) {
        let promises: any[] = [];
        for (let i = 0; i < reorderedLists.infusions.length; i++) {
          let infusion = reorderedLists.infusions[i] as InfusionSubItem;
          if (infusion.index !== i) {
            let newMed = cloneInfusionSubItem(infusion);
            if (database.department.parentDep?.parentDep) i += 10000;
            else if (database.department.parentDep) i += 1000;
            newMed.index = i;
            newMed.status = ProgressStatus.DRAFT;
            newMed.version = upgradeVersion(infusion.version);
            newMed.activeID =
              infusion.status === ProgressStatus.ACTIVE
                ? infusion.uid
                : infusion.activeID;

            promises.push(createInfusionDoseItem(newMed, infusion));
          }
        }
        let results = await Promise.all(promises);
        let meds: InfusionSubItem[] = [];
        for (let i = 0; i < results.length; i++) {
          let result = results[i];
          if (result.type === ResponseType.Failure) {
            console.error('Failed to reorder medications', result.data);
          } else {
            meds.push(result.data as InfusionSubItem);
          }
        }
        p.infusions = meds.sort((m1: InfusionSubItem, m2: InfusionSubItem) => {
          if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
          return m1.index - m2.index;
        });
        console.log('SUCCESS: Reordered Infusions', meds);
      }

      if (reorderedLists.electrical.length > 0) {
        let promises: any[] = [];
        for (let i = 0; i < reorderedLists.electrical.length; i++) {
          let elect = reorderedLists.electrical[i] as ElectricalSubItem;
          if (elect.index !== i) {
            let newMed = cloneElectricalSubItem(elect);
            if (database.department.parentDep?.parentDep) i += 10000;
            else if (database.department.parentDep) i += 1000;
            newMed.index = i;
            newMed.status = ProgressStatus.DRAFT;
            newMed.version = upgradeVersion(elect.version);
            newMed.activeID =
              elect.status === ProgressStatus.ACTIVE
                ? elect.uid
                : elect.activeID;

            promises.push(createElectricalDoseItem(newMed, elect));
          }
        }
        let results = await Promise.all(promises);
        let meds: InfusionSubItem[] = [];
        for (let i = 0; i < results.length; i++) {
          let result = results[i];
          if (result.type === ResponseType.Failure) {
            console.error('Failed to reorder medications', result.data);
          } else {
            meds.push(result.data as InfusionSubItem);
          }
        }
        p.infusions = meds.sort((m1: InfusionSubItem, m2: InfusionSubItem) => {
          if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
          return m1.index - m2.index;
        });
        console.log('SUCCESS: Reordered Infusions', meds);
      }

      setProtocol(p);
      setReorderedLists({
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
      });
    }
  };

  const handleCancel = () => {
    if (isSaveActive && !isCancelWarningModal) {
      setIsCancelWarningModal(true);
    } else {
      setIsEditMode(false);
      // setProtocol(originalProtocolData);
      let p = cloneProtocol(protocol);
      setProtocol(p);
      setNewPairedProtocols(p.pairedProtocols);
      setNewEquipment(p.equipment);
      setNewChecklist(p.forms);
      setReorderedLists({
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
      });
    }
  };

  const handleDeleteProtocol = async (isPermanent: boolean) => {
    let result: Response = await deleteProtocol(
      department,
      protocol,
      user,
      !isPermanent
    );
    if (result.type === ResponseType.Success) {
      if (globals.debug)
        console.log('Successfully deleted protocol', result.data);
      reloadDatabase();
      setIsDeleteModal(false);
      navigate('/protocol');
    } else {
      console.error('Failed to delete protocol', result.data);
    }
  };

  const updateCurrentProtocol = (
    newDB: DatabaseResponse,
    parmProtocol?: ProtocolItem
  ) => {
    let p = parmProtocol ? parmProtocol : protocol;
    let id =
      p.status === ProgressStatus.DRAFT && p.activeID ? p.activeID : p.uid;
    for (let i = 0; i < newDB.protocols.length; i++) {
      let p = newDB.protocols[i];
      let pID =
        p.status === ProgressStatus.DRAFT && p.activeID ? p.activeID : p.uid;
      if (pID === id) {
        console.log('FOUND UPDATED PROTOCOL', p);
        setProtocol(p);
      }
    }
  };

  const handleParsingPDF = async (id: string) => {
    if (!id) return;
    setIsPdfLoading(true);
    setStartPage('');
    setEndPage('');

    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();
    const apiUrl = url;
    const requestBody = {
      bucket: bucketName,
      key: `public/${id}`,
      startPage,
      endPage: endPage ? endPage : startPage,
      customName: `workBook-${startPage}-${endPage}`,
      depID: department.id,
    };
    if (globals.debug) console.log(requestBody, 'URL');

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const parsedBody = JSON.parse(result.body);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      if (globals.debug) console.log(processedPdfUrl, 'DATA');
      const key = processedPdfUrl.split('.com/')[1];
      const keywWithoutPublic = key.split('public/')[1];
      const extractedWorkbook = await Storage.get(keywWithoutPublic, {
        level: 'public',
        contentType: 'application/pdf',
      });
      setNewExtractedWorkbookKey(keywWithoutPublic);
      setIsParsedSuccessfull(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsPdfLoading(false);
    }
  };

  const removeParsedPDF = async () => {
    if (isParsedSuccessfull && newExtractedWorkbookKey) {
      try {
        await Storage.remove(newExtractedWorkbookKey, { level: 'public' });
        if (globals.debug) console.log('Parsed PDF deleted successfully');
      } catch (error) {
        console.error('Error deleting parsed PDF from S3:', error);
      }
    }
  };

  const handleViewFullScreen = () => {
    if (pdfUrl !== '' || objURL !== '') {
      if (globals.debug) console.log('pdfUrl', objURL !== '' ? objURL : pdfUrl);
      localStorage.setItem('pdfURL', objURL !== '' ? objURL : pdfUrl);
      localStorage.setItem('pdfName', protocol.name);
      let filterName = protocol.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        pdfUrl + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  const handleActivateState = async (isDeactivate: boolean) => {
    setIsActiveStateModal(false);
  };

  return (
    <div className="screen-container">
      {selectedMedicationDose && (
        <NewMedicationDoseSideout
          visible={selectedMedicationDose != null}
          protocol={protocol}
          dose={selectedMedicationDose}
          parentModel={selectedMedicationDose.parent}
          handleClose={() => setSelectedMedicationDose(undefined)}
          handleSubmit={() => {
            console.log('submit');
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
          }}
        />
      )}

      {selectedInfusionDose && (
        <NewInfusionDoseSideout
          visible={selectedInfusionDose != null}
          protocol={protocol}
          dose={selectedInfusionDose}
          parentModel={selectedInfusionDose.parent}
          handleClose={() => setSelectedInfusionDose(undefined)}
          handleSubmit={() => {
            console.log('submit');
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
          }}
        />
      )}
      {selectedElectricalDose && (
        <NewElectricalDoseSideout
          visible={selectedElectricalDose != null}
          protocol={protocol}
          dose={selectedElectricalDose}
          parentModel={selectedElectricalDose.parent}
          handleClose={() => setSelectedElectricalDose(undefined)}
          handleSubmit={() => {
            console.log('submit');
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
          }}
        />
      )}

      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={handleContinue}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      <AddProtocolSideout
        isVisible={isOpenSideOut}
        isEditMode={isEditMode}
        page={'protocolDetailPage'}
        protocol={protocol}
        handleAdd={handleSetFromSideout}
        title={'modalName'}
        handleClose={() => {
          setIsOpenSideOut(false);
        }}
        data-testid="add-edit-protocol-modal"
        selectedEquipment={newEquipment}
        selectedForm={newChecklist}
        selectedProtocols={newPairedProtocols}
        handleSubmit={() => {
          setIsOpenSideOut(false);
          reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
            if (newDB) updateCurrentProtocol(newDB);
          });
        }}
      />

      <ConfirmModal
        isVisible={isCancelWarningModal}
        title="Abandon Changes?"
        handleClose={() => {
          setIsCancelWarningModal(false);
        }}
        handleSubmit={handleCancel}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Abandon"
        primaryDescription={`Changes were made to this Protocol.  Click cancel to return to Protocol.  To cancel without saving changes, select Abandon.`}
      />

      <ConfirmModal
        isVisible={routeToProtocolWarning}
        title="Abandon Changes?"
        handleClose={() => {
          setRouteToProtocolWarning(false);
        }}
        handleSubmit={() => {
          setRouteToProtocolWarning(false);
          let p = selectedData as ProtocolItem;
          navigate(`/${p.nickname}/protocol-detail`, {
            state: { selectedProtocol: p, editMode: false },
          });
          setSelectedData(null);
        }}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Route"
        primaryDescription={`Changes were made to this Protocol.  Click cancel to return to Protocol.  To cancel without saving changes, select Abandon and continue to route to the protocol.`}
      />

      <ConfirmModal
        isVisible={isDeleteModal}
        title={
          'Delete ' +
          protocol.name +
          (protocol.status === ProgressStatus.DRAFT ? ' (Draft)' : '') +
          '?'
        }
        handleClose={() => {
          setIsDeleteModal(false);
        }}
        handleSubmit={handleDeleteProtocol}
        // isPermanentlyDeleteBtn={true}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Delete"
        primaryDescription={`Are you sure that you want to delete ${protocol.name}? The protocol has ${protocol.sum} sub items. The protocol will be able to be recovered from the trash bin.`}
      />

      <ConfirmModal
        isVisible={isActiveStateModal}
        title={
          protocol.status === ProgressStatus.DEACTIVATED
            ? 'Activate'
            : 'Deactivate'
        }
        handleClose={() => {
          setIsActiveStateModal(false);
        }}
        handleSubmit={() => {
          handleActivateState(protocol.status === ProgressStatus.DEACTIVATED);
        }}
        // isPermanentlyDeleteBtn={true}
        isDeleteBtn={protocol.status !== ProgressStatus.DEACTIVATED}
        primaryBtnName="Cancel"
        secondaryBtnName={
          protocol.status === ProgressStatus.DEACTIVATED
            ? 'Activate'
            : 'Deactivate'
        }
        primaryDescription={
          protocol.status === ProgressStatus.DEACTIVATED
            ? `Are you sure that you want to activate ${protocol.name}? This will make the protocol visible to all users.`
            : `Are you sure that you want to deactivate ${protocol.name}? This will hide the protocol from all users.`
        }
      />

      {reviewSideOutVisible && (
        <ReviewSideOut
          database={database}
          isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
          handleClose={() => {
            setReviewSideOutVisible(false);
          }}
          setVisible={setReviewSideOutVisible}
          handleAdd={() => {
            setReviewSideOutVisible(false);
          }}
          onPublish={() => {
            setReviewSideOutVisible(false);
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
            // handleCancel();
          }}
        />
      )}

      {/* <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header"> */}
      <ProtocolHeader
        name={
          isPdfModal ? 'Upload PDF' : protocol.name
          // (protocol.status === ProgressStatus.DRAFT ? ' (Draft)' : '') +
          // (temporaryStatus === ProgressStatus.DRAFT ? ' (Draft)' : '')
        }
        isDotButton={false}
        status={temporaryStatus != null ? temporaryStatus : protocol.status}
        description={'Folder: ' + protocol.parent.name}
        isBackButton={true}
        isDeleteButton={isLoggedIn && !isPdfModal && isEditMode}
        isFullScreenButton={
          (selectedTab === 'Pdf' && !isPdfModal) ||
          (isPdfModal && file != null) ||
          (isPdfModal && selectedWorkbookURL !== '')
        }
        handleFullScreen={() => {
          handleViewFullScreen();
        }}
        // isDeactivateButton={
        //   isLoggedIn &&
        //   (adminLevel === 1 || adminLevel === 3) &&
        //   protocol.status !== ProgressStatus.DEACTIVATED
        // }
        // isDeactivateActive={isLoggedIn}
        // handleDeactivate={() => {
        //   setIsActiveStateModal(true);
        // }}
        // isReactivateButton={
        //   isLoggedIn &&
        //   (adminLevel === 1 || adminLevel === 3) &&
        //   protocol.status === ProgressStatus.DEACTIVATED
        // }
        // isReactivateActive={isLoggedIn}
        // handleReactivate={() => {
        //   setIsActiveStateModal(true);
        // }}
        isReviewButton={
          isLoggedIn &&
          adminAccess &&
          !isPdfModal &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        isReviewActive={true}
        handleDelete={() => setIsDeleteModal(true)}
        handleReview={() => setReviewSideOutVisible(true)}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft) setIsEditMode(true);
          });
        }}
        handleSave={() => {
          handleSave();
        }}
        handleCancelEdit={() => {
          removeParsedPDF();
          protocol.equipment = originalProtocolData.equipment;
          protocol.forms = originalProtocolData.forms;
          protocol.pairedProtocols = originalProtocolData.pairedProtocols;
          protocol.sum = originalProtocolData.sum;
          if (protocol.sum === 0 && selectedTab === 'Details')
            setSelectedTab('Pdf');
          setNewPairedProtocols(originalProtocolData.pairedProtocols);
          setNewEquipment(originalProtocolData.equipment);
          setNewChecklist(originalProtocolData.forms);
          setIsParsedSuccessfull(false);
          setSelectedData(null);
          if (isPdfModal) {
            setFile(undefined);
            setIsPdfModal(false);
          } else setIsEditMode(false);
          formik.resetForm();
          setFile(undefined);
          setSelectedWorkbookURL('');
        }}
        // isVersionButton={isLoggedIn && !isPdfModal}
        type={'protocol'}
        isEditIcon={
          isLoggedIn &&
          adminAccess &&
          !isEditMode &&
          user &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        handleCancel={handleBack}
        isSaveButton={isLoggedIn && isEditMode}
        isSaveActive={isSaveActive}
        isAddButton={
          isLoggedIn &&
          !isPdfModal &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        rightSideBtn={isPdfModal ? 'save' : 'add'}
        handleAdd={handleAdd}
        isUploadButton={
          isLoggedIn &&
          isEditMode &&
          selectedTab === 'Pdf' &&
          (!isPdfModal || file != null)
        }
        handleUpload={() => {
          if (isPdfModal) {
            if (file != null) setFile(undefined);
            else setIsPdfModal(false);
          } else setIsPdfModal(true);
        }}
        itemOwner={
          department.isMultiDep ? departmentOwner ?? undefined : undefined
        }
        page={isPdfModal ? protocol.name : 'Folders'}
        isArrowButtons={!isPdfModal}
        isLeftArrowActive={curIndex !== 0}
        isRightArrowActive={curIndex !== protocol.parent.protocols.length - 1}
        isLockButton={!isLoggedIn || !adminAccess}
        handleArrow={(isRight: boolean) => {
          if (isRight) {
            if (curIndex === protocol.parent.protocols.length - 1) return;
            let nextProtocol = protocol.parent.protocols[curIndex + 1];
            navigate(`/${protocol.parent.name}/protocol-detail`, {
              state: { selectedProtocol: nextProtocol },
            });
            setSelectedTab(
              isLoggedIn && nextProtocol.sum !== 0 ? 'Details' : 'Pdf'
            );
          } else {
            if (curIndex <= 0) return;
            let prevProtocol = protocol.parent.protocols[curIndex - 1];
            navigate(`/${protocol.parent.name}/protocol-detail`, {
              state: { selectedProtocol: prevProtocol },
            });
            setSelectedTab(
              isLoggedIn && prevProtocol.sum !== 0 ? 'Details' : 'Pdf'
            );
          }
        }}
      />
      {/* </div> */}

      {itemSelected === '' && (
        <div className="backgroundHidden">
          {isPdfModal ? (
            <UploadProtocolPDF
              protocol={protocol}
              fileValue={file}
              department={department}
              onWorkbookSelected={(workbookURL: string) => {
                setSelectedWorkbookURL(workbookURL);
              }}
              onUpload={(file: File) => {
                setFile(file);
              }}
            />
          ) : (
            <>
              <div>
                {isLoggedIn && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: ' space-between',
                    }}
                  >
                    <div className="buttonContainer">
                      <Button
                        className={`secondary-button-gray btn-rightMargin ${
                          selectedTab === 'Details'
                            ? 'calculation-active btn-active'
                            : 'calculation-inActive'
                        }`}
                        onClick={() => handleTabClick('Details')}
                        disabled={!isLoggedIn}
                      >
                        Details
                      </Button>

                      <Button
                        className={`secondary-button-gray btn-rightMargin ${
                          selectedTab === 'Pdf'
                            ? 'btn-active pdf-active'
                            : 'pdf-inActive'
                        }`}
                        onClick={() => handleTabClick('Pdf')}
                      >
                        Pdf
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {selectedTab === 'Details' && isLoggedIn && (
                <CalculationScreen
                  formik={formik}
                  isEditMode={isEditMode}
                  protocolDetail={protocol}
                  onReorder={onReorder}
                  newPairedProtocols={newPairedProtocols}
                  newEquipment={newEquipment}
                  newChecklists={newChecklist}
                  onItemClick={handleItemClick}
                  pairedDeps={pairedDeps}
                  setPairedDeps={setPairedDeps}
                  onRemoveItem={handleRemoveItem}
                />
              )}
              {selectedTab === 'Pdf' || !isLoggedIn ? (
                <PDFScreen pdf={pdfUrl} />
              ) : null}
              {/* {selectedTab === "Checklists" && isLoggedIn && (
                <Checklist isEdit={isEditMode} protocol={protocol} />
              )} */}
            </>
          )}
        </div>
      )}
      {loading && <Loading type="bubbles" />}
    </div>
  );
};

export default ProtocolDetails;
