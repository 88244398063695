// Note: EditDB for ProtocolItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';
import { Storage } from '@aws-amplify/storage';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import CategoryItem from '../model/CategoryItem';
import { ProgressStatus } from '../../API';

import { globals, upgradeVersion } from '../../ui/_global/common/Utils';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import DepartmentItem from '../model/DepartmentItem';
import { create } from 'lodash';
import {
  deleteMedicationsForProtocol,
  duplicateMedicationDose,
} from './MedicationDB';
import {
  deleteInfusionsForProtocol,
  duplicateInfusionDose,
} from './InfusionDB';
import {
  deleteElectricalsForProtocol,
  duplicateElectricalDose,
} from './ElectricalDB';
import KeychainItem from '../model/KeychainItem';
import { executeQuery } from '../GraphQL_API';
import { graphqlOperation } from 'aws-amplify';
import { getProtocol } from '../../graphql/queries';
import { getCategoryByID } from './CategoryDB';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Category, Protocol, User } from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { act } from 'react-dom/test-utils';
import {
  DraftChangeJSON,
  getDraftChangesByChangeID,
  updateDraftChangeItem,
} from './ReviewalDB';
import { compareProtocolItems } from '../../ui/pages/review/reviewComparsion/ProtocolComparison';

export type ProtocolJSON = {
  departmentID: string;
  name: string;
  nickname: string;
  index: number;
  rangeLow: number | null | undefined;
  rangeHigh: number | null | undefined;
  parentCategory: CategoryItem;
  pdfID: string;
  pairedDepsIDs: string[];
  pairedProtocols: string[];
  medications: string[];
  infusions: string[];
  equipment: string[];
  electrical: string[];
  forms: string[];
  createdBy: string;
  modifiedBy?: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  version: string;
  pdfVersion: string;
  isPublic: boolean;
  isRestrictive: boolean;
  keychainID: string | null | undefined;
};

export const updateProtocolAccess = async (
  protocol: ProtocolItem,
  isPublic?: boolean,
  keychain?: KeychainItem,
  override?: boolean
): Promise<Response> => {
  try {
    const dbItem = await DataStore.query(Protocol, protocol.uid);
    if (!dbItem) {
      return {
        type: ResponseType.Failure,
        data: 'Protocol does not exist' + protocol,
      };
    }

    if (!override && keychain && dbItem.keychainID) {
      return {
        type: ResponseType.Failure,
        data: 'Protocol already has a keychain',
      };
    }

    let newProtocol = await DataStore.save(
      Protocol.copyOf(dbItem, (updated) => {
        updated.keychainID = keychain ? keychain.uid : null;
        updated.isPublic = isPublic != null ? isPublic : false;
      })
    );

    if (globals.debug) console.log('Updated Protocol:', newProtocol);

    let newProt = cloneProtocol(protocol);
    newProt.keychain = keychain;
    newProt.keychainID = keychain ? keychain.uid : null;
    newProt.model = newProtocol;
    newProt.isPublic = isPublic != null ? isPublic : false;

    return {
      type: ResponseType.Success,
      data: newProt,
    };
  } catch (error: any) {
    console.error('Error updating protocol access:', error);
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

/**
 * This function will check if the category is a draft version and delete it
 * @param activeID The protocol to check
 * @returns Success if ready to create a new protocol or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Protocol[] | null;
    if (isActive)
      results = await DataStore.query(Protocol, (p) => p.activeID.eq(id));
    else
      results = await DataStore.query(Protocol, (p) =>
        p.and((p) => [p.status.eq('DRAFT'), p.id.eq(id)])
      );

    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbProt = results[0];
    if (dbProt.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Protocol, dbProt.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbProt,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new protocol in the database and choose the version
 * @param protocol ProtocolJSON JSON format
 * @returns The successful ProtocolItem or the error
 */
export const createProtocol = async (
  protocol: ProtocolJSON | ProtocolItem,
  previousItem?: ProtocolItem
): Promise<Response> => {
  try {
    let json: ProtocolJSON;
    if (protocol instanceof ProtocolItem) {
      let protItem = protocol as ProtocolItem;
      json = {
        departmentID: protItem.parent.departmentID,
        name: protItem.name,
        nickname: protItem.nickname,
        index: protItem.index,

        parentCategory: protItem.parent,
        pdfID: protItem.pdfUrl,
        pairedDepsIDs: protItem.pairedDeps
          ? protItem.pairedDeps.map((d) => d.id)
          : [],

        pairedProtocols:
          protItem.pairedProtocolIDs != null ? protItem.pairedProtocolIDs : [],
        medications:
          protItem.model?.medicationIDs != null
            ? protItem.model.medicationIDs
            : [],
        equipment: protItem.equipmentIDs != null ? protItem.equipmentIDs : [],
        electrical:
          protItem.model?.electricalIDs != null
            ? protItem.model.electricalIDs
            : [],
        infusions:
          protItem.model?.dripIDs != null ? protItem.model.dripIDs : [],
        forms: protItem.checklistIDs != null ? protItem.checklistIDs : [],
        rangeLow: protItem.rangeLow != null ? protItem.rangeLow : null,
        rangeHigh: protItem?.rangeHigh != null ? protItem.rangeHigh : null,

        status: protItem.status,
        activeID: protItem.activeID,
        version: protItem.version != null ? protItem.version : 'v1.0.0',
        pdfVersion:
          protItem.pdfVersion != null ? protItem.pdfVersion : 'v1.0.0',
        createdBy: protItem.model.createdBy ? protItem.model.createdBy : '',
        modifiedBy: protItem.modifiedBy ? protItem.modifiedBy.id : undefined,
        isPublic: protItem.isPublic != null ? protItem.isPublic : false,
        isRestrictive: protItem.isRestrictive,
        keychainID: protItem.keychainID,
      };
    } else json = protocol as ProtocolJSON;

    let ids = json.pairedDepsIDs;
    if (!ids.includes(json.departmentID)) ids.push(json.departmentID);

    let p: Protocol;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbProt = await DataStore.query(Protocol, previousItem.uid);
      if (dbProt == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT protocol does not exist could not update',
        };
      }
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      let ID: string | null | undefined =
        json.parentCategory.status === ProgressStatus.DRAFT &&
        json.parentCategory.activeID != null
          ? json.parentCategory.activeID
          : json.parentCategory.uid;
      if (ID == null) {
        return {
          type: ResponseType.Failure,
          data: 'The parent category does not exist',
        };
      }
      p = await DataStore.save(
        Protocol.copyOf(dbProt, (updated) => {
          updated.categoryID = ID as string;
          updated.departmentID = json.departmentID;
          updated.name = json.name;
          updated.nickname = json.nickname;
          updated.index = json.index;
          updated.pdfID = json.pdfID;
          updated.pairedProtocols = json.pairedProtocols;
          updated.medicationIDs = json.medications;
          updated.equipmentIDs = json.equipment;
          updated.electricalIDs = json.electrical;
          updated.dripIDs = json.infusions;
          updated.formIDs = json.forms;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.modifiedBy = json.modifiedBy;
          updated.pairedDepIDs = ids;
          updated.isPublic = json.isPublic ? json.isPublic : false;
          updated.isRestrictive = json.isRestrictive;
          updated.keychainID = json.keychainID;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      let ID: string | null | undefined =
        json.parentCategory.status === ProgressStatus.DRAFT &&
        json.parentCategory.activeID != null
          ? json.parentCategory.activeID
          : json.parentCategory.uid;
      if (ID == null) {
        return {
          type: ResponseType.Failure,
          data: 'The parent category does not exist',
        };
      }
      p = await DataStore.save(
        new Protocol({
          departmentID: json.departmentID,
          name: json.name,
          nickname: json.nickname,
          index: Number(json.index),
          rangeLow: json.rangeLow != null ? Number(json.rangeLow) : null,
          rangeHigh: json.rangeHigh != null ? Number(json.rangeHigh) : null,
          categoryID: ID,
          pdfID: json.pdfID,
          pairedDepIDs: ids,
          pairedProtocols: json.pairedProtocols,
          medicationIDs: json.medications,
          equipmentIDs: json.equipment,
          electricalIDs: json.electrical,
          dripIDs: json.infusions,
          formIDs: json.forms,
          isPublic: json.isPublic != null ? json.isPublic : false,
          isRestrictive:
            json.isRestrictive != null ? json.isRestrictive : false,
          keychainID: json.keychainID,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          version: json.version,
          pdfVersion: json.pdfVersion,
        })
      );
    }

    let protItem = new ProtocolItem(p, json.parentCategory);
    if (previousItem) {
      protItem.pairedDeps = previousItem.pairedDeps;
      protItem.medications = previousItem.medications;
      protItem.infusions = previousItem.infusions;
      protItem.equipment = previousItem.equipment;
      protItem.electrical = previousItem.electrical;
      protItem.forms = previousItem.forms;
      protItem.pairedProtocols = previousItem.pairedProtocols;
      protItem.fileURI = previousItem.fileURI;
      protItem.createdBy = previousItem.createdBy;
      protItem.modifiedBy = previousItem.modifiedBy;
      protItem.sum = previousItem.sum;
      protItem.keychain = previousItem.keychain;
      // protItem.pairedDepIDs = previousItem.pairedDepIDs;
    }
    return {
      type: ResponseType.Success,
      data: protItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const duplicateProtocol = async (
  department: DepartmentItem,
  protocol: ProtocolItem,
  previousProtocol: ProtocolItem,
  user: User
): Promise<Response> => {
  try {
    let json: ProtocolJSON;
    let protItem = protocol as ProtocolItem;
    json = {
      departmentID: protItem.parent.departmentID,
      name: protItem.name,
      nickname: protItem.nickname,
      index: protItem.index,

      parentCategory: protItem.parent,
      pdfID: protItem.pdfUrl,
      pairedDepsIDs: protItem.pairedDeps
        ? protItem.pairedDeps.map((d) => d.id)
        : protocol.pairedDepIDs ?? [],

      pairedProtocols:
        protItem.model?.pairedProtocols != null
          ? protItem.model.pairedProtocols
          : [],
      medications:
        protItem.model?.medicationIDs != null
          ? protItem.model.medicationIDs
          : [],
      equipment:
        protItem.model?.equipmentIDs != null ? protItem.model.equipmentIDs : [],
      electrical:
        protItem.model?.electricalIDs != null
          ? protItem.model.electricalIDs
          : [],
      infusions: protItem.model?.dripIDs != null ? protItem.model.dripIDs : [],
      forms: protItem.model?.formIDs != null ? protItem.model.formIDs : [],
      rangeLow: protItem.rangeLow != null ? protItem.rangeLow : null,
      rangeHigh: protItem?.rangeHigh != null ? protItem.rangeHigh : null,

      status: ProgressStatus.DRAFT,
      activeID: null,
      version: 'v1.0.0',
      pdfVersion: protItem.pdfVersion != null ? protItem.pdfVersion : 'v1.0.0',
      createdBy: user.id,
      modifiedBy: undefined,
      isPublic: protItem.isPublic != null ? protItem.isPublic : false,
      isRestrictive:
        protItem.isRestrictive != null ? protItem.isRestrictive : false,
      keychainID: protItem.keychainID,
    };

    if (globals.debug)
      console.log(
        'Duplicating Protocol',
        previousProtocol.name,
        'to',
        json.name
      );

    const result: Response = await createProtocol(json);
    if (result.type === ResponseType.Failure) {
      return result;
    }

    /* Get the newely created protocol and now duplicate all the ModelItems dosing */
    const newProtocol = result.data as ProtocolItem;
    if (globals.debug)
      console.log('Successfully created new protocol', newProtocol);

    /* Create all the duplicate promises */
    let promises = [];
    for (let i = 0; i < previousProtocol.medications.length; i++) {
      let med = previousProtocol.medications[i];
      promises.push(
        duplicateMedicationDose(department, med, user, newProtocol)
      );
    }

    for (let i = 0; i < previousProtocol.infusions.length; i++) {
      let inf = previousProtocol.infusions[i];
      promises.push(duplicateInfusionDose(department, inf, user, newProtocol));
    }

    for (let i = 0; i < previousProtocol.electrical.length; i++) {
      let elec = previousProtocol.electrical[i];
      promises.push(
        duplicateElectricalDose(department, elec, user, newProtocol)
      );
    }

    /* Wait for the promises to finish */
    if (globals.debug) console.log('Running the duplicate promises');
    let [medResp, infusResp, elecResp] = await Promise.all(promises);
    if (globals.debug) console.log('Finished the duplicate promises');

    if (medResp.type === ResponseType.Failure) {
      return medResp;
    } else {
      if (globals.debug)
        console.log(
          'Successfully duplicated ' + medResp.data.length + ' medication doses'
        );
      newProtocol.medications = medResp.data;
    }

    if (infusResp.type === ResponseType.Failure) {
      return infusResp;
    } else {
      if (globals.debug)
        console.log(
          'Successfully duplicated ' + infusResp.data.length + ' infusion doses'
        );
      newProtocol.infusions = infusResp.data;
    }

    if (elecResp.type === ResponseType.Failure) {
      return elecResp;
    } else {
      if (globals.debug)
        console.log(
          'Successfully duplicated ' +
            elecResp.data.length +
            ' electrical shocks'
        );
      newProtocol.electrical = elecResp.data;
    }

    return {
      type: ResponseType.Success,
      data: newProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const publishProtocolDrafts = async (
  protocols: ProtocolItem[]
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let promises = [];
    for (let i = 0; i < protocols.length; i++)
      promises.push(publishProtocol(protocols[i]));

    let results = await Promise.all(promises);
    for (let i = 0; i < results.length; i++) {
      if (results[i].type === ResponseType.Failure) {
        console.error('ERROR PUBLISHING PROTOCOL:', results[i].data);
        if (globals.debug) console.log('Protocol Data', protocols[i]);
      } else
        updates.push({
          model: results[i].data,
          message: 'Published Protocol: ' + protocols[i].name,
        });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the protocol to the database
 *    1. Create a new ARCHEIVED protocol based on the current ACTIVE protocol
 *    2. Update the ACTIVE protocol with the new information
 *    3. Delete the DRAFT protocol
 * @param draftProtocolItem The ProtocolItem to publish
 * @returns The successful ProtocolItem or the error
 */
export const publishProtocol = async (
  draftProtocolItem: ProtocolItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    console.log('Publishing Protocol:', draftProtocolItem);
    console.log('Draft Change Item:', draftChangeItem);
    /* Base Case 1 -- check if the protocol is configured correctly as a draft version */
    if (draftProtocolItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol is not a draft version',
      };
    }

    /* Confirm the Protocol exists */
    let dbProt = await DataStore.query(Protocol, draftProtocolItem.uid);
    if (dbProt == null) {
      return {
        type: ResponseType.Failure,
        data: 'The protocol does not exist in database',
      };
    }

    let activeProtocol: Protocol;
    let parentCategory: Category;

    /* Use Case 1: Create the first release of the protocol */
    if (draftProtocolItem.activeID == null) {
      activeProtocol = await DataStore.save(
        Protocol.copyOf(dbProt, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );

      let parent = await DataStore.query(Category, dbProt.categoryID);
      if (parent == null) {
        return {
          type: ResponseType.Failure,
          data: 'The parent category does not exist',
        };
      }
      parentCategory = parent;
    } else {
      /* Use Case 2: Update the current active protocol */
      /* Step 1. Fetch the active protocol item */
      let id: string = draftProtocolItem.activeID;
      let curProtocol = await DataStore.query(Protocol, id);
      let parent = await DataStore.query(Category, dbProt.categoryID);

      /* Base Case 3 -- check if the active protocol exists */
      if (curProtocol == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active protocol does not exist',
        };
      }
      /* Base Case 4 -- check if the parent category exists */
      if (parent == null) {
        return {
          type: ResponseType.Failure,
          data: 'The parent category does not exist',
        };
      }

      //TODO need to make sure the dep IDs are added

      parentCategory = parent;
      let newArcheivedProtocol = new ProtocolItem(
        curProtocol,
        new CategoryItem(parentCategory)
      );
      newArcheivedProtocol.status = ProgressStatus.ARCHIVE;
      newArcheivedProtocol.activeID = curProtocol.id;

      // 2. Create a new ARCHEIVED protocol based on the current ACTIVE protocol
      let archiveResult: Response = await createProtocol(newArcheivedProtocol);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      let archivedProtocol = archiveResult.data as ProtocolItem;

      // 2. Update the ACTIVE protocol with the new information
      activeProtocol = await DataStore.save(
        Protocol.copyOf(curProtocol, (updated) => {
          updated.categoryID = draftProtocolItem.parent.uid;
          updated.name = draftProtocolItem.name;
          updated.nickname = draftProtocolItem.nickname;
          updated.index = draftProtocolItem.index;
          updated.pdfID = draftProtocolItem.model.pdfID;
          updated.pairedProtocols = draftProtocolItem.model.pairedProtocols;
          updated.medicationIDs = draftProtocolItem.model.medicationIDs;
          updated.equipmentIDs = draftProtocolItem.model.equipmentIDs;
          updated.electricalIDs = draftProtocolItem.model.electricalIDs;
          updated.dripIDs = draftProtocolItem.model.dripIDs;
          updated.formIDs = draftProtocolItem.model.formIDs;
          updated.rangeLow = draftProtocolItem.rangeLow;
          updated.rangeHigh = draftProtocolItem.rangeHigh;
          updated.pairedDepIDs = draftProtocolItem.pairedDepIDs;

          updated.modifiedBy = draftProtocolItem.model.modifiedBy;
          updated.createdBy = draftProtocolItem.model.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftProtocolItem.version;
          updated.pdfVersion = draftProtocolItem.pdfVersion;
          updated.isPublic = draftProtocolItem.isPublic;
          updated.isRestrictive = draftProtocolItem.isRestrictive;
          updated.keychainID = draftProtocolItem.keychainID;
        })
      );
      // 3. Delete the DRAFT protocol
      let draftCategory = await DataStore.delete(
        Protocol,
        draftProtocolItem.uid
      );
      if (draftCategory == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft protocol does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeProtocol.id, archivedProtocol.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeProtocol.id,
          changeType: draftChangeItem.changeType,
          previousItem: archivedProtocol.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }

      // 5. Check if there any other Drafts with the
    }

    if (parentCategory == null) {
      return {
        type: ResponseType.Failure,
        data: 'The parent category does not exist',
      };
    }

    let protItem = new ProtocolItem(
      activeProtocol,
      new CategoryItem(parentCategory)
    );
    return {
      type: ResponseType.Success,
      data: protItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteProtocol = async (
  department: DepartmentItem,
  protocolItem: ProtocolItem,
  modifiedBy: User,
  isSoft: boolean,
  includeSubItems: boolean = false
): Promise<Response> => {
  try {
    let id: string = protocolItem.uid;
    if (isSoft) {
      let protocol = await DataStore.query(Protocol, id);
      if (protocol == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol does not exist',
        };
      }

      let result = await DataStore.save(
        Protocol.copyOf(protocol, (updated) => {
          updated.status = ProgressStatus.DELETED;
          updated.modifiedBy = modifiedBy.id;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol did not update correctly',
        };
      }
    } else {
      let protocol = await DataStore.delete(Protocol, id);
      if (protocol == null) {
        return {
          type: ResponseType.Failure,
          data: 'The protocol does not exist',
        };
      }
      /* Now delete the PDF file */
      if (protocolItem.pdfUrl != null) {
        Storage.remove(protocolItem.pdfUrl)
          .then((result) => {
            if (result == null) {
              return {
                type: ResponseType.Failure,
                data: 'The PDF file did not delete correctly',
              };
            }
          })
          .catch((e) => {
            console.error('Error deleting the PDF file:', e);
          });
      }
    }

    if (includeSubItems) {
      if (protocolItem.medications.length > 0) {
        let promises = [
          deleteMedicationsForProtocol(department, protocolItem, modifiedBy),
          deleteInfusionsForProtocol(department, protocolItem, modifiedBy),
          deleteElectricalsForProtocol(protocolItem, modifiedBy),
        ];
      }
    }

    return {
      type: ResponseType.Success,
      data: protocolItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isProtocolDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let categories = await DataStore.query(Category, (c) =>
      c.departmentID.eq(department.id)
    );
    if (categories.length === 0) {
      return {
        type: ResponseType.Success,
        data: false,
      };
    }

    /* Loop through all the categories and check if there is a draft protocol
     *   - This is required because the protocols are owned by categories and not departments
     */
    let count: number = 0;
    for (let i = 0; i < categories.length; i++) {
      let parent = categories[i];
      let drafts = await DataStore.query(Protocol, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.categoryID.eq(parent.id)])
      );
      count += drafts.length;
    }

    return {
      type: ResponseType.Success,
      data: count !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getProtocolDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];

    let modelUpdates = db.protocols.filter(
      (c) =>
        c.status === ProgressStatus.DRAFT && c.departmentID === db.department.id
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      // if (
      //   compareProtocolItems(model.activeItem as ProtocolItem, model).length > 0
      // ) {
      updates.push({
        model: model,
        title: 'Protocol ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.PROTOCOL,
      });
      // }

      if (
        model.activeItem &&
        model.pdfVersion !== (model.activeItem as ProtocolItem)?.pdfVersion
      ) {
        updates.push({
          model: model,
          title: 'Protocol PDF ' + model.name,
          message: 'Updated Protocol PDF: ' + model.name,
          changeType: DraftChangeType.PROTOCOL_PDF,
        });
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertProtocolChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Protocol ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as ProtocolItem),
      changeType: DraftChangeType.PROTOCOL,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: ProtocolItem): string {
  if (draftItem.activeItem == null)
    return `Created Protocol: ${draftItem.name}`;
  return `Updated Protocol: ${draftItem.name}`;
}

export const removeCurrentProtocolDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];

    let categories = await DataStore.query(Category, (c) =>
      c.departmentID.eq(department.id)
    );
    if (categories.length === 0) {
      return {
        type: ResponseType.Failure,
        data: 'The department does not have any categories',
      };
    }

    /* Loop through all the categories and check if there is a draft protocol
     *   - This is required because the protocols are owned by categories and not departments
     */
    for (let i = 0; i < categories.length; i++) {
      let parent = categories[i];
      let draftProtocols = await DataStore.query(Protocol, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.categoryID.eq(parent.id)])
      );
      for (let i = 0; i < draftProtocols.length; i++) {
        let prot: Protocol = draftProtocols[i];
        await DataStore.delete(prot);
        updates.push({
          model: prot,
          message: `Removed Protocol: ${prot.name}`,
        });
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Add the ID to the protocol if it does not already exist
 * @param protocol The protocol to add the id to
 * @param id The activeID of the equipment if it is in DRAFT mode or the unique ID if it is ACTIVE
 * @param modifiedByID The ID of the user who modified the protocol
 * @param type The type of pointer ID to add
 * @returns Success if the pointer ID was added or Failure if it already exists
 */
export const validatePointerID = async (
  protocol: ProtocolItem,
  id: string,
  modifiedByID: string | undefined,
  type:
    | 'Medication'
    | 'Equipment'
    | 'Electrical'
    | 'Infusion'
    | 'Checklist'
    | 'Paired Protocol'
): Promise<Response> => {
  try {
    let found = false;
    let dbProt: Protocol | undefined;
    if (protocol.status === ProgressStatus.DRAFT)
      dbProt = await DataStore.query(
        Protocol,
        protocol.activeID ? protocol.activeID : protocol.uid
      );
    else dbProt = await DataStore.query(Protocol, protocol.uid);

    if (dbProt == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not exist with ID: ' +
          protocol.uid +
          ' activeID ' +
          protocol.activeID +
          ' status ' +
          protocol.status,
      };
    }

    let json: ProtocolJSON = {
      departmentID: protocol.parent.departmentID,
      name: protocol.name,
      nickname: protocol.nickname,
      index: protocol.index,
      rangeLow: protocol.rangeLow,
      rangeHigh: protocol.rangeHigh,
      parentCategory: protocol.parent,
      pdfID: protocol.pdfUrl,
      pairedDepsIDs: protocol.pairedDepIDs ? protocol.pairedDepIDs : [],
      pairedProtocols: protocol.model.pairedProtocols
        ? protocol.model.pairedProtocols
        : [],
      medications: protocol.model.medicationIDs
        ? protocol.model.medicationIDs
        : [],
      equipment: protocol.model.equipmentIDs ? protocol.model.equipmentIDs : [],
      electrical: protocol.model.electricalIDs
        ? protocol.model.electricalIDs
        : [],
      infusions: protocol.model.dripIDs ? protocol.model.dripIDs : [],
      forms: protocol.model.formIDs ? protocol.model.formIDs : [],

      createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
      modifiedBy: modifiedByID,

      status: ProgressStatus.DRAFT,
      activeID:
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID,
      version: upgradeVersion(protocol.version ? protocol.version : 'v1.0.0'),
      pdfVersion: protocol.pdfVersion ? protocol.pdfVersion : 'v1.0.0',
      isPublic: protocol.isPublic,
      isRestrictive: protocol.isRestrictive,
      keychainID: protocol.keychainID,
    };

    /* Check if the Medication ID exists in the protocol otherwise add it */
    if (type === 'Medication' && !json.medications.includes(id)) {
      let ids = [...json.medications, id];
      json.medications = ids;
      found = true;
    } else if (type === 'Equipment' && !json.equipment.includes(id)) {
      /* Check if the Equipment ID exists in the protocol otherwise add it */
      let ids = [...new Set([...json.equipment, id])];
      json.equipment = ids;
      console.log('Updated Equipment IDs: ', ids);
      found = true;
    } else if (type === 'Electrical' && !json.electrical.includes(id)) {
      /* Check if the Electrical ID exists in the protocol otherwise add it */
      let ids = [...json.electrical, id];
      json.electrical = ids;
      found = true;
    } else if (type === 'Infusion' && !json.infusions.includes(id)) {
      /* Check if the Infusion ID exists in the protocol otherwise add it */
      let ids = [...json.infusions, id];
      json.infusions = ids;
      found = true;
    } else if (type === 'Checklist' && !json.forms.includes(id)) {
      /* Check if the Form ID exists in the protocol otherwise add it */
      let ids = [...json.forms, id];
      json.forms = ids;
      found = true;
    } else if (
      type === 'Paired Protocol' &&
      !json.pairedProtocols.includes(id)
    ) {
      /* Check if the Form ID exists in the protocol otherwise add it */
      let ids = [...json.pairedProtocols, id];
      json.pairedProtocols = ids;
      found = true;
    }

    if (found) {
      let result: Response = await createProtocol(json, protocol);
      if (result.type === ResponseType.Failure) return result;
      let newProtocol = result.data as ProtocolItem;
      if (globals.debug) console.log('Updated Protocol:', newProtocol);
    }

    return {
      type: ResponseType.Success,
      data: id,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Auhtor: Guru (01-29-2024)
 * Adds IDs to a protocol and updates it in the database.
 * @param protocol The protocol item to update.
 * @param equipmentIDs The IDs of the equipment to add to the protocol.
 * @param checklistIDs The IDs of the checklists to add to the protocol.
 * @param pairedProtocolIDs The IDs of the paired protocols to add to the protocol.
 * @returns A Promise that resolves to a Response object indicating the success or failure of the operation.
 */

export const addIDtoProtocol = async (
  protocol: ProtocolItem,
  equipmentIDs: string[],
  checklistID: string | undefined,
  pairedProtocolIDs: string[],
  modifiedByID: string | undefined
): Promise<Response> => {
  try {
    let found = false;
    let dbProt: Protocol | undefined;
    dbProt = await DataStore.query(Protocol, protocol.uid);
    if (dbProt == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not exist with ID: ' +
          protocol.uid +
          ' activeID ' +
          protocol.activeID +
          ' status ' +
          protocol.status,
      };
    }
    let json: ProtocolJSON = {
      departmentID: protocol.parent.departmentID,
      name: protocol.name,
      nickname: protocol.nickname,
      index: protocol.index,
      rangeLow: protocol.rangeLow,
      rangeHigh: protocol.rangeHigh,
      parentCategory: protocol.parent,
      pdfID: protocol.pdfUrl,
      pairedDepsIDs: protocol.pairedDepIDs ? protocol.pairedDepIDs : [],
      pairedProtocols: protocol.model.pairedProtocols
        ? protocol.model.pairedProtocols
        : [],
      medications: protocol.model.medicationIDs
        ? protocol.model.medicationIDs
        : [],
      equipment: protocol.model.equipmentIDs ? protocol.model.equipmentIDs : [],
      electrical: protocol.model.electricalIDs
        ? protocol.model.electricalIDs
        : [],
      infusions: protocol.model.dripIDs ? protocol.model.dripIDs : [],
      forms: protocol.model.formIDs ? protocol.model.formIDs : [],

      createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
      modifiedBy: modifiedByID,

      status: ProgressStatus.DRAFT,
      activeID:
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID,
      version: upgradeVersion(protocol.version ? protocol.version : 'v1.0.0'),
      pdfVersion: protocol.pdfVersion ? protocol.pdfVersion : 'v1.0.0',
      isPublic: protocol.isPublic,
      isRestrictive: protocol.isRestrictive,
      keychainID: protocol.keychainID,
    };

    if (equipmentIDs.length > 0) {
      let ids = [...json.equipment, ...equipmentIDs];
      /* Filter out duplicates */
      ids = ids.filter((id, index, self) => self.indexOf(id) === index);
      json.equipment = ids;
      found = true;
    }
    let formID: any = checklistID;
    if (formID && !json.forms.includes(formID)) {
      json.forms.push(formID);
      found = true;
    }
    if (pairedProtocolIDs.length > 0) {
      let ids = [...json.pairedProtocols, ...pairedProtocolIDs];
      /* Filter out duplicates */
      ids = ids.filter((id, index, self) => self.indexOf(id) === index);
      json.pairedProtocols = ids;
      found = true;
    }

    if (found) {
      let result: Response = await createProtocol(json, protocol);
      if (result.type === ResponseType.Failure) return result;
      let newProtocol = result.data as ProtocolItem;
      return {
        type: ResponseType.Success,
        data: newProtocol,
      };
    }

    return {
      type: ResponseType.Success,
      data: protocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Upload the new PDF to S3 and return the file path
 * @param department The department that owns the protocol
 * @param protocol The protocol to upload the new PDF to
 * @param pdf The new PDF file
 * @returns Success if the PDF was uploaded or Failure if there was an error
 */
export const uploadNewPDFToS3 = async (
  department: DepartmentItem,
  protocol: ProtocolItem,
  pdf: File
): Promise<Response> => {
  try {
    if (globals.debug) console.log('uploadNewPDFToS3', pdf);
    /* First create the file path -> Public / departmentID / categoryID / protocolID / (version)_(protocol name).pdf*/
    let catID =
      protocol.parent.status === ProgressStatus.ACTIVE
        ? protocol.parent.uid
        : protocol.parent.activeID;
    let protID =
      protocol.status === ProgressStatus.ACTIVE
        ? protocol.uid
        : protocol.activeID;
    let name = protocol.name.replace(/[^a-zA-Z0-9]/g, '_');
    let filePath =
      department.id +
      '/' +
      catID +
      '/' +
      protID +
      '/' +
      protocol.pdfVersion +
      '_' +
      name +
      '.pdf';

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-_]/g, '_');

    /* Make sure all the folders exist in the S3 bucket */
    await Storage.put(filePath, pdf, {
      contentType: 'application/pdf',
      level: 'public',
    });

    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Upload the new PDF to S3 and return the file path
 * @param department The department that owns the protocol
 * @param parent The parent category of the protocol
 * @param protocolName The name of the protocol
 * @param pdf The new PDF file
 * @returns Success if the PDF was uploaded or Failure if there was an error
 */
export const uploadInitialPDFToS3 = async (
  department: DepartmentItem,
  parent: CategoryItem,
  protocolName: string,
  pdf: File,
  errorCallback: (error: any) => void
): Promise<Response> => {
  try {
    /* First create the file path -> Public / departmentID / categoryID / protocolID / (version)_(protocol name).pdf*/
    let catID =
      parent.status === ProgressStatus.ACTIVE ? parent.uid : parent.activeID;
    let name = protocolName.replace(/[^a-zA-Z0-9-]/g, '_');
    let filePath =
      department.id + '/' + catID + '/' + 'v1_0_0_' + name + '.pdf';

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-_]/g, '_');

    /* Make sure all the folders exist in the S3 bucket */
    Storage.put(filePath, pdf, {
      contentType: 'application/pdf',
      level: 'public',
    })
      .then((result) => {
        if (globals.debug) console.log('uploadInitialPDFToS3', result);
      })
      .catch((error) => {
        if (globals.debug) console.log('uploadInitialPDFToS3', error);
        errorCallback(error);
      });
    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/* GraphQL API Queries */
export const getProtocolByID = async (
  db: DatabaseResponse,
  id: string
): Promise<ProtocolItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbProt = db.protocols.find((c) => c.uid === id);
      if (dbProt != null) return resolve(dbProt);
      else {
        executeQuery(graphqlOperation(getProtocol, { id: id }), 1500)
          .then((response: any) => {
            if (response.data.getProtocol == null) return null;
            let protocol = response.data.getProtocol;
            console.log('FOUND PROTOCOL', protocol);
            getCategoryByID(db, protocol.categoryID)
              .then((category) => {
                if (category == null)
                  return reject(
                    'Folder does not exist -> ID: ' + protocol.categoryID
                  );
                return resolve(new ProtocolItem(protocol, category));
              })
              .catch((error) => {
                console.error('getCategoryByID', error);
                reject(error);
              });
          })
          .catch((error: any) => {
            console.error('getProtocolByID', error);
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
