import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
} from '../../../_global/common/Utils';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import { ProgressStatus } from '../../../../models';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import SearchBar from '../../../components/Search/SearchBar';
import CategoryItem from '../../../../data/model/CategoryItem';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { filter } from 'lodash';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListProtocols = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const protocols: ProtocolItem[] = database.protocols;
  const categories: CategoryItem[] = database.categories;

  const scrollPosition = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filters, setFilters] = useState<any[]>(
    state.search ? state.search.filters : []
  );
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state.search ? state.search.categoriesFilter : []
  );
  const [sort, setSort] = useState('name_asc');

  if (globals.debug) console.log('State search:', state.search);

  const filterOptions = [
    'Medications',
    'Infusions',
    'Equipment',
    'Electrical',
    'Paired Protocols',
    'Forms',
  ];

  const [list, setList] = useState<any[]>([]);

  console.log('Protocols', protocols);

  // const reloadDatabase = async () => {
  //     const response = await loadDatabase(department);
  //     if (response.type === ResponseType.Success) {
  //         const database = response.data;
  //         setDatabase(database);
  //         const protocols: ProtocolItem[] = database.protocols;
  //         handleFilterChange(protocols);
  //     }
  // }

  // useEffect(() => {
  //     reloadDatabase();
  // }
  // , []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = protocols.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery) ||
          item.nickname.toLowerCase().includes(searchQuery) ||
          item.uid.toLowerCase().includes(searchQuery)
        );
      });
    }
    if (filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return filters.some((filter) => {
          switch (filter) {
            case 'Medications':
              return item.medications?.length;
            case 'Infusions':
              return item.infusions?.length;
            case 'Equipment':
              return item.equipment?.length;
            case 'Electrical':
              return item.electrical?.length;
            case 'Paired Protocols':
              return item.pairedProtocols?.length;
            case 'Forms':
              return item.forms?.length;
            default:
              return false;
          }
        });
      });
    }
    if (categoriesFilter.length > 0) {
      filteredList = filteredList.filter((item) => {
        return categoriesFilter.some(
          (category) => item.parent.uid === category.uid
        );
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a, b) => {
      if (a.parent.index === b.parent.index) {
        if (a.index === b.index) return a.name.localeCompare(b.name);
        return a.index - b.index;
      }
      return a.parent.index - b.parent.index;
    });

    filteredList = filteredList.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      } else if (sort === 'file_size_asc') return a.getSum() - b.getSum();
      else if (sort === 'file_size_desc') return b.getSum() - a.getSum();
      else return a.name.localeCompare(b.name);
    });

    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(protocols);
  }, [searchQuery, filters, categoriesFilter, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (protocol: ProtocolItem) => {
    const state = {
      selectedProtocol: protocol,
      editMode: false,
      backPage: 'list-protocols',
      search: { searchQuery, filters, categoriesFilter },
    };
    const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
    navigate(`/${formattedString}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '4fr 2fr 2fr' +
      (department.subDeps && department.subDeps.length > 0 ? ' 2fr ' : '') +
      ' 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  /* 10-09-23 Arul: function for handling the draft confirm modal make new button functionality*/
  const handleMakeNew = async () => {
    navigate(`/protocol/edit`);
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      else navigate(`/protocol/edit`);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  return (
    <div className="screen-container">
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            navigate(`/protocol/edit`);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Protocols: ' + list.length + ' items'}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={true}
        handleEdit={() => {
          handleCheckIsDraft();
        }}
        type={'protocol'}
      />
      <Row>
        <Col sm={6}>
          <SearchBar
            value={searchQuery}
            containerStyle={{ width: '100%' }}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Protocols...'}
          />
        </Col>
        <Col sm={6}>
          <MultiSelectDropdown<CategoryItem>
            title={'Categories'}
            options={categories}
            initialSelectedItems={categoriesFilter}
            labelField={(option: CategoryItem) => option.name}
            keyField={(option: CategoryItem) => option.uid}
            onSelected={(selected: CategoryItem[]) => {
              setCategoriesFilter(selected);
            }}
          />
          <MultiSelectDropdown<string>
            style={{ margin: '0px 10px' }}
            title={'Filters'}
            options={filterOptions}
            initialSelectedItems={filters}
            labelField={(option: string) => option}
            keyField={(option: string) => option}
            onSelected={(selected: string[]) => {
              setFilters(selected);
            }}
          />
        </Col>
      </Row>

      <div className="accordion-container">
        <div style={{ borderBottom: '1px solid #ccc' }}>
          <Accordion>
            <div
              style={rowStyle}
              className="departmentItemNoHover"
              // onMouseEnter={() => setIsHovered(true)}
              // onMouseLeave={() => setIsHovered(false)}
            >
              {/* <h6 className='departmentItemText'>Version</h6> */}
              <h6
                className="departmentItemTextClickable"
                style={{
                  textDecoration: sort.includes('name') ? 'underline' : 'none',
                }}
                onClick={() => {
                  if (sort === 'name_desc') setSort('name_asc');
                  else setSort('name_desc');
                }}
              >
                Name
                <span>
                  {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </h6>
              <h6
                className="departmentItemTextClickable"
                style={{
                  textDecoration: sort.includes('modified')
                    ? 'underline'
                    : 'none',
                }}
                onClick={() => {
                  if (sort === 'modified_desc') setSort('modified_asc');
                  else setSort('modified_desc');
                }}
              >
                Modified Date
                <span>
                  {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </h6>
              <h6 className="departmentItemText">Modified By</h6>
              {department.subDeps && department.subDeps.length > 0 && (
                <h6 className="departmentItemText">Department Subs</h6>
              )}
              <h6
                className="departmentItemTextClickable"
                style={{
                  textDecoration: sort.includes('doses') ? 'underline' : 'none',
                }}
                onClick={() => {
                  if (sort === 'file_size_desc') setSort('file_size_asc');
                  else setSort('file_size_desc');
                }}
              >
                File Size
                <span>
                  {sort === 'file_size_asc' ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </h6>
            </div>
          </Accordion>
        </div>
        <div className="list-container">
          <ViewportList items={list}>
            {(item: ProtocolItem, index) => {
              let username = item.modifiedBy
                ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                : 'Hinckley Medical';
              const itemSubs = department.allSubDeps
                ? !item.isRestrictive
                  ? department.allSubDeps.length + 1
                  : item.pairedDepIDs?.length
                : -1;
              return (
                <div
                  key={item.uid + index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div
                    style={rowStyle}
                    className="departmentItem"
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                  >
                    {/* <h6 className='departmentItemText'>{item.protocolVersion}</h6> */}
                    <h6 className="departmentItemText">
                      {item.name}
                      {item.status !== 'ACTIVE' && (
                        <Status status={item.status} />
                      )}
                    </h6>

                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.model?.updatedAt
                          ? item?.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    {department.allSubDeps &&
                      department.allSubDeps.length > 0 && (
                        <h6
                          className="departmentItemText"
                          style={{ width: '100%', textAlign: 'center' }}
                        >
                          {itemSubs + '/' + (department.allSubDeps.length + 1)}
                        </h6>
                      )}
                    {/* {department.subDeps && <h6 className='departmentItemText' style={{color: item.pairedDepIDs?.length ? 'black' : 'grey'}}>{item.pairedDepIDs?.length ? item.pairedDepIDs.length : '-'}</h6>} */}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item?.getSum()}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListProtocols;
