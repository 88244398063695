import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  formatTimestamp,
  getAllWorkbooks,
} from '../../../data/AmplifyDB';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { ViewportList } from 'react-viewport-list';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../components/Search/SearchBar';
import { User, Workbook } from '../../../models';
import { DataStore, Storage } from 'aws-amplify';
import PDFScreen from '../protocol/details/PdfScreen';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import Dropzone from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import DepartmentItem from '../../../data/model/DepartmentItem';
import {
  WorkbookDB,
  createWorkbook,
  deleteWorkbook,
  publishWorkbook,
  uploadWorkbookPDF,
} from '../../../data/functions/WorkbookDB';
import Loading from '../../components/Loading/Loading';
import { ProgressStatus } from '../../../models';
import { InputText } from 'primereact/inputtext';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SearchableDropdown from '../../components/SearchableDropdown';
import ProtocolHeader from '../protocol/ProtocolHeader';
import WorkbookItem from '../../../data/model/WorkbookItem';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
  handleCopy,
  upgradeVersion,
} from '../../_global/common/Utils';
import { getHashedPin } from '../../_global/common/Encrypt';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa6';
import { set } from 'lodash';
import * as XLSX from 'xlsx';

const ProtocolSetEdit = (props: any) => {
  const rootUrl = window.location.origin;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state.user);
  const parmWorkbook: WorkbookItem = state.selectedItem;
  const [selectedWorkbook, setSelectedWorkbook] =
    useState<WorkbookItem>(parmWorkbook);

  const [name, setName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [defPaireDeps, setDefPairedDeps] = useState<DepartmentItem[]>([]);
  const [isDeleteResults, setIsDeleteResults] = useState(false);

  const [errorText, setErrorText] = useState('');
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>(
    state && state.list ? state.list : []
  );
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [dropState, setDropState] = useState({
    isDragActive: false,
  });

  const [isUploadMode, setIsUploadMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState('Loading...');
  const [workbookURL, setWorkbookURL] = useState('');
  const [isCopied, setIsCopied] = useState('');

  const objURL = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else return workbookURL;
  }, [file, workbookURL]);

  useEffect(() => {
    const fetchURL = async () => {
      return await Storage.get(selectedWorkbook.workbookUrl, {
        level: 'public',
      });
    };
    const fetchSubDeps = () => {
      if (globals.debug)
        console.log(
          'selectedWorkbook:',
          selectedWorkbook,
          'department:',
          department
        );
      if (department.subDeps && selectedWorkbook.pairedDepIDs) {
        let deps = [];
        for (let i = 0; i < department.subDeps.length; i++) {
          if (selectedWorkbook.pairedDepIDs.includes(department.subDeps[i].id))
            deps.push(department.subDeps[i]);
        }
        setPairedDeps(deps);
        setDefPairedDeps(deps);
      }
      return;
    };
    if (selectedWorkbook) {
      setName(selectedWorkbook.name);
      fetchSubDeps();
      let url = fetchURL().then((url) => {
        setWorkbookURL(url);
      });
    }
  }, [selectedWorkbook, department.subDeps]);

  const handleSave = async () => {
    if (isValid) {
      try {
        let fileKey = selectedWorkbook.workbookUrl;
        let fileSize = selectedWorkbook.fileSize;
        if (file != null) {
          setIsUploading(true);
          fileSize = file.size;
          fileKey = await uploadWorkbookPDF(
            department,
            file,
            name,
            parmWorkbook,
            (progress) => {
              setUploadingText(`Uploading... ${Math.floor(progress * 100)}%`);
            }
          );
        }
        selectedWorkbook.name = name;
        selectedWorkbook.workbookUrl = fileKey;
        selectedWorkbook.fileSize = fileSize;
        selectedWorkbook.modifiedBy = user;
        selectedWorkbook.pairedDepIDs = pairedDeps.map(
          (d: DepartmentItem) => d.id
        );

        const resp = await publishWorkbook(selectedWorkbook, user);
        if (resp.type === ResponseType.Success) {
          if (globals.debug) console.log('Editted workbook:', resp.data);
          navigate(`/protocol-sets`, {
            state: { searchState: state.searchState },
          });
        } else {
          throw new Error('Failed to save workbook');
        }
      } catch (error) {
        if (globals.debug)
          console.log('Failed to upload and save workbook:', error);
      }
    }
  };

  const handleDeleteWorkbook = async () => {
    const resp = await deleteWorkbook(selectedWorkbook, true);
    if (resp.type === ResponseType.Success) {
      if (globals.debug) console.log('Deleted workbook:', resp.data);
      navigate(`/protocol-sets`, { state: { searchState: state.searchState } });
    } else {
      if (globals.debug) console.log('Failed to delete workbook:', resp.data);
    }
  };

  /* Add and filter all the departments */
  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, pairedDeps]);

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const handleFileDrop = (file: File) => {
    setFile(file);
    // setIsUploadMode(false);
  };

  const handleRemoveResults = async () => {
    let w = await DataStore.query(Workbook, selectedWorkbook.uid);
    if (w && w.aiPdfParserResults) {
      console.log('Removing AI results:', w.aiPdfParserResults);
      let newWB = await DataStore.save(
        Workbook.copyOf(w, (updated) => {
          updated.aiPdfParserResults = null;
        })
      );
      setSelectedWorkbook(new WorkbookItem(newWB, user));
      setIsDeleteResults(false);
    }
  };

  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if ((file || name !== parmWorkbook.name) && !isWarningModal) {
      setIsWarningModal(true);
    } else {
      navigate(`/protocol-sets`, { state: { searchState: state.searchState } });
    }
  };

  const handleViewFullScreen = () => {
    if (workbookURL !== '') {
      localStorage.setItem('pdfURL', workbookURL);
      localStorage.setItem('pdfName', selectedWorkbook.name);
      let filterName = selectedWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        workbookURL + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  const downloadJsonFile = (json: any) => {
    try {
      if (!json || json.length === 0) {
        alert('Error parsing AI data Body is empty or undefined');
        return;
      }

      /* Save the results of the JSON to a text file and download it */
      const blob = new Blob([JSON.stringify(json)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      let date = new Date();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let year = String(date.getFullYear()).substring(2);
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');
      let name =
        parmWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_') +
        '_' +
        month +
        '_' +
        day +
        '_' +
        year +
        '_' +
        hours +
        '_' +
        minutes +
        '_' +
        seconds;
      //Add the timestamp to the file name
      a.download = name;
      a.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      console.error('Error parsing AI data', e);
      return;
    }
  };

  const downloadExcelFile = (json: any) => {
    try {
      if (!json || json.length === 0) {
        alert('Error parsing AI data Body is empty or undefined');
        return;
      }

      /* Save the results of the JSON to a text file and download it */
      const excelData: any[] = [];

      json.forEach((item: any) => {
        const { file, index, doses } = item;

        if (doses && doses.length > 0) {
          excelData.push({
            'File Name': file,
            'Page Number': index,
            type: '',
            name: '',
            dose: '',
            administrationRoutes: '',
            maxSingleDose: '',
            minSingleDose: '',
            maxTotalDose: '',
            repeatTime: '',
            repeatAllowed: '',
            ageGroup: '',
            weightLow: '',
            weightHigh: '',
            ageLow: '',
            ageHigh: '',
            instructionMain: '',
            instructionSecond: '',
            warning: '',
            source: '',
          });

          doses.forEach((dose: any) => {
            excelData.push({
              'File Name': '',
              'Page Number': '',
              type: dose.type || '',
              name: dose.name || '',
              dose: dose.dose || '',
              administrationRoutes: dose.administrationRoutes || '',
              maxSingleDose: dose.maxSingleDose || '',
              minSingleDose: dose.minSingleDose || '',
              maxTotalDose: dose.maxTotalDose || '',
              repeatTime: dose.repeatTime || '',
              repeatAllowed: dose.repeatAllowed || '',
              ageGroup: dose.ageGroup || '',
              weightLow: dose.weightLow || '',
              weightHigh: dose.weightHigh || '',
              ageLow: dose.ageLow || '',
              ageHigh: dose.ageHigh || '',
              instructionMain: dose.instructionMain || '',
              instructionSecond: dose.instructionSecond || '',
              warning: dose.warning || '',
              source: dose.source || '',
            });
          });
        } else {
          excelData.push({
            'File Name': file,
            'Page Number': index,
            type: '',
            name: '',
            dose: '',
            administrationRoutes: '',
            maxSingleDose: '',
            minSingleDose: '',
            maxTotalDose: '',
            repeatTime: '',
            repeatAllowed: '',
            ageGroup: '',
            weightLow: '',
            weightHigh: '',
            ageLow: '',
            ageHigh: '',
            instructionMain: '',
            instructionSecond: '',
            warning: '',
            source: '',
          });
        }
      });

      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      let sheetName = parmWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_');
      if (sheetName.length > 31) sheetName = sheetName.substring(0, 28) + '...';

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Convert the workbook to a Blob and download it as an Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      const excelUrl = URL.createObjectURL(blob);
      const excelLink = document.createElement('a');
      excelLink.href = excelUrl;
      let date = new Date();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let year = String(date.getFullYear()).substring(2);
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');
      let name =
        parmWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_') +
        '_' +
        month +
        '_' +
        day +
        '_' +
        year +
        '_' +
        hours +
        '_' +
        minutes +
        '_' +
        seconds;
      excelLink.download = name + '.xlsx';
      excelLink.click();
      URL.revokeObjectURL(excelUrl);
    } catch (e) {
      console.error('Error parsing AI data', e);
      return;
    }
  };

  const isValid = useMemo(() => {
    return (
      (isUploadMode && file != null) ||
      (name.length > 0 && name !== parmWorkbook.name) ||
      (department.subDeps && department.subDeps.length > 0
        ? pairedDeps.length > 0
        : false)
    );
  }, [
    file,
    name,
    pairedDeps,
    department.subDeps,
    isUploadMode,
    parmWorkbook.name,
  ]);

  return (
    <div className="screen-container">
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Protocol Set.  Click cancel to return to Protocol Set or Abandon.`}
        />
      )}
      {isDeleteModal && (
        <ConfirmModal
          isVisible={isDeleteModal}
          title="Delete Protocol Set?"
          handleClose={() => {
            setIsDeleteModal(false);
          }}
          handleSubmit={handleDeleteWorkbook}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you want to delete this Protocol Set: ${selectedWorkbook.name}?`}
        />
      )}
      {isDeleteResults && (
        <ConfirmModal
          isVisible={isDeleteResults}
          title={'Delete AI Results for ' + selectedWorkbook.name + '?'}
          handleClose={() => {
            setIsDeleteResults(false);
          }}
          handleSubmit={handleRemoveResults}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you want to delete the AI results for this Protocol Set: ${selectedWorkbook.name}?`}
        />
      )}
      <ProtocolHeader
        isBackButton={true}
        isDeleteButton={isEditMode && !isUploadMode && file == null}
        handleDelete={() => {
          setIsDeleteModal(true);
        }}
        handleCancel={handleBack}
        name={isEditMode ? 'Edit Protocol Set' : selectedWorkbook.name}
        page={'Protocol Sets'}
        rightSideBtn={'edit'}
        isEditButton={!isEditMode}
        isFullScreenButton={true}
        handleFullScreen={handleViewFullScreen}
        handleCancelEdit={() => {
          setFile(null);
          setName(parmWorkbook.name);
          setPairedDeps(defPaireDeps);
          setIsEditMode(false);
          setIsUploadMode(false);
        }}
        isUploadButton={!isUploadMode && isEditMode}
        handleUpload={() => {
          setIsUploadMode(true);
        }}
        isSaveButton={isEditMode}
        isSaveActive={isValid}
        isDotButton={true}
        handleSave={() => {
          handleSave();
        }}
        handleEdit={() => {
          setIsEditMode(true);
        }}
        type={'protocol'}
      />
      <div className="ketamineContent">
        {isUploadMode && file === null && (
          <Dropzone
            accept={{ 'application/pdf': [] }}
            maxFiles={1}
            onDragOver={() =>
              setDropState({ ...dropState, isDragActive: true })
            }
            onDragLeave={() =>
              setDropState({ ...dropState, isDragActive: false })
            }
            onDropRejected={(files: any[], event: any) => {
              if (files.length === 0) return;
              else if (files.length > 1)
                setErrorText('Only one file can be uploaded at a time');
              else setErrorText('File type not supported:' + files[0].type);

              setDropState({ ...dropState, isDragActive: false });
            }}
            onDropAccepted={(files: File[], event: any) => {
              if (files.length > 1)
                setErrorText('Only one file can be uploaded at a time');
              else handleFileDrop(files[0]);
              setDropState({ ...dropState, isDragActive: false });
            }}
            disabled={false}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps()}
                  className="cursorPointer dragDropDotGridNewWorkbook"
                  style={{
                    display: 'flex',
                    background: dropState.isDragActive ? '#E0EADD' : '#FFF',
                    border: dropState.isDragActive
                      ? '2px dashed #00534C'
                      : errorText
                        ? '2px dashed #880808'
                        : '2px dashed #cdc8c8',
                  }}
                >
                  <input {...getInputProps({ disabled: false })} />
                  {errorText !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '24px',
                      }}
                    >
                      {errorText}
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    {dropState.isDragActive ? (
                      <RiUpload2Line className="drag-drop-icon" />
                    ) : (
                      <RiDownload2Line className="drag-drop-icon" />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    {dropState.isDragActive ? 'Upload' : 'Drag and Drop'}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    or{' '}
                    <span
                      style={{ marginLeft: '5px' }}
                      className="green_accent_text"
                    >
                      {' '}
                      Browse
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      justifyContent: 'center',
                    }}
                    className="grey_text ketamine-general-label"
                  >
                    Support: PDF
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {((isUploadMode && file) || !isUploadMode) && (
          <div className="workbook-upload-container">
            <embed
              src={objURL}
              title="Uploaded Workbook"
              style={{
                height: '100%',
                width: '100%',
                flex: 2,
                border: 'none',
                marginRight: '10px',
              }}
            />
            <div className="dragDropDotGridNewWorkbookDetails">
              <h5 className="ketmine-header-text">Protocol Set Details</h5>
              {/* <div className="contentText">
              {`Great, now name the workook${department.subDeps && department.subDeps.length > 0 ? ' and pair it with departments' : ''}.`}
              </div> */}
              <div className="input-container roundBorder">
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                  }}
                  className="ketamine-general-label"
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginRight: '10px' }}>Modified By:</div>
                    <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                      {selectedWorkbook.modifiedBy
                        ? selectedWorkbook.modifiedBy.firstName +
                          ' ' +
                          selectedWorkbook.modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </div>
                  </div>
                  {selectedWorkbook.model.updatedAt && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Last Updated:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {getFormattedDate(
                          selectedWorkbook.model.updatedAt,
                          false
                        )}
                      </div>
                    </div>
                  )}
                  {selectedWorkbook.model.createdAt && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Created At:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {getFormattedDate(
                          selectedWorkbook.model.createdAt,
                          false
                        )}
                      </div>
                    </div>
                  )}
                  {selectedWorkbook.version && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Version:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {selectedWorkbook.version}
                      </div>
                    </div>
                  )}
                  {user.type === 'ADMIN' && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>
                          Protocol Set ID:
                        </div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                          }}
                        >
                          {parmWorkbook.uid}
                          <span>
                            {isCopied && isCopied === parmWorkbook.uid ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(parmWorkbook.uid, e, setIsCopied)
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>
                          Protocol Set URL:
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            // alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                              maxWidth: '20vw',
                              //Tail with ellipsis
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {parmWorkbook.workbookUrl}
                          </div>
                          <span>
                            {isCopied &&
                            isCopied === parmWorkbook.workbookUrl ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmWorkbook.workbookUrl,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            marginRight: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          AI Parsed Results:
                          <span
                            className="iconButton"
                            onClick={() => {
                              setIsDeleteResults(true);
                            }}
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            <FaTrash
                              data-testid="isFullScreenButton"
                              className="icon"
                              style={{
                                height: '20px',
                                width: '20px',
                                color: '#8c1010',
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                              color: selectedWorkbook.aiPdfParserResults
                                ? '#00534C'
                                : '#636363',
                              textDecoration:
                                selectedWorkbook.aiPdfParserResults
                                  ? 'underline'
                                  : 'none',
                              cursor: selectedWorkbook.aiPdfParserResults
                                ? 'pointer'
                                : '',
                              marginRight: '2rem',
                            }}
                            onClick={() => {
                              if (selectedWorkbook.aiPdfParserResults)
                                downloadJsonFile(
                                  selectedWorkbook.aiPdfParserResults
                                );
                            }}
                          >
                            Download JSON
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                              color: selectedWorkbook.aiPdfParserResults
                                ? '#00534C'
                                : '#636363',
                              textDecoration:
                                selectedWorkbook.aiPdfParserResults
                                  ? 'underline'
                                  : 'none',
                              cursor: selectedWorkbook.aiPdfParserResults
                                ? 'pointer'
                                : '',
                            }}
                            onClick={() => {
                              if (selectedWorkbook.aiPdfParserResults)
                                downloadExcelFile(
                                  selectedWorkbook.aiPdfParserResults
                                );
                            }}
                          >
                            Download Excel
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <label
                htmlFor="name"
                className={`notification-css-title`}
                style={{ fontSize: '16px' }}
              >
                Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="notification-model"
                  id="name"
                  name="name"
                  disabled={!isEditMode}
                  autoFocus={true}
                  required={true}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  style={{
                    fontSize: '16px',
                    cursor: 'auto',
                    padding: '20px 20px',
                  }}
                />
                <div className="input-border" />
              </div>
              {department &&
                department.subDeps &&
                department.subDeps.length > 0 && (
                  <>
                    <label htmlFor="" className={`notification-css-title`}>
                      <span
                        className="headerTextMargin"
                        style={{ fontSize: '16px', marginTop: '10px' }}
                      >
                        Subscribed Departments: {pairedDeps.length} /{' '}
                        {department.subDeps.length}
                        {isEditMode && (
                          <span
                            onClick={() =>
                              setPairedDeps(
                                department.subDeps ? department.subDeps : []
                              )
                            }
                          >
                            <div className="clickableText">Add All</div>
                          </span>
                        )}
                      </span>
                    </label>
                    <span
                      className="contentText greyText"
                      style={{ fontSize: '13px', marginLeft: '10px' }}
                    >
                      This is a list of every department that will subscribe to
                      the protocol.
                    </span>
                    <SearchableDropdown<DepartmentItem>
                      id="searchDropdown"
                      options={departmentList}
                      labelField={(option) => option.name}
                      valueField={(option) => option.name}
                      keyField={(option) => option.id}
                      isActive={isEditMode}
                      multiSelect={true}
                      onChange={(option: DepartmentItem) =>
                        handleAddDepartment(option)
                      }
                      onClear={handleClearDepartments}
                      placeholder="Search department..."
                    />
                    {pairedDeps.length === 0 && (
                      <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                        No paired departments...
                      </h6>
                    )}
                    <div
                      style={{
                        border:
                          pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                        borderRadius: '5px',
                        marginBottom: '20px',
                        marginTop: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ViewportList items={pairedDeps}>
                        {(item: DepartmentItem, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '16fr 1fr',
                              padding: '6px 10px',
                              alignItems: 'center',
                              borderBottom:
                                index === pairedDeps.length - 1
                                  ? ''
                                  : '1px solid #ccc',
                            }}
                            className="listItem"
                          >
                            <div className="contentText">{item.name}</div>
                            {isEditMode && (
                              <FaTimes
                                className="icon-cancel"
                                size={16}
                                onClick={(e) => handleRemoveDepartment(item, e)}
                              />
                            )}
                          </div>
                        )}
                      </ViewportList>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
      </div>
      {isUploading && (
        <div className="loading">
          <Loading type="bubbles" message={uploadingText} />
        </div>
      )}
    </div>
  );
};

export default ProtocolSetEdit;
