import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { globals } from '../../ui/_global/common/Utils';
import { User, VitalOption, Vitals } from '../../models';
import { ProgressStatus } from '../../models';

export function parseOption(option: VitalOption) {
  if (option.amntLow == null || option.amntHigh === '') return '';
  try {
    /* First parse the low amount into the value and units */
    let optionArr = option.amntLow.split(' ', 2);
    let optionHighArr = option.amntHigh.split(' ', 2);

    if (optionArr.length !== 2) {
      if (globals.debug)
        if (globals.debug)
          console.log(
            'ERROR: Option string is not in the correct format: ' +
              option.amntLow
          );
      return '';
    }

    /* If there is no range return the low amount */
    if (option.amntHigh == null || option.amntHigh === '')
      return option.amntLow;
    /* Otherwise parse the range and match units if necessary */ else {
      /* Parse the high amount into the value and units */
      if (optionHighArr.length !== 2) {
        if (globals.debug)
          if (globals.debug)
            console.log(
              'ERROR: Option string is not in the correct format: ' +
                option.amntHigh
            );
        return '';
      }

      /* If the units are the same, then return the low and high amounts Ex. 72-104 mmHg */
      if (optionArr[1] === optionHighArr[1]) {
        return `${optionArr[0]} - ${optionHighArr[0]} ${optionArr[1]}`;
      } else
      /* Otherwise return the low and high amounts with the units Ex. 72 mmHg/102 kmHg */
        return `${optionArr[0]} ${optionHighArr[1]}/${optionHighArr[0]} ${optionArr[1]}`;
    }
  } catch (e) {
    if (globals.debug)
      if (globals.debug)
        console.log(
          'ERROR: Failed to parse vital option -> ' +
            option.amntLow +
            ' ' +
            option.amntHigh
        );
  }
  return '';
}
class VitalItem extends ModelItem<Vitals> {
  instruction: string;
  warning: string;
  options: VitalOption[];
  overrideItem: VitalItem | null | undefined;
  activeItem: VitalItem | null | undefined;

  constructor(vital: Vitals) {
    super(vital, vital.title, vital.departmentID, vital.status, vital.version);
    this.options = vital.options;
    this.instruction = '';
    this.warning = '';
  }

  getAmount(weight: PatientWeight): string {
    for (let option of this.options) {
      if (
        weight.getAge() >= option.rangeLow &&
        weight.getAge() < option.rangeHigh
      ) {
        return parseOption(option);
      }
    }
    return 'N/A';
  }

  getUid(): string {
    return this.uid;
  }

  getName(): string {
    return this.name;
  }

  getInstruction(): string {
    return this.instruction;
  }

  getWarning(): string {
    return this.warning;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof VitalItem)) return false;
    let other: VitalItem = obj as VitalItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  toString(): string {
    return `VitalItem -> {
            uid=${this.uid},
            name=${this.name},
            instruction=${this.instruction},
            warning=${this.warning},
            options=${this.options.length},
        }`;
  }
}

export default VitalItem;
