import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaSearch, FaCaretDown, FaTimes } from 'react-icons/fa';
import { ViewportList } from 'react-viewport-list';
import { ProgressStatus } from '../../../API';
import ModelItem from '../../../data/model/ModelInterface';
import { toTitleCase } from '../../_global/common/Utils';
import { FaPencil } from 'react-icons/fa6';

interface ProgressStatusInterface {
  status:
    | ProgressStatus
    | keyof typeof ProgressStatus
    | 'Private'
    | 'Protected'
    | 'Public'
    | 'DEACTIVATED';
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  onClick?: (status: string) => void;
}

const Status: React.FC<ProgressStatusInterface> = (props) => {
  const [status, setStatus] = useState<
    | ProgressStatus
    | keyof typeof ProgressStatus
    | 'Private'
    | 'Protected'
    | 'Public'
  >(props.status);

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  return (
    <div
      className={`status-container ${status} ${props.onClick ? 'clickable' : ''}`}
      style={{ ...props.style }}
      onClick={() => {
        if (props.onClick) props.onClick(status);
      }}
    >
      <h6 className={`status ${status}`} style={{ ...props.textStyle }}>
        {toTitleCase(status)}
        {props.onClick && (
          <FaPencil style={{ marginLeft: '8px' }} color="black" />
        )}
      </h6>
    </div>
  );
};

export default Status;
