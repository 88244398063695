import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Contact, Log, User, UserStatus } from '../../../../../models';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../ProtocolHeader';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { handleGetDepartment } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { UserType } from '../../../../../models';
import { Col, Row } from 'react-bootstrap';
import {
  getFormattedDateTime,
  globals,
} from '../../../../_global/common/Utils';
import { Dropdown } from 'primereact/dropdown';
import {
  adminUserTypes,
  genUserTypes,
} from '../../../../_global/constants/Protocol_constants';
import UserStatusUI from '../../../../components/UserStatus/UserStatus';
import { set } from 'lodash';
import Loading from '../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  editUser,
  fetchUsers,
  updateUserStatus,
} from '../../../../../data/functions/UserDB';

const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});
const lambda = new AWS.Lambda();
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CreateEditUser = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const searchState = state.searchState;
  const currentUser: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<Log[]>([]);
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;
  const parmUser: User = state.value;
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [isPermanentlyDelete, setIsPermanentlyDelete] = useState(
    parmUser.status === UserStatus.SUSPENDED
  );
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: parmUser ? parmUser.id : '',
      firstName: parmUser ? parmUser.firstName : '',
      lastName: parmUser ? parmUser.lastName : '',
      type: parmUser ? parmUser.type : UserType.USER,
      username: parmUser ? parmUser.cognitoID : '',
      departmentID: parmUser ? parmUser.departmentID : department.id,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      username: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      pairedDeps: Yup.array(),
      departmentID: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      let result;
      if (parmUser == null)
        result = {
          type: ResponseType.Failure,
          data: 'TODO CANNOT CREATE USER YET',
        };
      else result = await editUser(values, pairedDeps);

      if (result.type === ResponseType.Success) {
        let user = result.data as User;
        if (globals.debug)
          console.log('User', parmUser ? ' editted' : ' created', user);
        //  check if previous user type was different from the new user type
        handleUserTypeChange(parmUser, user);
        await reloadDatabase();

        navigate(`/database/list-users`, {
          state: { department: department, database, searchState },
        });
      } else {
        if (globals.debug) console.log('Create/Edit user failed', result.data);
      }
    },
  });

  const handleUserTypeChange = async (oldUser: User, newUser: User) => {
    try {
      if (oldUser && oldUser.type !== newUser.type) {
        if (globals.debug)
          console.log(
            'User type changed from',
            oldUser.type,
            'to',
            newUser.type
          );

        const payload = {
          isUpdateType: true,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userName: newUser.cognitoID,
          newType: newUser.type,
          previousType: oldUser.type,
        };

        const params = {
          FunctionName: 'update-user-type',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify(payload),
        };

        try {
          const result = await lambda.invoke(params).promise();
          if (globals.debug)
            console.log('Lambda invoked successfully:', result);
        } catch (error) {
          setIsWarningModal(true);
          console.error('Failed to invoke Lambda:', error);
        }
      }
    } catch (error) {
      setIsWarningModal(true);
      console.error('Failed to change user type:', error);
    }
  };

  useEffect(() => {
    if (parmUser.pairedDepIDs && department.subDeps) {
      let ids = parmUser.pairedDepIDs;
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return ids.some((d: string) => d === dep.id);
      });
      setPairedDeps(l);
    }
  }, [parmUser, department]);

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, pairedDeps]);

  const reloadDatabase = async (): Promise<DatabaseResponse | null> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await fetchUsers(database.department);
    if (resp.type === ResponseType.Success) {
      const users: User[] = resp.data as User[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          users: users,
        })
      );
      return { ...database, users: users } as DatabaseResponse;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return null;
    }
  };

  // useEffect(() => {
  //     let l = [...database.users];
  //     setList(l);
  // }
  // , [parmUser, database]);

  const handleBack = () => {
    navigate(`/database/list-users`, {
      state: { department: department, database, searchState },
    });
  };

  const RenderUser = ({ item, index }: any) => {
    let isThis = parmUser && item.uid === parmUser.id;
    let user = item as User;
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {user.firstName + ' ' + user.lastName}
            {isThis ? ' (This)' : ''}
          </div>
          {/* <div className="headerTextLight" style={{fontSize: '14px', margin: 0, fontWeight: '500'}}>{user.number}</div> */}
        </div>
      </div>
    );
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const handleUpdateUser = async (status: UserStatus) => {
    let promises: Promise<Response>[] = [];
    setIsLoading(true);
    promises.push(updateUserStatus(parmUser, status));
    promises.push(updateCognitoUser(parmUser.cognitoID, status));

    let results = await Promise.all(promises);
    if (results[0] && results[0].type === ResponseType.Success) {
      if (globals.debug) console.log('User status updated');
    } else {
      console.error('Error updating user status:', results[0].data);
    }

    if (results[1] && results[1].type === ResponseType.Success) {
      if (globals.debug) console.log('User deleted');
    } else {
      console.error('Error deleting user:', results[1].data);
    }
    setIsLoading(false);

    let newDB = await reloadDatabase();
    navigate(`/database/list-users`, {
      state: {
        department: department,
        database: newDB ? newDB : database,
        searchState,
      },
    });
  };

  const updateCognitoUser = async (
    username: string,
    status: UserStatus
  ): Promise<Response> => {
    try {
      const url =
        process.env.REACT_APP_API_GATEWAY_URL_USER ??
        (function () {
          throw new Error('API URL is not defined');
        })();
      if (globals.debug) console.log('API URL:', url);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          action:
            status === UserStatus.ACTIVE
              ? 'enable'
              : status === UserStatus.SUSPENDED
                ? 'disable'
                : 'delete',
        }),
      });

      if (!response.ok) {
        throw new Error('API call failed with status ' + response.status);
      }
      if (globals.debug) console.log('API call successful', response);
      if (globals.debug) console.log('Response Bdy:', response.body);
      const data = await response.json();
      if (globals.debug) console.log('Response data:', data);
      if (globals.debug) console.log('Response body:', data.body);
      if (globals.debug) console.log('Response body', data.message);
      return {
        type: ResponseType.Success,
        data: data.message,
      };
    } catch (error) {
      console.error('Error calling the API:', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  const isSaveActive = useMemo(() => {
    let changed = false;
    //Check if every ID is in the pairedDeps
    if (pairedDeps && parmUser.pairedDepIDs) {
      if (pairedDeps.length !== parmUser.pairedDepIDs.length) changed = true;
      else {
        let ids = parmUser.pairedDepIDs;
        if (ids) {
          ids.forEach((id: string) => {
            if (!pairedDeps.some((d: DepartmentItem) => d.id === id)) {
              changed = true;
            }
          });
        }
      }
    }
    return changed || (formik.dirty && formik.isValid);
  }, [pairedDeps, formik.dirty, formik.isValid, parmUser.pairedDepIDs]);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  if (!isLoggedIn) {
    navigate('/login');
    return <div></div>;
  }

  return (
    <div className="screen-container">
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Error: User Update Failed"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          isDeleteBtn={true}
          handleSubmit={() => setIsWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Please try again later or contact Hinckley Medical Representative at support@hinckleymed.com."
        />
      )}

      {isLoading && <Loading type="bubbles" />}
      {parmUser && (
        <ConfirmModal
          isVisible={isDelete}
          title={isPermanentlyDelete ? 'Delete User?' : 'Disable User?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={() => {
            handleUpdateUser(
              isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED
            );
            setIsEnable(false);
          }}
          handleCheck={(checked: boolean) => {
            setIsPermanentlyDelete(checked);
          }}
          isDeleteBtn={true}
          isPermanentlyDeleteBtn={parmUser.status !== UserStatus.SUSPENDED}
          primaryBtnName="Cancel"
          secondaryBtnName={isPermanentlyDelete ? 'Delete' : 'Disable'}
          primaryDescription={
            isPermanentlyDelete
              ? 'Are you sure you want to DELETE ' +
                parmUser.firstName +
                ' ' +
                parmUser.lastName +
                '?'
              : 'Are you sure you want to disable ' +
                parmUser.firstName +
                ' ' +
                parmUser.lastName +
                ''
          }
          secondaryDescription={
            isPermanentlyDelete
              ? 'This is a PERMANENT action and cannot be undone. Deleting user will remove all access to the OneDose App and OneDose Admin Console.'
              : 'Disabling user will make it so he cannot access the OneDose App or OneDose Admin Console. You can re-enable the user in the future.'
          }
        />
      )}
      {parmUser && (
        <ConfirmModal
          isVisible={isEnable}
          title={'Re-Enable User?'}
          handleClose={() => {
            setIsEnable(false);
          }}
          handleSubmit={() => {
            handleUpdateUser(UserStatus.ACTIVE);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName={'Enable'}
          primaryDescription={
            'Do you want to re-enable ' +
            parmUser.firstName +
            ' ' +
            parmUser.lastName +
            '?'
          }
          secondaryDescription={
            'This will give this user access again to the OneDose App and OneDose Admin Console.'
          }
        />
      )}
      <div className="fixedHeader">
        <ProtocolHeader
          name={parmUser ? 'Edit User' : 'New User'}
          type={'protocol'}
          page={'Users'}
          isCreateButton={parmUser ? false : true}
          isSaveButton={
            parmUser && parmUser.status !== UserStatus.DELETED ? true : false
          }
          isDeleteButton={
            parmUser && parmUser.status !== UserStatus.DELETED ? true : false
          }
          handleDelete={() => {
            setIsDelete(true);
          }}
          isSaveActive={isSaveActive}
          isBackButton={true}
          isDotButton={true}
          isCreateActive={formik.dirty && formik.isValid}
          handleCancel={handleBack}
          handleCreate={() => formik.handleSubmit()}
          handleSave={() => formik.handleSubmit()}
          handleCancelEdit={handleBack}
          isEnableButton={parmUser.status === UserStatus.SUSPENDED}
          handleEnable={() => {
            setIsEnable(true);
          }}
        />
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">User Information</h5>
          <div className="input-container roundBorder">
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
              }}
              className="ketamine-general-label"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Status:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  <UserStatusUI
                    status={parmUser.status}
                    style={{ fontSize: '14px' }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Username:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {parmUser.cognitoID}
                </div>
              </div>
              {parmUser.updatedAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDateTime(parmUser.updatedAt, false)}
                  </div>
                </div>
              )}
              {parmUser.createdAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Created Date:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDateTime(parmUser.createdAt, false)}
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {parmUser.id}
                    <span>
                      {isCopied && isCopied === parmUser.id ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(parmUser.id as string, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Dep Admin:</div>
                  <div
                    style={{
                      marginRight: '10px',
                      fontWeight: '500',
                      paddingBottom: '10px',
                    }}
                  >
                    {parmUser.depAdmins ? parmUser.depAdmins.length : '0'}
                  </div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {parmUser.depAdmins &&
                    parmUser.depAdmins.includes(department.id)
                      ? 'True'
                      : 'false'}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="FirstName" className="ketamine-general-label">
                First Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="FirstName"
                  name="FirstName"
                  data-testid="FirstName"
                  value={formik.values.firstName}
                  onChange={(e: any) => {
                    formik.setFieldValue('firstName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="LastName" className="ketamine-general-label">
                Last Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="LastName"
                  name="LastName"
                  data-testid="LastName"
                  value={formik.values.lastName}
                  onChange={(e: any) => {
                    formik.setFieldValue('lastName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="type" className={`notification-css-title`}>
            User Role
          </label>
          {currentUser.type !== UserType.ADMIN &&
            currentUser.type !== UserType.DEPT_ADMIN && (
              <p
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '0px', margin: 0 }}
              >
                Only Admins can change the user role.
              </p>
            )}
          <div className="input-container-we cursorPointer">
            <Dropdown
              id="type"
              name="type"
              data-testid="type"
              value={formik.values.type}
              options={
                currentUser.type === UserType.ADMIN
                  ? adminUserTypes
                  : genUserTypes
              }
              disabled={
                currentUser.type !== UserType.ADMIN &&
                currentUser.type !== UserType.DEPT_ADMIN
              }
              onChange={(e) => {
                if (globals.debug) console.log('e', e.value);
                formik.setFieldValue('type', e.value);
              }}
              style={{ width: '100%', borderRadius: '10px', margin: 0 }}
              className="dropDownIconWidth input-container-we"
            />
          </div>
          {department.subDeps && department.subDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {department.subDeps.length}
                  <span
                    onClick={() =>
                      setPairedDeps(department.subDeps as DepartmentItem[])
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">
            {parmUser.firstName + ' ' + parmUser.lastName}'s Logs
          </h5>
          {list.length === 0 && (
            <div className="container">
              <div className="text" style={{ color: '#616161' }}>
                User has no logs...
              </div>
            </div>
          )}
          {list.length > 0 && (
            <div className="list-container">
              <ViewportList items={list}>
                {(item: Log, index: any) => {
                  return (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      {/* <RenderContact item={item} index={index} /> */}
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateEditUser;
