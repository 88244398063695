// Note: EditDB for InfusionItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';

import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import {
  MedicationConcentrationInput,
  MedicationRouteInput,
  PatientAgeGroup,
  ProgressStatus,
} from '../../API';
import {
  globals,
  isObjectEqual,
  removeTypename,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import InfusionItem, { cloneInfusion } from '../model/InfusionItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../model/InfusionSubItem';
import ProtocolItem from '../model/ProtocolItem';
import {
  ProtocolJSON,
  createProtocol,
  getProtocolByID,
  validatePointerID,
} from './ProtocolDB';
import { graphqlOperation } from 'aws-amplify';
import {
  getDrip,
  getInfusionDose,
  infusionDosesByDripID,
} from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import {
  Concentration,
  Drip,
  InfusionDose,
  MedicationProtocol,
  MedicationRange,
  MedicationRoute,
  ModelMetaData,
  User,
} from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
export type InfusionJSON = {
  name: string;
  concentration: Concentration[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  routes: string[];
  dripOptions: MedicationProtocol[];
  taggedProtocols?: ProtocolItem[];

  rxNormCode?: string | null | undefined;
  nemsisRoutes?: MedicationRoute[] | null | undefined;
  minDose?: string | null | undefined;
  maxDose?: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;

  contraindication: string | null | undefined;
  note: string | null | undefined;
  warning: string | null | undefined;
  medClass: string | null | undefined;
  action: string | null | undefined;
  indication: string | null | undefined;
  interaction: string | null | undefined;
  onset: string | null | undefined;
  duration: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
};

export type InfusionDoseJSON = {
  departmentID: string;
  infusion: InfusionItem;
  protocol: ProtocolItem;

  index: number;
  basis: string;
  route: string[];
  nemsisRoutes?: MedicationRouteInput[];

  rangeLow: number;
  rangeHigh: number;
  ageLow?: number | null | undefined;
  ageHigh?: number | null | undefined;
  ageGroup?: PatientAgeGroup | null | undefined;
  repeatTime?: number | null | undefined;

  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;

  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  maxTotalDose?: string | null | undefined;
  calcMax?: string | null | undefined;
  calcMin?: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  createdAt: Date;
};

/**
 * Create a new PARENT infusion in the database and choose the version
 * @param infusion InfusionJSON JSON format or Model InfusionItem
 * @returns The successful InfusionItem or the error
 */
export const createParentInfusion = async (
  department: DepartmentItem,
  infusion: InfusionJSON | InfusionItem,
  previousItem?: InfusionItem
): Promise<Response> => {
  try {
    let json: InfusionJSON;
    if (infusion instanceof InfusionItem) {
      let medItem = infusion as InfusionItem;
      if (medItem.model == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medItem does not have a database object',
        };
      }

      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < medItem.subItems.length; i++) {
        if (!protocols.includes(medItem.subItems[i].parentProtocol))
          protocols.push(medItem.subItems[i].parentProtocol);
      }

      json = {
        name: medItem.name,
        concentration: medItem.concentrations,
        rangeLow: medItem.rangeLow ? medItem.rangeLow : 0,
        rangeHigh: medItem.rangeHigh ? medItem.rangeHigh : globals.MAX_VALUE,
        departmentID: medItem.departmentID,
        routes: medItem.routes,
        dripOptions: medItem.protocolOptions,
        status: medItem.status,
        overrideID: medItem.overrideID,
        activeID: medItem.activeID,
        version: medItem.version,
        taggedProtocols: protocols,

        rxNormCode: medItem.model.rxNormCode,
        nemsisRoutes: medItem.model.nemsisRoutes,
        minDose: medItem.model.minDose,
        maxDose: medItem.model.maxDose,
        metaData: medItem.model.metaData,

        contraindication: medItem.model.contraindication,
        note: medItem.model.note,
        warning: medItem.model.warning,
        medClass: medItem.model.medClass,
        action: medItem.model.action,
        indication: medItem.model.indication,
        interaction: medItem.model.interaction,
        onset: medItem.model.onset,
        duration: medItem.model.duration,

        createdBy: medItem.model.createdBy ? medItem.model.createdBy : '',
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,
      };
    } else json = infusion as InfusionJSON;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    /* WARNING this only updates the old MedicationConcentrations */
    let oldConcentrations: MedicationConcentrationInput[] = [];
    if (json.concentration != null) {
      for (let i = 0; i < json.concentration.length; i++) {
        let con = json.concentration[i];
        let newConcentration: MedicationConcentrationInput = {
          firstAmnt: con.firstAmnt,
          secAmnt: con.secAmnt,
          firstUnit: con.firstUnit,
          secUnit: con.secUnit,
        };
        oldConcentrations.push(newConcentration);
      }
    }

    let d: Drip;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbDrip = await DataStore.query(Drip, previousItem.uid);
      if (dbDrip == null) {
        return {
          type: ResponseType.Failure,
          data:
            'The DRAFT Infusion does not exist could not update. ID: ' +
            previousItem.uid,
        };
      }

      d = await DataStore.save(
        Drip.copyOf(dbDrip, (updated) => {
          updated.name = json.name;
          updated.concentration = removeTypename(oldConcentrations);
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.route = json.routes;
          updated.dripOptions = json.dripOptions;

          updated.contraindication = json.contraindication;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.medClass = json.medClass;
          updated.action = json.action;
          updated.indication = json.indication;
          updated.interaction = json.interaction;
          updated.onset = json.onset;
          updated.duration = json.duration;

          updated.modifiedBy = json.modifiedBy;
          updated.nemsisRoutes = removeTypename(nemsisRoutes);
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      d = await DataStore.save(
        new Drip({
          name: json.name,
          concentration: removeTypename(oldConcentrations),
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          departmentID: json.departmentID,
          route: json.routes,
          dripOptions: json.dripOptions,

          contraindication: json.contraindication,
          note: json.note,
          warning: json.warning,
          medClass: json.medClass,
          action: json.action,
          indication: json.indication,
          interaction: json.interaction,
          onset: json.onset,
          duration: json.duration,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,

          rxNormCode: json.rxNormCode,
          nemsisRoutes: removeTypename(nemsisRoutes),
          minDose: json.minDose,
          maxDose: json.maxDose,
          metaData: removeTypename(json.metaData),
        })
      );
    }

    let infusItem = new InfusionItem(d);

    let concenResp = await updateConcentrations(
      department,
      infusItem,
      json.concentration
    );
    infusItem.concentrations = concenResp.data as Concentration[];

    /* Validate the protocols point to the infusion --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        let infusID =
          infusItem.status === ProgressStatus.ACTIVE ||
          (infusItem.status === ProgressStatus.DRAFT &&
            infusItem.activeID == null)
            ? infusItem.uid
            : infusItem.activeID;
        if (infusID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The infusion does not have a proper version ID ' +
              infusItem.uid +
              ' ' +
              infusItem.activeID +
              ' ' +
              infusItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          infusID,
          json.modifiedBy,
          'Infusion'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING INFUSION:', e, infusion);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchDrips = async (
  dep: DepartmentItem,
  db?: DatabaseResponse
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    const dripList = await DataStore.query(Drip, (d) =>
      d.and((d) => [
        d.or((d) => depIDs.map((id) => d.departmentID.eq(id))),
        d.and((d) => [d.status.ne('ARCHIVE'), d.status.ne('DELETED')]),
      ])
    );

    let infus: InfusionItem[] = [];
    for (let i = 0; i < dripList.length; i++) {
      let infusion = new InfusionItem(dripList[i]);
      // if (infusion.status.includes('DRAFT') || infusion.status === 'ACTIVE') {
      /* Take out the active version if there is one */
      mapModelItems(infusion, infus, infusion.status, dep);
      // }
    }
    /* TODO - dteermine multiple queries or one large query and mapping is better */
    for (let i = 0; i < infus.length; i++) {
      let infusion = infus[i];
      DataStore.query(Concentration, (c) =>
        c.and((c) => [c.dripID.eq(infusion.uid), c.departmentID.eq(dep.id)])
      ).then((concentrations) => {
        infusion.concentrations = concentrations.sort((a, b) => {
          let aVal = a.secAmnt ? a.firstAmnt / a.secAmnt : a.firstAmnt;
          let bVal = b.secAmnt ? b.firstAmnt / b.secAmnt : b.firstAmnt;
          return aVal - bVal;
        });
      });
    }
    infus.sort((a, b) => a.getName().localeCompare(b.getName()));

    if (db) {
      let promises: any[] = [];
      for (let i = 0; i < infus.length; i++)
        promises.push(fetchInfusionDosesForInfusion(db, dep, infus[i]));
      await Promise.all(promises);
    }
    return {
      type: ResponseType.Success,
      data: infus,
    };
  } catch (error) {
    console.error('Error fetching infusions:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchInfusionDoses = async (
  dep: DepartmentItem
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let infusionList = await DataStore.query(InfusionDose, (m) =>
      m.and((m) => [
        m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
        m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
      ])
    );

    for (let i = 0; i < infusionList.length; i++) {
      let infusion = infusionList[i];
      if (infusion.status.includes('DRAFT') && infusion.departmentID !== dep.id)
        infusionList.splice(i, 1);
    }

    return {
      type: ResponseType.Success,
      data: infusionList,
    };
  } catch (error) {
    console.error('Error fetching medications:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchInfusionDosesForInfusion = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  infusion: InfusionItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let infusionID =
      infusion.status.includes('DRAFT') && infusion.activeID
        ? infusion.activeID
        : infusion.uid;

    let infusionList;
    if (useDataStore) {
      infusionList = await DataStore.query(InfusionDose, (m) =>
        m.and((m) => [
          m.dripID.eq(infusionID),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      infusionList = await executeQuery(infusionDosesByDripID, {
        dripID: infusionID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }

    let infusionDoses: InfusionSubItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      let infusion = infusionList[i];
      if (infusion.status.includes('DRAFT') && infusion.departmentID !== dep.id)
        continue;
      else {
        let protocol = db.protocols.find(
          (p) =>
            p.uid === infusion.protocolID || p.activeID === infusion.protocolID
        );
        if (protocol) {
          let elec = new InfusionSubItem(infusion, protocol, infusion);
          infusionDoses.push(elec);
        }
      }
    }

    infusionDoses.sort((a, b) => a.index - b.index);
    infusion.subItems = infusionDoses;

    return {
      type: ResponseType.Success,
      data: infusionDoses,
    };
  } catch (error) {
    console.error('Error fetching infusionList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Replace the existing concentrations with the new ones and add new ones
 * 1. Exisiting infusion has more concentrations than the new one
 *    - Delete the extra concentrations
 * 2. Exisiting infusion has less concentrations than the new one
 *    - Create the new concentrations
 * 3. Exisiting infusion has the same number of concentrations as the new one
 *    - Update the existing concentrations
 * @param med The infusion to update the concentrations
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const updateConcentrations = async (
  department: DepartmentItem,
  infus: InfusionItem,
  concentrations: Concentration[],
  isDelete: boolean = false
): Promise<Response> => {
  try {
    /* First query for existing concentrations */
    let existingConcentrations = await DataStore.query(Concentration, (c) =>
      c.and((c) => [c.dripID.eq(infus.uid), c.departmentID.eq(department.id)])
    );

    console.log(
      'Infusion ',
      infus.name,
      ' has ',
      existingConcentrations.length,
      ' existing concentrations'
    );

    if (isDelete) {
      for (let i = 0; i < existingConcentrations.length; i++) {
        let con = existingConcentrations[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }
      console.log('Deleted ', existingConcentrations.length, ' concentrations');

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    } else {
      let concens: Concentration[] = [...concentrations];
      let oldConcens: Concentration[] = [...existingConcentrations];
      let n = 0;
      for (
        let i = 0;
        i < Math.min(concentrations.length, existingConcentrations.length);
        i++
      ) {
        let newCon = concentrations[i];
        let oldCon = existingConcentrations[i];
        /* Update the existing concentration */
        let result = await DataStore.save(
          Concentration.copyOf(oldCon, (updated) => {
            updated.firstAmnt = newCon.firstAmnt;
            updated.secAmnt = newCon.secAmnt;
            updated.firstUnit = newCon.firstUnit;
            updated.secUnit = newCon.secUnit;
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not update correctly',
          };
        }
        /* Remove the concentration from the list */
        concens = concens.filter((c) => !isObjectEqual(c, newCon));
        oldConcens = oldConcens.filter((c) => !isObjectEqual(c, oldCon));
        n++;
      }
      console.log('Updated ', n, ' concentrations');

      /* Create any new concentrations */
      for (let i = 0; i < concens.length; i++) {
        let con = concens[i];
        let result = await DataStore.save(
          new Concentration({
            departmentID: department.id,
            dripID: infus.uid,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }

      console.log('Created ', concens.length, ' concentrations');

      /* Delete any extra concentrations */
      for (let i = 0; i < oldConcens.length; i++) {
        let con = oldConcens[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }

      console.log('Deleted ', oldConcens.length, ' concentrations');

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    }
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, infus);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Propagate the concentrations to all the medications that are associated with the department
 *  - This should only be called when creating a new medication
 * @param department The curren department to propagate the concentrations from
 * @param infus The infusion to propagate the concentrations to
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const propagateInfusionConcentrations = async (
  department: DepartmentItem,
  infus: InfusionItem,
  concentrations: Concentration[]
): Promise<Response> => {
  try {
    let deps = department.allSubDeps;
    if (deps == null || deps.length === 0)
      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    let concens: Concentration[] = [...concentrations];
    let dripID =
      infus.status === ProgressStatus.DRAFT && infus.activeID
        ? infus.activeID
        : infus.uid;
    for (let i = 0; i < deps.length; i++) {
      let dep = deps[i];
      /* Create any new concentrations */
      for (let j = 0; j < concens.length; j++) {
        let con = concens[j];
        let result = await DataStore.save(
          new Concentration({
            departmentID: dep.id,
            dripID: dripID,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }
    }

    console.log('Created ', concens.length, ' concentrations');
    return {
      type: ResponseType.Success,
      data: concentrations,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, infus);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new infusion DOSE in the database and choose the version
 * @param infusion InfusionDoseJSON JSON format or Model InfusionSubItem
 * @returns The successful InfusionSubItem or the error
 */
export const createInfusionDoseItem = async (
  infusion: InfusionDoseJSON | InfusionSubItem,
  previousItem?: InfusionSubItem
): Promise<Response> => {
  try {
    let json: InfusionDoseJSON;
    if (infusion instanceof InfusionSubItem) {
      let medItem = infusion as InfusionSubItem;
      json = {
        departmentID: medItem.departmentID,
        infusion: medItem.parent,
        protocol: medItem.parentProtocol,
        index: medItem.index,
        basis: medItem.fullBasis,
        route: medItem.routes,
        nemsisRoutes: medItem.nemsisRoutes,
        rangeLow: medItem.rangeLow,
        rangeHigh: medItem.rangeHigh,
        ageLow: medItem.ageLow ? medItem.ageLow.ageValue : null,
        ageHigh: medItem.ageHigh ? medItem.ageHigh.ageValue : null,
        ageGroup: medItem.ageGroup,
        repeatTime: medItem.repeatTimeSec,
        title: medItem.title,
        warning: medItem.warning,
        instruction: medItem.instruction,
        note: medItem.note,
        maxDose: medItem.fullMaxDose,
        minDose: medItem.fullMinDose,
        maxTotalDose: medItem.fullMaxTotalDose,
        calcMax: medItem.model.calcMax,
        calcMin: medItem.model.calcMin,
        createdBy: medItem.model.createdBy,
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,
        status: medItem.status,
        activeID: medItem.activeID,
        version: medItem.version,
        createdAt: new Date(medItem.model.createdAt),
      };
    } else json = infusion as InfusionDoseJSON;

    /* Validate the routes are of string array or convert to string array */
    if (json.route == null) json.route = [];
    else if (typeof json.route === 'string') json.route = [json.route];

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    let m: InfusionDose;
    let protID: string =
      json.protocol.status.includes('DRAFT') && json.protocol.activeID
        ? json.protocol.activeID
        : json.protocol.uid;
    let infusID =
      json.infusion.status.includes('DRAFT') && json.infusion.activeID
        ? json.infusion.activeID
        : json.infusion.uid;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbMed = await DataStore.query(InfusionDose, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Medication Dose does not exist could not update',
        };
      }

      m = await DataStore.save(
        InfusionDose.copyOf(dbMed, (updated) => {
          updated.index = json.index;
          updated.basis = json.basis;
          updated.route = json.route;
          updated.nemsisRoutes = json.nemsisRoutes;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.ageLow = json.ageLow;
          updated.ageHigh = json.ageHigh;
          updated.ageGroup = json.ageGroup;
          updated.repeatTime = json.repeatTime
            ? json.repeatTime + ''
            : undefined;
          updated.title = json.title;
          updated.warning = json.warning;
          updated.instruction = json.instruction;
          updated.note = json.note;

          updated.maxDose = json.maxDose;
          updated.minDose = json.minDose;
          updated.maxTotalDose = json.maxTotalDose;
          updated.calcMax = json.calcMax;
          updated.calcMin = json.calcMin;

          updated.status = json.status;
          updated.activeID = json.activeID;
          updated.version = json.version ? json.version : 'v1.0.0';
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      m = await DataStore.save(
        new InfusionDose({
          departmentID: json.departmentID,
          dripID: infusID,
          protocolID: protID,
          index: json.index,
          basis: json.basis,
          route: json.route,
          nemsisRoutes: json.nemsisRoutes,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          ageLow: json.ageLow,
          ageHigh: json.ageHigh,
          ageGroup: json.ageGroup,
          repeatTime: json.repeatTime ? json.repeatTime + '' : undefined,
          title: json.title,
          warning: json.warning,
          instruction: json.instruction,
          note: json.note,
          maxDose: json.maxDose,
          minDose: json.minDose,
          maxTotalDose: json.maxTotalDose,
          calcMax: json.calcMax,
          calcMin: json.calcMin,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version ? json.version : 'v1.0.0',
          createdAt: new Date().toISOString(),
        })
      );
    }

    let medItem = new InfusionSubItem(json.infusion, json.protocol, m);

    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug)
      console.log('ERROR CREATING INFUSION DOSE:', e, infusion);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the infusions that are associated with the protocol
 * @param protocol The protocol to delete the infusion from
 * @param modifiedBy The user that modified the infusion
 * @returns Success: data is the number of infusions deleted or Failure if there was an error
 */
export const deleteInfusionsForProtocol = async (
  department: DepartmentItem,
  protocol: ProtocolItem,
  modifiedBy: User,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      protocol.status === ProgressStatus.ACTIVE ||
      (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
        ? protocol.uid
        : protocol.activeID;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          protocol.uid +
          ' ' +
          protocol.activeID +
          ' ' +
          protocol.status,
      };
    }

    /* Get all the Medications that the department has */
    let medicationList = await DataStore.query(InfusionDose, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
          c.status.eq(ProgressStatus.DRAFT_DELETE),
        ]),
        c.protocolID.eq(protID as string),
      ])
    );

    let n = 0;
    for (let i = 0; i < medicationList.length; i++) {
      let med = medicationList[i];
      if (med.status === 'ACTIVE' && isSoft) {
        let result = await DataStore.save(
          InfusionDose.copyOf(med, (updated) => {
            updated.status = ProgressStatus.DRAFT_DELETE;
            updated.modifiedBy = modifiedBy.id;
          })
        );
        if (result == null) {
          console.error('ERROR DELETING INFUSION:', result);
          continue;
        }
      } else {
        let result = await DataStore.delete(InfusionDose, med.id);
        if (result == null) {
          console.error('ERROR DELETING INFUSION:', result);
          continue;
        }
      }
      n++;
    }

    console.log('DELETED INFUSION DOSES -> PROTOCOL', protocol.name, protID, n);
    n = 0;
    /* Get all the Medications that the department has */
    let infusionList = await DataStore.query(Drip, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
        ]),
      ])
    );

    let infusions: InfusionItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      infusions.push(new InfusionItem(infusionList[i]));
    }

    for (let i = 0; i < infusions.length; i++) {
      let infus = infusions[i];

      let protocolOptions = infus.protocolOptions;

      let medProtocol: MedicationProtocol | undefined = undefined;
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        if (po.protocolID === protID) {
          medProtocol = po;
          break;
        }
      }
      /* If there is no infusion protocol then there is a problem */
      if (!medProtocol) continue;

      /* Remove the infusion protocol from the infusion */
      let newOptions = protocolOptions.filter(
        (p) => p.protocolID !== (medProtocol as MedicationProtocol).protocolID
      );

      let newInfus = cloneInfusion(infus);
      newInfus.protocolOptions = newOptions;
      newInfus.status = ProgressStatus.DRAFT;
      newInfus.activeID =
        infus.status === ProgressStatus.ACTIVE ? infus.uid : infus.activeID;
      newInfus.version = upgradeVersion(
        infus.version == null ? 'v1.0.0' : infus.version
      );
      newInfus.modifiedBy = modifiedBy;

      //Increment the count modified
      n++;

      createParentInfusion(department, newInfus, infus)
        .then((result) => {
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DELETING INFUSION:', result.data);
          }
          console.log('DELETED PROTOCOL REFERENCE -> MED', infus.name, protID);
        })
        .catch((e) => {
          console.error('ERROR DELETING INFUSION:', e);
        });
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Duplicate the medication doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @param isDraft True if the protocol is a draft version, otherwise will update the active version
 * @returns An array of the medications that were duplicated
 */
export const duplicateInfusionDose = async (
  department: DepartmentItem,
  dose: InfusionSubItem,
  user: User,
  protocolItem?: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID = protocolItem
      ? protocolItem.status === ProgressStatus.DRAFT && protocolItem.activeID
        ? protocolItem.activeID
        : protocolItem.uid
      : dose.parentProtocol.status === ProgressStatus.DRAFT &&
          dose.parentProtocol.activeID != null
        ? dose.parentProtocol.activeID
        : dose.parentProtocol.uid;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          dose.parentProtocol +
          ' ' +
          protID,
      };
    }

    let clone = cloneInfusionSubItem(dose);
    clone.parentProtocol = protocolItem ? protocolItem : dose.parentProtocol;
    clone.departmentID = department.id;
    clone.status = ProgressStatus.DRAFT;
    clone.activeID = null;
    clone.version = 'v1.0.0';
    clone.modifiedBy = user;

    let result = await createInfusionDoseItem(clone);
    if (result.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: result.data,
      };
    }

    if (globals.debug)
      console.log('Successfully duplicated medication Dose', result.data);
    return {
      type: ResponseType.Success,
      data: result.data as InfusionSubItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if the protocol is already pointing at the infusion
 *    - Otherwise add the infusion to the protocol and create a new protocol draft
 * @param infusion The infusion o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the infusion
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the infusion is paired with the protocol and returns a MedicationProtocol or Failure if there was an error
 */
export const validateInfusionProtocolPairing = async (
  infusion: InfusionItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (infusion.model == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not have a database object could be an infusion item',
      };
    }
    let infusionProtocol: MedicationProtocol;
    let infusID =
      infusion.status === ProgressStatus.ACTIVE ||
      (infusion.status === ProgressStatus.DRAFT && infusion.activeID == null)
        ? infusion.uid
        : infusion.activeID;
    let pairedID = protocol.model.dripIDs?.find((id) => id === infusID);

    if (infusID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The infusion does not have a proper version ID ' +
          infusion.uid +
          ' ' +
          infusion.activeID +
          ' ' +
          infusion.status,
      };
    }

    /* Create a new protocol draft if the infusion is not paired with the protocol */
    if (pairedID == null) {
      let infusIDs = protocol.model.dripIDs ? protocol.model.dripIDs : [];
      infusIDs.push(infusID);

      let draftProtocol: ProtocolJSON = {
        departmentID: protocol.parent.departmentID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDepsIDs: protocol.pairedDeps
          ? protocol.pairedDeps.map((d) => d.id)
          : protocol.pairedDepIDs ?? [],
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: protocol.medications.map((m) => m.uid),
        infusions: infusIDs,
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: protocol.electrical.map((e) => e.parent.uid),

        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        isRestrictive: protocol.isRestrictive,
        keychainID: protocol.keychainID,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      infusionProtocol = new MedicationProtocol({
        protocolID: protocol.uid,
        options: [],
      });
    } else {
      /* Find the infusion protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let mo = infusion.model.dripOptions.find((p) => p.protocolID === protID);
      if (mo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion protocol does not exist',
        };
      }
      infusionProtocol = mo;
    }

    /* Otherwise we just need to update the MedicationProtocol */
    return {
      type: ResponseType.Success,
      data: infusionProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if two MedicationRanges are equal
 * @param a MedicationRange to compare
 * @param b MedicationRange to compare
 * @returns if the infusion ranges are equal
 */
export const equalsInfusionRange = (
  a: MedicationRange,
  b: MedicationRange
): boolean => {
  let isRouteSame =
    a.route.length === b.route.length &&
    a.route.every((value, index) => value === b.route[index]);
  return (
    isRouteSame &&
    a.basis === b.basis &&
    a.rangeLow === b.rangeLow &&
    a.rangeHigh === b.rangeHigh &&
    a.title === b.title &&
    a.warning === b.warning &&
    a.instruction === b.instruction &&
    a.note === b.note &&
    a.maxDose === b.maxDose &&
    a.minDose === b.minDose &&
    a.calcMax === b.calcMax &&
    a.calcMin === b.calcMin &&
    a.index === b.index
  );
};

/**
 * This function will publish the infusion to the database
 *    1. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
 *    2. Update the ACTIVE infusion with the new information
 *    3. Delete the DRAFT infusion
 * @param draftInfusionItem The infusion to publish
 */
export const publishInfusion = async (
  department: DepartmentItem,
  draftInfusionItem: InfusionItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the infusion is configured correctly as a draft version */
    if (draftInfusionItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion is not a draft version',
      };
    }

    let dbDrip = await DataStore.query(Drip, draftInfusionItem.uid);
    if (dbDrip == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not exist',
      };
    }

    let activeInfusion: Drip;

    /* Use case 1: Creating the FIRST active version */
    if (draftInfusionItem.activeID == null) {
      /* Update the draft infusion to be active */
      activeInfusion = await DataStore.save(
        Drip.copyOf(dbDrip, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active infusion item */
      let id: string = draftInfusionItem.activeID;
      let curInfusion = await DataStore.query(Drip, id);

      /* Base Case 3 -- check if the active infusion exists */
      if (curInfusion == null) {
        console.error('Couldn not find active item:', draftInfusionItem);
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist: ' + draftInfusionItem,
        };
      }

      let archive = new InfusionItem(curInfusion);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curInfusion.id;

      // 2. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
      let archiveResult: Response = await createParentInfusion(
        department,
        archive
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as InfusionItem;

      // 2. Update the ACTIVE infusion with the new information
      activeInfusion = await DataStore.save(
        Drip.copyOf(curInfusion, (updated) => {
          updated.name = draftInfusionItem.name;
          updated.concentration = draftInfusionItem.model.concentration;
          updated.rangeLow = draftInfusionItem.rangeLow;
          updated.rangeHigh = draftInfusionItem.rangeHigh;
          updated.route = draftInfusionItem.model.route;
          updated.dripOptions = (draftInfusionItem.model as Drip).dripOptions;

          updated.contraindication = draftInfusionItem.contraindication;
          updated.note = draftInfusionItem.note;
          updated.warning = draftInfusionItem.warning;
          updated.medClass = draftInfusionItem.medClass;
          updated.action = draftInfusionItem.action;
          updated.indication = draftInfusionItem.indication;
          updated.interaction = draftInfusionItem.interaction;
          updated.onset = draftInfusionItem.onset;
          updated.duration = draftInfusionItem.duration;

          updated.createdBy = draftInfusionItem.model.createdBy;
          updated.modifiedBy = draftInfusionItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftInfusionItem.overrideID;
          updated.version = draftInfusionItem.version;
        })
      );

      //3 Update the Concentrations for the medication
      let concentrationResult: Response = await updateConcentrations(
        department,
        new InfusionItem(activeInfusion),
        draftInfusionItem.concentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;
      else console.log('Concentration Updated:', concentrationResult.data);

      // 4. Delete the DRAFT infusion
      let draftInfusion = await DataStore.delete(Drip, draftInfusionItem.uid);
      if (draftInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      // 5. Delete the Concentrations for the medication
      updateConcentrations(
        department,
        draftInfusionItem,
        draftInfusionItem.concentrations,
        true
      );

      // 6. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeInfusion.id, archive.uid);

      // 7. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeInfusion.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let infusItem = new InfusionItem(activeInfusion);
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the infusion to the database
 *    1. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
 *    2. Update the ACTIVE infusion with the new information
 *    3. Delete the DRAFT infusion
 * @param draftInfusionItem The infusion to publish
 */
export const publishInfusionDoses = async (
  draftInfusionItem: InfusionSubItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the infusion is configured correctly as a draft version */
    if (
      draftInfusionItem.status !== ProgressStatus.DRAFT &&
      draftInfusionItem.status !== ProgressStatus.DRAFT_DELETE
    ) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion is not a draft version',
      };
    }

    let dbMed = await DataStore.query(InfusionDose, draftInfusionItem.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion dose does not exist',
      };
    }

    /* Base Case 1: Fully Deleting a Item */
    if (draftInfusionItem.status === ProgressStatus.DRAFT_DELETE) {
      /* Remove the delete draft infusion and switch the activeID version to DELETED */
      let med = await DataStore.delete(InfusionDose, draftInfusionItem.uid);
      if (med == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      if (draftInfusionItem.activeID == null) {
        return {
          type: ResponseType.Success,
          data: 'The infusion dose was deleted',
        };
      }

      /* Update the active infusion to be DELETED */
      let activeMed = await DataStore.query(
        InfusionDose,
        draftInfusionItem.activeID
      );
      if (activeMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist',
        };
      }

      let deletedMed = await DataStore.save(
        InfusionDose.copyOf(activeMed, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      let medItem = new InfusionSubItem(
        draftInfusionItem.parent,
        draftInfusionItem.parentProtocol,
        deletedMed
      );

      return {
        type: ResponseType.Success,
        data: medItem,
      };
    }

    let activeInfusion: InfusionDose;

    /* Use case 1: Creating the FIRST active version */
    if (draftInfusionItem.activeID == null) {
      /* Update the draft infusion to be active */
      activeInfusion = await DataStore.save(
        InfusionDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active infusion item */
      let id: string = draftInfusionItem.activeID;
      let curInfusion = await DataStore.query(InfusionDose, id);

      /* Base Case 3 -- check if the active infusion exists */
      if (curInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist',
        };
      }

      let activeItem = draftInfusionItem.activeItem as InfusionSubItem | null;
      if (activeItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist',
        };
      }

      activeItem.status = ProgressStatus.ARCHIVE;
      activeItem.activeID = curInfusion.id;

      // 2. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
      let archiveResult: Response = await createInfusionDoseItem(activeItem);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      activeItem = archiveResult.data as InfusionSubItem;

      // 2. Update the ACTIVE infusion with the new information
      activeInfusion = await DataStore.save(
        InfusionDose.copyOf(curInfusion, (updated) => {
          updated.protocolID = draftInfusionItem.parentProtocol.uid;
          updated.index = draftInfusionItem.index;
          updated.basis = draftInfusionItem.fullBasis;
          updated.route = draftInfusionItem.routes;
          updated.nemsisRoutes = draftInfusionItem.nemsisRoutes;
          updated.rangeLow = draftInfusionItem.rangeLow;
          updated.rangeHigh = draftInfusionItem.rangeHigh;
          updated.ageLow = draftInfusionItem.ageLow
            ? draftInfusionItem.ageLow.ageValue
            : null;
          updated.ageHigh = draftInfusionItem.ageHigh
            ? draftInfusionItem.ageHigh.ageValue
            : null;
          updated.ageGroup = draftInfusionItem.ageGroup;
          updated.repeatTime = draftInfusionItem.repeatTimeSec
            ? draftInfusionItem.repeatTimeSec + ''
            : undefined;
          updated.title = draftInfusionItem.title;
          updated.warning = draftInfusionItem.warning;
          updated.instruction = draftInfusionItem.instruction;
          updated.note = draftInfusionItem.note;
          updated.maxDose = draftInfusionItem.fullMaxDose;
          updated.minDose = draftInfusionItem.fullMinDose;
          updated.maxTotalDose = draftInfusionItem.fullMaxTotalDose;
          updated.calcMax = draftInfusionItem.model.calcMax;
          updated.calcMin = draftInfusionItem.model.calcMin;
          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftInfusionItem.overrideID;
          updated.version = draftInfusionItem.version
            ? draftInfusionItem.version
            : 'v1.0.0';
          updated.modifiedBy = draftInfusionItem.modifiedBy
            ? draftInfusionItem.modifiedBy.id
            : undefined;
        })
      );

      // 3. Delete the DRAFT infusion
      let draftInfusion = await DataStore.delete(
        InfusionDose,
        draftInfusionItem.uid
      );
      if (draftInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeInfusion.id, activeItem.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeInfusion.id,
          changeType: draftChangeItem.changeType,
          previousItem: activeItem.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let medItem = new InfusionSubItem(
      draftInfusionItem.parent,
      draftInfusionItem.parentProtocol,
      activeInfusion
    );
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will update the infusion in the database
 * @param infusionItem The infusion to delete from the database
 * @param isSoft True if the infusion should be soft deleted, false if it should be hard deleted
 * @returns Success if the infusion was deleted or Failure if there was an error
 */
export const deleteInfusion = async (
  infusionItem: InfusionItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = infusionItem.uid;
    if (isSoft) {
      let infusion = await DataStore.query(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }

      let result = await DataStore.save(
        Drip.copyOf(infusion, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion did not update correctly',
        };
      }
    } else {
      let infusion = await DataStore.delete(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: infusionItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will create a new infusion draft in the database
 * @param department The department to check for infusion drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isInfusionDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will create a new infusion draft in the database
 * @param department The department to check for infusion drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isInfusionDosesDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(InfusionDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(department.id),
      ])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get all the infusion drafts from the database
 * @param department The department to get the infusion drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getInfusionDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = db.infusions.filter((c) => c.status === 'DRAFT');

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if(model.status === "DELETED")
      // message = `Deleted Infusion: ${model.name}`;
      if (model.activeID == null) message = `Created Infusion: ${model.name}`;
      else message = `Updated Infusion: ${model.name}`;
      updates.push({
        model: model,
        title: 'Infusion ' + model.name,
        message: message,
        changeType: DraftChangeType.INFUSION,
      });
    }

    let doseUpdates = db.infusionDoses.filter(
      (d) => d.status === 'DRAFT' || d.status === 'DRAFT_DELETE'
    );
    for (let j = 0; j < doseUpdates.length; j++) {
      let dose = doseUpdates[j];
      let doseMessage = '';
      if (dose.status === 'DRAFT_DELETE')
        doseMessage = `Deleted Infusion Dose: ${dose.name} in ${dose.parentProtocol.name}`;
      else if (dose.activeID == null)
        doseMessage = `Created Infusion Dose: ${dose.name} in ${dose.parentProtocol.name}`;
      else
        doseMessage = `Updated Infusion Dose: ${dose.name} in ${dose.parentProtocol.name}`;
      updates.push({
        model: dose,
        title: 'Infusion Dose ' + dose.name,
        message: doseMessage,
        changeType: DraftChangeType.INFUSION_DOSE,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

function getChangeDescription(draftItem: InfusionItem): string {
  if (draftItem.activeItem == null)
    return `Created Infusion: ${draftItem.name}`;
  else return `Updated Infusion: ${draftItem.name}`;
}

export const convertInfusionChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Infusion ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as InfusionItem),
      changeType: DraftChangeType.INFUSION,
    };

    return update;
  } catch (error) {
    return null;
  }
};

/**
 * Remove all the infusion drafts from the database - User choose to override the drafts
 * @param department The department to remove the drafts from
 * @returns Success if the drafts were removed or Failure if there was an error
 */
export const removeCurrentInfusionDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftInfusions = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftInfusions.length; i++) {
      let med: Drip = draftInfusions[i];
      await DataStore.delete(med);
      updates.push({
        model: med,
        message: `Removed Drip Draft for: ${med.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getInfusionByID = async (
  db: DatabaseResponse,
  id: string
): Promise<InfusionItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.infusions.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeSingleQuery(getDrip, { id: id }, 1500)
          .then((drip: Drip | null | undefined) => {
            if (drip == null) {
              resolve(null);
            } else {
              let infusion = new InfusionItem(drip);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(infusion);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/* GraphQL API Queries */
export const getInfusionDoseByID = async (
  db: DatabaseResponse,
  id: string
): Promise<InfusionSubItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.infusionDoses.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeSingleQuery(getInfusionDose, { id: id }, 1500)
          .then((infusDose: InfusionDose | null | undefined) => {
            if (infusDose == null) {
              resolve(null);
            } else {
              console.log('INFUSION DOSE:', infusDose);
              let promises = [
                getProtocolByID(db, infusDose.protocolID),
                getInfusionByID(db, infusDose.dripID),
              ];
              Promise.all(promises).then((results) => {
                let protocol = results[0] as ProtocolItem;
                let infusion = results[1] as InfusionItem;
                if (protocol == null || infusion == null) resolve(null);

                let infusionDose = new InfusionSubItem(
                  infusion,
                  protocol,
                  infusDose
                );
                resolve(infusionDose);
              });

              // let infusion = new MedicationItem(med);
              // /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              // resolve(infusion);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

export const deleteInfusionDoseItem = async (
  infusionDose: InfusionSubItem,
  isSoftDelete: boolean = true
): Promise<Response> => {
  try {
    let dbMed = await DataStore.query(InfusionDose, infusionDose.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not exist',
      };
    }
    if (isSoftDelete) {
      let result = await DataStore.save(
        InfusionDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion did not update correctly',
        };
      }
    } else {
      let resp = await DataStore.delete(InfusionDose, dbMed.id);
      if (resp == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
    }
    console.log('DELETED INFUSION DOSE:', infusionDose);
    infusionDose.status = ProgressStatus.DELETED;
    return {
      type: ResponseType.Success,
      data: infusionDose,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
