import { useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import {
  findPairedDepartments,
  handleCopy,
  toTitleCase,
} from '../../_global/common/Utils';
import Status from '../ProgressStatus/ProgressStatus';
import EditAccessModal from './EditAccessModal';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { FaLock } from 'react-icons/fa6';

interface FolderProtocolModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleAdd: (data: any) => void;
  department: DepartmentItem;
  item: CategoryItem | ProtocolItem;
}
/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const EditFolderModal = (props: FolderProtocolModalProps) => {
  const { isVisible, handleClose, handleAdd, department, item } = props;
  console.log('Item:', item);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const user: User = useSelector((state: any) => state.user);

  const [name, setName] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [index, setIndex] = useState<number>(0);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>(
    findPairedDepartments(department, item) ?? []
  );
  const [activeID, setActiveID] = useState<string | null | undefined>(
    item.activeID
  );
  const [owner, setOwner] = useState<DepartmentItem | undefined>(department);
  const [isRestrictive, setIsRestrictive] = useState<boolean>(
    item?.isRestrictive ?? false
  );
  const [isAccessVisible, setAccessVisible] = useState<boolean>(false);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const isLockedStatus = useMemo(() => {
    if (item instanceof ProtocolItem && !item.parent.isPublic) return true;
    return false;
  }, [item]);

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, pairedDeps]);

  useEffect(() => {
    if (item) {
      setName(item.name);
      setIndex(item.index);
      if (item instanceof ProtocolItem) setNickname(item.nickname);
      let l = findPairedDepartments(department, item);
      setPairedDeps(l ?? []);
    }
  }, [item, department]);

  /* 10-11-23 Arul: Function for handling modal submit */
  const handleSubmit = async () => {
    const data = {
      value: name,
      index: index,
      nickName: nickname,
      pdf: '',
      pairedDeps: pairedDeps,
      owner: owner,
      isRestrictive: isRestrictive,
    };
    handleAdd(data);
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const isSaveActive = useMemo(() => {
    if (item && owner) {
      let changed = false;
      //Check if every ID is in the pairedDeps
      if (item.isRestrictive !== isRestrictive) changed = true;
      else if (isRestrictive) {
        if (pairedDeps && item.pairedDepIDs) {
          if (pairedDeps.length !== item.pairedDepIDs.length) changed = true;
          else {
            let ids = item.pairedDepIDs;
            if (ids) {
              for (let i = 0; i < pairedDeps.length; i++) {
                if (!ids.includes(pairedDeps[i].id)) {
                  changed = true;
                  break;
                }
              }
            }
          }
        }
      }

      //Check if the owner is different
      changed = changed || owner.id !== item.departmentID;
      if (item instanceof ProtocolItem)
        changed = changed || (nickname !== item.nickname && nickname !== '');
      return (
        (changed || name !== item.name || index !== item.index) &&
        name !== '' &&
        index >= 0
      );
    }
    return false;
  }, [name, item, pairedDeps, index, nickname, owner, isRestrictive]);

  const availableDepartments = useMemo(() => {
    if (owner == null) return department.allSubDeps ?? [];
    let list = owner.allSubDeps ?? [];

    if (item instanceof ProtocolItem) {
      const parentFolder = item.parent;
      list = list.filter((dep) => {
        if (!parentFolder.isRestrictive) return true;
        else if (parentFolder.pairedDepIDs) {
          return parentFolder.pairedDepIDs.includes(dep.id);
        } else return false;
      });
    }
    return list;
  }, [owner, item, department]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: '25%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <EditAccessModal
        isVisible={isAccessVisible}
        handleClose={() => setAccessVisible(false)}
        handleSubmit={(
          newItem: DepartmentItem | CategoryItem | ProtocolItem,
          metaData: any
        ) => {
          console.log('Updated item:', newItem);
          setAccessVisible(false);
        }}
        department={department}
        keychains={database.keychains}
        item={item}
      />

      <div className="sidebarContainer">
        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Edit {item instanceof CategoryItem ? 'Folder' : 'Protocol'}
        </div>
        <div className="contentText">
          {item instanceof CategoryItem
            ? 'Edit the folder name, index and the protocols that are subscribed to it.'
            : 'Edit the protocol name, index and the departments that are subscribed to it.'}
        </div>
        {user.type === 'ADMIN' && (
          <div>
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              ID: {item.uid}
              <span>
                {isCopied && isCopied === item.uid ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size=".75rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(item.uid, e, setIsCopied)}
                  />
                )}
              </span>
            </div>
            {item.activeID != null && (
              <div
                className="contentText"
                style={{
                  marginTop: '10px',
                }}
              >
                Actv. ID: {item.uid}
                <span>
                  {isCopied && isCopied === item.activeID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(item.activeID as string, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        )}
        {/* <label htmlFor="name" className={`notification-css-title`} style={{fontSize: '16px'}}>
                        Version: <span style={{fontWeight: '400', marginLeft: '10px'}}>{"   " + (item instanceof CategoryItem ? item.version : item.protocolVersion)}</span>
                    </label> */}
        <label
          htmlFor="index"
          className={`notification-css-title`}
          style={{
            marginTop: '10px',
            alignContent: 'flex-end',
          }}
        >
          <span className="headerTextMargin" style={{ fontSize: '16px' }}>
            Access:
          </span>
          <span
            style={{
              marginTop: '2px',
              marginLeft: '6px',
            }}
          >
            <Status
              status={
                !item.isPublic
                  ? 'Private'
                  : item.keychainID
                    ? 'Protected'
                    : 'Public'
              }
              onClick={
                isLockedStatus
                  ? undefined
                  : () => {
                      setAccessVisible(true);
                    }
              }
            />
          </span>
          {isLockedStatus && (
            <FaLock
              size={16}
              color={'#616161'}
              style={{
                marginLeft: '5px',
                marginTop: '4px',
              }}
            />
          )}
        </label>
        {isLockedStatus && (
          <div
            className="contentText"
            style={{
              marginTop: 0,
            }}
          >
            NOTE: Protocol Access is locked because the folder is private. To
            change the access, edit the folder.
          </div>
        )}
        <label
          htmlFor="name"
          className={`notification-css-title`}
          style={{ fontSize: '16px' }}
        >
          Name
          <span className="required-field ">
            *
            <BiRename
              className="refresh-icon"
              onClick={() => {
                setName(toTitleCase(name));
              }}
              size="1.25rem"
              style={{ marginRight: '5px', cursor: 'pointer' }}
            />
          </span>
        </label>
        <div className="input-container">
          <InputText
            type="text"
            className=" notification-model"
            id="name"
            name="name"
            autoFocus={true}
            required={true}
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            style={{ fontSize: '16px', cursor: 'auto', padding: '20px 20px' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
          <div className="input-border"></div>
        </div>
        {item instanceof ProtocolItem && (
          <>
            <label
              htmlFor="nickname"
              className={`notification-css-title`}
              style={{ fontSize: '16px' }}
            >
              Nickname
              <span className="required-field ">
                *
                <BiRename
                  className="refresh-icon"
                  onClick={() => {
                    setNickname(toTitleCase(nickname));
                  }}
                  size="1.25rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
            </label>
            <span
              className="contentText"
              style={{ fontSize: '13px', margin: 0 }}
            >
              This will be the protocols shorthand name (Ex. "A1.")
            </span>
            <div className="input-container">
              <InputText
                type="text"
                className=" notification-model"
                id="nickname"
                name="nickname"
                autoFocus={true}
                required={true}
                value={nickname}
                onChange={(e: any) => {
                  setNickname(e.target.value);
                }}
                style={{
                  fontSize: '16px',
                  cursor: 'auto',
                  padding: '20px 20px',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
              <div className="input-border"></div>
            </div>
          </>
        )}
        {/* <label htmlFor="index" className={`notification-css-title`}>
          <span className="headerTextMargin" style={{ fontSize: '16px' }}>
            Index
          </span>
        </label>
        <div className="input-container">
          <InputText
            type="number"
            className=" notification-model"
            id="index"
            name="index"
            autoFocus={true}
            required={true}
            value={index + ''}
            onChange={(e: any) => {
              if (isNaN(Number(e.target.value))) return;
              setIndex(Number(e.target.value));
            }}
            style={{ fontSize: '16px', cursor: 'auto', padding: '20px 20px' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
          <div className="input-border"></div>
        </div> */}

        {department.isMultiDep && (
          <FolderProtocolAdvancedSettings
            type={item instanceof CategoryItem ? 'Folder' : 'Protocol'}
            owner={owner}
            setOwner={setOwner}
            isRestrictive={isRestrictive}
            setIsRestrictive={setIsRestrictive}
            availableDepartments={availableDepartments}
            pairedDeps={pairedDeps}
            setPairedDeps={setPairedDeps}
            department={department}
            activeID={activeID}
            setActiveID={setActiveID}
          />
        )}
        {/* {department.subDeps && department.subDeps.length > 0 && (
          <>
            <label htmlFor="pairedDeps" className={`notification-css-title`}>
              <span
                className="headerTextMargin"
                style={{ fontSize: '16px', marginTop: '0px' }}
              >
                Subscribed Departments: {pairedDeps.length} /{' '}
                {department.subDeps.length}
                <span
                  onClick={() =>
                    setPairedDeps(department.subDeps as DepartmentItem[])
                  }
                >
                  <div className="clickableText">Add All</div>
                </span>
              </span>
            </label>
            <span
              className="contentText greyText"
              style={{ fontSize: '13px', marginLeft: '10px' }}
            >
              This is a list of every department that will subscribe to the
              protocol.
            </span>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={departmentList}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              multiSelect={true}
              onChange={(option: DepartmentItem) => handleAddDepartment(option)}
              onClear={handleClearDepartments}
              placeholder="Search department..."
            />
            {pairedDeps.length === 0 && (
              <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                No paired departments...
              </h6>
            )}
            <div
              style={{
                overflowY: 'auto',
                maxHeight: '400px',
                border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px',
                marginTop: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ViewportList items={pairedDeps}>
                {(item: DepartmentItem, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '16fr 1fr',
                      padding: '6px 10px',
                      alignItems: 'center',
                      borderBottom:
                        index === pairedDeps.length - 1 ? '' : '1px solid #ccc',
                      // borderTopLeftRadius: '6px',
                      // borderTopRightRadius: '6px',
                      // borderBottomLeftRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                      // borderBottomRightRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                    }}
                    className="listItem"
                  >
                    <div className="contentText">{item.name}</div>
                    <FaTimes
                      className="icon-cancel"
                      size={16}
                      onClick={(e) => handleRemoveDepartment(item, e)}
                    />
                  </div>
                )}
              </ViewportList>
            </div>
          </>
        )} */}

        <div className="dialogButtonsForce">
          <Button
            data-testid="cancelBtnn"
            className="secondary-button-small-border btn-rightMargin"
            onClick={handleClose}
          >
            {' '}
            Cancel
          </Button>
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isSaveActive}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default EditFolderModal;
