import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../data/AmplifyDB';
import SearchBar from '../Search/SearchBar';
import { fetchItemFromAPI } from '../../../data/GraphQL_API';
import { handleHinckleyCache } from '../../../store/actions';
import { toTitleCase } from '../../_global/common/Utils';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

interface NewMedicationSideoutProps {
  handleClose: () => void;
  type: 'medication' | 'infusion' | 'electrical' | 'equipment' | 'vital';
  isVisible: boolean;
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const NewModelItemSideout: React.FC<NewMedicationSideoutProps> = (props) => {
  const { handleClose, isVisible, type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const [hmDB, setHmDB] = useState<any>({
    medications: [],
    infusions: [],
    electrical: [],
    equipment: [],
    vitals: [],
  });
  const hinckleyState = useSelector((state: any) => state.hinckley);

  useEffect(() => {
    if (type === 'medication' && hinckleyState.medications == null)
      loadHinckleyDB();
    if (type === 'infusion' && hinckleyState.infusions == null)
      loadHinckleyDB();
    if (type === 'electrical' && hinckleyState.electricals == null)
      loadHinckleyDB();
    if (type === 'equipment' && hinckleyState.equipment == null)
      loadHinckleyDB();
    if (type === 'vital' && hinckleyState.vitals == null) loadHinckleyDB();
  }, [hinckleyState, type]);

  useEffect(() => {
    if (isVisible) {
      setHmDB({
        medications: hinckleyState.medications,
        infusions: hinckleyState.infusions,
        electrical: hinckleyState.electricals,
        equipment: hinckleyState.equipment,
        vitals: hinckleyState.vitals,
        checklists: hinckleyState.checklists,
      });
    }
  }, [isVisible, hinckleyState]);

  const loadHinckleyDB = async () => {
    console.log('Loading Hinckley DB Medicaitons' + '...');
    let response: Response = await fetchItemFromAPI(
      database.department,
      type === 'medication'
        ? 'Medications'
        : type === 'infusion'
          ? 'Drips'
          : type === 'electrical'
            ? 'Electrical'
            : type === 'equipment'
              ? 'Equipment'
              : 'Vitals',
      HM_DB_ID
    );
    if (response.type === ResponseType.Success) {
      console.log('Hinckley DB Medications loaded successfully');
      dispatch<any>(
        handleHinckleyCache({
          medications:
            type === 'medication' ? response.data : hinckleyState.medications,
          infusions:
            type === 'infusion' ? response.data : hinckleyState.infusions,
          electricals:
            type === 'electrical' ? response.data : hinckleyState.electricals,
          equipment:
            type === 'equipment' ? response.data : hinckleyState.equipment,
          vitals: type === 'vital' ? response.data : hinckleyState.vitals,
        })
      );
    } else {
      console.error('Failed to load Hinckley DB Medications');
    }
  };

  /* 10-13-23 Praveen: handled to navigate new protocol page*/
  const handleNavigateToNewPage = (selectedDataType: any) => {
    const state = {
      value: '',
      type: toTitleCase(selectedDataType),
      editType: 'new',
      editMode: true,
      page: 'newMedication',
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  /* 7-22-24 Hazlett: handled to navigate create from Hinckley page*/
  const handleNavigateToCreateFromHinckley = (data: any) => {
    const state = {
      // selectedProtocol: protocol,
      value: data,
      type: toTitleCase(type),
      editType: 'new_hm',
      editMode: true,
      page: 'newMedication',
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  const dataList = useMemo(() => {
    if (type === 'medication') return hmDB.medications ?? [];
    else if (type === 'infusion') return hmDB.infusions ?? [];
    else if (type === 'electrical') return hmDB.electrical ?? [];
    else if (type === 'equipment') return hmDB.equipment ?? [];
    else if (type === 'vital') return hmDB.vitals ?? [];
    else return [];
  }, [hmDB, type]);

  useEffect(() => {
    setOptions(dataList);
  }, [dataList]);

  /* 10-13-23 Praveen: For filtering the protocol sub item list */
  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = dataList?.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        setOptions(dataList);
      }
    },
    [value, dataList, setOptions]
  );

  /* 10-13-23 Praveen: to handle the search input*/
  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };

  const handleCloseSideouts = () => {
    handleClose();
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleCloseSideouts}
      style={{
        width: '25%',
        minWidth: '400px',
      }}
      className={'protocolSidebar sidebarWidth'}
    >
      <div style={{ height: '100%', padding: '10px' }}>
        <div
          className="buttonContainer contentTitleLarge hoverText"
          onClick={() => {
            setOptions(dataList);
            handleClose();
          }}
        >
          <span className="headerTilte">
            <BiArrowBack
              className="header-icon cursorPointer"
              style={{ paddingLeft: '4px' }}
            />
            <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
            Create New {toTitleCase(type)}
          </span>
        </div>
        <div className="contentText">
          Create a new {type} from Hinckley Medical's {type} list. This will
          populate all the concentrations, routes, and etc. The information can
          always be edited later for your agency.
        </div>
        <div>
          <div className="contentLabelBold">Custom {toTitleCase(type)}</div>
          <div
            onClick={() => handleNavigateToNewPage(type)}
            className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span>
              <HiPlus className="text-icon " /> Create Custom{' '}
              {toTitleCase(type)}
            </span>
          </div>
        </div>

        <div className="contentLabelBold">
          Hinckley Medical {toTitleCase(type)}s: {options.length}
        </div>
        {/* <div className="search-custom-input">
              <span className="icon_search_pad">
                <FiSearch className="icon-normal" />
              </span>
              <InputText
                value={value}
                onChange={handleSearch}
                className="search-inputs"
              />
            </div> */}
        <SearchBar
          value={value}
          onChange={handleSearch}
          placeholder="Search"
          containerStyle={{ width: '100%' }}
        />
        <div
          style={{ maxHeight: '60%' }}
          className="contentBorder protocolCalculationPad secondaryListScroll"
        >
          <div>
            {options && options.length === 0 && (
              <div
                className="contentHeading"
                style={{
                  color: '#636363',
                }}
              >
                No {type}s found...
              </div>
            )}
            <ViewportList items={options}>
              {(item: any, index: any) => (
                <div
                  key={item + index}
                  onClick={() => {
                    handleNavigateToCreateFromHinckley(item);
                  }}
                  className={`listhover cursorPointer item contentHeading ${
                    options.length !== index + 1 ? 'contentUnderline' : ''
                  }`}
                >
                  {item?.name}
                </div>
              )}
            </ViewportList>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default NewModelItemSideout;
