import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaSearch, FaCaretDown, FaTimes } from 'react-icons/fa';
import MultiSelect from './MultiSelectDropdown/MultiSelect';
import { ViewportList } from 'react-viewport-list';

interface SearchableDropdownProps<T> {
  id: string;
  options: T[];
  labelField: (option: T) => string;
  valueField: (option: T) => string;
  keyField: (option: T) => string;
  onChange: (selectedOption: T) => void;
  onClear?: () => void;
  onCreate?: (newOption: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  value?: T;
  multiSelect?: boolean;
  isActive?: boolean;
  showItems?: number;
  containerStyle?: React.CSSProperties;
  redBorder?: boolean;
  notFoundText?: string;
  disabled?: boolean;
}

function SearchableDropdown<T>(props: SearchableDropdownProps<T>) {
  const displayItems = props.showItems || 15;
  const ref = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false); // Add this state for disabling the input
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [parmList, setParmList] = useState<T[]>(props.options);

  useEffect(() => {
    setParmList(props.options);
  }, [props.options]);

  useEffect(() => {
    if (props.value) {
      setSearchTerm(props.labelField(props.value));
      setIsDisabled(props.value !== ''); // Disable the input field when a value is selected
    } else {
      setSearchTerm('');
      setIsDisabled(false); // Enable the input field when a value is not selected
    }
  }, [props.value]);

  useEffect(() => {
    if (!isDropdownOpen) setHighlightedIndex(-1);
  }, [isDropdownOpen]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [searchTerm]);

  /* Use this effect to close the dropdown when clicked outside */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [divRef]);

  const filteredOptions = useMemo(() => {
    let list = parmList.filter((option) =>
      props.labelField(option).toLowerCase().includes(searchTerm.toLowerCase())
    );
    /* Filter out duplicate options */
    return list.filter((option, index) => {
      return (
        list.findIndex(
          (item) => props.keyField(item) === props.keyField(option)
        ) === index
      );
    });
  }, [parmList, searchTerm]);

  return (
    <div style={{ ...props.containerStyle, position: 'relative' }} ref={divRef}>
      <div
        className={`searchBox ${!props.isActive ? '' : 'searchHoverable'} ${props.redBorder ? 'red-border' : ''}`}
      >
        {/* <div style={{ transform: 'translateY(-50%)' }}> */}
        <FaSearch size={16} color={'#9e9e9e'} style={{ marginLeft: '8px' }} />
        {/* </div> */}
        <input
          id={props.id}
          ref={ref}
          className={`contentText searchNoStyle`}
          autoFocus={props.autoFocus ? true : false}
          type="text"
          placeholder={props.placeholder || 'Search...'}
          value={searchTerm}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
          // style={{ paddingLeft: '40px', paddingRight: '30px' }}
          disabled={isDisabled || props.isActive === false} // Disable the input field based on the state
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (filteredOptions.length === 1) {
                const option = filteredOptions[0];
                if (!props.multiSelect) {
                  setSearchTerm(props.labelField(option));
                  props.onChange(option);
                  setIsDropdownOpen(false);
                  setIsDisabled(true); // Enable the input field when FaTimes is clicked
                } else {
                  setSearchTerm('');
                  props.onChange(option);
                  // setIsDropdownOpen(false);
                }
              } else if (
                highlightedIndex >= 0 &&
                highlightedIndex < filteredOptions.length
              ) {
                const option = filteredOptions[highlightedIndex];
                if (!props.multiSelect) {
                  setSearchTerm(props.labelField(option));
                  props.onChange(option);
                  setIsDropdownOpen(false);
                  setIsDisabled(true); // Enable the input field when FaTimes is clicked
                } else {
                  setSearchTerm('');
                  props.onChange(option);
                  // setIsDropdownOpen(false);
                }
              }
            } else if (e.key === 'ArrowDown') {
              if (highlightedIndex < filteredOptions.length - 1) {
                setHighlightedIndex(highlightedIndex + 1);
              }
            } else if (e.key === 'ArrowUp') {
              if (highlightedIndex > 0) {
                setHighlightedIndex(highlightedIndex - 1);
              }
            } else if (e.key === 'Escape') {
              setIsDropdownOpen(false);
              ref.current?.blur();
            }
          }}
        />
        {!props.disabled && props.isActive !== false && searchTerm && (
          <FaTimes
            size={16}
            className="icon-cancel"
            onClick={() => {
              setSearchTerm('');
              setIsDisabled(false); // Enable the input field when FaTimes is clicked
              if (props.onClear) props.onClear();
            }}
          />
        )}

        <FaCaretDown
          onClick={() => {
            if (!isDisabled) setIsDropdownOpen(!isDropdownOpen);
          }}
          style={{
            transition: '0.3s',
            transform: `rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
            marginRight: '8px',
          }}
          size={16}
          color={props.isActive === false ? '#e0e0e0' : '#000'}
        />
      </div>

      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1,
            marginTop: '4px',
          }}
        >
          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '6px',
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              backgroundColor: 'white',
              maxHeight: `calc(${displayItems} * 26px)`, // Assuming each item has a height of 1.5em and 10px padding
              overflowY: 'auto',
            }}
          >
            {filteredOptions.length === 0 && (
              <li
                className={`contentText listItem rounded ${props.onCreate ? 'hoverableBackground' : ''}`}
                style={{
                  padding: '5px',
                  alignContent: 'center',
                  alignSelf: 'center',
                  color: props.onCreate ? '#00534C' : '#000',
                  textDecoration: props.onCreate ? 'underline' : 'none',
                  cursor: props.onCreate ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (props.onCreate) {
                    props.onCreate(searchTerm);
                    setSearchTerm('');
                    setIsDropdownOpen(false);
                  }
                }}
              >
                {props.onCreate
                  ? '+ Create New'
                  : props.notFoundText
                    ? props.notFoundText
                    : 'No options found...'}
              </li>
            )}
            <ViewportList items={filteredOptions}>
              {(option: T, index) => {
                return (
                  <li
                    key={
                      props.keyField
                        ? props.keyField(option)
                        : props.valueField(option)
                    }
                    className="contentText hoverableBackground listItem rounded"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!props.multiSelect) {
                        setSearchTerm(props.labelField(option));
                        props.onChange(option);
                        setIsDropdownOpen(false);
                        setIsDisabled(true); // Enable the input field when FaTimes is clicked
                      } else {
                        setSearchTerm('');
                        props.onChange(option);
                        setIsDropdownOpen(false);
                      }
                    }}
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                      backgroundColor:
                        highlightedIndex === index ? '#E0EADD' : '',
                      borderBottom:
                        index === filteredOptions.length - 1
                          ? 'none'
                          : '1px solid #ddd',
                    }}
                  >
                    {props.labelField(option)}
                  </li>
                );
              }}
            </ViewportList>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchableDropdown;
