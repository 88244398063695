import { Dispatch } from 'redux';
import GlobalService from './services/GlobalService';
import {
  HANDLE_DATATABLE_ROW_SELECTION,
  HANDLE_DEP_CACHE,
  HANDLE_GET_DEPARTMENT_LIST,
  HANDLE_GET_NOTIFICATION,
  HANDLE_HM_CACHE,
  HANDLE_LAODING_FAVICON,
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  HANDLE_NOTIFICATION_CACHE,
  HANDLE_PUBLIC_SELECTED_ITEM,
  HANDLE_REVIEWALS_CACHE,
  HANDLE_SELECTED_DOSE_VALUE,
  HANDLE_SELECT_DATATABLE,
  HANDLE_SET_PROTOCOL_DATA,
  HANDLE_SHOW_SIDEBAR,
  HANDLE_UPDATE_COGNITO_USER_DATA,
  HANDLE_UPDATE_EDIT_FOLDER_DATA,
  HANDLE_UPDATE_EDIT_PROTOCOL,
  HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES,
  HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES,
  HANDLE_UPDATE_ROUTES_VALUES_LIST,
  HANDLE_UPDATE_SELECTED_ACCORDION,
  HANDLE_UPDATE_USER_DATA,
} from './types';
import DepartmentItem from '../data/model/DepartmentItem';
import { DatabaseResponse } from '../data/AmplifyDB';

/* 10-03-23 Arul: Handled state actions for login */
export const handleLogin = (dispatch: Dispatch) => {
  return GlobalService.handleLogin().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_LOGIN,
      });
      return response;
    }
  });
};

/* 10-03-23 Arul: Handled state actions for login */
export const handleLoadingFaviocon =
  (isLoading: boolean) => (dispatch: Dispatch) => {
    return GlobalService.handleLoadingFavoicon().then((response: any) => {
      if (response.status === 'success') {
        dispatch({
          type: HANDLE_LAODING_FAVICON,
          payload: { displayLoadingIcon: isLoading },
        });
        return response;
      }
    });
  };

/* 10-03-23 Arul: Handled state actions for logout */
export const handleLogOut = () => (dispatch: Dispatch) => {
  return GlobalService.handleLogOut().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_LOGOUT,
      });
      return response;
    }
  });
};

/* Handled state actions for user */
export const handleUpdateUser = (data?: any) => (dispatch: Dispatch) => {
  return GlobalService.handleUpdateUser().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_UPDATE_USER_DATA,
        payload: { user: data },
      });
      return response;
    }
  });
};

/* Handled state actions for user */
export const handleUpdateCognitoUser = (data?: any) => (dispatch: Dispatch) => {
  return GlobalService.handleUpdateCognitoUser().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_UPDATE_COGNITO_USER_DATA,
        payload: { cognitoUser: data.user, waitForSync: data.waitForSync },
      });
      return response;
    }
  });
};

/* 10-04-23 Arul: Handled state actions for sidebar */
export const handleShowSideBar = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleShowSideBar().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_SHOW_SIDEBAR,
        payload: { sidebar: data.sidebar },
      });
      return response;
    }
  });
};

/* 10-04-23 Arul: Handled state actions for update the edit protocol */
export const handleUpdateEditProtocol = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleUpdateEditProtocol().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_UPDATE_EDIT_PROTOCOL,
        payload: { editProtocol: data },
      });
      return response;
    }
  });
};

/* 10-16-23 Arul: Handled state actions for update the edit Folder */
export const handleUpdateEditFolderCategory =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleUpdateEditFolderCategory().then(
      (response: any) => {
        if (response.status === 'success') {
          dispatch({
            type: HANDLE_UPDATE_EDIT_FOLDER_DATA,
            payload: { editFolderData: data },
          });
          return response;
        }
      }
    );
  };

/* 10-18-23 Arul: Handled state actions for datatable breadcrumbs and table data selection */
export const handleSelectDataTable = (props: any) => (dispatch: Dispatch) => {
  return GlobalService.handleSelectDataTable().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_SELECT_DATATABLE,
        payload: {
          breadcrumpData: props.breadcrumpData,
          selectedRowData: props.selectedRowData,
          tableData: props.tableData,
        },
      });
      return response;
    }
  });
};

/* 10-18-23 Arul: Handled state actions for datatable Row selection */
export const handleDataTableRowSelection =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleDataTableRowSelection().then((response: any) => {
      if (response.status === 'success') {
        dispatch({
          type: HANDLE_DATATABLE_ROW_SELECTION,
          payload: { selectedRowData: data.selectedRowData },
        });
        return response;
      }
    });
  };

/* 10-30-23 : Handled state actions for Equipment protocol value*/
export const handleUpdateEquipmentValue =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleUpdateEquipmentValue().then((response: any) => {
      if (response.status === 'success') {
        dispatch({
          type: HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES,
          payload: { equipment: data },
        });
        return response;
      }
    });
  };

/* 10-30-23 : Handled state actions for Equipment option values*/
export const handleUpdateEquipmentOptionValue =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleUpdateEquipmentOptionValue().then(
      (response: any) => {
        if (response.status === 'success') {
          dispatch({
            type: HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES,
            payload: { equipmentDose: data },
          });
          return response;
        }
      }
    );
  };

// Handled state actions for selected protocol values
export const handleSetProtocolData = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleSetProtocolData().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_SET_PROTOCOL_DATA,
        payload: { selectedProtocol: data },
      });
      return response;
    }
  });
};

// Handled state actions for selected dose and option values
export const handleSetSelectedDoseValue =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleSetSelectedDoseValue().then((response: any) => {
      if (response.status === 'success') {
        dispatch({
          type: HANDLE_SELECTED_DOSE_VALUE,
          payload: { selectedDose: data },
        });
        return response;
      }
    });
  };

// Handled state actions for routes list
export const handleUpdateRouteList = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleUpdateRouteList().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_UPDATE_ROUTES_VALUES_LIST,
        payload: { routes: data },
      });
      return response;
    }
  });
};

// Handled state actions for selected Accordion  list
export const handleUpdateSelectedAccordion =
  (data: any) => (dispatch: Dispatch) => {
    return GlobalService.handleUpdateSelectedAccordion().then(
      (response: any) => {
        if (response.status === 'success') {
          dispatch({
            type: HANDLE_UPDATE_SELECTED_ACCORDION,
            payload: { selectedAccordion: data },
          });
          return response;
        }
      }
    );
  };

// Handled state actions for selected Accordion  list
export const handleSetNotification = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleGetNotification().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_GET_NOTIFICATION,
        payload: { notifications: data },
      });
      return response;
    }
  });
};

// Handled for Get department list
export const handleGetDepartment = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handleGetDepartment().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_GET_DEPARTMENT_LIST,
        payload: { departmentItem: data },
      });
      return response;
    }
  });
};

/** Public Actions */
export const handlePublicSelectedItem = (data: any) => (dispatch: Dispatch) => {
  return GlobalService.handlePublicSelectedItem().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_PUBLIC_SELECTED_ITEM,
        payload: { selectedItem: data },
      });
      return response;
    }
  });
};

export const handleDepartmentCache = (db: any) => (dispatch: Dispatch) => {
  return GlobalService.handleDepartmentCache().then((response: any) => {
    if (response.status === 'success') {
      dispatch({
        type: HANDLE_DEP_CACHE,
        payload: {
          database: db,
        },
      });
      return response;
    }
  });
};

export const handleNotificationCache =
  (nots: any[]) => (dispatch: Dispatch) => {
    return GlobalService.handleNotificationCache().then((response: any) => {
      if (response.status === 'success') {
        dispatch({
          type: HANDLE_NOTIFICATION_CACHE,
          payload: {
            notifications: nots,
          },
        });
        return response;
      }
    });
  };

export const handleHinckleyCache =
  (payload: any) => (dispatch: Dispatch, getState: any) => {
    return GlobalService.handleHinckleyCache().then((response: any) => {
      let state = getState();

      if (response.status === 'success') {
        dispatch({
          type: HANDLE_HM_CACHE,
          payload: {
            medications: payload.medications
              ? payload.medications
              : state.hinckley.medications,
            infusions: payload.infusions
              ? payload.infusions
              : state.hinckley.infusions,
            equipment: payload.equipment
              ? payload.equipment
              : state.hinckley.equipment,
            electricals: payload.electricals
              ? payload.electricals
              : state.hinckley.electricals,
            vitals: payload.vitals ? payload.vitals : state.hinckley.vitals,
          },
        });
        return response;
      }
    });
  };

export const handleReviewalsCache =
  (payload: any) => (dispatch: Dispatch, getState: any) => {
    return GlobalService.handleReviewalsCache().then((response: any) => {
      let state = getState();

      if (response.status === 'success') {
        dispatch({
          type: HANDLE_REVIEWALS_CACHE,
          payload: {
            reviewals: payload,
          },
        });
        return response;
      }
    });
  };
