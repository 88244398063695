import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import {
  handleGetDepartment,
  handleSetProtocolData,
  handleSetSelectedDoseValue,
  handleUpdateRouteList,
} from '../../../../../../store/actions';
import AddRouteModal from '../../../../../components/Modal/AddRouteModal';
import ConcentrationDialog from '../../../../../components/Modal/ConcentrationDialogModal';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import ConfirmWarningModal from '../../../../../components/Modal/ConfirmWarningModal';
import DraftSaveModal from '../../../../../components/Modal/DraftSaveModal';
import RouteDialog from '../../../../../components/Modal/RouteDialogModal';
import ProtocolDoseSideout from '../../../../../components/SideOut/doseSideOut/ProtocolDoseSideout';
import DoseSelection from '../../../../../components/dose/DoseSelection';
import * as _ from 'lodash';
import {
  getFormattedDate,
  getObjectDifference,
  globals,
  upgradeVersion,
} from '../../../../../_global/common/Utils';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../../data/AmplifyDB';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../../data/model/MedicationItem';
import {
  MedicationConcentration,
  MedicationProtocol,
  MedicationRange,
  ModelMetaData,
  User,
} from '../../../../../../models';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import MedicationSubItem from '../../../../../../data/model/MedicationSubItem';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../../data/AmplifyVersion';
import {
  MedicationDoseJSON,
  MedicationJSON,
  createParentMedication,
  deleteMedication,
} from '../../../../../../data/functions/MedicationDB';
import { ProgressStatus } from '../../../../../../models';
import { Col, Row } from 'react-bootstrap';
import MedicationDoseSideout from '../../../../../components/SideOut/doseSideOut/MedicationDoseSideout';
import { UserType } from '../../../../../../models';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import MedicationDoseUI from '../../../../protocol/edit/Medication/MedicationDoseUI';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import { InputTextarea } from 'primereact/inputtextarea';

interface MedicationProtocolEditPageProps {
  stateData?: any;
}

const SHOW_PROTOCOL_ID = false;

/* 10-24-23  Created Medication Edit Page component for Medication layout */
const HinckleyEditMedication: React.FC<MedicationProtocolEditPageProps> = ({
  stateData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { medication, list } = stateData;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  // const parmMedication: MedicationItem = protocolDetail;
  const [medications, setMedications] = useState<MedicationItem[]>(list);
  const [parmMedication, setParmMedication] =
    useState<MedicationItem>(medication);
  const [subMedicationItems, setSubMedicationItems] = useState<
    MedicationSubItem[]
  >(medication.subItems);
  const [allSubMedicationItems, setAllSubMedicationItems] = useState<
    MedicationSubItem[]
  >(parmMedication.subItems);
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    [...new Set(allSubMedicationItems.map((m) => m.parentProtocol))].sort(
      (a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      }
    )
  );

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddRouteVisible, setIsAddRouteVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    null
  );
  const [isDelete, setIsDelete] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [editDose, setEditDose] = useState(false);
  const [concentrationDialogVisible, setConcentrationDialogVisible] =
    useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const [deleteData, setDeleteData] = useState<any>(null);
  const [isWarning, setIsWarning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDraftConfirmModal, setIsDraftConfirmModal] = useState(false);

  const [doseIndex, setDoseIndex] = useState(subMedicationItems.length);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmMedication ? parmMedication.name : '',
      concentration: parmMedication ? parmMedication.concentrations : [],
      routes: parmMedication ? parmMedication.routes : [],
      rangeLow: parmMedication ? parmMedication.rangeLow + '' : '',
      rangeHigh: parmMedication ? parmMedication.rangeHigh + '' : '',
      protocolOptions:
        parmMedication && parmMedication.model
          ? parmMedication.model.protocolOptions
          : [],

      contraindication:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.contraindication
          ? parmMedication.model.contraindication
          : '',
      note:
        parmMedication && parmMedication.model && parmMedication.model.note
          ? parmMedication.model.note
          : '',
      warning:
        parmMedication && parmMedication.model && parmMedication.model.warning
          ? parmMedication.model.warning
          : '',
      medClass:
        parmMedication && parmMedication.model && parmMedication.model.medClass
          ? parmMedication.model.medClass
          : '',
      action:
        parmMedication && parmMedication.model && parmMedication.model.action
          ? parmMedication.model.action
          : '',
      indication:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.indication
          ? parmMedication.model.indication
          : '',
      interaction:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.interaction
          ? parmMedication.model.interaction
          : '',
      onset:
        parmMedication && parmMedication.model && parmMedication.model.onset
          ? parmMedication.model.onset
          : '',
      duration:
        parmMedication && parmMedication.model && parmMedication.model.duration
          ? parmMedication.model.duration
          : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      concentration: Yup.array().min(
        1,
        'At least one concentration is required'
      ),
      routes: Yup.array().min(1, 'At least one route is required'),
      rangeLow: Yup.number().required(),
      rangeHigh: Yup.number().required(),
      protocolOptions: Yup.array(),
      contraindication: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      medClass: Yup.string(),
      action: Yup.string(),
      indication: Yup.string(),
      interaction: Yup.string(),
      onset: Yup.string(),
      duration: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (globals.debug) console.log('SUBMITTING NEW MEDICATION', values);
      /* First check if the medication has changed */
      if (
        formik.dirty &&
        formik.isValid &&
        values.concentration.length > 0 &&
        values.routes.length > 0
      ) {
        const protocolList = findProtocols(values.protocolOptions);
        const newMedication: MedicationJSON = {
          name: values.name,
          concentration: values.concentration,
          routes: values.routes,
          rangeLow: !isNaN(Number(values.rangeLow))
            ? Number(values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(values.rangeHigh))
            ? Number(values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          protocolOptions: values.protocolOptions,

          contraindication: values.contraindication,
          note: values.note,
          warning: values.warning,
          medClass: values.medClass,
          action: values.action,
          indication: values.indication,
          interaction: values.interaction,
          onset: values.onset,
          duration: values.duration,
          taggedProtocols: protocolList,
          createdBy:
            parmMedication.model && parmMedication.model.createdBy
              ? parmMedication.model.createdBy
              : '',
          modifiedBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID:
            parmMedication.status === ProgressStatus.ACTIVE
              ? parmMedication.uid
              : parmMedication.activeID,
          version:
            parmMedication.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmMedication.version == null
                    ? 'v1.0.0'
                    : parmMedication.version
                )
              : parmMedication.version == null
                ? 'v1.0.0'
                : parmMedication.version,
        };
        let results: Response = await createParentMedication(
          department,
          newMedication,
          parmMedication
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created medication', results.data);
          let newMedication: MedicationItem = results.data;
          updateMedication(newMedication);
        } else {
          console.error('Failed to create medication', results.data);
        }
        // formik.resetForm();

        /* Update the initial values to the current values so dirty works */
        // formik.setValues({
        // 	...formik.values,
        // 	name: values.name,
        // 	concentration: values.concentration,
        // 	routes: values.routes,
        // 	rangeLow: values.rangeLow,
        // 	rangeHigh: values.rangeHigh,
        // 	protocolOptions: values.protocolOptions,
        // 	contraindication: values.contraindication,
        // 	note: values.note,
        // 	warning: values.warning,
        // 	medClass: values.medClass,
        // 	action: values.action,
        // 	indication: values.indication,
        // 	interaction: values.interaction,
        // 	onset: values.onset,
        // 	duration: values.duration,
        // });
      }
    },
  });

  useEffect(() => {
    let id = parmMedication.model?.modifiedBy;
    if (id == null) id = parmMedication.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmMedication.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmMedication]);

  // useEffect(() => {
  //     formik.setValues({
  //         ...formik.values,
  //         'name': parmMedication?.name ? parmMedication?.name : '',
  //         'concentration': parmMedication?.concentrations?.length ? parmMedication?.concentrations : [],
  //         'routes': parmMedication?.routes ? parmMedication?.routes : [],
  //         'rangeLow': parmMedication?.rangeLow ? parmMedication?.rangeLow + '' : '',
  //         'rangeHigh': parmMedication?.rangeHigh ? parmMedication?.rangeHigh + '' : '',
  //         'protocolOptions': parmMedication?.model?.protocolOptions ? parmMedication?.model?.protocolOptions : [],
  //         'contraindication': parmMedication?.model?.contraindication ? parmMedication?.model?.contraindication : '',
  //         'note': parmMedication?.model?.note ? parmMedication?.model?.note : '',
  //         'warning': parmMedication?.model?.warning ? parmMedication?.model?.warning : '',
  //         'medClass': parmMedication?.model?.medClass ? parmMedication?.model?.medClass : '',
  //         'action': parmMedication?.model?.action ? parmMedication?.model?.action : '',
  //         'indication': parmMedication?.model?.indication ? parmMedication?.model?.indication : '',
  //         'interaction': parmMedication?.model?.interaction ? parmMedication?.model?.interaction : '',
  //         'onset': parmMedication?.model?.onset ? parmMedication?.model?.onset : '',
  //         'duration': parmMedication?.model?.duration ? parmMedication?.model?.duration : '',
  //     })
  //     if(globals.debug) console.log('formik', formik.values)
  // }, [parmMedication]);

  const solidUnits = useMemo(() => {
    if (parmMedication.concentrations.length > 0) {
      let concen = parmMedication.concentrations[0];
      return concen.firstUnit;
    }
    return '';
  }, [parmMedication]);

  /**
   * Find the protocols from the protocol options
   * @param protocolOptions The protocol options to search for
   * @returns The protocols that were found
   */
  const findProtocols = (
    protocolOptions: MedicationProtocol[]
  ): ProtocolItem[] => {
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolOptions.length; i++) {
      let po = protocolOptions[i];
      let protocol = database.protocols.find((p: ProtocolItem) => {
        let id =
          p.status === ProgressStatus.ACTIVE ||
          (p.status === ProgressStatus.DRAFT && p.activeID == null)
            ? p.uid
            : p.activeID;
        return id === po.protocolID;
      });
      if (protocol) protocols.push(protocol);
    }
    return protocols;
  };

  /* After reloading the database update the medication item */
  // useEffect(() => {

  // }, [database]);

  /**
   * Reload the database from the server
   * @returns true when the database is reloaded
   */
  const reloadDatabase = async (
    medicationCheck?: MedicationItem
  ): Promise<boolean> => {
    return true;
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    // const resp: Response = await loadDatabase(database.department);
    // if (resp.type === ResponseType.Success) {
    //   const newDB: DatabaseResponse = resp.data;
    //   setDatabase(newDB);
    //   dispatch<any>(handleGetDepartment(newDB));

    //   /* Search for the updated medication item in the database - ALWAYS search for activeID
    //    *  Use cases:
    //    *  1.  Medication was DRAFT FIRST RELEASE and updated item - match uid
    //    *  2.  Medication was ACTIVE and updated item - match old uid to new activeID
    //    */
    //   let updatedMedication: MedicationItem | null = null;
    //   let medicationID = medicationCheck
    //     ? medicationCheck.uid
    //     : parmMedication.uid;
    //   for (let i = 0; i < newDB.medications.length; i++) {
    //     let medID =
    //       parmMedication.status === ProgressStatus.ACTIVE
    //         ? newDB.medications[i].activeID
    //         : newDB.medications[i].uid;
    //     if (medID === medicationID) {
    //       updatedMedication = newDB.medications[i];
    //       break;
    //     }
    //   }

    //   if (globals.debug) console.log('Searching for updated protocol item');
    //   let updatedProtocol: ProtocolItem | null = null;
    //   if (selectedProtocol) {
    //     for (let i = 0; i < newDB.protocols.length; i++) {
    //       /* Was ACTIVE and is ACTIVE now */
    //       if (
    //         selectedProtocol.status === ProgressStatus.ACTIVE &&
    //         newDB.protocols[i].uid === selectedProtocol.uid
    //       ) {
    //         updatedProtocol = newDB.protocols[i];
    //         break;
    //       } else if (
    //         /* Was ACTIVE and is DRAFT now */
    //         selectedProtocol.status === ProgressStatus.ACTIVE &&
    //         newDB.protocols[i].activeID === selectedProtocol.uid
    //       ) {
    //         updatedProtocol = newDB.protocols[i];
    //         break;
    //       } else if (
    //         /* Was DRAFT and is DRAFT now */
    //         selectedProtocol.status === ProgressStatus.DRAFT &&
    //         ((newDB.protocols[i].activeID == null &&
    //           newDB.protocols[i].uid === selectedProtocol.uid) ||
    //           (newDB.protocols[i].activeID != null &&
    //             newDB.protocols[i].activeID === selectedProtocol.activeID))
    //       ) {
    //         updatedProtocol = newDB.protocols[i];
    //         break;
    //       }
    //     }

    //     if (updatedProtocol) {
    //       setProtocol(updatedProtocol);
    //       if (globals.debug)
    //         console.log('Found updated protocol', updatedProtocol.name);
    //     } else if (globals.debug)
    //       console.log('Failed to find updated protocol', selectedProtocol);
    //   }

    //   /* Search for the right sub medications */
    //   if (updatedMedication) {
    //     if (globals.debug)
    //       console.log('Found updated medication', updatedMedication);
    //     setParmMedication(updatedMedication);
    //     setSubMedicationItems(
    //       selectedProtocol
    //         ? updatedMedication.subItems.filter(
    //             (item: MedicationSubItem) =>
    //               item.parentProtocol.uid === updatedProtocol?.uid
    //           )
    //         : updatedMedication.subItems
    //     );
    //     setAllSubMedicationItems(updatedMedication.subItems);
    //     setAllProtocols(
    //       [
    //         ...new Set(
    //           updatedMedication.subItems.map((m) => m.parentProtocol)
    //         ),
    //       ].sort((a, b) => {
    //         if (a.parent.index === b.parent.index)
    //           return a.name.localeCompare(b.name);
    //         return a.parent.index - b.parent.index;
    //       })
    //     );
    //   } else if (globals.debug)
    //     console.log('Failed to find updated medication');
    //   return true;
    // } else {
    //   console.error('ERROR LOADING DATABASE', resp.data);
    //   return false;
    // }
  };

  /**
   * Todo have this open up a sideout to view the history of the medication
   */
  const handleVersion = () => {
    /* Output why the formik is dirty in the console */
  };

  /**
   * Back button has been pressed
   */
  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else
      navigate(`/actions/hinckley-database/medications`, {
        state: { list: medications },
      });
  };

  /**
   * Cancen button has been pressed reset the formik values
   */
  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isCancelWarningModal) setIsCancelWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
      if (formik.values.protocolOptions.length === 0) handleBack();

      /* Reset the sub medication items */
      setSubMedicationItems(medication.subItems);
      setAllSubMedicationItems(medication.subItems);
    }
  };

  /* 10/11/2023: Praveen, Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedDose(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /*function to close the modal*/
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedDose(null);
  };

  /* 11-01-23 Praveen: handled function to remove concentration values to show confirm popup*/
  const handleRemoveConcentration = (e: any, item: MedicationConcentration) => {
    e.stopPropagation();
    setModalType('Concentration');
    setDeleteData(item);
    setIsConfirmModal(true);
  };

  /* 11-01-23 Praveen: handled function to remove route values to show confirm popup*/
  const handleRemoveRoute = (e: any, value: any) => {
    e.stopPropagation();
    setModalType('Route');
    setIsConfirmModal(true);
    setDeleteData(value);
  };

  const updateMedication = async (newMedication: MedicationItem) => {
    reloadDatabase();

    formik.setValues({
      ...formik.values,
      name: newMedication?.name ? newMedication?.name : '',
      concentration: newMedication?.concentrations?.length
        ? newMedication?.concentrations
        : [],
      routes: newMedication?.routes ? newMedication?.routes : [],
      rangeLow: newMedication?.rangeLow ? newMedication?.rangeLow + '' : '',
      rangeHigh: newMedication?.rangeHigh ? newMedication?.rangeHigh + '' : '',
      protocolOptions: newMedication?.model?.protocolOptions
        ? newMedication?.model?.protocolOptions
        : [],
      contraindication: newMedication?.model?.contraindication
        ? newMedication?.model?.contraindication
        : '',
      note: newMedication?.model?.note ? newMedication?.model?.note : '',
      warning: newMedication?.model?.warning
        ? newMedication?.model?.warning
        : '',
      medClass: newMedication?.model?.medClass
        ? newMedication?.model?.medClass
        : '',
      action: newMedication?.model?.action ? newMedication?.model?.action : '',
      indication: newMedication?.model?.indication
        ? newMedication?.model?.indication
        : '',
      interaction: newMedication?.model?.interaction
        ? newMedication?.model?.interaction
        : '',
      onset: newMedication?.model?.onset ? newMedication?.model?.onset : '',
      duration: newMedication?.model?.duration
        ? newMedication?.model?.duration
        : '',
    });
  };

  /* 11-01-23 Praveen: handled function to add new administration route*/
  const handleNewRouteSet = async (value: string) => {
    setIsAddRouteVisible(false);
    let routes = [...formik.values.routes, value];
    formik.setFieldValue('routes', routes);
  };

  /* 11-01-23 Praveen: handled function to delete concentartion and route values */
  const handleConfirmDelete = async (type: any) => {
    setIsConfirmModal(false);
    if (type === 'Route') {
      let routes = formik.values.routes.filter((r: string) => r !== deleteData);
      formik.setFieldValue('routes', routes);
    } else if (type === 'Concentration') {
      let concentrations = formik.values.concentration.filter(
        (c: MedicationConcentration) => {
          return (
            c.firstAmnt !== deleteData.firstAmnt ||
            c.secAmnt !== deleteData.secAmnt ||
            c.firstUnit !== deleteData.firstUnit ||
            c.secUnit !== deleteData.secUnit
          );
        }
      );
      formik.setFieldValue('concentration', concentrations);
    }
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationSet = async (
    amount1: any,
    amount2: any,
    unit1: any,
    unit2: any
  ) => {
    setConcentrationDialogVisible(false);
    let newConcentration: MedicationConcentration = {
      firstAmnt: amount1,
      secAmnt: amount2,
      firstUnit: unit1,
      secUnit: unit2,
    };

    formik.setFieldValue('concentration', [
      ...formik.values.concentration,
      newConcentration,
    ]);
  };

  const handleRouteEdit = (event: any) => {
    if (!isEditMode) return;
    event.stopPropagation();
    setIsAddRouteVisible(true);
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationEdit = (event: any) => {
    if (!isEditMode) return;
    event.stopPropagation();
    setConcentrationDialogVisible(true);
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
    setIsDraftConfirmModal(false);
  };

  const handleOnSubmitMedicationDose = async (
    data: any,
    previousDose: MedicationSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    // const protocol = parentProtocol ? parentProtocol : selectedProtocol;
    // let protocolID =
    //   protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
    //     ? protocol.uid
    //     : protocol.activeID;
    // if (!protocolID) {
    //   console.error('Failed to find protocol ID');
    //   return;
    // }
    // /* Then check if there is a medication protocol already created */
    // let medProtocol: MedicationProtocol | null = null;
    // for (let i = 0; i < formik.values.protocolOptions.length; i++) {
    //   let po = formik.values.protocolOptions[i];
    //   if (po.protocolID === protocolID) {
    //     medProtocol = po;
    //     break;
    //   }
    // }
    // let prevDose = previousDose as MedicationSubItem;
    // /* Create the MedicationRange for the dose */
    // let medRange = new MedicationRange({
    //   basis: data.basis,
    //   rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
    //   rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
    //   route: data.routes,
    //   title: data.title,
    //   warning: data.warning,
    //   instruction: data.instruction,
    //   note: data.note,
    //   maxDose: data.maxDose,
    //   minDose: data.minDose,
    //   calcMax: data.calcMax,
    //   index: prevDose ? prevDose.index : subMedicationItems.length,
    // });
    // /* If there is no medication protocol then create one */
    // if (!medProtocol) {
    //   medProtocol = new MedicationProtocol({
    //     protocolID: protocolID,
    //     options: [medRange],
    //   });
    //   /* Add the medication protocol to the formik values */
    //   formik.setFieldValue('protocolOptions', [
    //     ...formik.values.protocolOptions,
    //     medProtocol,
    //   ]);
    // } else {
    //   /* Check if we are updating a previous dose and filter out the previoud dose otherwise add the new range */
    //   let medicationRanges = prevDose
    //     ? medProtocol.options.filter(
    //         (m) => getObjectDifference(m, prevDose.range).length != 0
    //       )
    //     : medProtocol.options;
    //   let ranges = [...medicationRanges, medRange];
    //   // medicationRanges.push(medRange);
    //   ranges.sort((a: MedicationRange, b: MedicationRange) => {
    //     if (a.rangeLow === b.rangeLow) return a.basis.localeCompare(b.basis);
    //     return a.rangeLow - b.rangeLow;
    //   });
    //   medProtocol = new MedicationProtocol({
    //     protocolID: medProtocol.protocolID,
    //     options: [...ranges],
    //   });
    //   /* Add the medication protocol to the formik values */
    //   let newProtocolOptions = formik.values.protocolOptions.filter(
    //     (po: MedicationProtocol) => po.protocolID !== protocolID
    //   );
    //   formik.setFieldValue('protocolOptions', [
    //     ...newProtocolOptions,
    //     medProtocol,
    //   ]);
    // }
    // /* Need to create a MedicationSubItem for the new dose */
    // let subMeds = prevDose
    //   ? subMedicationItems.filter(
    //       (item: MedicationSubItem) => item !== prevDose
    //     )
    //   : subMedicationItems;
    // let allSubMeds = prevDose
    //   ? allSubMedicationItems.filter(
    //       (item: MedicationSubItem) => item !== prevDose
    //     )
    //   : allSubMedicationItems;
    // let newMedicationSubItem = new MedicationSubItem(
    //   parmMedication,
    //   protocol,
    //   medRange
    // );
    // setSubMedicationItems([...subMeds, newMedicationSubItem]);
    // setAllSubMedicationItems([...allSubMeds, newMedicationSubItem]);
    // setAllProtocols(
    //   [
    //     ...new Set(
    //       ([...allSubMeds, newMedicationSubItem] as MedicationSubItem[]).map(
    //         (m) => m.parentProtocol
    //       )
    //     ),
    //   ].sort((a, b) => {
    //     if (a.parent.index === b.parent.index)
    //       return a.name.localeCompare(b.name);
    //     return a.parent.index - b.parent.index;
    //   })
    // );
  };

  const handleCopyMedicationProtocol = (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    // /* First find the Medication Protocol */
    // let protocolID =
    //   fromProtocol.status === ProgressStatus.ACTIVE
    //     ? fromProtocol.uid
    //     : fromProtocol.activeID;
    // let medProtocol: MedicationProtocol | null = null;
    // for (let i = 0; i < formik.values.protocolOptions.length; i++) {
    //   let po = formik.values.protocolOptions[i];
    //   if (po.protocolID === protocolID) {
    //     medProtocol = po;
    //     break;
    //   }
    // }
    // /* If there is no medication protocol then there is a problem */
    // if (!medProtocol) {
    //   console.error('Failed to find medication protocol');
    //   return;
    // }
    // /* Copy the medication protocol to the new protocol */
    // let newProtocolID =
    //   toProtocol.status === ProgressStatus.ACTIVE
    //     ? toProtocol.uid
    //     : toProtocol.activeID;
    // if (!newProtocolID) {
    //   console.error('Failed to find new protocol ID');
    //   return;
    // }
    // let newMedProtocol = new MedicationProtocol({
    //   protocolID: newProtocolID,
    //   options: [...medProtocol.options],
    // });
    // /* Add the medication protocol to the formik values */
    // let protocolOptions = formik.values.protocolOptions;
    // formik.setFieldValue('protocolOptions', [
    //   ...protocolOptions,
    //   newMedProtocol,
    // ]);
    // /* Create a new medicationSubItem for the new protocol */
    // let newMedSubItems = [...subMedicationItems];
    // for (let i = 0; i < newMedProtocol.options.length; i++) {
    //   let range = medProtocol.options[i];
    //   let newMedicationSubItem = new MedicationSubItem(
    //     parmMedication,
    //     toProtocol,
    //     range
    //   );
    //   newMedSubItems.push(newMedicationSubItem);
    // }
    // setSubMedicationItems(newMedSubItems);
    // /* Filter duplicates from the all sub medication items */
    // let allSubMeds = [...allSubMedicationItems, ...newMedSubItems];
    // setAllSubMedicationItems([...new Set(allSubMeds)]);
    // setAllProtocols(
    //   [
    //     ...new Set(
    //       ([...new Set(allSubMeds)] as MedicationSubItem[]).map(
    //         (m) => m.parentProtocol
    //       )
    //     ),
    //   ].sort((a, b) => {
    //     if (a.parent.index === b.parent.index)
    //       return a.name.localeCompare(b.name);
    //     return a.parent.index - b.parent.index;
    //   })
    // );
  };

  const handleAddMedicationProtocol = (mp: MedicationProtocol) => {
    // let protocolID = mp.protocolID;
    // let medProtocol: MedicationProtocol | null = null;
    // for (let i = 0; i < formik.values.protocolOptions.length; i++) {
    //   let po = formik.values.protocolOptions[i];
    //   if (po.protocolID === protocolID) {
    //     medProtocol = po;
    //     break;
    //   }
    // }
    // /* If there is medication protocol throw an error */
    // if (medProtocol != null) {
    //   console.error('Protocol already exists in the medication');
    //   return;
    // }
    // /* Add the medication protocol to the formik values */
    // let newProtocolOptions: MedicationProtocol[] = [
    //   ...formik.values.protocolOptions,
    //   mp,
    // ];
    // formik.setFieldValue('protocolOptions', newProtocolOptions);
    // console.log('Protocol Options', formik.values.protocolOptions);
    // let prot = database.protocols.find((p: ProtocolItem) =>
    //   p.status === ProgressStatus.ACTIVE || p.activeID == null
    //     ? p.uid === protocolID
    //     : p.activeID === protocolID
    // );
    // if (!prot) {
    //   console.error('Failed to find protocol');
    //   return;
    // }
    // console.log('Found Protocol', prot.name);
    // /* Create a new medicationSubItem for the new protocol */
    // let newMedSubItems = [...subMedicationItems];
    // for (let i = 0; i < mp.options.length; i++) {
    //   let range = mp.options[i];
    //   let newMedicationSubItem = new MedicationSubItem(
    //     parmMedication,
    //     prot,
    //     range
    //   );
    //   newMedSubItems.push(newMedicationSubItem);
    // }
    // console.log(
    //   'Added Doses',
    //   (newMedSubItems as MedicationSubItem[]).length - subMedicationItems.length
    // );
    // setSubMedicationItems(newMedSubItems);
    // /* Filter duplicates from the all sub medication items */
    // let allSubMeds = [...allSubMedicationItems, ...newMedSubItems];
    // setAllSubMedicationItems([...new Set(allSubMeds)]);
    // setAllProtocols(
    //   [
    //     ...new Set(
    //       ([...new Set(allSubMeds)] as MedicationSubItem[]).map(
    //         (m) => m.parentProtocol
    //       )
    //     ),
    //   ].sort((a, b) => {
    //     if (a.parent.index === b.parent.index)
    //       return a.name.localeCompare(b.name);
    //     return a.parent.index - b.parent.index;
    //   })
    // );
  };

  const handleRemoveMedicationDose = async (dose: MedicationSubItem) => {
    // const protocol = dose.parentProtocol;
    // let protocolID =
    //   protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
    //     ? protocol.uid
    //     : protocol.activeID;
    // let medProtocol: MedicationProtocol | null = null;
    // for (let i = 0; i < formik.values.protocolOptions.length; i++) {
    //   let po = formik.values.protocolOptions[i];
    //   if (po.protocolID === protocolID) {
    //     medProtocol = po;
    //     break;
    //   }
    // }
    // /* If there is no medication protocol then there is a problem */
    // if (!medProtocol) {
    //   console.error('Failed to find medication protocol');
    //   return;
    // }
    // /* Check if we are updating a previous dose and filter out the previoud dose otherwise add the new range */
    // let medicationRanges = medProtocol.options.filter(
    //   (m) => getObjectDifference(m, dose.range).length != 0
    // );
    // medProtocol = new MedicationProtocol({
    //   protocolID: medProtocol.protocolID,
    //   options: [...medicationRanges],
    // });
    // /* Add the medication protocol to the formik values */
    // let newProtocolOptions = formik.values.protocolOptions.filter(
    //   (po: MedicationProtocol) => po.protocolID !== protocolID
    // );
    // formik.setFieldValue('protocolOptions', [
    //   ...newProtocolOptions,
    //   medProtocol,
    // ]);
    // /* Need to create a MedicationSubItem for the new dose */
    // let subMeds = subMedicationItems.filter(
    //   (item: MedicationSubItem) => item !== dose
    // );
    // setSubMedicationItems([...subMeds]);
    // let allDoses = [...new Set([...allSubMedicationItems, ...subMeds])];
    // allDoses = allDoses.filter((item: MedicationSubItem) => item !== dose);
    // /* Filter duplicates from the all sub medication items */
    // setAllSubMedicationItems(allDoses);
    // setAllProtocols(
    //   [...new Set(allDoses.map((m) => m.parentProtocol))].sort((a, b) => {
    //     if (a.parent.index === b.parent.index)
    //       return a.name.localeCompare(b.name);
    //     return a.parent.index - b.parent.index;
    //   })
    // );
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    // if (isEditMode) return false;
    // let response: Response = await isDraftCreated(database.department);
    // if (response.type === ResponseType.Success) {
    //   let isDraft = response.data;
    //   if (isDraft) setIsOpen(true);
    //   return isDraft;
    // } else {
    //   if (globals.debug)
    //     console.log('Failed to check if draft exists', response);
    // }
    return false;
  }, [database.department, isEditMode]);

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteMedication = async () => {
    // const isDraft =
    //   parmMedication.status === ProgressStatus.DRAFT &&
    //   parmMedication.activeID == null;
    // let response = await deleteMedication(parmMedication, !isDraft);
    // if (response.type === ResponseType.Success) {
    //   if (protocol) {
    //     const state = { selectedProtocol: protocol, editMode: isEditMode };
    //     const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
    //     navigate(`/${formattedString}}/protocol-detail`, { state });
    //   } else {
    //     navigate(`/database/list-medications`, {
    //       state: {
    //         department: department,
    //         data: database.medications,
    //         database: database,
    //       },
    //     });
    //   }
    // } else {
    //   console.error('Failed to delete medication', response.data);
    // }
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const Modals = () => {
    return (
      <>
        {isOpen && (
          <DraftSaveModal
            database={database}
            isVisible={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleContinue={() => {
              setIsEditMode(true);
              setIsOpen(false);
            }}
            handleNew={() => {
              setIsDraftConfirmModal(true);
              setIsOpen(false);
            }}
          />
        )}

        <ConfirmModal
          isVisible={isDraftConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsDraftConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleBack}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication.  Click cancel to return to Medication details.  To continue without saving changes, select Abandon.`}
          />
        )}

        {isDelete && (
          <ConfirmModal
            isVisible={isDelete}
            title={'Delete Medication?'}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleSubmit={handleConfirmDeleteMedication}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={`Are you sure you would like to delete ${parmMedication.name}?`}
          />
        )}

        {isCancelWarningModal && (
          <ConfirmModal
            isVisible={isCancelWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsCancelWarningModal(false);
            }}
            handleSubmit={handleCancel}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication.  Click cancel to return to Medication details.  To cancel without saving changes, select Abandon.`}
          />
        )}

        <ConcentrationDialog
          dialogVisible={concentrationDialogVisible}
          handleSet={handleConcentrationSet}
          setDialogVisible={setConcentrationDialogVisible}
          headerText="Concentration"
        />

        <ConfirmWarningModal
          isVisible={isConfirmModal}
          modalType={isWarning ? 'warning' : modalType}
          type={modalType}
          handleClose={() => {
            setIsConfirmModal(false);
            setIsWarning(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        <MedicationDoseSideout
          doseIndex={doseIndex}
          solidUnit={solidUnits}
          protocol={null}
          parentModel={parmMedication}
          adminRoutes={formik.values.routes}
          dose={selectedDose ? selectedDose : undefined}
          subMedicationItems={allSubMedicationItems}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          onSubmit={handleOnSubmitMedicationDose}
          onSubmitMultiDose={handleCopyMedicationProtocol}
          onSetNewRoutes={(newRoutes: string[]) => {
            formik.setFieldValue('routes', newRoutes);
          }}
          onRemove={handleRemoveMedicationDose}
          onSubmitMedicationProtocol={handleAddMedicationProtocol}
        />

        <AddRouteModal
          dialogVisible={isAddRouteVisible}
          handleSet={handleNewRouteSet}
          onNewRouteCancel={() => {
            setIsAddRouteVisible(false);
          }}
          headerText="New Administration Route"
        />
      </>
    );
  };

  function toMetaDataString(metaData: ModelMetaData) {
    return JSON.stringify(metaData, null, 2);
  }

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(dose: MedicationSubItem, index: number) => {
          const lastIndex = data.length === index + 1;
          return (
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isEditMode) {
                  setSelectedDose(dose);
                  setDoseIndex(index);
                  setEditDose(true);
                  setSidebarVisible(true);
                }
              }}
            >
              <MedicationDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubMedicationItems.filter(
      (item: MedicationSubItem) => item.parentProtocol.uid === protocol.uid
    );
    // if (globals.debug) console.log('Doses', doses);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(protocol.uid) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          {expanded.includes(protocol.uid) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {protocol.name}
            </span>
            {SHOW_PROTOCOL_ID && (
              <span
                className="ketamine-general-label"
                style={{
                  fontWeight: '400',
                  fontSize: '11px',
                  marginTop: '-8px',
                }}
              >
                {protocol.status === ProgressStatus.ACTIVE ||
                protocol.activeID == null
                  ? protocol.uid
                  : protocol.activeID}

                <span>
                  {isCopied &&
                  isCopied ===
                    (protocol.status === ProgressStatus.ACTIVE ||
                    protocol.activeID == null
                      ? protocol.uid
                      : protocol.activeID) ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(
                          protocol.status === ProgressStatus.ACTIVE ||
                            protocol.activeID == null
                            ? protocol.uid
                            : protocol.activeID,
                          e
                        )
                      }
                    />
                  )}
                </span>
              </span>
            )}
          </div>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {doses.length} doses
          </span>
        </div>
        {expanded.includes(protocol.uid) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={doses} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="screen-container">
      <Modals />
      <ProtocolHeader
        name={
          parmMedication.name
          // (parmMedication.status === "DRAFT" ? " (Draft)" : "")
        }
        status={parmMedication.status}
        isBackButton={true}
        page={'HM Medications'}
        type={'protocol'}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isSaveButton={isEditMode}
        isSaveActive={
          formik.dirty &&
          formik.isValid &&
          formik.values.concentration.length > 0 &&
          formik.values.routes.length > 0
        }
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmMedication.uid : ''}
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        isEditButton={!isEditMode}
        isDotButton={true}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}
        handleVersion={handleVersion}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral ">
          <h5 className="ketmine-header-text">General Information</h5>
          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {parmMedication.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(parmMedication.model.updatedAt, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>
                  {parmMedication.version}
                </div>
              </div>
              {user.type === UserType.ADMIN &&
                parmMedication.rxNormCode != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>RxNorm Code:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.rxNormCode}
                      <span>
                        {isCopied && isCopied === parmMedication.rxNormCode ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(parmMedication.rxNormCode as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmMedication.uid}
                    <span>
                      {isCopied && isCopied === parmMedication.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(parmMedication.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                parmMedication.status === ProgressStatus.DRAFT &&
                parmMedication.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.activeID}
                      <span>
                        {isCopied && isCopied === parmMedication.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(parmMedication.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Concentration <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideConcentration"
            data-div-id="outsideConcentration"
            onClick={handleConcentrationEdit}
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${
              formik.values.concentration.length !== 0 ? '' : 'centerContent'
            }`}
          >
            {formik.values.concentration.length !== 0 && (
              <ViewportList items={formik.values.concentration}>
                {(item: MedicationConcentration, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{getConcentrationString(item)}</div>
                      {isEditMode ? (
                        <span
                          className="close_icon_color"
                          data-testid={`deleteConcentration ${index}`}
                          onClick={(e) => handleRemoveConcentration(e, item)}
                        >
                          <AiOutlineCloseCircle className="icon-normal" />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }}
              </ViewportList>
            )}
            {formik.values.concentration.length !== 0 && isEditMode && (
              <HiPencil
                data-testid={`editConcentration`}
                className={`icon-black rightIcon ${
                  isEditMode && 'cursorPointer'
                }`}
                onClick={() => {
                  if (isEditMode) setConcentrationDialogVisible(true);
                }}
              />
            )}
            {formik.values.concentration.length === 0 && (
              <div
                onClick={() => {
                  isEditMode &&
                    setConcentrationDialogVisible(!concentrationDialogVisible);
                }}
              >
                <span className="formAnchorText">+Add Concentration</span>
              </div>
            )}
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Routes <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            onClick={handleRouteEdit}
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {formik.values.routes.length >= 1 && (
              <ViewportList items={formik.values.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{item}</div>
                      {isEditMode ? (
                        <span className="close_icon_color">
                          <AiOutlineCloseCircle
                            data-testid={`deleteRoute ${index}`}
                            className={`icon-normal ${
                              isEditMode && 'cursorPointer'
                            }`}
                            onClick={(e) => {
                              if (isEditMode) handleRemoveRoute(e, item);
                            }}
                          />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }}
              </ViewportList>
            )}
            {formik.values.routes.length >= 1 && isEditMode && (
              <HiPencil
                data-testid={`EditRoute`}
                className={`icon-black rightIcon ${
                  isEditMode && 'cursorPointer'
                }`}
              />
            )}
            {formik.values.routes.length === 0 && (
              <div
                onClick={() => {
                  isEditMode && setIsAddRouteVisible(true);
                }}
              >
                <span className="cursorPointer formAnchorText">+Add Route</span>
              </div>
            )}
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Routes <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            onClick={handleRouteEdit}
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {formik.values.routes.length >= 1 && (
              <ViewportList items={formik.values.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{item}</div>
                      {isEditMode ? (
                        <span className="close_icon_color">
                          <AiOutlineCloseCircle
                            data-testid={`deleteRoute ${index}`}
                            className={`icon-normal ${
                              isEditMode && 'cursorPointer'
                            }`}
                            onClick={(e) => {
                              if (isEditMode) handleRemoveRoute(e, item);
                            }}
                          />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }}
              </ViewportList>
            )}
            {formik.values.routes.length >= 1 && isEditMode && (
              <HiPencil
                data-testid={`EditRoute`}
                className={`icon-black rightIcon ${
                  isEditMode && 'cursorPointer'
                }`}
              />
            )}
            {formik.values.routes.length === 0 && (
              <div
                onClick={() => {
                  isEditMode && setIsAddRouteVisible(true);
                }}
              >
                <span className="cursorPointer formAnchorText">+Add Route</span>
              </div>
            )}
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    formik.values.rangeLow &&
                    Number(formik.values.rangeLow) !== 0
                      ? formik.values.rangeLow
                      : ''
                  }
                  // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode}
                  onChange={(e: any) => {
                    /* Check for "Min" */
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeLow', 'Invalid input');
                    else formik.setFieldValue('rangeLow', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeLow && formik.errors.rangeLow ? (
                <span className="errorText">{formik.errors.rangeLow}</span>
              ) : null}
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    formik.values.rangeHigh &&
                    Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                      ? formik.values.rangeHigh
                      : ''
                  }
                  placeholder="Max"
                  onChange={(e: any) => {
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeHigh', 'Invalid input');
                    else formik.setFieldValue('rangeHigh', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                <span className="errorText">{formik.errors.rangeHigh}</span>
              ) : null}
            </Col>
          </Row>
          <label htmlFor="Contraindication" className="ketamine-general-label">
            Contraindication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="contraindication"
              data-testid="contraindication"
              name="contraindication"
              value={formik.values.contraindication}
              onChange={(e: any) => {
                formik.setFieldValue('contraindication', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              data-testid="warning"
              name="warning"
              value={formik.values.warning}
              onChange={(e: any) => {
                formik.setFieldValue('warning', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Indication" className="ketamine-general-label">
            Indication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="indication"
              data-testid="indication"
              name="indication"
              value={formik.values.indication}
              onChange={(e: any) => {
                formik.setFieldValue('indication', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="DrugInteraction" className="ketamine-general-label">
            Drug Interaction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="interaction"
              data-testid="interaction"
              name="interaction"
              value={formik.values.interaction}
              onChange={(e: any) => {
                formik.setFieldValue('interaction', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Onset" className="ketamine-general-label">
            Onset
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="onset"
              data-testid="onset"
              name="onset"
              value={formik.values.onset}
              onChange={(e: any) => {
                formik.setFieldValue('onset', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Duration" className="ketamine-general-label">
            Duration
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="duration"
              data-testid="duration"
              name="duration"
              value={formik.values.duration}
              onChange={(e: any) => {
                formik.setFieldValue('duration', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="notes"
              data-testid="notes"
              name="notes"
              value={formik.values.note}
              onChange={(e: any) => {
                formik.setFieldValue('note', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Class" className="ketamine-general-label">
            Class
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="class"
              data-testid="class"
              name="class"
              value={formik.values.medClass}
              onChange={(e: any) => {
                formik.setFieldValue('medClass', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Action" className="ketamine-general-label">
            Action
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="action"
              data-testid="action"
              name="action"
              value={formik.values.action}
              onChange={(e: any) => {
                formik.setFieldValue('action', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">
            {parmMedication.name} Meta Data
          </h5>
          {isEditMode && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Doses</span>
              <div
                onClick={onAddDoseClick}
                className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                  isEditMode && 'cursorPointer newRouteButton'
                }`}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  style={{
                    textDecoration: 'underLine',
                    textUnderlinePosition: 'under',
                  }}
                >
                  <HiPlus className="text-icon " /> Add Dose
                </span>
              </div>
            </div>
          )}

          <ViewportList items={allProtocols}>
            {(protocol: ProtocolItem, index: number) => {
              return <ProtocolItemUI key={index} protocol={protocol} />;
            }}
          </ViewportList>
          <label htmlFor="notifyType" className={`notification-css-title`}>
            Meta Data
          </label>
          <InputTextarea
            className="notificatonText form-control-general"
            id="message"
            name="message"
            required={true}
            value={
              parmMedication.model && parmMedication.model.metaData
                ? toMetaDataString(parmMedication.model.metaData)
                : ''
            }
            data-testid="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ height: '300px', verticalAlign: 'top' }}
          />
        </div>
      </div>
    </div>
  );
};

export default HinckleyEditMedication;
