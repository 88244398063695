import { API, graphqlOperation } from 'aws-amplify';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  findDepartmentLogo,
  mapItems,
} from './AmplifyDB';
import {
  categoriesByDepartmentID,
  dripsByDepartmentID,
  electricalShocksByDepartmentID,
  equipmentByDepartmentID,
  getAmbulance,
  getCategory,
  getContact,
  getDepartment,
  getDrip,
  getElectrical,
  getElectricalShock,
  getEquipment,
  getForm,
  getInputForm,
  getKeychain,
  getMedication,
  getMedicShift,
  getPatientInteraction,
  getProtocol,
  getUser,
  getVitals,
  getWeightObject,
  keychainsByDepartmentID,
  listDepartments,
  medicationsByDepartmentID,
  protocolsByCategoryID,
  protocolsByDepartmentID,
  usersByCognitoID,
  vitalsByDepartmentID,
} from '../graphql/queries';
import {
  Medication,
  UserType,
  Drip,
  Electrical,
  Category,
  Department,
  Keychain,
  Protocol,
  User,
  Equipment,
} from '../API';
import { getHashedPin, getSalt } from '../ui/_global/common/Encrypt';
import { createUser } from '../graphql/mutations';
import DepartmentItem from './model/DepartmentItem';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import { globals } from '../ui/_global/common/Utils';
import KeychainItem from './model/KeychainItem';
import MedicationItem from './model/MedicationItem';
import InfusionItem from './model/InfusionItem';
import ElectricalItem from './model/ElectricalItem';
import EquipmentItem from './model/EquipmentItem';
import ModelItem from './model/ModelItem';
import VitalItem from './model/VitalItem';

export function executeQuery(query: any, timeout = 5000): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      let promises = [
        API.graphql(query),
        new Promise((resolve) => setTimeout(() => resolve('timeout'), timeout)),
      ];
      let result = await Promise.race(promises);
      if (result === 'timeout') {
        reject('Timeout');
      }
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export const fetchDepartmentByName = async (
  departmentName: string
): Promise<Response> => {
  try {
    let reponse = await fetchDepartments();
    if (reponse.type === ResponseType.Failure) return reponse;

    let [departments, allDepartments] = reponse.data as [
      Department[],
      Department[],
    ];

    let department = departments.find((d) => {
      let urlName = d.name.replaceAll(' ', '').toLowerCase().trim();
      return urlName === departmentName.toLowerCase().trim();
    });

    if (!department)
      return { type: ResponseType.Failure, data: 'Department not found' };

    /* If the department is a sub department then get the parent department */
    let activeSubDepartment: Department | null = null;
    if (department && department.parentDepID != null) {
      activeSubDepartment = department;
      department = departments.find(
        (d) => (department as Department).parentDepID === d.id
      );
      if (!department)
        return { type: ResponseType.Failure, data: 'No department found' };
      else console.log('Parent department found:', department);
    }

    let dep = new DepartmentItem(department as any);
    let logoResult = await findDepartmentLogo(dep);
    if (logoResult.type === ResponseType.Success)
      dep.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    if (!dep.isPublic) {
      return {
        type: ResponseType.Failure,
        data: 'Department is not public.',
      };
    } else if (dep.keychainID) {
      const keychainData: any = await API.graphql(
        graphqlOperation(getKeychain, { id: department.keychainID })
      );
      let keychain = new KeychainItem(keychainData.data.getKeychain);
      dep.keychain = keychain;
      if (!keychain.isUnlocked) {
        return {
          type: ResponseType.Success,
          data: {
            department: dep,
            keychain: keychain,
          },
        };
      }
    }

    if (dep.subDepIDs.length > 0) {
      /* Get the sub departments & the last selected department */
      for (let i = 0; i < dep.subDepIDs.length; i++) {
        let subDep = allDepartments.find((d) => d.id === dep.subDepIDs[i]);
        if (subDep && subDep.activeStatus === true && subDep.isPublic) {
          let subDepartment = new DepartmentItem(subDep as any);
          dep.addSubDep(subDepartment);
          let logoResult = await findDepartmentLogo(subDepartment);
          if (logoResult.type === ResponseType.Success) {
            subDepartment.logoVerifiedUrl = logoResult.data;
          }
        }
      }
      if (activeSubDepartment) {
        dep.activeSubDep = dep.subDeps?.find(
          (d) => d.id === (activeSubDepartment as Department).id
        );
      } else {
        let id = localStorage.getItem('lastSelectedDepID');
        if (id) {
          let lastSelectedDep = dep.subDeps?.find((d) => d.id === id);
          if (lastSelectedDep) dep.activeSubDep = lastSelectedDep;
          else dep.activeSubDep = dep.subDeps ? dep.subDeps[0] : null;
        } else dep.activeSubDep = dep.subDeps ? dep.subDeps[0] : null;
      }
    }

    let response = await loadPublicDepartmentDatabase(dep);
    if (response.type === ResponseType.Failure) return response;

    return {
      type: ResponseType.Success,
      data: {
        db: response.data,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Fetches all departments and the codes for each department.
 * @returns A list of all departments in the database and there respective codes.
 */
export const fetchDepartments = async () // isParentOnly: boolean = false
: Promise<Response> => {
  try {
    /* Make an API call to get all departments. Without being authenticated yet */
    let filter: any = {
      and: {
        _deleted: { ne: true },
        activeStatus: { ne: false },
      },
    };
    // if (isParentOnly)
    //   filter = {
    //     ...filter,
    //     parentDepID: { attributeExists: false },
    //   };

    const departmentsData: any = await API.graphql(
      graphqlOperation(listDepartments, filter)
    );
    let departments: Department[] = departmentsData.data.listDepartments.items;

    /* Make sure every department is a parent department. */
    let departmentList: any = [];
    for (let i = 0; i < departments.length; i++) {
      /* First check if the department is a parent department. */
      let ids = departments[i].subDepIDs
        ? (departments[i].subDepIDs as string[])
        : [];
      if (ids.length > 0) {
        /* If is a sub departmet filter out any of its sub departments. */
        for (let j = 0; j < ids.length; j++) {
          if (ids[j] == null || ids[j] === '') continue;
          let id = ids[j] as string;
          departmentList = departmentList.filter(
            (dep: Department) => dep.id !== id
          );
        }

        /* Add the parent department to the list. */
        departmentList.push(departments[i]);
      } else {
        /* If it is not a parent department, check if it is a sub department. */
        let id = departments[i].id;
        let found = false;
        for (let j = 0; j < departmentList.length; j++) {
          if (departmentList[j].subDepIDs?.includes(id)) {
            found = true;
            break;
          }
        }
        if (!found) departmentList.push(departments[i]);
      }
    }
    departmentList.sort((a: Department, b: Department) =>
      a.name.localeCompare(b.name)
    );
    departments.sort((a: Department, b: Department) =>
      a.name.localeCompare(b.name)
    );
    return {
      type: ResponseType.Success,
      data: [departmentList, departments], //, codes]
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const loadPublicDepartmentDatabase = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Parent Department:', department.name, department.id);

    let promises: any[] = [];
    promises.push(fetchCategoriesAndProtocols_v2(department, 'ACTIVE'));
    promises.push(fetchKeychains(department));
    let [catResp, keychainResp] = await Promise.all(promises);
    if (catResp.type === ResponseType.Failure) return catResp;
    if (keychainResp.type === ResponseType.Failure) return keychainResp;

    let db: DatabaseResponse = {
      department: department,
      categories: catResp.data.categories,
      protocols: catResp.data.protocols,
      ambulances: [],
      users: [],
      oneWeights: [],
      medications: [],
      medicationDoses: [],
      infusions: [],
      infusionDoses: [],
      equipment: [],
      electrical: [],
      electricalDoses: [],
      checklists: [],
      vitals: [],
      logs: [],
      notifications: [],
      contacts: [],
      weightObjects: [],
      keychains: keychainResp.data,
      groups: [],
    };

    /* Map the protocols to the keychains */
    for (let i = 0; i < db.keychains.length; i++) {
      let keychain = db.keychains[i];
      let protocols: ProtocolItem[] = db.protocols.filter(
        (p: ProtocolItem) => p.keychainID === keychain.uid
      );
      let categories: CategoryItem[] = db.categories.filter(
        (c: CategoryItem) => c.keychainID === keychain.uid
      );
      keychain.categories = categories;
      keychain.protocols = protocols;
    }

    if (globals.debug) {
      console.log('Loaded Public Database:', department.name);
      console.log('Categories:', db.categories);
      console.log('Protocols:', db.protocols.length);
      console.log('Keychains:', db.keychains.length);
    }

    mapItems(db, [], [], []); //TODO MAP MED DOSES

    return {
      type: ResponseType.Success,
      data: db,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchCategoriesAndProtocols_v2 = async (
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  fetchAll: boolean = false
): Promise<Response> => {
  try {
    let categoryList: Category[] = [];
    let nextToken: string | undefined = undefined;
    let filter: any = {
      and: {
        status: { eq: _status ? _status : 'ACTIVE' },
        _deleted: { ne: true },
        isPublic: { eq: true },
      },
    };

    if (department.activeSubDep != null) {
      filter = {
        and: {
          pairedDepIDs: { contains: department.activeSubDep.id },
          _deleted: { ne: true },
          status: { eq: _status ? _status : 'ACTIVE' },
          isPublic: { eq: true },
        },
      };
    }
    try {
      do {
        const categoryData: any = await executeQuery(
          graphqlOperation(categoriesByDepartmentID, {
            departmentID: department.id,
            nextToken: nextToken ? nextToken : undefined,
            filter: filter,
            limit: 500,
          }),
          globals.maxDatabaseDelayMS
        );

        categoryList = categoryList.concat(
          categoryData.data.categoriesByDepartmentID.items
        );
        nextToken = categoryData.data.categoriesByDepartmentID.nextToken;
      } while (fetchAll && nextToken);
    } catch (error) {
      console.log(error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }

    let promises = [];
    let categories: CategoryItem[] = [];
    for (let i = 0; i < categoryList.length; i++) {
      let data: any = categoryList[i];
      let category = new CategoryItem(data);

      if (category.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (category.activeID !== null)
          categories = categories.filter(
            (c: CategoryItem) => c.uid !== category.activeID
          );
      } else if (category.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = categories.find(
          (c: CategoryItem) => c.activeID === category.uid
        );
        if (isAlreadyDraft) continue;
      }

      categories.push(category);

      promises.push(fetchProtocols_v2(category, department, _status, true));
    }

    let protocolResponses = await Promise.all(promises);
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolResponses.length; i++) {
      let data = protocolResponses[i].data as ProtocolItem[];
      categories[i].protocols = data;
      protocols = protocols.concat(data);
    }

    /* Make an API call to get all departments. Without being authenticated yet */
    // let categories: CategoryItem[] = [];
    // let protocols: ProtocolItem[] = [];
    // for (let i = 0; i < categoryList.length; i++) {
    //   let data: any = categoryList[i];
    //   let category = new CategoryItem(data);

    //   if (category.status === 'DRAFT') {
    //     /* Take out the active version if there is one */
    //     if (category.activeID !== null)
    //       categories = categories.filter(
    //         (c: CategoryItem) => c.uid !== category.activeID
    //       );
    //   } else if (category.status === 'ACTIVE') {
    //     /* Make sure the draft version is not in the list */
    //     let isAlreadyDraft = categories.find(
    //       (c: CategoryItem) => c.activeID === category.uid
    //     );
    //     if (isAlreadyDraft) continue;
    //   }

    //   categories.push(category);

    //   /* Get the protocols for the category */
    //   let prots: Protocol[] = data.Protocols.items;
    //   let catProtocols: ProtocolItem[] = [];
    //   for (let j = 0; j < prots.length; j++) {
    //     let protocol = new ProtocolItem(prots[j], category);
    //     if (protocol.status === 'DRAFT') {
    //       /* Take out the active version if there is one */
    //       if (protocol.activeID != null)
    //         categories = categories.filter(
    //           (c: CategoryItem) => c.uid !== protocol.activeID
    //         );
    //     } else if (protocol.status === 'ACTIVE') {
    //       /* Make sure the draft version is not in the list */
    //       let isAlreadyDraft = categories.find(
    //         (c: CategoryItem) => c.activeID === protocol.uid
    //       );
    //       if (isAlreadyDraft) continue;
    //     }

    //     catProtocols.push(protocol);
    //   }
    //   catProtocols.sort((a: ProtocolItem, b: ProtocolItem) => {
    //     if (a.index === b.index) return a.name.localeCompare(b.name);
    //     return a.index - b.index;
    //   });
    //   category.protocols = catProtocols;
    //   protocols = protocols.concat(catProtocols);
    // }

    categories.sort((a: CategoryItem, b: CategoryItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.parent.index === b.parent.index)
        return a.name.localeCompare(b.name);
      return a.parent.index - b.parent.index;
    });

    return {
      type: ResponseType.Success,
      data: {
        categories: categories,
        protocols: protocols,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchProtocols_v2 = async (
  category: CategoryItem,
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  isFetchAll: boolean = false
): Promise<Response> => {
  try {
    /* Make an API call to get all departments. Without being authenticated yet */
    let protocolList: Protocol[] = [];
    let nextToken: string | undefined = undefined;
    do {
      let filter: any = {
        and: {
          departmentID: { eq: department.id },
          status: { eq: _status ? _status : 'ACTIVE' },
          _deleted: { ne: true },
        },
      };
      if (department.activeSubDep != null) {
        filter = {
          and: {
            departmentID: { eq: department.id },
            pairedDepIDs: { contains: department.activeSubDep.id },
            status: { eq: _status ? _status : 'ACTIVE' },
            _deleted: { ne: true },
          },
        };
      }
      const protocolData: any = await API.graphql(
        graphqlOperation(protocolsByCategoryID, {
          categoryID: category.uid,
          nextToken: nextToken,
          filter: filter,
          limit: 500,
        })
      );
      protocolList = protocolList.concat(
        protocolData.data.protocolsByCategoryID.items
      );
      nextToken = protocolData.data.protocolsByCategoryID.nextToken;
    } while (isFetchAll && nextToken);

    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolList.length; i++) {
      let data: any = protocolList[i];
      let protocol = new ProtocolItem(data, category);

      if (protocol.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (protocol.activeID != null)
          protocols = protocols.filter(
            (p: ProtocolItem) => p.uid !== protocol.activeID
          );
      } else if (protocol.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = protocols.find(
          (p: ProtocolItem) => p.activeID === protocol.uid
        );
        if (isAlreadyDraft) continue;
      }

      protocols.push(protocol);
    }

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    return {
      type: ResponseType.Success,
      data: protocols,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchKeychains = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    // let ids: string[] = [];
    // for (let i = 0; i < protocols.length; i++) {
    //   let protocol = protocols[i];
    //   if (protocol.keychainID && !ids.includes(protocol.keychainID))
    //     ids.push(protocol.keychainID);
    // }

    let keychains: KeychainItem[] = [];
    // if (ids.length > 0) {
    const keychainData: any = await API.graphql(
      graphqlOperation(keychainsByDepartmentID, {
        departmentID: department.id,
        filter: {
          _deleted: { ne: true },
        },
      })
    );
    let keychainList: Keychain[] =
      keychainData.data.keychainsByDepartmentID.items;

    for (let i = 0; i < keychainList.length; i++) {
      //   if (ids.includes(keychainList[i].id))
      keychains.push(new KeychainItem(keychainList[i] as any));
    }
    // }
    // /* Map the keychains to the protocols */
    // for (let i = 0; i < protocols.length; i++) {
    //   let protocol = protocols[i];
    //   if (protocol.keychainID) {
    //     let keychain = keychains.find(
    //       (k: KeychainItem) => k.uid === protocol.keychainID
    //     );
    //     if (keychain) protocol.keychain = keychain;
    //   }
    // }

    return {
      type: ResponseType.Success,
      data: keychains,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchItemFromAPI = async (
  department: DepartmentItem,
  type: 'Medications' | 'Drips' | 'Electrical' | 'Equipment' | 'Vitals',
  overrideID?: string
): Promise<Response> => {
  try {
    let query: any | undefined = undefined;
    switch (type) {
      case 'Medications':
        query = medicationsByDepartmentID;
        break;
      case 'Drips':
        query = dripsByDepartmentID;
        break;
      case 'Electrical':
        query = electricalShocksByDepartmentID;
        break;
      case 'Equipment':
        query = equipmentByDepartmentID;
        break;
      case 'Vitals':
        query = vitalsByDepartmentID;
        break;
      default:
        return {
          type: ResponseType.Failure,
          data: 'Invalid type',
        };
    }
    let items: ModelItem<any>[] = [];

    const data: any = await API.graphql(
      graphqlOperation(query, {
        departmentID: overrideID ? overrideID : department.id,
        filter: {
          _deleted: { ne: true },
          status: { eq: 'ACTIVE' },
        },
      })
    );

    let list: any[] = data.data[Object.keys(data.data)[0]].items;

    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      switch (type) {
        case 'Medications':
          items.push(new MedicationItem(item as any));
          break;
        case 'Drips':
          items.push(new InfusionItem(item as any));
          break;
        case 'Electrical':
          items.push(new ElectricalItem(item as any));
          break;
        case 'Equipment':
          items.push(new EquipmentItem(item as any));
          break;
        case 'Vitals':
          items.push(new VitalItem(item as any));
          break;
        default:
          break;
      }
    }

    items.sort((a: ModelItem<any>, b: ModelItem<any>) =>
      a.name.localeCompare(b.name)
    );

    return {
      type: ResponseType.Success,
      data: items,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const validateDepartmentCode = async (
  departmentCode: string,
  code: string,
  departments: Department[]
): Promise<Response> => {
  try {
    const department = departments.find(
      (dept) => dept?.uniqueCode === departmentCode
    );

    if (!department) {
      return { type: ResponseType.Failure, data: 'Department not found' };
    }

    const hashedPin = getHashedPin(code, department.saltedPin);

    if (hashedPin === department.hashedPin) {
      return { type: ResponseType.Success, data: [department] };
    } else {
      return { type: ResponseType.Failure, data: 'Invalid PIN' };
    }
  } catch (error) {
    console.error(error);
    return {
      type: ResponseType.Failure,
      data: error || 'An error occurred',
    };
  }
};

export const getDepartmentCode = async (userID: string): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Make an API call to get all departments. Without being authenticated yet */
      const userData: any = await API.graphql(
        graphqlOperation(getUser, { id: userID })
      );
      let user = userData.data.getUser;
      if (user == null)
        return resolve({
          type: ResponseType.Failure,
          data: 'User not found',
        });

      return resolve({
        type: ResponseType.Success,
        data: user.hashedPin,
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const createUserGraphQL = async (
  type: UserType,
  subID: string,
  firstName: string,
  lastName: string,
  pin: string,
  department: Department,
  pairedDepartments: Department[]
): Promise<Response> => {
  try {
    let saltPin = getSalt();
    let hashedPin = getHashedPin(pin, saltPin);

    let pairedDepIDs: string[] = pairedDepartments.map((d: Department) => d.id);

    let input = {
      firstName: firstName,
      lastName: lastName,
      type: type,
      cognitoID: subID.toLowerCase(),
      departmentID: department.id,
      hashedPin: hashedPin,
      saltPin: saltPin,
      pairedDepIDs: pairedDepIDs,
      oneDoseVersion: '',
      notificationTokens: [],
      status: 'ACTIVE',
    };
    /* Make an API call to get all departments. Without being authenticated yet */
    const userData: any = await API.graphql(
      graphqlOperation(createUser, { input: input })
    );

    let user = userData.data.createUser;

    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getUserAndDepartment = async (
  username: string
): Promise<Response> => {
  try {
    /* Make an API call to get the user prior to syncing with the database. */
    const userData: any = await API.graphql(
      graphqlOperation(usersByCognitoID, { cognitoID: username })
    );
    const user: User = userData.data.usersByCognitoID.items[0];

    if (user == null) {
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };
    }

    /* Make an API call to get all departments. Without being authenticated yet */
    const departmentData: any = await API.graphql(
      graphqlOperation(getDepartment, { id: user.departmentID })
    );
    const department = departmentData.data.getDepartment;

    const dep = new DepartmentItem(department);
    findDepartmentLogo(dep).then((resultLogo) => {
      if (resultLogo.type === ResponseType.Success)
        dep.logoVerifiedUrl = resultLogo.data;
      else console.log('Error fetching department logo:', resultLogo.data);
    });

    if (dep.isMultiDep && user.pairedDepIDs) {
      let promises: any[] = [];
      for (let i = 0; i < user.pairedDepIDs.length; i++) {
        promises.push(
          API.graphql(
            graphqlOperation(getDepartment, { id: user.pairedDepIDs[i] })
          )
        );
      }
      const resp = await Promise.all(promises);
      for (let i = 0; i < resp.length; i++) {
        const subDep = resp[i].data.getDepartment;
        if (subDep.activeStatus === false) continue;
        const subDepModel = new DepartmentItem(subDep);
        dep.addSubDep(subDepModel);
        if (i === 0) {
          dep.activeSubDep = subDepModel;
          findDepartmentLogo(subDepModel).then((resultLogo) => {
            if (resultLogo.type === ResponseType.Success && dep.activeSubDep)
              dep.activeSubDep.logoVerifiedUrl = resultLogo.data;
            else
              console.log('Error fetching department logo:', resultLogo.data);
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: {
        user: user,
        department: dep,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get an item by its ID from the database
 * @param type ItemType of the item to get
 * @param id ID of the item to get
 * @returns A promise that resolves to a Response object
 */
export const getItemByID = (
  key: string,
  type: string,
  id: string
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query: any;
      switch (type) {
        case 'Department':
          query = getDepartment;
          break;
        case 'Protocol':
          query = getProtocol;
          break;
        case 'Category':
          query = getCategory;
          break;
        case 'Medication':
          query = getMedication;
          break;
        case 'Drip':
          query = getDrip;
          break;
        case 'Equipment':
          query = getEquipment;
          break;
        case 'Form':
          query = getForm;
          break;
        case 'Electrical':
          query = getElectrical;
          break;
        case 'ElectricalShock':
          query = getElectricalShock;
          break;
        case 'Vitals':
          query = getVitals;
          break;
        case 'Checklist':
          query = getUser;
          break;
        case 'Ambulance':
          query = getAmbulance;
          break;
        case 'Contact':
          query = getContact;
          break;
        case 'InputForm':
          query = getInputForm;
          break;
        case 'User':
          query = getUser;
          break;
        case 'WeightObject':
          query = getWeightObject;
          break;
        case 'PatientInteraction':
          query = getPatientInteraction;
          break;
        case 'MedicShift':
          query = getMedicShift;
          break;
        default:
          console.log('Invalid type:', type, 'for key:', key, 'and id:', id);
          return resolve({
            type: ResponseType.Failure,
            data: 'Invalid type',
          });
      }

      const result = await executeQuery(
        graphqlOperation(query, { id: id }),
        1500
      );
      const value = result.data[`get${type}`];
      return resolve({
        type: ResponseType.Success,
        data: {
          value: value,
          id: id,
          key: key,
        },
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};
