import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { BiChevronRight } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { ViewportList } from 'react-viewport-list';
import '../Popup.css';
import { useEffect, useState } from 'react';
import { getDraftUpdates } from '../../../data/AmplifyVersion';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';

interface DraftSaveModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleNew: () => void;
  handleContinue: () => void;
  database: DatabaseResponse;
}

/* 10-09-23 Arul: Created the DraftSaveModal component for globally for Edit Folder Page */
const DraftSaveModal = (props: DraftSaveModalProps) => {
  const { isVisible, handleClose, handleNew, handleContinue, database } = props;
  // const draftData = useSelector((state: any) => state?.protocol?.draft);
  const [draftList, setDraftList] = useState<any[]>([]);

  useEffect(() => {
    const loadDetectedChanges = async () => {
      const response: Response = await getDraftUpdates(database, true, true);
      if (response.type === ResponseType.Success) {
        setDraftList(response.data);
      }
    };
    loadDetectedChanges();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'b') {
        event.preventDefault();
        handleClose();
      } else if (event.key === 'Enter') {
        event.preventDefault();
        handleContinue();
      } else if (event.metaKey && event.key === 'o') {
        event.preventDefault();
        handleNew();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{ width: '60vw' }}
        header="Continue on saved draft"
        onHide={handleClose}
      >
        <div className="outerDialogDiv">
          <div className="dialogInputContainer">
            <div className="dialogContent">
              <div className="">
                <div className="modalLabel">
                  Continue on saved draft of make a new one?
                </div>
                <div className="contentText">
                  We have detected that there is already asaved draft that a
                  user has started would you like to continue on this same.
                </div>
                <div className="saveDraftModal">
                  <div className="modalLabel" style={{ marginBottom: '5px' }}>
                    Detected Changes {draftList.length}
                  </div>
                  <div className="contentBorder protocolCalculationPad">
                    <ViewportList items={draftList}>
                      {(item: any, index: any) => (
                        <div
                          key={item.model.id}
                          className={`item contentHeading ${draftList.length !== index + 1 ? 'contentUnderline' : ''}`}
                        >
                          {item.message}
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </div>
              </div>
            </div>
            <div className="dialogButtons">
              <Button
                className="secondary-button-small-border btn-rightMargin"
                onClick={handleClose}
              >
                {' '}
                Dismiss
              </Button>
              <Button
                className=" red-border-button btn-rightMargin cancel-button"
                onClick={handleNew}
              >
                {' '}
                Make New
              </Button>
              <Button className="primary-button-small" onClick={handleContinue}>
                {' '}
                Continue{' '}
                <span>
                  <BiChevronRight className="icon-normal" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DraftSaveModal;
