import { ProgressStatus } from '../../API';
import { User } from '../../models';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import {
  getProgressStatus,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import DepartmentItem from './DepartmentItem';
import ModelInterface from './ModelInterface';

class ModelItem<T> implements ModelInterface {
  TAG = 'ModelItem';
  uid: string;
  departmentID: string;
  name: string;
  model: T;
  anyModel: any;
  version: string;
  isPublic: boolean;
  status: ProgressStatus;
  activeID: string | null | undefined;
  overrideID: string | null | undefined;
  pairedDepIDs: string[] | null | undefined;
  activeItem: ModelItem<T> | null | undefined;
  overrideItem: ModelItem<T> | null | undefined;
  modifiedBy: User | null | undefined;
  keychainID: string | null | undefined;
  pairedDeps: DepartmentItem[] | undefined | null;
  createdAt: Date | null | undefined;
  modifiedAt: Date | null | undefined;

  constructor(
    model: any,
    name: string,
    departmentID: string,
    status?: any | null,
    version?: string | null
  ) {
    this.uid = model.id;
    this.name = name;
    this.model = model;
    this.anyModel = model;
    this.departmentID = departmentID;
    this.activeID = model.activeID;
    this.overrideID = model.overrideID;
    this.status = getProgressStatus(status ? status : model.status);
    this.activeItem = null;
    this.modifiedBy = null;
    this.version = version ? version : model.version ? model.version : 'v1.0.0';
    this.isPublic = model.isPublic ? model.isPublic : false;
    this.keychainID = model.keychainID;
    this.pairedDepIDs = model.pairedDepIDs;
  }

  getUid(): string {
    return this.uid;
  }
  getName(): string {
    return this.name;
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method at parent should not be called.');
  }
  getModel() {
    return this.model;
  }
  equals(obj: any): boolean {
    if (obj == null) return false;
    return isObjectEqual(this, obj);
  }
  toString(): string {
    return `ModelItem -> {
            uid=${this.uid},
            name=${this.name},
        }`;
  }
}

export default ModelItem;
