import { Link, useLocation, useNavigate } from 'react-router-dom';
// import * as FaIcons from "react-icons/fa"
// import * as SiIcons from "react-icons/si"
// import * as HiIcons from "react-icons/hi"
// import * as MdIcons from "react-icons/md"
import { Fa6, FaCloudArrowUp, FaDatabase, FaFolderOpen } from 'react-icons/fa6';
import { FaUserFriends } from 'react-icons/fa';

import * as Io5 from 'react-icons/io5';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoBook, IoNotifications, IoArchive } from 'react-icons/io5';
import { User } from '../../../models';
import { UserType } from '../../../models';
import { TbPlugConnected } from 'react-icons/tb';
import { globals } from '../../_global/common/Utils';
import DepartmentItem from '../../../data/model/DepartmentItem';

/* 09-29-23 Arul: Created the  sidebar component globally  */
const Sidebar = ({ show, name }: any) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState('/');
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const user: User = useSelector((state: any) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.shiftKey && event.key === 'f') {
        event.preventDefault();
        navigate('/protocol');
        setSelectedLink('/protocol');
      } else if (event.metaKey && event.shiftKey && event.key === 'd') {
        event.preventDefault();
        navigate('/database');
        setSelectedLink('/database');
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/protocol-sets'))
      setSelectedLink('/protocol-sets');
    else if (location.pathname.includes('/notification'))
      setSelectedLink('/notification');
    else if (location.pathname.includes('/database'))
      setSelectedLink('/database');
    // else if(location.pathname.includes('/protocol'))
    // setSelectedLink('/protocol');
    else if (location.pathname.includes('/snapshot'))
      setSelectedLink('/snapshot');
  }, [location.pathname]);
  return (
    <div className={show ? 'sidebar active' : 'sidebar'}>
      {/* <img src={logo} alt='Logo' className='logo' /> */}
      {name !== '' && <h1 className="greeting no-select">Hi, {name}</h1>}
      {isLoggedIn &&
      user &&
      (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN) ? (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/protocol" onClick={() => setSelectedLink('/protocol')}>
                {/* <Io5.IoLayers /> */}
                {/* Protocols */}
                <FaFolderOpen />
                Folders
              </Link>
            </div>
          </li>
          {/* <li >
                        <div style={selectedLink === '/snapshot' ? { backgroundColor: "#003935", borderRadius: 10 } : {}} >
                            <Link to='/snapshot' onClick={() => setSelectedLink('/snapshot')}><FaCloudArrowUp />Snapshots</Link>
                        </div>
                    </li> */}
          <li>
            <div
              style={
                selectedLink === '/database'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/database" onClick={() => setSelectedLink('/database')}>
                <FaDatabase />
                Database
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/notification'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link
                to="/notification"
                onClick={() => setSelectedLink('/notification')}
              >
                <IoNotifications />
                Notifications
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/protocol-sets'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link
                to="/protocol-sets"
                onClick={() => setSelectedLink('/protocol-sets')}
              >
                <IoBook />
                Protocol Sets
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/archive'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/archive" onClick={() => setSelectedLink('/archive')}>
                <IoArchive />
                Archive
              </Link>
            </div>
          </li>
          {user.type === 'ADMIN' && (
            <li>
              <div
                style={
                  selectedLink === '/actions'
                    ? { backgroundColor: '#003935', borderRadius: 10 }
                    : {}
                }
              >
                <Link to="/actions" onClick={() => setSelectedLink('/actions')}>
                  <TbPlugConnected />
                  Actions
                </Link>
              </div>
            </li>
          )}
          {/* <li>
            <div
              style={
                selectedLink === '/review'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/review" onClick={() => setSelectedLink('/review')}>
                <FaUserFriends />
                Review
              </Link>
            </div>
          </li> */}

          <li>
            <div
              style={
                selectedLink === '/settings'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/settings" onClick={() => setSelectedLink('/settings')}>
                <Io5.IoSettings />
                Settings
              </Link>
            </div>
          </li>

          {/* <li>
                    <Link to='/'><FaIcons.FaHome />Home</Link>
                </li>
                <li>
                    <Link to='/assembly'><SiIcons.SiAssemblyscript />Assembly</Link>
                </li>
                <li>
                    <Link to='/devices'><MdIcons.MdDeviceHub />Devices</Link>
                </li>
                <li>
                    <Link to='/inventory'><MdIcons.MdOutlineInventory />Inventory</Link>
                </li>
                <li>
                    <Link to='/product'><FaIcons.FaProductHunt />Product</Link>
                </li>
                <li>
                    <Link to='/qms'><HiIcons.HiOutlineDocumentDuplicate />QMS</Link>
                </li>
                <li>
                    <Link to='/shipments'><FaIcons.FaShippingFast />Shipments</Link>
                </li>
                <li>
                    <Link to='/serviceability'><RiIcons.RiCustomerService2Fill />Serviceability</Link>
                </li> */}
        </ul>
      ) : (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/protocol" onClick={() => setSelectedLink('/protocol')}>
                <FaFolderOpen />
                Folders
              </Link>
            </div>
          </li>
        </ul>
      )}
      <div className="sidebar-footer">
        <p style={{ padding: 0, margin: 0, fontSize: '12px' }}>
          OneDose Admin {globals.VERSION}
        </p>
        <p style={{ padding: 0, margin: '0px 0px 10px 0px', fontSize: '12px' }}>
          © Hinckley Medical, Inc
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
