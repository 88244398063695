import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  IoArrowBack,
  IoDocuments,
  IoHeart,
  IoMedkit,
  IoWater,
} from 'react-icons/io5';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { FaBoltLightning, FaListCheck, FaSyringe } from 'react-icons/fa6';
import { GiConcentrationOrb } from 'react-icons/gi';
import { HiClipboardDocumentCheck } from 'react-icons/hi2';
const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const DatabaseMigratePage = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  /* Uncomment when the are finished */
  const actionList: any = [
    // 'Department',
    // 'User',
    // {name: 'Category-Protocols', icon: <IoDocuments />},
    // // 'Protocol',
    { name: 'Medication Doses', icon: <FaSyringe /> },
    { name: 'Infusion Doses', icon: <IoWater /> },
    { name: 'Electrical Doses', icon: <FaBoltLightning /> },
    {
      name: 'Concentrations',
      icon: <GiConcentrationOrb />,
    },
    {
      name: 'Is Restrictive',
      icon: <HiClipboardDocumentCheck />,
    },
    // // 'Infusion',
    // // 'Electrical',
    // {name: 'Equipment', icon: <IoMedkit />},
    // {name: 'Vitals', icon: <IoHeart />},
    // // 'Log',
    // {name: 'Checklist', icon: <FaListCheck />},
    // 'Ambulance',
    // 'Notification',
  ];

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (item: any) => {
    let route = item.name.toLowerCase().replaceAll(' ', '-');
    navigate(`/actions/database-migration/${route}`);
  };

  const handleBack = () => {
    navigate(`/actions`);
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Database Migration'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="grid-container">
        {actionList.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleItemClick(item)}
              style={{
                gap: '10px',
              }}
            >
              {item.icon}

              {item.name.length > 30 ? (
                <>
                  {item.name.split(' ').map((word: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="item-name"
                        style={{
                          marginBottom: '0px',
                        }}
                      >
                        {word}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="item-name">{item.name}</div>
              )}
              {/* <div className="item-count">{item.description}</div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DatabaseMigratePage;
