import React, { useState } from 'react';
import ConfirmWarningModal from '../../components/Modal/ConfirmWarningModal';
import DepartmentItem from '../../../data/model/DepartmentItem';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useSelector } from 'react-redux';
import { IoBook } from 'react-icons/io5';

/* 11-27-23 Arul: Created common Component for Notification Main Screen*/
const ProtocolSetFeatures = (props: any) => {
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const department: DepartmentItem = useSelector(
    (state: any) => state.protocol.departmentItem.department
  );

  return (
    <div className="screen-container">
      <ConfirmWarningModal
        isVisible={isSuccessModal}
        modalType={'success'}
        handleClose={() => {
          setIsSuccessModal(false);
        }}
        handleDelete={() => {
          setIsSuccessModal(false);
        }}
      />

      <ProtocolHeader
        // homeScreen={true}
        type={'protocol'}
        name={'Protocol Sets'}
        description={
          'Create and manage protocol sets for your department here.'
        }
        isCreateButton={false}
        isCreateActive={true}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 20px',
        }}
      >
        <IoBook size={300} color="#A3A3A3" />
        <h3 style={{ color: '#A3A3A3', marginTop: '20px' }}>
          Create/Manage Protocol Sets for your department here.
        </h3>
      </div>
    </div>
  );
};

export default ProtocolSetFeatures;
