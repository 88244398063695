import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import { ProgressStatus } from '../../../../models';
import SearchBar from '../../../components/Search/SearchBar';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import InfusionItem from '../../../../data/model/InfusionItem';
import { getConcentrationsArrayString } from '../../../../data/model/MedicationItem';
import NewMedicationSideout from '../../../components/SideOut/NewModelItemSideout';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { fetchDrips } from '../../../../data/functions/InfusionDB';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListInfusions = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const isEditMode = useSelector((state: any) => state.isEditMode);
  const [isCreateMedication, setIsCreateMedication] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const scrollPosition = useRef(0);
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filters, setFilters] = useState<any[]>(
    state.search ? state.search.filters : []
  );
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state.search ? state.search.categoriesFilter : []
  );
  const [infusions, setInfusions] = useState<InfusionItem[]>(
    database.infusions
  );

  const [list, setList] = useState<any[]>([]);

  /* TODO Need to redo the mappings */
  const reloadDatabase = async () => {
    const response = await fetchDrips(department, database);
    if (response.type === ResponseType.Success) {
      setInfusions(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          infusions: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = infusions.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery);
      });
    } else filteredList = infusions;
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: InfusionItem, b: InfusionItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(infusions);
  }, [searchQuery, filters, categoriesFilter, infusions]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (medication: InfusionItem) => {
    const state = {
      selectedProtocol: null,
      value: medication,
      subValue: null,
      type: 'Infusion',
      editType: 'edit',
      editMode: isEditMode,
      page: 'listInfusionsPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    setIsCreateMedication(true);
    // const state = {
    //   selectedProtocol: null,
    //   value: null,
    //   subValue: null,
    //   type: 'Infusion',
    //   editType: 'new',
    //   editMode: false,
    //   page: 'listMedicationsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  return (
    <div className="screen-container">
      <NewMedicationSideout
        type="infusion"
        isVisible={isCreateMedication}
        handleClose={() => setIsCreateMedication(false)}
      />
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Infusions: ' + list.length + ' items'}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Infusions...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div
                style={rowStyle}
                className="departmentItemNoHover"
                // onMouseEnter={() => setIsHovered(true)}
                // onMouseLeave={() => setIsHovered(false)}
              >
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Concentration</h6>
                <h6 className="departmentItemText">Modified Date</h6>
                <h6 className="departmentItemText">Modified By</h6>
                {/* {department.subDeps && <h6 className='departmentItemText'>Sub-Deps</h6>} */}
                <h6 className="departmentItemText">Doses</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: InfusionItem, index) => {
              let id = item.model?.modifiedBy;
              if (id == null) id = item.model?.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = database.users.find((user) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div
                    style={rowStyle}
                    className="departmentItem"
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                  >
                    {/* <h6 className='departmentItemText'>{item.version}</h6> */}
                    <h6 className="departmentItemText">
                      {item.name}
                      {item.status !== 'ACTIVE' && (
                        <Status status={item.status} />
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {getConcentrationsArrayString(item.concentrations)}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.model?.updatedAt
                          ? item?.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    {/* {department.subDeps && <h6 className='departmentItemText' style={{color: item.pairedDepIDs?.length ? 'black' : 'grey'}}>{item.pairedDepIDs?.length ? item.pairedDepIDs.length : '-'}</h6>} */}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item.subItems.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListInfusions;
