import ProgressBar from '@asyrafhussin/react-progress-bar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa6';
import { GoChecklist } from 'react-icons/go';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import {
  IoChevronForward,
  IoClose,
  IoNotifications,
  IoNotificationsOutline,
  IoSearch,
} from 'react-icons/io5';
import { TbBellRinging } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate } from '../../../_global/common/Utils';
import {
  filterOption,
  filterOptionDateRange,
} from '../../../_global/constants/Protocol_constants';
import ConfirmWarningModal from '../../../components/Modal/ConfirmWarningModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../store/actions';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import { Notification } from '../../../../models';
import NotificationItem from '../../../../data/model/NotificationItem';
import { NotificationType } from '../../../../API';
import { DataStore } from 'aws-amplify';
import SearchBar from '../../../components/Search/SearchBar';

/* 11-27-23 Arul: Created common Component for Notification Main Screen*/
const NotificationFeatures = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );

  const [notifications, setNotificactions] = useState(
    database && database.notifications
      ? database.notifications
      : ([] as NotificationItem[])
  );

  const [searchQuery, setSearchQuery] = useState('');

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFilterBtn, setIsFilterBtn] = useState(false);
  const [isDateBtn, setIsDateBtn] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<any>('');
  const newButtonRef: any = useRef(null);
  const dateButtonRef: any = useRef(null);

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      setNotificactions(newDB.notifications);
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  useEffect(() => {
    if (database) {
      const subscription = DataStore.observe(Notification).subscribe((msg) => {
        if (
          msg.opType === 'UPDATE' ||
          msg.opType === 'INSERT' ||
          msg.opType === 'DELETE'
        ) {
          reloadDatabase();
        }
      });
      reloadDatabase();
      return () => subscription.unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (location?.state?.isCreate) {
      setIsSuccessModal(true);
    }
  }, [location?.state]);

  /* 11-27-23 Arul: function for create new notification*/
  const handleCreate = () => {
    navigate('/create/notification');
  };
  //onrow click to navigate view page
  const handleNotificationTitleClick = (item: any, index: any) => () => {
    const value = { ...item, index: index };
    navigate('/view/notification', { state: value });
  };

  const NotificationIcon = ({ _type }: any) => {
    let type = _type as NotificationType;
    return (
      <>
        {type == NotificationType.GENERAL && <TbBellRinging size={24} />}
        {type == NotificationType.ACKNOWLEDGE && (
          <IoMdCheckmarkCircleOutline size={24} />
        )}
        {type == NotificationType.TRAINING && <GoChecklist size={24} />}
      </>
    );
  };

  const filterData = useCallback(
    (selectedOption: any) => {
      const currentDate = new Date(); // Current date
      const parseDate = (dateStr: any) => new Date(dateStr);

      switch (selectedOption) {
        case '3 Days':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              3
          );
        case '1 Week':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              7
          );
        case '2 Weeks':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              14
          );
        case '1 Month':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              30
          );
        case '3 Months':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              90
          );
        case '2023 - Dates back to the furthest date':
          const furthestDate = new Date('01/01/2023');
          return notifications.filter(
            (item: NotificationItem) =>
              parseDate(item.timestamp) >= furthestDate
          );
        default:
          return notifications;
      }
    },
    [notifications]
  );

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
    handleFilterData(selectedOptions, selectedDateRange, text);
  };

  //progress value
  const handleGetProgress = (notification: NotificationItem) => {
    const uniqueViewIds = new Set(notification.isReadIDs);
    const uniqueAckIds = new Set(notification.isAckIds);

    const validViewIds = [...uniqueViewIds].filter((id) =>
      database.users.some((user) => user.id === id)
    );
    const validAckIds = [...uniqueAckIds].filter((id) =>
      database.users.some((user) => user.id === id)
    );

    let relevantIds =
      notification.type === 'GENERAL' ? validViewIds : validAckIds;

    let percentage = Math.round(
      (relevantIds.length / database.users.length) * 100
    );

    return {
      amount: relevantIds.length,
      percentage: percentage,
    };
  };

  const handleFilterData = (
    options: any,
    dateRange: any,
    searchQuery: string
  ) => {
    let filteredNotifications =
      database && database.notifications ? [...database.notifications] : [];

    // Convert selectedOptions to uppercase for case-insensitive comparison
    const upperCaseOptions = options.map((option: any) => option.toUpperCase());

    // Filter by selected notification type
    if (upperCaseOptions.length > 0) {
      filteredNotifications = filteredNotifications.filter((notification) =>
        upperCaseOptions.includes(notification.type.toUpperCase())
      );
    }

    // Filter by selected date range
    if (dateRange && dateRange !== '') {
      const filteredByDate = filterData(dateRange);
      filteredNotifications = filteredNotifications.filter((notification) =>
        filteredByDate.includes(notification)
      );
    }

    if (searchQuery) {
      filteredNotifications = filteredNotifications.filter(
        (notification: NotificationItem) =>
          notification.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setNotificactions(filteredNotifications);
  };

  const handeleDateChange = (e: any) => {
    setSelectedDateRange(e);
    setIsDateBtn(false);
    handleFilterData(selectedOptions, e, searchQuery);
  };

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isFilterBtn, isDateBtn]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (
      !newButtonRef?.current ||
      !newButtonRef?.current.contains(e.target as Node)
    ) {
      setIsFilterBtn(false);
    }
    if (
      !dateButtonRef?.current ||
      !dateButtonRef?.current.contains(e.target as Node)
    ) {
      setIsDateBtn(false);
    }
  };

  const handleCheckboxChange = (option: any, protocol: any) => {
    let newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item: any) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    handleFilterData(newSelectedOptions, selectedDateRange, searchQuery);
  };

  useEffect(() => {
    handleFilterData(selectedOptions, selectedDateRange, searchQuery);
  }, [selectedOptions, selectedDateRange, searchQuery, database.notifications]);

  return (
    <div className="screen-container">
      <ConfirmWarningModal
        isVisible={isSuccessModal}
        modalType={'success'}
        handleClose={() => {
          setIsSuccessModal(false);
        }}
        handleDelete={() => {
          setIsSuccessModal(false);
        }}
      />

      <ProtocolHeader
        // homeScreen={true}
        type={'protocol'}
        name={'Notification'}
        description={
          'Create and manage notifications for your department here.'
        }
        isCreateButton={false}
        isCreateActive={true}
        handleCreate={handleCreate}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 20px',
        }}
      >
        <IoNotifications size={300} color="#A3A3A3" />
        <h3 style={{ color: '#A3A3A3', marginTop: '20px' }}>
          Create/Manage Notifications for your department here.
        </h3>
      </div>
    </div>
  );
};

export default NotificationFeatures;
