import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { Concentration, MedicationConcentration } from '../../../models';
import MedicationItem, {
  getConcentrationString,
  getConcentrationsArrayString,
} from '../../../data/model/MedicationItem';
import GeneralSelection from '../Selection/GeneralSelection';
import { useEffect, useMemo, useState } from 'react';
import './DeviceMockup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faChevronUp,
  faFileLines,
  faGear,
  faHouse,
  faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
import ProtocolItem from '../../../data/model/ProtocolItem';
import PatientWeight from '../../_global/common/PatientWeight';
import { Slider } from '@mui/material';

import { globals, roundToDec } from '../../_global/common/Utils';
import ElectricalItem from '../../../data/model/ElectricalItem';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

interface ElectricalMockupProps {
  parentModel: ElectricalItem;
  formik: any;
  protocol: ProtocolItem;
  weightValue: number;
}
export const ElectricalMockup: React.FC<ElectricalMockupProps> = (props) => {
  const { parentModel, formik, protocol, weightValue } = props;
  const [patientWeightValue, setPatientWeightValue] = useState<PatientWeight>(
    new PatientWeight(weightValue, false)
  );
  const department = useSelector((state: any) => state.department);

  const inRange = (weight: PatientWeight): boolean => {
    let rangeLow = formik.values.rangeLow ? formik.values.rangeLow : 0;
    let rangeHigh = formik.values.rangeHigh
      ? formik.values.rangeHigh
      : globals.MAX_VALUE;
    return rangeLow <= weight.getWeightKg() && weight.getWeightKg() < rangeHigh;
  };
  const isEstimateOnly = true;
  const [sliderValue, setSliderValue] = useState(0);
  const [isDosageCalculator, setIsDrugCalculator] = useState<boolean>(false);

  useEffect(() => {
    setPatientWeightValue(new PatientWeight(weightValue, false));
  }, [weightValue]);

  const isRangeElectrical = (): boolean => {
    let basisHigh = globals.MAX_VALUE;
    let basisValue = 0;

    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      let basisArr: string[] = formik.values.basis.split(' ', 2);
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;
    }

    return basisHigh !== globals.MAX_VALUE;
  };

  useEffect(() => {
    let dosageCalc: boolean = isRangeElectrical();
    dosageCalc =
      dosageCalc &&
      getAmount(patientWeightValue as PatientWeight) !== 'Out of Range';
    if (dosageCalc) {
      let low = getAmountLow(patientWeightValue);
      let high = getAmountHigh(patientWeightValue);
      if (low === high) dosageCalc = false;
    }
    setIsDrugCalculator(dosageCalc);
    setSliderValue(getAmountLow(patientWeightValue));
  }, [patientWeightValue, formik.values.basis]);

  const getAmount = (weight: PatientWeight): string => {
    if (!inRange(weight)) return 'Out of Range';

    try {
      let basisHigh = globals.MAX_VALUE;
      let calcUnit = '';
      let basisUnit = '';
      let fullBasisUnit = '';
      let basisValue = 0;

      let calcMaxValue = globals.MAX_VALUE;
      let calcMaxUnit = '';
      let maxDoseValue = globals.MAX_VALUE;
      let maxDoseUnit = '';

      /* Parse basis amount to a double for comparison */
      if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
        basisHigh = globals.MAX_VALUE;
      } else {
        let basisArr: string[] = formik.values.basis.split(' ', 2);
        let basisNums: string[] = basisArr[0].split('-');
        basisValue = parseFloat(basisNums[0]);
        if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
        else basisHigh = globals.MAX_VALUE;

        let unitArr = basisArr[1].split('/');
        basisUnit = unitArr[0];
      }
      let calcMaxArr: string[] = formik.values.calcMax.split(' ', 2);
      calcMaxValue = isNaN(parseFloat(calcMaxArr[0]))
        ? globals.MAX_VALUE
        : parseFloat(calcMaxArr[0]);
      calcMaxUnit = calcMaxArr[1];

      let maxDoseArr: string[] = formik.values.maxDose.split(' ', 2);
      maxDoseValue = isNaN(parseFloat(maxDoseArr[0]))
        ? globals.MAX_VALUE
        : parseFloat(maxDoseArr[0]);
      maxDoseUnit = maxDoseArr[1];

      /* First check if it a single dose or a range */
      if (basisHigh === globals.MAX_VALUE) {
        /* If there is no calculation needed return the dose amount */
        if (calcUnit == null || calcUnit.length == 0)
          return `${roundToDec(basisValue, 1).toFixed(1)} ${basisUnit}`;
        else {
          /* First calculate the dose based on the basis */
          let dose: number = roundToDec(basisValue * weight.getWeightKg(), 1);

          /* If there is a calcMax and it is not zero, then use it to limit the dose */
          if (calcMaxValue != -1.0 && calcMaxValue != globals.MAX_VALUE) {
            if (calcMaxUnit !== '' && basisUnit !== calcMaxUnit) {
              return '';
            }
            dose = roundToDec(
              Math.min(dose, calcMaxValue * weight.getWeightKg()),
              1
            );
          }

          /* If there is a maxDose and it is not zero, then use it to limit the dose */
          if (maxDoseValue != -1.0 && maxDoseValue != globals.MAX_VALUE) {
            if (maxDoseUnit !== '' && basisUnit !== maxDoseUnit) {
              return '';
            }
            dose = roundToDec(Math.min(dose, maxDoseValue), 1);
          }

          dose = Math.max(0, dose);

          /* Return the dose */
          return `${dose.toFixed(1)} ${basisUnit}`;
        }
      } else {
        /* Otherwise it is a range dose */
        /* If there is no calculation needed return the dose amount */
        if (!calcUnit || calcUnit.length == 0) {
          if (basisValue === basisHigh)
            return `${roundToDec(basisValue, 1).toFixed(1)} ${basisUnit}`;
          return `${roundToDec(basisValue, 1).toFixed(1)}-${roundToDec(basisHigh, 1).toFixed(1)} ${basisUnit}`;
        } else {
          /* First calculate the dose based on the basis low and high */
          let doseLow: number = roundToDec(
            basisValue * weight.getWeightKg(),
            1
          );
          let doseHigh: number = roundToDec(
            basisHigh * weight.getWeightKg(),
            1
          );

          /* Next check if there is a calculated max dose and check the units match, if so check to see if it is within the bounds */
          if (calcMaxValue != -1.0 && calcMaxValue != globals.MAX_VALUE) {
            if (basisUnit !== calcMaxUnit) {
              return '';
            }
            doseLow = roundToDec(
              Math.min(doseLow, calcMaxValue * weight.getWeightKg()),
              1
            );
            doseHigh = roundToDec(
              Math.min(doseHigh, calcMaxValue * weight.getWeightKg()),
              1
            );
          }

          /* Next check if there is a fixed max dose and check the units match, if so check to see if it is within the bounds */
          if (maxDoseValue != -1.0 && maxDoseValue != globals.MAX_VALUE) {
            if (basisUnit !== maxDoseUnit) {
              return '';
            }
            doseLow = roundToDec(Math.min(doseLow, maxDoseValue), 1);
            doseHigh = roundToDec(Math.min(doseHigh, maxDoseValue), 1);
          }
          doseLow = Math.max(0, doseLow);
          doseHigh = Math.max(0, doseHigh);
          /* Lastly, check if the values are the same, if so return a single value, otherwise return a range */
          if (doseLow == doseHigh) return `${doseLow.toFixed(1)} ${basisUnit}`;
          return `${doseLow.toFixed(1)}-${doseHigh.toFixed(1)} ${basisUnit}`;
        }
      }
    } catch (error) {}
    return '';
  };

  const getAmountLow = (weight: PatientWeight): number => {
    let basisHigh = globals.MAX_VALUE;
    let calcUnit = '';
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;

    let calcMaxValue = globals.MAX_VALUE;
    let calcMaxUnit = '';
    let maxDoseValue = globals.MAX_VALUE;
    let maxDoseUnit = '';

    /* Parse basis amount to a double for comparison */
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      let basisArr: string[] = formik.values.basis.split(' ', 2);
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;
      let unitArr = basisArr[1].split('/');
      basisUnit = unitArr[0];
    }

    let calcMaxArr: string[] = formik.values.calcMax.split(' ', 2);
    calcMaxValue = isNaN(parseFloat(calcMaxArr[0]))
      ? globals.MAX_VALUE
      : parseFloat(calcMaxArr[0]);
    calcMaxUnit = calcMaxArr[1];

    let maxDoseArr: string[] = formik.values.maxDose.split(' ', 2);
    maxDoseValue = isNaN(parseFloat(maxDoseArr[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseArr[0]);
    maxDoseUnit = maxDoseArr[1];

    /* First check if it is a calculated dose or a standard */
    if (calcUnit == null || calcUnit.length == 0) return basisValue;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(basisValue * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (calcMaxValue != -1.0 && calcMaxValue != globals.MAX_VALUE) {
      if (basisUnit !== calcMaxUnit) {
        return -1;
      }
      dose = roundToDec(Math.min(dose, calcMaxValue * weight.getWeightKg()), 1);
    }

    /* If there is a maxDose and it is not zero, then use it to limit the dose */
    if (maxDoseValue != -1.0 && maxDoseValue != globals.MAX_VALUE) {
      if (basisUnit !== maxDoseUnit) {
        return -1;
      }
      dose = roundToDec(Math.min(dose, maxDoseValue), 1);
    }
    return dose;
  };

  const getAmountHigh = (weight: PatientWeight): number => {
    let basisHigh = globals.MAX_VALUE;
    let calcUnit = '';
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;

    let calcMaxValue = globals.MAX_VALUE;
    let calcMaxUnit = '';
    let maxDoseValue = globals.MAX_VALUE;
    let maxDoseUnit = '';

    /* Parse basis amount to a double for comparison */
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      let basisArr: string[] = formik.values.basis.split(' ', 2);
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;
      let unitArr = basisArr[1].split('/');
      basisUnit = unitArr[0];
    }

    let calcMaxArr: string[] = formik.values.calcMax.split(' ', 2);
    calcMaxValue = isNaN(parseFloat(calcMaxArr[0]))
      ? globals.MAX_VALUE
      : parseFloat(calcMaxArr[0]);
    calcMaxUnit = calcMaxArr[1];

    let maxDoseArr: string[] = formik.values.maxDose.split(' ', 2);
    maxDoseValue = isNaN(parseFloat(maxDoseArr[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseArr[0]);
    maxDoseUnit = maxDoseArr[1];

    /* First check if it is a calculated dose or a standard */
    if (calcUnit == null || calcUnit.length == 0) return basisHigh;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(basisHigh * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (calcMaxValue != -1.0 && calcMaxValue != globals.MAX_VALUE) {
      if (basisUnit !== calcMaxUnit) {
        return -1;
      }
      dose = roundToDec(Math.min(dose, calcMaxValue * weight.getWeightKg()), 1);
    }

    /* If there is a maxDose and it is not zero, then use it to limit the dose */
    if (maxDoseValue != -1.0 && maxDoseValue != globals.MAX_VALUE) {
      if (basisUnit !== maxDoseUnit) {
        return -1;
      }
      dose = roundToDec(Math.min(dose, maxDoseValue), 1);
    }
    return dose;
  };

  const getInterval = (weight: PatientWeight): number => {
    let low: number = getAmountLow(weight);
    let high: number = getAmountHigh(weight);
    if (high - low > 10) return 1;
    else if (high - low > 5) return 0.1;
    else return 0.05;
  };

  const getBasisUnit = (): string => {
    let basisUnit = '';
    let basisArr: string[] = formik.values.basis.split(' ', 2);
    let unitArr = basisArr[1].split('/');
    basisUnit = unitArr[0];
    return basisUnit;
  };

  const handleSliderChange = (event: Event, value: any) => {
    console.log('handleSliderChange -> value', value);
    let decimals = 0;
    let interval = getInterval(patientWeightValue);
    if (interval < 1) decimals = 1;
    if (interval < 0.1) decimals = 2;
    setSliderValue(value.toFixed(decimals));
  };

  return (
    <div
      className={`accordHeader border-active`}
      style={{
        borderBottom: 'none',
        borderRadius: '8px',
        padding: '40px 12px 40px 12px',
        fontSize: '15px',
        backgroundColor: '#E0EADD',
        height: '100%',
      }}
    >
      <div
        style={{
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
        }}
      >
        <div
          style={{
            borderTopLeftRadius: '10px', // Set radius for top-left corner
            borderTopRightRadius: '10px', // Set radius for top-right corner
            padding: '10px',
            backgroundColor: (patientWeightValue as PatientWeight)
              .getColorTheme()
              .getPrimary(),
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faChevronUp} color={'white'} size={'sm'} />
          <div
            style={{
              paddingLeft: '10px',
            }}
          >
            Electrical
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: (patientWeightValue as PatientWeight)
              .getColorTheme()
              .getSecondarySelected(),
            justifyContent: 'space-between',
            borderBottom: '1px solid #d9d9d9',
            minHeight: '80px',
            paddingLeft: '4px',
            paddingRight: '4px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // marginLeft: '-8px',
              // padding: '5px',
              // width: '5%',
              justifyContent: 'center',
            }}
          >
            <FaChevronUp
              style={{
                color: 'black',
                fontSize: '18px',
                // marginLeft: '10px',
              }}
            />
          </div>
          <div
            style={{
              padding: '5px',
              width: '65%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {parentModel.name && (
              <span
                className="title-text"
                style={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {parentModel.name || ' '}
              </span>
            )}

            {formik.values.title && (
              <div>
                <div
                  style={{
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  <span className="med-large-text">{formik.values.title}</span>
                </div>
              </div>
            )}
            {formik.values.warning && (
              <span
                className="warning-text"
                style={{
                  color: '#880808',
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {formik.values.warning}
              </span>
            )}
            {formik.values.instruction && (
              <span
                className="instruction-text"
                style={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {formik.values.instruction || ' '}
              </span>
            )}
          </div>
          <div
            style={{
              padding: '5px',
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            {getAmount(patientWeightValue as PatientWeight) ? (
              <div className="dose-container solid">
                <div
                  className="dose-text"
                  style={{
                    color:
                      getAmount(patientWeightValue as PatientWeight) ===
                      'Out of Range'
                        ? 'red'
                        : 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {getAmount(patientWeightValue as PatientWeight)}
                </div>
              </div>
            ) : (
              <div className="dose-container">
                <div className="dose-text">---</div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white', // White background
            padding: '0px 10px 10px 10px',
          }}
        >
          <div
            style={{
              paddingTop: '4px',
            }}
          ></div>
          {formik.values.warning && (
            <div
              style={{
                border: '1px solid #880808',
                padding: '2px 8px 2px 8px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#880808', // Assuming colors.declineDark is red
                }}
              >
                Warning:
              </span>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: '1rem', // Adjust font size as needed
                      fontWeight: 'bold',
                    }}
                  >
                    {formik.values.warning}
                  </span>
                </div>
              </div>
            </div>
          )}

          {
            <div
              style={{
                marginTop: '4px', // theme.padding.vertical_half
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  // theme.fontSize.medium_large
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Calculation Basis
              </span>
              <div style={{ flex: 1, textAlign: 'right' }}>
                <span
                  style={
                    {
                      // theme.fontSize.medium_large
                    }
                  }
                >
                  {formik.values.basis === '+Add' ? '---' : formik.values.basis}
                </span>
              </div>
            </div>
          }
          {formik.values.maxDose && (
            <div style={{ marginTop: '4px' }}>
              {' '}
              {/* theme.padding.vertical_half */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Max Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {/* {isMaxDose ? '*Reached max dose - ' : ''} */}
                    {formik.values.maxDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.minDose && (
            <div style={{ marginTop: '4px' }}>
              {' '}
              {/* theme.padding.vertical_half */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Min Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.minDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.repeatTime && (
            <>
              <div style={{ marginTop: '4px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span
                    style={{
                      // fontSize.medium_large
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Repeat Time:
                  </span>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    <span
                      style={
                        {
                          // fontSize.medium_large
                        }
                      }
                    >
                      {formik.values.repeatTime}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {formik.values.maxTotalDose && (
            <div style={{ marginTop: '4px' }}>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Max Total Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {/* {isMaxTotalDose ? '*Reached max total dose - ' : ''} */}
                    {formik.values.maxTotalDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.title && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Title:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.title}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.instruction && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Instruction:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.instruction}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.note && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Note:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.note}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Dosage Calculator added */}
          {isDosageCalculator && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    marginTop: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      // ...styles.medLargeText,
                      // fontSize: fontSize.medium_large,
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Dosage Calculator:
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'column',
                    marginBottom: 0,
                    borderRadius: '8px',
                    padding: '4px 10px 4px 10px',
                    backgroundColor: 'rgb(255, 214, 153)',
                  }}
                >
                  {sliderValue} {getBasisUnit()}
                </div>
              </div>
              <div style={{}}>
                <div style={{ marginTop: '4px', padding: '0px 4px 0px 4px' }}>
                  <Slider
                    value={sliderValue}
                    min={getAmountLow(patientWeightValue)}
                    max={getAmountHigh(patientWeightValue)}
                    step={getInterval(patientWeightValue)}
                    onChange={handleSliderChange}
                    sx={{
                      color: '#5A8F5C', // Color of the slider
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#808080', // Color of the thumb
                        '&:hover': {
                          boxShadow: 'inherit', // Shadow on hover
                        },
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: '#5A8F5C', // Color of the track
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: '#5A8F5C', // Color of the rail
                        height: '10px', // Height of the rail
                      },
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        marginTop: -10,
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div>
                        {getAmountLow(patientWeightValue)} {getBasisUnit()}
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: 'flex-end',
                        marginTop: -10,
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div>
                        {getAmountHigh(patientWeightValue)} {getBasisUnit()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
