import React, { useEffect, useMemo, useState } from 'react';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { HiPencil } from 'react-icons/hi';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  convertToHighestTime,
  findDepartmentOwner,
  globals,
  toTitleCase,
} from '../../../../_global/common/Utils';
import { useSelector } from 'react-redux';
import { User } from '../../../../../models';
import Status from '../../../../components/ProgressStatus/ProgressStatus';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { FaLock } from 'react-icons/fa6';
import OwnerImage from '../../../../components/OwnerImage/OwnerImage';

interface InfusionDoseProps {
  dose: InfusionSubItem;
  doseIndex: number;
  showProtocol: boolean;
}
const InfusionDoseUI: React.FC<InfusionDoseProps> = (props) => {
  const { dose, doseIndex, showProtocol } = props;
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = useSelector(
    (state: any) => state?.protocol?.departmentItem.department
  );
  const departmentOwner = useMemo(() => {
    return findDepartmentOwner(department, dose);
  }, [department, dose]);
  const isDeactivated = useMemo(() => {
    if (
      dose.status === 'DEACTIVATED' &&
      dose.overrideItem &&
      dose.overrideItem.departmentID !== department.id
    )
      return true;
    return false;
  }, [dose, department]);
  const additonalFields = useMemo(() => {
    let num: number = 0;
    if (dose.title) num++;
    if (dose.warning) num++;
    if (dose.instruction) num++;
    if (dose.note) num++;
    if (dose.ageLow) num++;
    if (dose.ageHigh) num++;
    if (dose.repeatTimeSec > 0) num++;

    return num;
  }, [dose]);

  return (
    <div>
      <div className="addDoseHeader"></div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
      >
        {isDeactivated && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(242, 242, 242, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              zIndex: 1,
            }}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                display: 'flex',
                marginTop: '10px',
              }}
            >
              <FaLock
                className="table-icon"
                style={{
                  // marginBottom: '4px',
                  marginRight: '5px',
                }}
                color="#A3A3A3"
              />
              <Status
                status="DEACTIVATED"
                textStyle={{
                  fontSize: '18px',
                }}
              />
            </div>
          </div>
        )}
        <div className="dose-header">
          <span className="ketamine-general-label">
            Dose {doseIndex}
            {showProtocol ? ' - ' + dose.parentProtocol.name : ''}
          </span>
          <span>
            <span
              style={{
                // marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Status status={dose.status} />
              {departmentOwner && (
                <OwnerImage
                  style={{
                    padding: 0,
                    marginRight: '-10px',
                  }}
                  owner={departmentOwner}
                />
              )}
            </span>
          </span>
        </div>
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <LiaWeightHangingSolid
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Weight Low (kg)
                </label>
                <div className="p-input-icon-right" data-testid="editWeightLow">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="weightLow"
                    name="weightLow"
                    placeholder="Min"
                    value={dose.rangeLow > 0 ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>

              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Weight High (kg)
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="weightHigh"
                    name="weightHigh"
                    placeholder="Max"
                    data-testid="weightHigh"
                    value={
                      dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>

              {/* {dose.ageLow && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age Low
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="ageLow"
                      name="ageLow"
                      data-testid="ageLow"
                      placeholder="Max"
                      value={
                        dose.ageLow
                          ? dose.ageLow.age + ' ' + dose.ageLow.units
                          : ''
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {dose.ageHigh && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age High
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="ageHigh"
                      name="ageHigh"
                      data-testid="ageHigh"
                      placeholder="Max"
                      value={
                        dose.ageHigh
                          ? dose.ageHigh.age + ' ' + dose.ageHigh.units
                          : ''
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )} */}
              {/* {dose.repeatTimeSec > 0 && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Repeat Time
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="repeatTime"
                      name="repeatTime"
                      data-testid="repeatTime"
                      placeholder="Optional..."
                      value={convertToHighestTime(dose.repeatTimeSec) + ''}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )} */}
            </div>
            <div
              className=""
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  marginRight: '20px',
                }}
              >
                <PiSyringe
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 2,
                }}
              >
                <label className="input-label">Calculation Basis</label>
                <div className={`p-input-icon-right`}>
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      dose.model.basis === '-1' ? 'EMERGENCY' : dose.model.basis
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* {dose.minDose > 0 && ( */}
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Max Calc
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="minDose"
                    name="minDose"
                    data-testid="minDose"
                    placeholder="Min"
                    value={
                      dose.calcMax !== globals.MAX_VALUE
                        ? dose.calcMax +
                          ' ' +
                          dose.maxDoseUnit +
                          '/' +
                          dose.calcUnit +
                          '/' +
                          dose.timeUnit
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* )} */}
              {/* {dose.maxDose !== globals.MAX_VALUE && ( */}
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Max Total
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={
                      dose.maxTotalDose !== globals.MAX_VALUE
                        ? dose.maxTotalDose +
                          ' ' +
                          dose.maxDoseUnit +
                          '/' +
                          dose.timeUnit
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* )} */}
              {/* {dose.maxTotalDose !== globals.MAX_VALUE && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Max Total Dose
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="maxTotalDose"
                      name="maxTotalDose"
                      data-testid="maxTotalDose"
                      placeholder="Max"
                      value={
                        dose.maxTotalDose
                          ? dose.maxTotalDose + ' ' + dose.maxDoseUnit
                          : ''
                      }
                      readOnly={true}
                      // disabled={true}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {/* <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${!dose.rangeLow ? 'greyText' : ''}`}
                    id="rangeLow"
                    name="rangeLow"
                    placeholder="Min"
                    value={dose.rangeLow ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor={''}
                  className={` ketamine-label cursorPointerDiv`}
                >
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${Number(dose.rangeLow) === globals.MAX_VALUE ? 'greyText' : ''}`}
                    id="rangeHigh"
                    name="rangeHigh"
                    placeholder="Max"
                    data-testid="rangeHigh"
                    value={
                      dose.rangeHigh && dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <PiSyringe className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Calculation Basis
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      dose.range.basis === '-1' ? 'EMERGENCY' : dose.range.basis
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Min Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="minDose"
                    name="minDose"
                    placeholder="Min"
                    data-testid="minDose"
                    value={dose.range.minDose ? dose.range.minDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Max Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={dose.range.maxDose ? dose.range.maxDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div> */}
          {dose.model.basis !== '-1' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                marginTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <label htmlFor={''} className={` sidebarLabel`}>
                Route(s):
              </label>
              <div data-div-id="outside" className={`selectionArea-flex`}>
                {dose.routes.length !== 0 && (
                  <ViewportList items={dose.routes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {user.type === 'ADMIN' && dose.nemsisRoutes.length !== 0 && (
                  <ViewportList items={dose.nemsisRoutes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item.route + ' (' + item.code + ')'}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
              </div>
            </div>
          )}
          {additonalFields > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
                marginTop: '-6px',
                cursor: 'pointer',
              }}
            >
              <p className="sidebarText-hover">
                {additonalFields} more advanced fields {'>'}{' '}
              </p>
            </div>
          )}
        </div>

        {/* <div className={` ${'sideoutFieldPad'}`}>
          {dose.title && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Title" className={`sidebarLabel `}>
                Title:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder="Enter Title here..."
                  value={dose.title}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.warning && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Warning" className={`sidebarLabel`}>
                Warning:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="warning"
                  name="warning"
                  data-testid="warning"
                  placeholder="Enter Warning here..."
                  value={dose.warning}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.instruction && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="instructions" className={`sidebarLabel`}>
                Instructions:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="instruction"
                  name="instruction"
                  data-testid="instruction"
                  placeholder="Add Instructions here..."
                  value={dose.instruction}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.note && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Note" className={`sidebarLabel`}>
                Note:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general`}
                  id="note"
                  name="note"
                  data-testid="note"
                  placeholder="Enter Note here..."
                  value={dose.note}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};
export default InfusionDoseUI;
