import React, { useEffect, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiSolidRightArrow, BiSolidCopy } from 'react-icons/bi';
import {
  FaDatabase,
  FaFileImport,
  FaShareNodes,
  FaSitemap,
} from 'react-icons/fa6';
import { ImListNumbered, ImTarget } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { FaNode } from 'react-icons/fa';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useSelector } from 'react-redux';
import { User } from '../../../models';
import { IoLockClosed } from 'react-icons/io5';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { MdAddBusiness } from 'react-icons/md';
import { WiStars } from 'react-icons/wi';
import { TbDatabaseExport, TbExchange } from 'react-icons/tb';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ActionsPage = (props: any) => {
  const navigate = useNavigate();
  const user: User = useSelector((state: any) => state?.user);

  const actionList = [
    { name: 'Copy', icon: <BiSolidCopy />, length: 5 },
    { name: 'IDs', icon: <ImTarget />, length: 1 },
    { name: 'Import', icon: <FaFileImport />, length: 2 },
    { name: 'Sub-Departments', icon: <FaShareNodes />, length: 3 },
    { name: 'Encryption', icon: <IoLockClosed />, length: 1 },
    { name: 'Audit', icon: <AiOutlineFileSearch />, length: 1 },
    { name: 'Create Department', icon: <MdAddBusiness />, length: 1 },
    { name: 'AI PDF Parser', icon: <WiStars />, length: 1 },
    { name: 'Hinckley Database', icon: <FaDatabase />, length: 1 },
    { name: 'Database Migrate', icon: <TbExchange />, length: 5 },
    { name: 'Models', icon: <FaSitemap />, length: 1 },
    { name: 'Export', icon: <TbDatabaseExport />, length: 1 },
  ];

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (item: any) => {
    let clean = item.name.toLowerCase().replace(/ /g, '-');
    navigate(`/actions/${clean}`);
  };

  if (!user || user.type !== 'ADMIN') {
    navigate('/');
    return null;
  }

  return (
    <div className="screen-container">
      <ProtocolHeader
        homeScreen={true}
        page={'Actions'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
      />
      <div className="grid-container">
        {actionList.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleItemClick(item)}
            >
              <div className="item-name">{item.name}</div>
              {item.icon}
              <div className="item-count">{item.length} items</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActionsPage;
