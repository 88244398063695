import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  getAllWorkbooks,
} from '../../../data/AmplifyDB';
import { User, Workbook } from '../../../models';
import WorkbookItem from '../../../data/model/WorkbookItem';
import ProtocolHeader from '../protocol/ProtocolHeader';
import SearchBar from '../../components/Search/SearchBar';
import { ProgressStatus } from '../../../models';
import {
  CalculateByteSting,
  getFormattedDate,
  globals,
} from '../../_global/common/Utils';
import { TbDatabaseOff } from 'react-icons/tb';
import { MdCreateNewFolder } from 'react-icons/md';
import ProtocolSetFeatures from './ProtocolSetFeatures';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ProtocolSetList = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);
  const department = database.department;
  const user: User = useSelector((state: any) => state.user);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state && state.search ? state.search.searchQuery : ''
  );
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [list, setList] = useState<WorkbookItem[]>([]);

  const getDetails = useCallback(async () => {
    const result: Response = await getAllWorkbooks(department.id);
    if (result.type === ResponseType.Success) {
      setWorkbookList(result.data);
      setList(result.data);
    } else if (globals.debug)
      console.log('Error getting Workbooks', result.data);
  }, [department.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails, department.id]);

  useEffect(() => {
    if (sort) {
      const sortedList = list.sort((a, b) => {
        if (sort === 'name_desc') return a.name.localeCompare(b.name);
        else if (sort === 'modified_asc') {
          if (!a.model.updatedAt || !b.model.updatedAt) return 0;
          const aDate = new Date(a.model.updatedAt);
          const bDate = new Date(b.model.updatedAt);
          return aDate.getTime() - bDate.getTime();
        } else if (sort === 'modified_desc') {
          if (!a.model.updatedAt || !b.model.updatedAt) return 0;
          const aDate = new Date(a.model.updatedAt);
          const bDate = new Date(b.model.updatedAt);
          return bDate.getTime() - aDate.getTime();
        } else if (sort === 'file_size_asc') return a.fileSize - b.fileSize;
        else if (sort === 'file_size_desc') return b.fileSize - a.fileSize;

        return b.name.localeCompare(a.name);
      });
      setList(sortedList);
    }
  }, [sort]);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    const filteredList = workbookList.filter((item: WorkbookItem) => {
      return item.name.toLowerCase().includes(query);
    });
    setList(filteredList);
  };

  const handleItemClick = (wb: WorkbookItem) => {
    const state = {
      list: workbookList,
      selectedItem: wb,
      searchState: {
        searchQuery: searchQuery,
      },
    };
    navigate(`/protocol-sets/edit`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      list: workbookList,
      searchState: {
        searchQuery: searchQuery,
      },
    };
    navigate(`/protocol-sets/new`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '3fr 2fr 2fr 1fr' + (user.type === 'ADMIN' ? ' 1fr' : ''), // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  return adminLevel < 3 ? (
    <ProtocolSetFeatures />
  ) : (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        handleCancel={handleBack}
        name={'Protocol Sets: ' + list.length + ' items'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <SearchBar
        containerStyle={{ width: '60%' }}
        value={searchQuery}
        onChange={(searchTerm: string) => {
          handleSearchChange(searchTerm);
        }}
        onSubmit={(searchTerm: string) => {}}
        placeholder={'Search Protocol Sets...'}
      />

      <div>
        <div className="accordion-container">
          <div
          // style={{borderBottom: '1px solid #ccc'}}>
          >
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Modified By</h6>
                {user.type === 'ADMIN' && (
                  <h6 className="departmentItemText">AI Parsed</h6>
                )}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('file_size')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'file_size_desc') setSort('file_size_asc');
                    else setSort('file_size_desc');
                  }}
                >
                  File Size
                  <span>
                    {sort === 'file_size_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
              </div>
              <hr style={{ margin: 0, padding: 0 }} />
            </Accordion>
          </div>
          {workbookList.length == 0 && (
            <div className="no-data-container" style={{ marginTop: '15vh' }}>
              <MdCreateNewFolder size={220} className="light-grey-icon" />
              <h4 className="light-grey-icon">No workbooks created...</h4>
            </div>
          )}

          <ViewportList items={list}>
            {(item: WorkbookItem, index) => {
              let username = item.modifiedBy
                ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                : 'Hinckley Medical';
              return (
                <div
                  key={index}
                  style={{
                    borderBottom:
                      index !== list.length - 1 ? '1px solid #ccc' : 'none',
                  }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item.model?.updatedAt
                          ? item?.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    {user.type === 'ADMIN' && (
                      <h6 className="departmentItemText">
                        {item.aiPdfParserResults != null ? 'True' : '-'}
                      </h6>
                    )}
                    {/* {department.subDeps && <h6 className='departmentItemText' style={{color: item.pairedDepIDs?.length ? 'black' : 'grey'}}>{item.pairedDepIDs?.length ? item.pairedDepIDs.length : '-'}</h6>} */}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {CalculateByteSting(item.fileSize)}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ProtocolSetList;
