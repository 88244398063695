import React from 'react';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiPencil } from 'react-icons/bi';

type item = {
  value: any;
  label: string;
  icon?: any;
};

interface DropdownProps {
  value: any;
  options: item[];
  onChange: (item: any) => void;
  icon?: any;
  style?: React.CSSProperties;
  buttonColor?: string;
  textColor?: string;
}

/* 09-28-23 Arul: Created the global Accordion component to display the protocol list  */
const IconDropdown: React.FC<DropdownProps> = (props) => {
  const { value, options, onChange, style, buttonColor, textColor } = props;

  const findLabel = (value: any) => {
    const item = options.find((item) => item.value === value);
    return item ? item.label : '';
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ ...style }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<IoChevronDown />}
        autoCapitalize="none"
        style={{
          background: buttonColor ? buttonColor : '#00534C',
          color: textColor ? textColor : 'white',
          fontFamily: 'Titillium Web, sans-serif',
          fontWeight: '500',
          minWidth: '10px',
          margin: '0px 0 0 0',
          padding: '8px',
        }}
      >
        {props.icon ? props.icon : <IoChevronDown />}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item: any) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleClose();
              onChange(item.value);
            }}
          >
            {item.icon}
            {item.label}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
};

export default IconDropdown;
