import { DataStore } from 'aws-amplify';
import { contactsByDepartmentID } from '../../graphql/queries';
import DepartmentItem from '../model/DepartmentItem';
import { Contact } from '../../models';
import { executeQuery, Response, ResponseType } from '../AmplifyDB';
import ContactItem from '../model/ContactItem';

export const fetchContacts = async (
  department: DepartmentItem,
  userDataStore: boolean = true
): Promise<Response> => {
  try {
    let contactsList: any[];
    /* If it is a sub department query by the ID in the pairedDepartments array */
    // if (department.subDeps && department.subDeps.length > 0)
    // 	contactsList = await DataStore.query(Contact, (c) =>
    // 		c.pairedDepIDs.contains(department.id)
    // 	);
    /* Otherwise query by the department ID owner - This is needed because the umbrella department will own ALL the contacts */
    if (userDataStore) {
      contactsList = await DataStore.query(Contact, (c) =>
        c.departmentID.eq(department.id)
      );
    } else {
      contactsList = await executeQuery(contactsByDepartmentID, {
        departmentID: department.id,
        filter: {
          status: { ne: 'DELETED' },
        },
      });
    }
    /* Group all the contacts by their title then only have the first title display */

    /* Sort all contacts by their title then by their index */
    contactsList.sort((a: Contact, b: Contact) => {
      if (a.title === b.title) return a.index - b.index;
      else if (a.title && b.title) return a.title.localeCompare(b.title);
      else return a.fullName.localeCompare(b.fullName);
    });

    /* Go through the list and create the ContactItem objects */
    if (contactsList.length === 0)
      return {
        type: ResponseType.Success,
        data: [],
      };

    let contacts = [];
    let title = '';
    let isTitle = false;
    for (let i = 0; i < contactsList.length; i++) {
      let c: Contact = contactsList[i];
      /* If the title is not the same as the previous title then make it a title */
      if (c.title && c.title !== title) {
        title = c.title;
        isTitle = true;
      } else isTitle = false;

      contacts.push(new ContactItem(contactsList[i], isTitle));
    }

    // contactsList.sort((a: Contact, b: Contact) => a.fullName.localeCompare(b.fullName));
    return {
      type: ResponseType.Success,
      data: contacts,
    };
  } catch (error) {
    console.error('Error fetching contacts:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createContact = async (contact: any): Promise<Response> => {
  try {
    let depIDs = contact.pairedDeps
      ? contact.pairedDeps.map((d: any) => d.id)
      : [];
    let a = await DataStore.save(
      new Contact({
        fullName: contact.fullName,
        number: contact.number,
        index: Number(contact.index),
        note: contact.note,
        departmentID: contact.departmentID,
        pairedDepIDs: depIDs,
        title: contact.title,
      })
    );
    return {
      type: ResponseType.Success,
      data: a,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const editContact = async (contact: any): Promise<Response> => {
  try {
    let a = await DataStore.query(Contact, contact.id);
    if (!a)
      return {
        type: ResponseType.Failure,
        data: 'Contact not found',
      };
    let ids = contact.pairedDeps
      ? contact.pairedDeps.map((d: any) => d.id)
      : [];
    let res = await DataStore.save(
      Contact.copyOf(a, (updated) => {
        updated.fullName = contact.fullName;
        updated.number = contact.number;
        updated.note = contact.note;
        updated.index = Number(contact.index);
        updated.departmentID = contact.departmentID;
        updated.pairedDepIDs = ids;
        updated.title = contact.title;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteContact = async (
  contact: Contact | null
): Promise<Response> => {
  if (!contact)
    return {
      type: ResponseType.Failure,
      data: 'Contact not found',
    };
  try {
    const toDelete = await DataStore.query(Contact, contact.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Contact not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
