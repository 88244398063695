import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  ResponseType,
  createDepartment,
  fetchChecklists,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import {
  addDepartmentIDToAllReferences,
  copyChecklistsFromDeptoDep,
  copyElectricalFromDeptoDep,
  copyEquipmentFromDeptoDep,
  copyVitalsFromDeptoDep,
} from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin, getSalt } from '../../../../_global/common/Encrypt';
import { IoLockClosed } from 'react-icons/io5';
import { BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { USAStatesAbrev, toTitleCase } from '../../../../_global/common/Utils';
import { InputSwitch } from 'primereact/inputswitch';
import { DataStore } from 'aws-amplify';
import { Department } from '../../../../../models';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const DepartmentPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [hinckleyDepartment, setHinckleyDepartment] = useState<Department>();
  const [isSubDepartment, setIsSubDepartment] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [isAskAddID, setIsAskAddID] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      location: 'MN',
      uniqueCode: '',
      hashedPin: '',
      saltedPin: '',
      isPublic: false,
      parentDep: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      location: Yup.string().required(),
      uniqueCode: Yup.string().required(),
      hashedPin: Yup.string().required(),
      saltedPin: Yup.string().required(),
      isPublic: Yup.boolean().notRequired(),
      parentDep: Yup.object().notRequired(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleCreateDepartment = async (isSubAndAddIDs: boolean) => {
    try {
      if (!formik.dirty && !formik.isValid) return;
      let values = formik.values;
      let response = await createDepartment(values);
      if (response.type === ResponseType.Success) {
        let dep: Department = response.data;

        if (isSubAndAddIDs && formik.values.parentDep && isSubDepartment) {
          console.log(
            'Adding the sub-department id to all ModelItems in the parent department'
          );
          let parentDep = formik.values.parentDep as DepartmentItem;
          let resp = await addDepartmentIDToAllReferences(parentDep, dep.id);
          if (resp.type === ResponseType.Success) {
            alert(
              'Added sub-department ID to all references in parent department: ' +
                resp.data
            );
          }
        }

        /* Create the Electrical, Vitals, and Equipment items from Hinckley Database */
        if (hinckleyDepartment && !isSubDepartment) {
          let promises = [
            copyEquipmentFromDeptoDep(hinckleyDepartment, dep),
            copyVitalsFromDeptoDep(hinckleyDepartment, dep),
            copyElectricalFromDeptoDep(hinckleyDepartment, dep),
          ];
          let [equipNum, vitalNum, elecNum] = await Promise.all(promises);
          alert(
            `Copied ${equipNum.data} equipment, ${vitalNum.data} vitals, and ${elecNum.data} electrical items from Hinckley DB`
          );
        } else {
          alert('Department created successfully');
        }

        navigate('/actions');
      } else {
        alert('Error creating department');
      }
    } catch (error) {
      console.error('Error creating department:', error);
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      let resp = await getDepartments();
      if (resp.type === ResponseType.Success) setDepartmentList(resp.data);
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchHMDB = async () => {
      const db = await DataStore.query(Department, HM_DB_ID);
      if (db) setHinckleyDepartment(db);
    };
    fetchHMDB();
  }, []);

  const handleBack = () => {
    navigate('/actions');
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const generateCodes = () => {
    //Genereate 6 digit code
    let code = Math.floor(100000 + Math.random() * 900000);
    formik.setFieldValue('uniqueCode', code.toString());

    let _pin = '' + (pin ? pin : Math.floor(1000 + Math.random() * 9000));
    let _salt = getSalt();

    setPin(_pin);
    formik.setFieldValue('hashedPin', getHashedPin(_pin, _salt));
    formik.setFieldValue('saltedPin', _salt);
  };

  const isSaveValid = useMemo(() => {
    console.log(formik.isValid, formik.dirty, formik.values);
    return formik.isValid && formik.dirty;
  }, [formik]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Create Department Page'}
        description={'Create a new parent department or sub-department'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={false}
        isSaveButton={false}
        isCreateButton={true}
        isCreateActive={isSaveValid}
        type={'protocol'}
        handleCancel={handleBack}
        handleCreate={() => {
          if (isSubDepartment && formik.values.parentDep) {
            setIsAskAddID(true);
          } else if (!isSubDepartment) {
            handleCreateDepartment(false);
          }
        }}
      />
      <ConfirmModal
        isVisible={isAskAddID}
        title={
          'Do you want to have this new sub-department subscribe to all the references from ' +
          (formik.values.parentDep as DepartmentItem | null)?.name +
          '?'
        }
        handleClose={() => {
          setIsAskAddID(false);
        }}
        handleSubmit={() => {
          setIsAskAddID(false);
          handleCreateDepartment(true);
        }}
        isSingleBtn={true}
        primaryBtnName={'Cancel'}
        secondaryBtnName="Add"
        primaryDescription={
          'This will make the new department subscribe to all the references from ' +
          (formik.values.parentDep as DepartmentItem | null)?.name +
          ' including checklists, vitals, and equipment.'
        }
      />

      <Row>
        <Col sm={6}>
          <label
            htmlFor="isSubDepartment"
            className="ketamine-general-label"
            style={{ flexDirection: 'row' }}
          >
            Is Sub-Department:
            <InputSwitch
              name="isSubDepartment"
              style={{ marginLeft: '10px' }}
              checked={isSubDepartment}
              onChange={(e) => {
                setIsSubDepartment(e.value);
                if (!e.value) formik.setFieldValue('parentDep', null);
              }}
            />
          </label>
          {isSubDepartment && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Parent Department
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={false}
                onChange={(option: DepartmentItem) =>
                  // handleAddDepartment(option)
                  formik.setFieldValue('parentDep', option)
                }
                onClear={() => formik.setFieldValue('parentDep', null)}
                placeholder="Search department..."
              />
            </>
          )}
          {/* Left column */}
          <label htmlFor="Location" className="ketamine-general-label">
            Location
          </label>
          <Dropdown
            value={formik.values.location}
            options={USAStatesAbrev}
            onChange={(value: any) => {
              formik.setFieldValue('location', value);
            }}
            style={{
              marginTop: '-13px',
              marginLeft: '16px',
              padding: 0,
            }}
            buttonColor={'#E0EADD'}
            textColor={'#00534C'}
          />
          <label
            htmlFor="isSolidOnly"
            className="ketamine-general-label"
            style={{ flexDirection: 'row' }}
          >
            Is Public:
            <InputSwitch
              name="isSolidOnly"
              style={{ marginLeft: '10px' }}
              checked={formik.values.isPublic}
              onChange={(e) => {
                formik.setFieldValue('isPublic', e.value);
              }}
            />
          </label>

          <label htmlFor="Name" className="ketamine-general-label">
            Name
            <span className="required-field ">
              *
              <BiRename
                className="refresh-icon"
                onClick={() => {
                  formik.setFieldValue('name', toTitleCase(formik.values.name));
                }}
                size="1.25rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Pin:
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Optional to set the pin
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              name="pin"
              data-testid="pin"
              required={true}
              value={pin}
              onChange={(e: any) => {
                setPin(e.target.value);
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div>
          {/* <label htmlFor="Name" className="ketamine-general-label">
            Logo ID:
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Optional to set the logo
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              name="pin"
              data-testid="pin"
              required={true}
              value={formik.values.logoID}
              onChange={(e: any) => {
                formik.setFieldValue('logoID', e.target.value);
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Cognito ID:
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Optional to set the cognito ID
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              name="pin"
              data-testid="pin"
              required={true}
              value={formik.values.cognitoID}
              onChange={(e: any) => {
                formik.setFieldValue('cognitoID', e.target.value);
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div> */}

          <Button
            data-testid="set-Bttn"
            className="primary-button btn-rightMargin"
            disabled={false}
            style={
              {
                // marginTop: '3rem',
              }
            }
            onClick={() => {
              generateCodes();
            }}
          >
            Generate
          </Button>
        </Col>
        <Col sm={6}>
          {formik.values.parentDep != null && (
            <div>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Parent Sub-Departments:{' '}
                {(formik.values.parentDep as DepartmentItem).subDepIDs.length}
              </label>
              <div
                className="list-container"
                style={{
                  border: '1px solid #E0EADD',
                  borderRadius: '5px',
                }}
              >
                <ViewportList
                  items={
                    (formik.values.parentDep as DepartmentItem).subDeps || []
                  }
                >
                  {(item: any, index: any) => (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderBottom: '1px solid #E0EADD',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            marginRight: '10px',
                            padding: '5px',
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
          {formik.values.hashedPin !== '' && (
            <div>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Hashed Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.hashedPin}
                {isCopied !== formik.values.hashedPin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.hashedPin, e)}
                  />
                )}
                {isCopied === formik.values.hashedPin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Salt Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.saltedPin}
                {isCopied !== formik.values.saltedPin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.saltedPin, e)}
                  />
                )}
                {isCopied === formik.values.saltedPin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Unique Code:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.uniqueCode}
                {isCopied !== formik.values.uniqueCode && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.uniqueCode, e)}
                  />
                )}
                {isCopied === formik.values.uniqueCode && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {pin}
                {isCopied !== pin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(pin, e)}
                  />
                )}
                {isCopied === pin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentPage;
