import { isObjectEqual } from './Utils'; // Assuming you have a Utils.ts file with the roundToDec function
import { getWeightFromAge } from './PatientWeight';

class PatientAge {
  ageValue: number;
  age: number;
  units: string;

  /**
   * Creates a new PatientAge object
   * @param ageInMonths The age in months of the patient in int format
   *  - 1 month to 11 months: 1-11
   *  - 1 year to 14 years: 12-168
   *  - >14 years: ADULT
   */
  constructor(ageInMonths: number) {
    this.ageValue = ageInMonths;
    this.age = this.createAge(ageInMonths);
    this.units = this.createAgeUnits(ageInMonths);
  }

  createAge(ageInMonths: number): number {
    if (ageInMonths <= 0) return 0;
    if (ageInMonths > 0 && ageInMonths < 12) return ageInMonths;
    else return Math.floor(ageInMonths / 12);
  }

  createAgeUnits(age: number): string {
    if (age < 12) return 'Mo';
    else return 'Yr';
  }

  toString(): string {
    return `PatientAge -> {
              age: ${this.age},
              units: ${this.units},
              value: ${this.ageValue}
        }`;
  }
}

export default PatientAge;
