import { useSelector } from 'react-redux';
import MedicationSubItem, {
  getRoutesString,
} from '../../../../../data/model/MedicationSubItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { User } from '../../../../../models';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import MedicationItem from '../../../../../data/model/MedicationItem';
import { UserType } from '../../../../../models';
import { ViewportList } from 'react-viewport-list';
import { IoChevronForward, IoMenu } from 'react-icons/io5';
import Status from '../../../../components/ProgressStatus/ProgressStatus';
import { globals, hasAdminUserAccess } from '../../../../_global/common/Utils';
import DepartmentItem from '../../../../../data/model/DepartmentItem';

interface AccordionProps {
  onItemClick: (item: MedicationSubItem, state?: any) => void;
  onReorder: (items: MedicationSubItem[]) => void;
  isEditMode: boolean;
  protocol: ProtocolItem;
  medicationList: MedicationSubItem[];
}

/* 10-02-23 Arul: Created ProtocolAccordionSecondLayer component  for calculation page*/
const MedicationAccordion: React.FC<AccordionProps> = ({
  isEditMode,
  protocol,
  medicationList,
  onReorder,
  onItemClick,
}) => {
  const user: User = useSelector((state: any) => state.user);
  const [items, setItems] = useState<MedicationSubItem[]>(
    medicationList.sort((m1: MedicationSubItem, m2: MedicationSubItem) => {
      if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
      return m1.index - m2.index;
    })
  );
  const navigate = useNavigate();
  const isLandscape = window.innerWidth > window.innerHeight;
  const department: DepartmentItem = useSelector(
    (state: any) => state.protocol.departmentItem.department
  );

  const reorder = (
    list: MedicationSubItem[],
    startIndex: number,
    endIndex: number
  ): MedicationSubItem[] => {
    // Determine if the item being dragged or the target is in a locked area
    const isStartLocked = hasAdminUserAccess(user, list[startIndex]);
    const isEndLocked = hasAdminUserAccess(user, list[endIndex]);

    // Prevent dragging an unlocked item into a locked area
    if (isStartLocked && !isEndLocked) return list;
    // let departmentOwner = findDepartmentOwner(department, list[startIndex]);

    //Verify the owner of the items are grouped together
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    setItems(
      medicationList.sort((m1: MedicationSubItem, m2: MedicationSubItem) => {
        if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
        return m1.index - m2.index;
      })
    );
  }, [medicationList]);

  // function for dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(updatedItems);
    onReorder(updatedItems);
  };

  function formattedNumber(value: number) {
    if (value <= 0) return 'min';
    else if (value === globals.MAX_VALUE) return 'max';
    return value.toString();
  }

  // navigate to protocol edit page
  const handleNavigate = (parent: MedicationItem, child: MedicationSubItem) => {
    if (
      user &&
      user.type !== UserType.ADMIN &&
      user.type !== UserType.DEPT_ADMIN
    )
      return;
    const state = {
      selectedProtocol: protocol,
      value: parent,
      subValue: child,
      type: 'Medication',
      editType: 'edit',
      editMode: isEditMode,
      page: 'protocolDetailPage',
    };
    onItemClick(child, state);
    // navigate(`/protocol/edit/edit-protocol`, { state });
  };

  function cleanInstructions(instructions: string) {
    const value = isLandscape ? 100 : 60;
    if (instructions && instructions.length > value) {
      return instructions.substring(0, value) + '...';
    }
    return instructions;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="listItem"
          >
            <ViewportList items={items}>
              {(item: MedicationSubItem, index: number) => {
                let parent = item.parent;
                const isDeactivated =
                  item.status === 'DELETED' &&
                  item.overrideItem &&
                  item.overrideItem.departmentID !== department.id
                    ? true
                    : false;
                return (
                  <Draggable
                    key={item.name + '-' + index}
                    draggableId={item.name + '-' + index}
                    isDragDisabled={!isEditMode}
                    index={index}
                  >
                    {(provided: any) => (
                      <div
                        className={`cursorPointer listContainer ${
                          index % 2 === 0 ? 'listBackground' : ''
                        }`}
                      >
                        <li
                          key={index}
                          onClick={() => handleNavigate(parent, item)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="listContent"
                        >
                          <div className="a1SubITems">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 0,
                                  padding: 0,
                                  alignItems: 'center', // Center the text vertically
                                  overflow: 'hidden', // Hide overflow
                                  textOverflow: 'ellipsis', // Render an ellipsis ('...') to represent clipped text
                                  whiteSpace: 'nowrap', // Keep text on a single line
                                }}
                              >
                                {(item.title || item.warning) && (
                                  <h6
                                    className={`medication-title ${item.warning ? 'warning' : ''}`}
                                  >
                                    {item.warning ? item.warning : item.title}
                                  </h6>
                                )}
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                {isEditMode && (
                                  <div
                                    {...provided.dragHandleProps}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      // alignContent: 'center',
                                      // marginRight: '8px',
                                    }}
                                  >
                                    <IoMenu
                                      size="1.5rem"
                                      style={{ marginRight: '8px' }}
                                    />{' '}
                                  </div>
                                )}
                                <h6 className="departmentItemText">
                                  {globals.debug ? `${item.index} - ` : ''}
                                  {item.name}
                                  {item.rangeLow > 0 ||
                                  item.rangeHigh !== globals.MAX_VALUE
                                    ? ` [${formattedNumber(item.rangeLow)} - ${formattedNumber(item.rangeHigh)}] kg`
                                    : ''}
                                  {item.status !== 'ACTIVE' && (
                                    <Status status={item.status} />
                                  )}
                                </h6>
                                <div
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // justifyContent: 'space-between',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <div
                                    className="a1type"
                                    style={{
                                      borderRight: '1.75px solid #a0a0a0',
                                      // borderLeft: '1.5px solid #a0a0a0',
                                      paddingRight: '12px',
                                      // paddingLeft: '12px',
                                    }}
                                  >
                                    {item.model.basis}
                                  </div>
                                  <div
                                    className="a1type"
                                    style={{
                                      marginLeft: '12px',
                                    }}
                                  >
                                    {getRoutesString(item)}
                                    <span>
                                      <IoChevronForward
                                        size="1.5rem"
                                        style={{ marginLeft: '10px' }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {item.instruction && (
                                <div
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <h6
                                    className={`medication-description ${item.instruction ? '' : 'transparent'}`}
                                  >
                                    {item.instruction
                                      ? cleanInstructions(item.instruction)
                                      : 'A'}
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                        </li>
                      </div>
                    )}
                  </Draggable>
                );
              }}
            </ViewportList>
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MedicationAccordion;
