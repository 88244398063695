import { InputText } from 'primereact/inputtext';
import React, { useMemo } from 'react';
import { AiOutlineCloseCircle, AiOutlineThunderbolt } from 'react-icons/ai';
import { HiPencil } from 'react-icons/hi';
import { LiaHeartbeatSolid, LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import vitalAgeImage from '../../../assets/images/vitalAgeImage.png';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import InfusionItem from '../../../../../data/model/InfusionItem';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import { globals } from '../../../../_global/common/Utils';
import {
  FaArrowRightArrowLeft,
  FaArrowsLeftRightToLine,
  FaSyringe,
} from 'react-icons/fa6';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { FaInfoCircle } from 'react-icons/fa';
import { InputSwitch } from 'primereact/inputswitch';

interface DoseSelectionProps {
  doseIndex: number;
  parent: InfusionItem;
  formik: any;
  initialValue?: InfusionSubItem;
  isEmergency?: boolean;
  disabled?: boolean;
  onWeightRange: (range: string, isLow: boolean) => void;
  onAgeRange: (range: string, isLow: boolean) => void;
  onBasisClick?: (basis: string, parent: InfusionItem) => void;
  onMinMaxDoseClick: (
    value: string,
    type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
  ) => void;
  onRoutesClick: (route: string[]) => void;
  onSingleRouteClick: (route: string, isDelete: boolean) => void;
  handleSubmit: (dose: any) => void;
}

/* 10-17-23 Praveen: Added Global component for Dose Section  */
const InfusionDoseSelection: React.FC<DoseSelectionProps> = ({
  formik,
  doseIndex,
  parent,
  onWeightRange,
  onAgeRange,
  onBasisClick,
  onMinMaxDoseClick,
  onRoutesClick,
  onSingleRouteClick,
  handleSubmit,
  initialValue,
  isEmergency,
  disabled,
}) => {
  const isEditable: boolean = disabled !== true;
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const isInfusionCalc = useMemo(() => {
    return database.department.infusionCalculation;
  }, [database.department]);
  const isAgeBased = useMemo(() => {
    return database.department.isAgeFilterEnabled;
  }, [database.department]);
  const [isWarning, setIsWarning] = React.useState(
    formik.values.warning != null && formik.values.warning !== ''
  );
  return (
    <div
      className={`model-input-container  ${
        !isEditable
          ? 'cursorPointerSpan cursorPointerDiv cursorPointerInput cursorPointerLabel'
          : ''
      }`}
      style={{
        padding: '0px 10px',
      }}
    >
      <div className={`${!isEditable && 'doseGridPad'}`}>
        <div className="ketamine-container-pad">
          {/* Range Input */}
          <div
            className=""
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flex: 1,
              gap: '10px',
              marginTop: '6px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '50px',
              }}
            >
              <FaArrowsLeftRightToLine
                style={{
                  fontSize: '1.5rem',
                  color: 'black',
                }}
              />
            </div>
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() => {
                if (isEditable) onWeightRange(formik.values.rangeLow, true);
              }}
            >
              <label htmlFor={''} className={`input-label`}>
                Weight Low (kg)
              </label>
              <div className="p-input-icon-right" data-testid="editRangeLow">
                <div
                  // type="text"
                  className={`form-control pointer ${
                    !isEditable
                      ? !formik.values.rangeLow
                        ? 'greyText'
                        : ''
                      : 'inputHover'
                  }`}
                  id="rangeLow"
                  // name="rangeLow"
                  placeholder="Min"
                  data-testid="rangeLow"
                  style={{
                    color: !formik.values.rangeLow ? '#6B6D70' : '#000000',
                  }}
                >
                  {formik.values.rangeLow
                    ? formik.values.rangeLow + ' kg'
                    : 'Min'}
                </div>
                {isEditable && <HiPencil className="icon-black" />}
              </div>
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Weight High (kg)
              </label>
              <div
                className="p-input-icon-right"
                onClick={() => {
                  if (isEditable) {
                    let rangeHigh = formik.values.rangeHigh
                      ? formik.values.rangeHigh
                      : globals.MAX_VALUE.toLocaleString();
                    onWeightRange(formik.values.rangeHigh, false);
                  }
                }}
              >
                <div
                  // type="text"
                  className={`form-control pointer ${
                    !isEditable
                      ? !formik.values.rangeHigh
                        ? 'greyText'
                        : ''
                      : 'inputHover'
                  }`}
                  id="rangeLow"
                  // name="rangeLow"
                  placeholder="Max"
                  data-testid="rangeLow"
                  style={{
                    color: !formik.values.rangeHigh ? '#6B6D70' : '#000000',
                  }}
                >
                  {formik.values.rangeHigh
                    ? formik?.values?.rangeHigh + ' kg'
                    : 'Max'}
                </div>
                {isEditable && <HiPencil className="icon-black" />}
              </div>
            </div>

            {isAgeBased && (
              <>
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={`input-label`}>
                    Age Low
                  </label>
                  <div
                    className="p-input-icon-right"
                    data-testid="editRangeLow"
                    onClick={() => {
                      if (isEditable) onAgeRange(formik.values.ageLow, true);
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.ageLow
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      placeholder="Min"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.ageLow ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.ageLow
                        ? formik.values.ageLow.age +
                          ' ' +
                          formik.values.ageLow.unit
                        : 'Min'}
                    </div>
                    {isEditable && <HiPencil className="icon-black" />}
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age High
                  </label>
                  <div
                    className="p-input-icon-right"
                    onClick={() => {
                      if (isEditable) {
                        let rangeHigh = formik.values.rangeHigh
                          ? formik.values.rangeHigh
                          : globals.MAX_VALUE.toLocaleString();
                        onAgeRange(formik.values.rangeHigh, false);
                      }
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.rangeHigh
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      placeholder="Max"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.rangeHigh ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.ageHigh
                        ? formik?.values?.ageHigh.age +
                          ' ' +
                          formik?.values?.ageHigh.unit
                        : 'Max'}
                    </div>
                    {isEditable && <HiPencil className="icon-black" />}
                  </div>
                </div>
              </>
            )}
          </div>

          {!isEmergency && (
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '50px',
                }}
              >
                <FaSyringe
                  style={{
                    fontSize: '1.5rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 2,
                }}
                onClick={() => {
                  if (isEditable)
                    onBasisClick?.(formik.values.basis, parent as InfusionItem);
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Calculation Basis
                  <span className="required-field">
                    {isEditable ? '*' : ''}
                  </span>
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control  pointer ${
                      formik.values.basis === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="basis"
                    name="basis"
                    placeholder=" "
                    data-testid="calculationBasisValue"
                    value={formik.values.basis}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable &&
                    formik.values.basis !== '' &&
                    formik.values.basis !== '+Add' && (
                      <HiPencil
                        className="icon-black"
                        style={{ marginRight: '8px' }}
                      />
                    )}
                </div>
              </div>

              {isInfusionCalc && (
                <>
                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Min Dose
                    </label>
                    <div
                      className="p-input-icon-right"
                      onClick={() => {
                        if (isEditable)
                          onMinMaxDoseClick(formik.values.minDose, 'min');
                      }}
                    >
                      <InputText
                        type="text"
                        className={`form-control pointer ${
                          formik.values.minDose !== '+Add'
                            ? ''
                            : 'formAnchorText'
                        } ${!isEditable ? '' : 'inputHover'}`}
                        id="minDose"
                        name="minDose"
                        placeholder="Min"
                        data-testid="minDose"
                        value={formik.values.minDose}
                        onChange={formik.handleChange}
                        readOnly={true}
                        disabled={!isEditable}
                      />
                      {formik.values.minDose !== '+Add' && isEditable && (
                        <HiPencil className="icon-black" />
                      )}
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <label htmlFor={''} className={`input-label`}>
                      Max Dose
                    </label>
                    <div
                      className="p-input-icon-right"
                      onClick={() => {
                        if (isEditable)
                          onMinMaxDoseClick(formik.values.maxDose, 'max');
                      }}
                    >
                      <InputText
                        type="text"
                        className={`form-control pointer ${
                          formik.values.maxDose !== '+Add'
                            ? ''
                            : 'formAnchorText'
                        } ${!isEditable ? '' : 'inputHover'}`}
                        id="maxDose"
                        name="maxDose"
                        data-testid="maxDose"
                        placeholder="Max"
                        value={formik.values.maxDose}
                        onChange={formik.handleChange}
                        readOnly={true}
                        disabled={!isEditable}
                      />
                      {isEditable && formik.values.maxDose !== '+Add' && (
                        <HiPencil className="icon-black" />
                      )}
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <label htmlFor={''} className={`input-label`}>
                      Max Total
                    </label>
                    <div
                      className="p-input-icon-right"
                      onClick={() => {
                        if (isEditable)
                          onMinMaxDoseClick(
                            formik.values.maxTotalDose,
                            'maxTotal'
                          );
                      }}
                    >
                      <InputText
                        type="text"
                        className={`form-control pointer ${
                          formik.values.maxTotalDose !== '+Add'
                            ? ''
                            : 'formAnchorText'
                        } ${!isEditable ? '' : 'inputHover'}`}
                        id="maxDose"
                        name="maxDose"
                        data-testid="maxDose"
                        placeholder="Max"
                        value={formik.values.maxTotalDose}
                        onChange={formik.handleChange}
                        readOnly={true}
                        disabled={!isEditable}
                      />
                      {isEditable && formik.values.maxTotalDose !== '+Add' && (
                        <HiPencil className="icon-black" />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Route Input */}
      {!isEmergency && (
        <div
          className="sidebarInputContent"
          style={{
            paddingBottom: !isEditable ? '10px' : '0px',
          }}
        >
          <label
            htmlFor={isEditable ? 'Route' : ''}
            className={` rowLabelWidth sidebarLabel`}
          >
            Route:{' '}
            <span className="required-field">{isEditable ? '*' : ''}</span>
          </label>
          <div
            data-div-id="outside"
            onClick={() => {
              if (isEditable) onRoutesClick(formik.values.routes);
            }}
            className={`selectionArea-hover ${
              isEditable ? 'cursorPointer ' : 'selectionArea-disable'
            } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {formik.values.routes.length !== 0 && (
              <ViewportList items={formik.values.routes}>
                {(route: string, index: number) => {
                  return (
                    <div
                      data-div-id="grey"
                      onClick={(e) => {
                        if (isEditable) {
                          e.stopPropagation();
                          onSingleRouteClick(route, false);
                        }
                      }}
                      key={index}
                      className={`selectedGreybox ${
                        isEditable && 'cursorPointer'
                      }`}
                    >
                      <div>{route}</div>
                      {!!isEditable && (
                        <span
                          className={`close_icon_color ${
                            isEditable && 'cursorPointer'
                          }`}
                          onClick={(e) => {
                            if (isEditable) {
                              e.stopPropagation();
                              onSingleRouteClick(route, true);
                            }
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      )}
                    </div>
                  );
                }}
              </ViewportList>
            )}
            {formik.values.routes.length !== 0 && !!isEditable && (
              <div data-div-id="edit">
                <HiPencil className="cursorPointer icon-black rightIcon" />
              </div>
            )}
            {formik.values.routes.length === 0 && (
              <div>
                <span className="formisEditable formAnchorText">+Add</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={` ${!isEditable && 'sideoutFieldPad'}`}>
        {isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add a a RED message above where the infusions's name is displayed.`}
              placement="left"
              arrow
            >
              <label
                htmlFor="warning"
                className={`sidebarLabel `}
                style={{
                  color: '#880808',
                }}
              >
                Warning:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <input
                type="text"
                className={`form-control-general `}
                id="warning"
                name="warning"
                data-testid="warning"
                placeholder="Enter Warning here..."
                value={formik.values.warning}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit(formik.values);
                }}
                //disabled={!isEditable}
                onChange={(e: any) => {
                  formik.setFieldValue('warning', e.target.value);
                }}
                disabled={!isEditable}
              />
              <div className="input-border"></div>
            </div>
          </div>
        )}
        {(isEditable || formik.values.title) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={
                isWarning
                  ? `Add a a RED message above where the infusion's name is displayed.`
                  : `Add a message above where the infusion's name is displayed.`
              }
              placement="left"
              arrow
            >
              <label
                htmlFor="Title"
                className={`sidebarLabel `}
                style={{
                  color: isWarning ? '#880808' : 'black',
                }}
              >
                {isWarning ? 'Warning:' : 'Title:'}
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder={`Enter ${isWarning ? 'Warning' : ''} Title here...`}
                  value={
                    isWarning ? formik.values.warning : formik.values.title
                  }
                  //disabled={!isEditable}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  onChange={(e: any) => {
                    if (isWarning)
                      formik.setFieldValue('warning', e.target.value);
                    else formik.setFieldValue('title', e.target.value);
                  }}
                  disabled={!isEditable}
                />
                {isEditable && (
                  <InputSwitch
                    tooltip="Swtich to Warning Title. Gives it red text."
                    tooltipOptions={{
                      position: 'left',
                      style: {
                        fontSize: '12px',
                      },
                    }}
                    name="warningTitle"
                    style={{ marginLeft: '10px' }}
                    checked={isWarning}
                    onChange={(e) => {
                      setIsWarning(e.value);
                      if (e.value) {
                        formik.setFieldValue('warning', formik.values.title);
                        formik.setFieldValue('title', '');
                      } else {
                        formik.setFieldValue('title', formik.values.warning);
                        formik.setFieldValue('warning', '');
                      }
                    }}
                  />
                )}
              </div>
              <div className="input-border"></div>
            </div>
          </div>
        )}
        {(isEditable || formik.values.instruction) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add instructions for the infusion. This will be displayed under the infusion's name.`}
              placement="left"
              arrow
            >
              <label htmlFor="instructions" className={`sidebarLabel `}>
                Instructions:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <input
                type="text"
                className={`form-control-general `}
                id="instruction"
                name="instruction"
                data-testid="instruction"
                placeholder="Add Instructions here..."
                value={formik.values.instruction}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit(formik.values);
                }}
                //disabled={!isEditable}
                onChange={(e: any) => {
                  formik.setFieldValue('instruction', e.target.value);
                }}
                disabled={!isEditable}
              />
              <div className="input-border"></div>
            </div>
          </div>
        )}
        {(isEditable || formik.values.note) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add additional information for the infusion. This will be displayed ONLY in the dropdown when the infusion is expaned.`}
              placement="left"
              arrow
            >
              <label htmlFor="note" className={`sidebarLabel `}>
                Note:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <input
                type="text"
                className={`form-control-general`}
                id="note"
                name="note"
                data-testid="note"
                placeholder="Enter Note here..."
                value={formik.values.note}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit(formik.values);
                }}
                //disabled={!isEditable}
                onChange={(e: any) => {
                  formik.setFieldValue('note', e.target.value);
                }}
                disabled={!isEditable}
              />
              <div className="input-border"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfusionDoseSelection;
