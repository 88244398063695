import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  DatabaseResponse,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import MedicationItem, {
  getConcentrationsArrayString,
  getConcentrationString,
} from '../../../../data/model/MedicationItem';
import SearchBar from '../../../components/Search/SearchBar';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import NewMedicationSideout from '../../../components/SideOut/NewModelItemSideout';
import { fetchMedications } from '../../../../data/functions/MedicationDB';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListMedicaions = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const [totalDoses, setTotalDoses] = useState(-1);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [sort, setSort] = useState('name_asc');
  const department = database.department;
  const [medications, setMedications] = useState<MedicationItem[]>(
    database.medications
  );
  const [isCreateMedication, setIsCreateMedication] = useState(false);

  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filters, setFilters] = useState<any[]>(
    state.search ? state.search.filters : []
  );
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state.search ? state.search.categoriesFilter : []
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'n') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        handleCreateItem();
        // Add your custom logic here
      } else if (event.metaKey && event.key === 'b') handleBack();
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /* TODO Need to redo the mappings */
  const reloadDatabase = async () => {
    const response = await fetchMedications(department, database);
    if (response.type === ResponseType.Success) {
      setMedications(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          medications: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    let count = 0;
    medications.forEach((item) => {
      count += item.subItems.length;
    });
    setTotalDoses(count !== 0 ? count : -1);
  }, [database]);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = medications.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery);
      });
    } else filteredList = medications;
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: MedicationItem, b: MedicationItem) => {
      return a.name.localeCompare(b.name);
    });
    handleSort(filteredList);
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(medications);
  }, [searchQuery, filters, categoriesFilter, sort, medications]);

  const handleSort = (list: MedicationItem[]): MedicationItem[] => {
    const sortedList = list.sort((a, b) => {
      if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'doses_desc') {
        if (a.subItems.length > b.subItems.length) return -1;
        else if (a.subItems.length < b.subItems.length) return 1;
        else return 0;
      } else if (sort === 'doses_asc') {
        if (a.subItems.length < b.subItems.length) return -1;
        else if (a.subItems.length > b.subItems.length) return 1;
        else return 0;
      } else if (!a.model || !b.model) return 0;
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      }
      return 0;
    });
    return sortedList;
  };

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (medication: MedicationItem) => {
    const state = {
      selectedProtocol: null,
      value: medication,
      subValue: null,
      type: 'Medication',
      editType: 'edit',
      editMode: false,
      page: 'listMedicationsPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    setIsCreateMedication(true);
    // const state = {
    //   selectedProtocol: null,
    //   value: null,
    //   subValue: null,
    //   type: 'Medication',
    //   editType: 'new',
    //   editMode: false,
    //   page: 'listMedicationsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  return (
    <div className="screen-container">
      <NewMedicationSideout
        type="medication"
        isVisible={isCreateMedication}
        handleClose={() => setIsCreateMedication(false)}
      />
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Medications: ' + list.length + ' items'}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Medications...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div
                style={rowStyle}
                className="departmentItemNoHover"
                // onMouseEnter={() => setIsHovered(true)}
                // onMouseLeave={() => setIsHovered(false)}
              >
                {/* <h6 className='departmentItemText'>Version</h6> */}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Concentration</h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Modified By</h6>
                {/* {department.subDeps && <h6 className='departmentItemText'>Sub-Deps</h6>} */}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('doses')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'doses_desc') setSort('doses_asc');
                    else setSort('doses_desc');
                  }}
                >
                  Doses{totalDoses !== -1 ? ' (' + totalDoses + ')' : ''}
                  <span>
                    {sort === 'doses_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: MedicationItem, index) => {
              let id = item.model?.modifiedBy;
              if (id == null) id = item.model?.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = database.users.find((user) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div
                    style={rowStyle}
                    className="departmentItem"
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                  >
                    {/* <h6 className='departmentItemText'>{item.version}</h6> */}
                    <h6 className="departmentItemText">
                      {item.name}
                      {item.status !== 'ACTIVE' && (
                        <Status status={item.status} />
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {getConcentrationsArrayString(item.concentrations)}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.model?.updatedAt
                          ? item?.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    {/* {department.subDeps && <h6 className='departmentItemText' style={{color: item.pairedDepIDs?.length ? 'black' : 'grey'}}>{item.pairedDepIDs?.length ? item.pairedDepIDs.length : '-'}</h6>} */}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item.subItems.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListMedicaions;
