import { DataStore, graphqlOperation, Storage } from 'aws-amplify';
import { ResponseType, Response, findDepartmentLogo } from '../AmplifyDB';
import KeychainItem from '../model/KeychainItem';

import { globals } from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import { DepartmentConfigInput, SoftwareType } from '../../API';
import { uniqueId } from 'lodash';
import { executeQuery } from '../GraphQL_API';
import { getDepartment } from '../../graphql/queries';
import {
  Department,
  DepartmentConfig,
  Keychain,
  LazyDepartment,
  User,
} from '../../models';
export type DepartmentJSON = {
  name: string;
  neonateCutoff: number;
  pediatricCutoff: number;
  softwarePlan?: SoftwareType | keyof typeof SoftwareType | null;
  activeStatus: boolean;
  infusionCalculation: boolean;
  realtimeUpdating: boolean;
  oneweightEnabled: boolean;
  ageFilterEnabled: boolean;
  agencyNumEMS: string;
  stateIDEMS: string;
  gnisCode: string;
  location: string;
  departmentCode: string;
  hashedPin: string;
  saltedPin: string;
  logoURI: string;
  isPublic?: boolean;
  keychain?: KeychainItem | null;
};

export const updateDepartmentAccess = async (
  department: DepartmentItem,
  isPublic?: boolean,
  keychain?: KeychainItem,
  override?: boolean
): Promise<Response> => {
  try {
    const dbDep = await DataStore.query(Department, department.id);
    if (!dbDep) {
      return {
        type: ResponseType.Failure,
        data: 'Department does not exist',
      };
    }

    if (!override && keychain && dbDep.keychainID) {
      return {
        type: ResponseType.Failure,
        data: 'Department already has a keychain',
      };
    }

    let newDep = await DataStore.save(
      Department.copyOf(dbDep as Department, (updated) => {
        updated.keychainID = keychain ? keychain.uid : null;
        updated.isPublic = isPublic ? isPublic : false;
      })
    );

    department.keychain = keychain;
    department.keychainID = keychain ? keychain.uid : null;
    department.model = newDep;
    department.isPublic = isPublic ? isPublic : false;

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

export const updateDepartment = async (
  data: DepartmentItem | DepartmentJSON,
  department: DepartmentItem
): Promise<Response> => {
  try {
    let json: DepartmentJSON;
    if (data instanceof DepartmentItem) {
      json = {
        name: data.name,
        neonateCutoff: data.config.neonateCutoff
          ? data.config.neonateCutoff
          : 5,
        pediatricCutoff: data.config.pediatricCutoff
          ? data.config.pediatricCutoff
          : 40,
        softwarePlan: data.model.softwarePlan
          ? data.model.softwarePlan
          : SoftwareType.PREMIUM,
        activeStatus: data.model.activeStatus ? data.model.activeStatus : false,
        agencyNumEMS: data.model.agencyNumEMS ? data.model.agencyNumEMS : '',
        stateIDEMS: data.model.stateIdEMS ? data.model.stateIdEMS : '',
        gnisCode: data.model.gnisCodeEMS ? data.model.gnisCodeEMS : '',
        location: data.location ? data.location : '',
        departmentCode: data.departmentCode,
        hashedPin: data.hashedPin,
        saltedPin: data.salt,
        logoURI: data.model.logoID ? data.model.logoID : '',
        realtimeUpdating: data.isRealTimeEnabled,
        oneweightEnabled: data.isOneWeightEnabled,
        infusionCalculation: data.infusionCalculation,
        ageFilterEnabled: data.isAgeFilterEnabled,
        isPublic: data.isPublic,
        keychain: data.keychain,
      };
    } else {
      json = data;
    }

    const dbDep = await DataStore.query(Department, department.id);
    if (!dbDep) {
      return {
        type: ResponseType.Failure,
        data: 'Department does not exist',
      };
    }

    const config: DepartmentConfig = department.config;
    const newConfig: DepartmentConfig = config
      ? {
          isTopEnabled: config.isTopEnabled,
          neonateCutoff: json.neonateCutoff,
          pediatricCutoff: json.pediatricCutoff,
          calculators: null,
          adultRanges: config.adultRanges ? config.adultRanges : [],
          softwarePlan: json.softwarePlan ? json.softwarePlan : null,
          infusionCalculation: json.infusionCalculation,
          isPublic: config.isPublic,
          realTimeUpdating: json.realtimeUpdating,
          epcrProvider: config.epcrProvider,
          oneweightEnabled: json.oneweightEnabled,
          ageFilterEnabled: json.ageFilterEnabled,
          ageGroupFilterEnabled: config.ageGroupFilterEnabled,
          // maxUsers: config.maxUsers,
        }
      : {
          isTopEnabled: true,
          neonateCutoff: json.neonateCutoff,
          pediatricCutoff: json.pediatricCutoff,
          calculators: null,
          adultRanges: [],
          softwarePlan: json.softwarePlan ? json.softwarePlan : null,
          infusionCalculation: json.infusionCalculation,
          isPublic: false,
          realTimeUpdating: json.realtimeUpdating,
          epcrProvider: null,
          oneweightEnabled: json.oneweightEnabled,
          ageFilterEnabled: json.ageFilterEnabled,
          ageGroupFilterEnabled: false,
          // maxUsers: 10,
        };

    console.log('New Config', newConfig);
    let newDep = await DataStore.save(
      Department.copyOf(dbDep as Department, (updated) => {
        updated.name = json.name;
        updated.softwarePlan = json.softwarePlan;
        updated.activeStatus = json.activeStatus;
        updated.agencyNumEMS = json.agencyNumEMS
          ? json.agencyNumEMS
          : uniqueId();
        updated.stateIdEMS = json.stateIDEMS;
        updated.gnisCodeEMS = json.gnisCode;
        updated.location = json.location;
        updated.uniqueCode = json.departmentCode;
        updated.hashedPin = json.hashedPin;
        updated.saltedPin = json.saltedPin;
        updated.logoID = json.logoURI;
        updated.config = newConfig ? newConfig : dbDep.config;
        updated.isPublic = json.isPublic ? json.isPublic : false;
        updated.keychainID = json.keychain ? json.keychain.uid : null;
      })
    );

    department.model = newDep;
    department.name = newDep.name;
    department.location = newDep.location ? newDep.location : json.location;
    department.departmentCode = newDep.uniqueCode
      ? newDep.uniqueCode
      : json.departmentCode;
    department.hashedPin = newDep.hashedPin ? newDep.hashedPin : json.hashedPin;
    department.salt = newDep.saltedPin ? newDep.saltedPin : json.saltedPin;
    department.logoURL = newDep.logoID ? newDep.logoID : json.logoURI;
    department.config = newConfig;
    department.isRealTimeEnabled = newConfig.realTimeUpdating;
    department.isOneWeightEnabled = newConfig.oneweightEnabled;
    department.infusionCalculation =
      newConfig.infusionCalculation === true ? true : false;
    department.isAgeFilterEnabled =
      newConfig.ageFilterEnabled === true ? true : false;
    department.isPublic = json.isPublic ? json.isPublic : department.isPublic;
    department.keychain = json.keychain ? json.keychain : department.keychain;

    // let logoResult = await findDepartmentLogo(department);
    // if (logoResult.type === ResponseType.Success)
    //   department.logoVerifiedUrl = logoResult.data;

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

/**
 * Upload the new PDF to S3 and return the file path
 * @param department The department that owns the protocol
 * @param parent The parent category of the protocol
 * @param protocolName The name of the protocol
 * @returns Success if the File was uploaded or Failure if there was an error
 */
export const uploadFileToS3 = async (
  department: DepartmentItem,
  file: File,
  errorCallback: (error: any) => void
): Promise<Response> => {
  try {
    const extension = file.name.split('.').pop();
    /* First create the file path -> Public / departmentID / categoryID / protocolID / (version)_(protocol name).pdf*/
    // let name = file.name.replace(/[^a-zA-Z0-9-]/g, '_');
    let filePath = department.id + '/' + file.name;

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-_]/g, '_');

    const type = extension === 'pdf' ? 'application/pdf' : 'image/' + extension;

    /* Make sure all the folders exist in the S3 bucket */
    Storage.put(filePath, file, {
      contentType: type,
      level: 'public',
    })
      .then((result) => {
        if (globals.debug) console.log('DepartmentDB File Upload', result);
      })
      .catch((error) => {
        if (globals.debug) console.log('DepartmentDB File Upload', error);
        errorCallback(error);
      });
    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};
//  * @param department The department that owns the protocol
//  datastore querry to get the department by id
export const getDepartmentByID = async (
  departmentID: string
): Promise<DepartmentItem | undefined> => {
  try {
    const department = await DataStore.query(Department, departmentID);
    if (department) {
      const depItem = new DepartmentItem(department);
      return depItem;
    }
    return undefined;
  } catch (error: any) {
    return undefined;
  }
};

/* ------------------ GRAPH QL API QUEIRES ------------------ */
