import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
} from '../../../_global/common/Utils';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import ContactItem from '../../../../data/model/ContactItem';
import SearchBar from '../../../components/Search/SearchBar';
import KeychainItem from '../../../../data/model/KeychainItem';
import { fetchKeychains } from '../../../../data/functions/KeychainDB';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListKeychains = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [keychains, setKeychains] = useState<KeychainItem[]>(
    database.keychains
  );

  const scrollPosition = useRef(0);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );

  const [list, setList] = useState<any[]>([]);

  const reloadDatabase = async () => {
    const response = await fetchKeychains(department);
    if (response.type === ResponseType.Success) {
      setKeychains(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          keychains: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = database.keychains.filter((item: KeychainItem) => {
        return item.name.toLowerCase().includes(searchQuery);
        // item.weight.toLocaleString().toLowerCase().includes(searchQuery)
      });
    } else filteredList = database.keychains;
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      return a.name.localeCompare(b.name);
    });

    /* Sort the list based on the sort value */
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort.includes('modified')) {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        if (sort === 'modified_desc') return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
      } else return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(keychains);
  }, [searchQuery, keychains, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (obj: KeychainItem) => {
    const state = {
      selectedProtocol: null,
      value: obj,
      subValue: null,
      type: 'KeychainItem',
      editType: 'edit',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/edit/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'KeychainItem',
      editType: 'new',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/new/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '12fr 6fr 6fr 6fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Keychains: ' + list.length + ' items'}
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Keychains...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>{' '}
                <h6 className="departmentItemText">Modified By</h6>
                <h6 className="departmentItemText">Type</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: KeychainItem, index) => {
              let id = item.model?.modifiedBy;
              if (id == null) id = item.model?.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = database.users.find((user) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              let type = 'None';
              if (department.keychainID === item.uid) type = 'Department';
              else {
                for (let i = 0; i < database.categories.length; i++) {
                  if (database.categories[i].keychainID === item.uid) {
                    type = 'Folder and Proocols';
                    break;
                  }
                }
                if (type === 'None') {
                  for (let i = 0; i < database.protocols.length; i++) {
                    if (database.protocols[i].keychainID === item.uid) {
                      type = 'Folder and Protocols';
                      break;
                    }
                  }
                }
              }
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item.model.updatedAt
                          ? item.model.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">{type}</h6>
                    </div>
                    <FaChevronRight
                      className="icon-normal "
                      style={{ margin: '4px' }}
                    />
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListKeychains;
